
import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';

// for Sidebar And Navbar
import Search from '../images/search.png';
import { Link } from 'react-router-dom';
import Logo from '../images/corteva-logo.png';
import Menu from '../images/Menu.png';
import CentralLogo from '../images/CentralLogo.png';
import FarmLogo from '../images/FarmLogo.png';
import Tracking from '../images/Tracking.png';

//for fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

//for modal
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import SideMenu from './SideMenu';
import Header from './Header';
import Footer from './Footer';
import Logout from './Logout';


function CentralStore() {
  // for sidebar And Navbar
  const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");

  const changeStyle = () => {
    if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
    }
    else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    }
  };
  const changeStyle1 = () => {
    if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
    }
    else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    }
  };

  //for data table 
  const tableRef = useRef(null);
  useEffect(() => {
    // Initialize the DataTable on component mount
    $(tableRef.current).DataTable();
  }, []);

  ///modal popup
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
       {/*  <!-- Sidebar --> */}
       <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className='container-fluid'>
                <div className='card border-0'>
                  <div className='card-body pt-0 pl-1'>
                    <h4>Central Store</h4>
                    <div className='d-flex justify-content-between mt-3'>
                      <div><h6>Mapping</h6></div>
                      <Button variant="primary" size="sm" onClick={handleModalOpen}  >
                        <FontAwesomeIcon icon={faPlus} className='plus_icon mr-1' />Add Master</Button>
                    </div>
                    <div className='mcrc_hrline'></div>
                    <div>
                      <div className='card data_tableCard'>
                        <div className='card-body'>
                          <Table className="table table-bordered Content_data_table table-striped" ref={tableRef} >
                            <Thead>
                              <Tr>
                                <Th>S.no</Th>
                                <Th>Role Designation</Th>
                                <Th>Discription</Th>
                                <Th>Status</Th>
                                <Th>Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>Tablescon</Td>
                                <Td>9 April 2019</Td>
                                <Td>Tablescon</Td>
                                <Td>Available</Td>
                                <Td className='text-center'><FontAwesomeIcon icon={faEdit} className='mr-1 
                                edit_delete_icon text-primary' />
                                  <FontAwesomeIcon icon={faTrash} className='ml-1 edit_delete_icon text-danger' /></Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>

                        <div>
                          <Modal show={showModal} onHide={handleModalClose} centered backdrop="static" keyboard={false} size="lg">
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button variant="link" className="close btn btn-danger btn-sm" onClick={handleModalClose}>
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className='pl-5 pr-5'>
                              <div className='row'>
                                <div className='col-md-6 col-12'>
                                  <label for="exampleInputPassword1" class="form-label text-sm">Name</label>
                                  <div className="input-group input-group w-100">
                                    <span className="input-group-text mcrc-input-group-text"
                                      id="inputGroup-sizing-sm">
                                      <img src={Search} alt='search' className='search_image'></img>
                                    </span>
                                    <input type="text" className="form-control p-2" placeholder='Name' />
                                  </div>
                                </div>
                                <div className='col-md-6 col-12'>
                                  <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label text-sm">Discription</label>
                                    <input type="textarea" class="form-control" id="exampleInputPassword1" />
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button variant="primary" onClick={handleModalClose} className="d-flex justify-content-center submit_button">
                                  Submit
                                </Button>
                              </div>
                            </Modal.Body>

                          </Modal>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Your Website 2021</span>
              </div>
            </div>
          </footer>
          {/* ... End of Footer...  */}

        </div>

      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

     {/*  <!-- Logout Modal--> */}
     <Logout />

    </body>
  )
}

export default CentralStore
