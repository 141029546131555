import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import UploadFile from "../images/uploadfile.png";

import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import Logout from "./Logout";

//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faEdit,
  faPlus,
  faEye,
  faL,
} from "@fortawesome/free-solid-svg-icons";

//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";

// Date
import "react-datepicker/dist/react-datepicker.css";
import {
  getUserMaster,
  addUserMaster,
  getUserTypeMaster,
  getRoleMaster,
  getUserSupportedMasters,
  resetPassword,
  getMasters,
  getManagerBasedOnCostCenter,
  getFunctionalLeadBasedOnCostCenter,
  getCostCenterBasedOnFunctionalLead,
  getEmployeeBasedOnManager,
  getEmployeeDetailsBasedOnName,
  getMainCropFunctionBasedOnEmployeeName,
  getSubCropFunctionBasedOnEmployeeName
} from "../Utilities/URLCONSTANTS.js";
// import { Oval as Loader } from 'react-loader-spinner';
import Loader from "../Utilities/Loader";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import moment from "moment";
// import Swal from 'sweetalert2';
import * as XLSX from "xlsx"; // Import XLSX as an object
import { json } from "react-router-dom";
import { setDate } from "date-fns";
import { Alexa } from "react-bootstrap-icons";

//UserMaster

const UserMaster = () => {
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [Id, setId] = useState(0);
  const [userType, setUserType] = useState("");

  const [alternateMobileNo, setAlternateMobileNo] = useState("");
  const [errorfutureDate, setErrorfutureDate] = useState("");
  const [dob, setDob] = useState();
  const [mobileNumber, setMobileNumber] = useState("");
  // const [error, setError] = useState('');//  for mobile nbr
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");

  //Email
  const [emailId, setEmailId] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const [showPopup, setShowPopup] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  //const [resetpassword, setResetPassword] = useState("");

  //Errors

  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorUserType, setErrorUserType] = useState("");
  const [errorRoleType, setErrorRoleType] = useState("");
  const [errorMobileNumber, setErrorMobileNumber] = useState("");
  const [errorDob, setErrorDob] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorAlternateMobileNumber, setErrorAlternateMobileNumber] =
    useState("");
  const [errorEmailId, setErrorEmailId] = useState("");
  const [errorGender, setErrorGender] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [roleType, setRoleType] = useState("");
  const [roleTypeOptions, setRoleTypeOptions] = useState([]);

  const [location, setLocation] = useState("");
  const [errorLocation, setErrorLocation] = useState("");
  const [locationTypeOptions, setLocationTypeOptions] = useState([]);
  const [subLocationTypeOptions, setSubLocationTypeOptions] = useState([]);

  const [costcenterID, setCostCenterID] = useState("");
  const [errorCostCenter, setErrorCostCenter] = useState("");
  const [costCenterOptions, setCostCenterOptions] = useState([]);

  const [cropID, setCropID] = useState();
  const [cropName, setCropName] = useState()
  const [subCropName, setSubCropName] = useState()
  const [errorCrop, setErrorCrop] = useState("");
  const [cropTypeOptions, setCropTypeOptions] = useState([]);

  const [subCropID, setSubCropID] = useState();
  const [errorSubCrop, setErrorSubCrop] = useState("");
  const [subCropTypeOptions, setSubCropTypeOptions] = useState([]);

  const [manager, setManager] = useState("");
  const [errorManager, setErrorManager] = useState("");
  const [managerTypeOptions, setManagerTypeOptions] = useState([]);

  const [profilePicUrl, setprofilePicUrl] = useState("");
  const [profilePicUrlShow, setprofilePicUrlShow] = useState("");
  const [errorProfilePicURL, setErrorProfilePicURL] = useState("");
  const [dmydob, setdmydob] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [functionalLead, setFunctionalLead] = useState([]);
  const [errfunctionalLead, setErrFunctionalLead] = useState("");
  const [sublocation, setSublocation] = useState();
  const [errSubLocation, setErrSubLocation] = useState("");
  const [fetchMobile, setFetchMobile] = useState([])

  const [modalData, setModalData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    userType: "",
    roleType: "",
    mobileNumber: "",
    dob: "",
    address: "",
    alternateMobileNo: "",
    emailId: "",
    gender: "",
    status: "",
  });

  const [data, setData] = useState([]);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/cs_loader.gif")
  );
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [newDateOfJoining, setNewDateOfJoining] = useState();
  const [errDateOfJoining, setErrDateOfJoining] = useState("");
  const [costName, setCostName] = useState();
  const [functionalLeadName, setFunctionalLeadName] = useState();
  const [getEmployee, setGetEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [subLocationId, setSubLocationId] = useState();
  const [locationId, setLocationId] = useState();
  

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>{
          return( 
          item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.managerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.userType.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.roleTypeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.locationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.costCenterName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.cropName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.subCropName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.dob.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.alternateMobileNo
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.emailId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.profilePicUrl.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.emailId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.status.toLowerCase().includes(searchTerm.toLowerCase())
          )
        });
      setFilteredData(filteredResults);
      handlePageClick(1);
    }
  };
  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };
  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const handleEmployee = (e) => {
    setSelectedEmployee(e.target.value);
    if (e.target.value) {
      setErrorFirstName("");
    }
  };
  const fetchCropType = async ()=>{ 
    const apiFetch = getMainCropFunctionBasedOnEmployeeName;
    const headers = await GetApiHeaders();
    const postData ={
      employeeName : selectedEmployee,
      functionalLead : functionalLeadName,
      manager :manager
    }
    try {
      const response = await axios.post(apiFetch, postData, {headers})
      console.log("fetchcroptype", response);
      setCropTypeOptions(response.data.response)
    } catch (error) {
      console.log(error);
    } 
  }
  useEffect(() => { 
      fetchCropType(); 
  }, [selectedEmployee]);
  const fetchSubCropType = async () =>{ 
    const apisubcrop = getSubCropFunctionBasedOnEmployeeName;
    const headers = await GetApiHeaders();
    const post = {
      cropName : cropName,
      cropID : cropID
    }
    try {
      const response = await axios.post(apisubcrop, post , {headers})
      setSubCropTypeOptions(response.data.response)
      console.log(response,'subcrop');
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchSubCropType();
  }, [ cropID]);

 
  const fetchEmployee = async () => { 
    const apiEmployee = getEmployeeBasedOnManager;
    const headers = await GetApiHeaders();
    const postData = {
      functionalLead: functionalLeadName,
      name: costcenterID,
      manager: manager,
    };
    try {
      const response = await axios.post(apiEmployee, postData, { headers });
      console.log("employee Data==>", response);
      if (response.data.response) {
        setGetEmployee(response.data.response);
      } else {
        console.log("Response is null or undefined");
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(getEmployee +"emple");
  useEffect(() => {
    fetchEmployee();
  }, [costcenterID, functionalLeadName, manager]);

  const fetchManager = async () => {
    const managerApi = getManagerBasedOnCostCenter;
    const headers = await GetApiHeaders();
    const postData = {
      name: costcenterID,
      functionalLead: functionalLeadName,
    };
    try {
      const response = await axios.post(managerApi, postData, { headers });
      console.log("managerResponse", response);
      if (response.data.response) {
        setManagerTypeOptions(response.data.response);
      } else {
        console.log("Response is null or undefined");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchManager();
  }, [costcenterID, functionalLeadName]);

  const fetchFunctionalLead = async () => {
    const functionalLeadApi = getCostCenterBasedOnFunctionalLead;
    const headers = await GetApiHeaders();
    const postData = {
      functionalLead: functionalLeadName,
    };
    try {
      const response = await axios.post(functionalLeadApi, postData, {
        headers,
      });
      console.log("response=ttt=>", response);
      setCostCenterOptions(response.data.response);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (functionalLeadName) {
      fetchFunctionalLead();
    }
  }, [functionalLeadName]);

  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "firstName",
        "lastName",
        "managerName",
        "userType",
        "roleTypeName",
        "locationName",
        "costCenterName",
        "cropName",
        "subCropName",
        "mobileNumber",
        "dob",
        "dateOfJoining",
        "address",
        "alternateMobileNo",
        "emailId",
        "gender",
        "profilePicUrl",
        "Status",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item) => [
        item.id,
        item.firstName,
        item.lastName,
        item.managerName,
        item.userType === "1"
          ? "Requester"
          : item.userType === "2"
          ? "acceptor"
          : "none",
        item.roleTypeName,
        item.locationName,
        item.costCenterName,
        item.cropName,
        item.subCropName,
        item.mobileNumber,
        item.dob,
        item.dateOfJoining,
        item.address,
        item.alternateMobileNo,
        item.emailId,
        item.gender,
        item.profilePicUrl,
        item.status === "1" ? "Active" : "InActive",
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "UserMaster.xlsx");
  };
  const handleFirstNameChange = (e) => {
    const input = e.target.value;
    if (input.length > 0) {
      setErrorFirstName("");
    }
    const result = input.replace(/[^a-z_\s]/gi, "");

    setFirstName(result);
  };
  const handleLastNameChange = (e) => {
    const Name = e.target.value;
    if (Name.length > 0) {
      setErrorLastName("");
    }
    const result = Name.replace(/[^a-z_\s]/gi, "");

    setLastName(result);
  };
  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
    const userInput = e.target.value;
    if (userInput.length > 0) {
      setErrorUserType("");
    }
    if (!isNaN(userInput)) {
      setUserType(userInput);
    }
  };
  const handleRoleTypeChange = (e) => {
    setRoleType(e.target.value);
    const roleType = e.target.value;
    if (roleType.length > 0) {
      setErrorRoleType("");
    }
    if (!isNaN(roleType)) {
      setRoleType(roleType);
    }
  };
  const handleLocationTypeChange = (e) => {
    setLocation(e.target.value);
    const selectedLocationId =
      e.target.options[e.target.selectedIndex].getAttribute("data-locationId");
    setLocationId(selectedLocationId);
    const location = e.target.value;
    if (location.length > 0) {
      setErrorLocation("");
    }
    if (!isNaN(location)) {
      setLocation(location);
    }
  };
  const handleSubLocation = (e) => {
    setSublocation(e.target.value);
    const selectedSublocationId =
      e.target.options[e.target.selectedIndex].getAttribute(
        "data-sublocationId"
      );
    setSubLocationId(selectedSublocationId);
    if (e.target.value) {
      setErrSubLocation("");
    }
  };
  const handleCostCenterTypeChange = (e) => {
    setCostCenterID(e.target.value);
    const Center = e.target.value;
    const selectedcostCenter =
      e.target.options[e.target.selectedIndex].getAttribute(
        "data-costcentrName"
      );
    console.log("con", selectedcostCenter);
    setCostName(selectedcostCenter);
    if (Center.length > 0) {
      setErrorCostCenter("");
    }
    if (!isNaN(Center)) {
      setCostCenterID(Center);
    }
  };

  const handleFunctionalLead = (e) => {
    setFunctionalLeadName(e.target.value);
    if (e.target.value) {
      setErrFunctionalLead("");
    }
  };

  const handleCropTypeChange = (e) => {
    setCropID(e.target.value);
    const cropName = e.target.options[e.target.selectedIndex].getAttribute('data-cropName');
    setCropName(cropName || ""); // Set to an empty string if data-cropName is null
    const crops = e.target.value;
    if (crops.length > 0) {
      setErrorCrop("");
    }
    if (!isNaN(crops)) {
      setCropID(crops);
    }
  };
  const handleSubCropTypeChange = (e) => {
    setSubCropID(e.target.value);
    const subcrops = e.target.value;
    const subcropName = e.target.options[e.target.selectedIndex].getAttribute('data-subcropName')
    setSubCropName(subcropName)
    if (subcrops.length > 0) {
      setErrorSubCrop("");
    }
    if (!isNaN(subcrops)) {
      setSubCropID(subcrops);
    }
  }; 
  const handleManagerTypeChange = (e) => {
    setManager(e.target.value);
    const managers = e.target.value;
    if (managers.length > 0) {
      setErrorManager("");
    }
    if (!isNaN(managers)) {
      setManager(managers);
    }
  };
  const handleMobileNbr = (e) => {
    const Input = e.target.value;

    if (Input.length > 0) {
      setErrorMobileNumber("");
    }
    let result = Input.replace(/[^0-9]/g, ""); // Remove non-digit characters

    if (result.length > 10) {
      // Trim the number to 10 digits
      result = result.substr(0, 10);
    }
    if (Input.length < 10) {
      setErrorMobileNumber("Please Enter 10 digit mobile number");
    }

    if (/^[6-9]/.test(result)) {
      // Check if the number starts with 6, 7, 8, or 9
      setMobileNumber(result);
      // setError(""); // Clear any previous error message
    } else {
      setMobileNumber(""); // Clear the mobile number if it doesn't meet the criteria
    }
  };
 

  const handleDateChange = (event) => {
    const newDob = event.target.value; //alert(newDob);
    const dateParts = newDob.split("-");

    if (dateParts.length === 3) {
      const selectedDateObj = new Date(newDob);
      const currentDate = new Date();
      if (selectedDateObj <= currentDate) {
        // Format the date as DD-MM-YYYY
        const yyyy = selectedDateObj.getFullYear();
        let mm = selectedDateObj.getMonth() + 1; // Months start at 0!
        let dd = selectedDateObj.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        setDob(newDob);
        setdmydob(dd + "-" + mm + "-" + yyyy);
        //alert(dd + '-' + mm + '-' + yyyy);
        setErrorDob("");
        setErrorfutureDate("");
      } else {
        setErrorfutureDate("Date of birth cannot be a future date");
        setDob("");
      }
      if (newDob.length === 0) {
        setErrorDob("Date of birth is required");
      } else {
        setErrorDob("");
      }
    } else {
      setErrorDob("Invalid date format (DD-MM-YYYY)");
      setErrorfutureDate("");
    }
  };
 

  const handleDateOfJoining = (e) => {
    const joiningDate = e.target.value;
    if (!joiningDate) {
      setErrDateOfJoining("Please select a date.");
      return;
    }
  
    const selectedDateObj = new Date(joiningDate);
    const yyyy = selectedDateObj.getFullYear();
    let mm = selectedDateObj.getMonth() + 1; // Months start at 0!
    let dd = selectedDateObj.getDate();
  
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
  
    setDateOfJoining(joiningDate);
    setNewDateOfJoining(dd + "-" + mm + "-" + yyyy);
  
    setErrDateOfJoining("");
    if (joiningDate) {
      const formattedSubmittedDate = moment(joiningDate).format("YYYY-MM-DD");
      console.log("Formatted Submitted Date:", formattedSubmittedDate);
    }
  };
   
  const handleAddressChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 0) {
      setErrorAddress("");
    }
    const regex = /^[a-zA-Z0-9!@#$%^&*()_+=[\]{};':"|,.<>/? ]+$/;
    if (regex.test(inputValue)) {
      setAddress(inputValue);
    } else {
      // Handle invalid input, such as displaying an error message
      console.log("Invalid address format");
    }
  };
  const handleGenderChange = (event) => {
    const selectedGender = event.target.value;
    if (selectedGender.length > 0) {
      setErrorGender("");
    }
    if (/^(male|female)$/i.test(selectedGender)) {
      setGender(selectedGender);
    } else {
      // Handle invalid gender value, such as displaying an error message or resetting the value.
    }
  };
  const handleChangeAlterMobileNumber = (e) => {
    const input = e.target.value;
    if (input.length > 0) {
      setErrorAlternateMobileNumber("");
    }
    let result = input.replace(/[^0-9]/g, ""); // Remove non-digit characters
    if (result.length > 10) {
      // Trim the number to 10 digits
      result = result.substr(0, 10);
    }
    if (/^[6-9]/.test(result)) {
      // Check if the number starts with 6, 7, 8, or 9
      setAlternateMobileNo(result);
    }
  };
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    if (inputEmail.length > 0) {
      setErrorEmailId("");
    }
    setEmailId(inputEmail);
    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputEmail)) {
      setErrorEmailId("Invalid email address");
    }
  };

  const handleFileChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        setprofilePicUrlShow(reader.result);
        // Remove the data URL prefix
        const base64 = dataURL.split(",")[1];
        setprofilePicUrl(base64);
        setErrorProfilePicURL(""); // Clear any previous error
      };
      reader.onerror = () => {
        setErrorProfilePicURL("Error reading the selected image.");
      };
      reader.readAsDataURL(selectedImage);
    }
  };
 

  const handleStatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value) {
      setErrorStatus("");
    }
  };
  const handleSubmit = () => {
    if (firstName === "") {
      setErrorFirstName("Please select Employee");
    }
    if (!functionalLeadName) {
      setErrFunctionalLead("Please Select Functional Lead");
    }
    if (manager === "") {
      setErrorManager("Please Select  Manager");
    }
    if (userType === "") {
      setErrorUserType("Please Select user Type");
    }

    if (roleType === "") {
      setErrorRoleType("Please Select RoleType");
    }
    if (location === "") {
      setErrorLocation("Please Select Location");
    }
    if (costcenterID === "") {
      setErrorCostCenter("Please Select Cost Center");
    }

    if (cropID === "") {
      setErrorCrop("Please Select Crop");
    }
    if (subCropID === "") {
      setErrorSubCrop("Please Select SubCrop");
    }

    if (mobileNumber === "") {
      setErrorMobileNumber("Please Enter mobile Number");
    }
    if (dob === "") {
      setErrorDob("Please Select Dob");
    }
    if (address === "") {
      setErrorAddress("Please Enter  Address");
    }

    if (buttonText === "Submit" && profilePicUrl === "") {
      setErrorProfilePicURL("Please Upload Profile PicURL");
    }
    if (dateOfJoining === "") {
      setErrDateOfJoining("Please Select Date");
    }
    if (emailId === "") {
      setErrorEmailId("Please Enter  EmailId");
    }
    if (gender === "") {
      setErrorGender("Please Select Gender");
    }
    if (status === "") {
      setErrorStatus("Please Select Status");
    }
    if (!sublocation) {
      setErrSubLocation("Please Select Sub Location");
    }

    if (
      userType !== "" &&
      roleType !== "" &&
      location !== "" &&
      functionalLead !== "" &&
      costcenterID !== "" &&
      cropID !== "" &&
      subCropID !== "" &&
      manager !== "" &&
      mobileNumber !== "" &&
      dob !== "" &&
      dateOfJoining !== "" &&
      address !== "" &&
      emailId !== "" &&
      gender !== "" &&
      sublocation !== "" &&
      status !== ""
    ) {
      if (Id === 0) {
        handlePostData();
        setIsSubmitted(true);
        setErrorFirstName("");
        setErrorUserType("");
        setErrorRoleType("");
        setErrorDob("");
        setErrSubLocation("");
        setErrorAddress("");
        setErrFunctionalLead("");
        setErrorEmailId("");
        setErrorMobileNumber("");
        setErrDateOfJoining("");
        setErrorGender("");
        setErrorStatus("");
        setErrorLocation("");
        setErrorCostCenter("");
        setErrorCrop("");
        setErrorSubCrop("");
        setErrorProfilePicURL("");
        setErrorManager("");
        setErrorAlternateMobileNumber("");
      } else {
        //  alert("update"+Id)
        setIsSubmitted(false);
        // handleUpdate();
        handlePostData();
        setIsUpdated(true);
      }
    }
  };
  const fetchData = async () => {
    // setLoading(true);
    const apiUrl = getUserMaster;
    const headers = await GetApiHeaders();
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(apiUrl, { headers })
        .then((response) => {
          setIsLoading(false);
          console.log("response", response);
          setData(response.data.response.UserMaster);
          // setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    }, 900);
  };
  useEffect(() => {
    fetchData(); // Fetch data immediately
    setTimeout(() => {
      getRole();
    }, 2000);
  }, []);
  const handlePostData = async () => {
    const apiUrl = addUserMaster;
    const headers = await GetApiHeaders();
    const postData = {
      Id: Id === 0 ? "" : Id.toString(),
      firstName: selectedEmployee,
      lastName: "",
      roleType: roleType.toString(),
      mobileNumber: mobileNumber,
      dob: dmydob,
      dateOfJoining: newDateOfJoining,
      address: address,
      alternateMobileNo: alternateMobileNo,
      emailId: emailId,
      gender: gender,
      status: status,
      locationName: location,
      subLocationName: sublocation,
      subLocationId: subLocationId,
      userType: userType,
      functionalLead: functionalLeadName,
      locationId: locationId,
      costcenterID: costcenterID.toString(),
      cropID: parseInt(cropID) ,
      cropName : cropName,
      subCropID: subCropID  ,
      subCropName :subCropName,
      profilePicUrl: profilePicUrl.toString(),
      manager: manager.toString(),
    };
    console.log("postD",postData);
    setLoading(true);
    axios
      .post(apiUrl, postData, { headers })
      .then((response) => {
        setsubmitResponse(response.data.response);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);
        console.log("submit Response:", response);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };
  const apiURL = getUserTypeMaster;
  useEffect(() => {
    setLoading(true);
    axios
      .get(apiURL)
      .then((response) => {
        setDynamicOptions(response.data.response.UserTypeMaster);

        setLoading(false);
        console.log("setDynamicOptions", response);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching dropdown options:", error);
      });
  }, [apiURL]);
  const getRole = async () => {
    const apiUrl = getRoleMaster;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    setIsLoading(true);
    setTimeout(() => {
      setLoading(true);
      axios
        .get(apiUrl, { headers })
        .then((response) => {
          setIsLoading(false);
          console.log("responseffff", response);
          setRoleTypeOptions(response.data.response.roleMaster);
          setLoading(false);
          console.log("setRoleTypeOptions", response);
        })

        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching data:", error);
        });
    }, 900);
  };
  useEffect(() => {
    getSupportedMasters();
    getMasterData();
  }, []);
  const getMasterData = async () => {
    const apiGetmaster = getMasters;
    const headers = await GetApiHeaders();
    try {
      const response = await axios.post(apiGetmaster, {}, { headers });
      setLocationTypeOptions(response.data.response.mainStoreLocation);
      setSubLocationTypeOptions(response.data.response.storeLocation);
      console.log(
        "getmaster response---D----->",
        response.data.response.mainStoreLocation
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getSupportedMasters = async () => {
    const apiUrl = getUserSupportedMasters; // Replace with your actual API endpoint
    const headers = await GetApiHeaders();
    setIsLoading(true);
    setTimeout(() => {
      setLoading(true);
      axios
        .get(apiUrl, { headers })

        .then((response) => {
          setIsLoading(false);
          console.log("getSupportedMasters", response);
          // setLocationTypeOptions(response.data.response.locations);
          setFunctionalLead(response.data.response.functionalLead);
          // setCropTypeOptions(response.data.response.crop);
          // setSubCropTypeOptions(response.data.response.subCrop);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching data:", error);
        });
    }, 900);
  };
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
  }, []);
  const handleFaEyeIconClick = async (item) => {
    if (!showResetForm) {
      setShowResetForm(true);
      setSelectedItem(item);
    }
  };
  const handleResetOpern = async (item) => {
    if (!showPasswordPopup) {
      setShowPasswordPopup(true);
      setSelectedItem(item);
    }
  };
  const handleResetPassword = async (selectedItem) => {
    console.log(JSON.stringify(selectedItem));
    const apiUrl = resetPassword;
    const postData = {
      Id: selectedItem.id,
    };
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const response = await axios.post(apiUrl, postData, { headers });
      console.log("checking resetp", response);
      setsubmitResponse(response.data.message);
      setShowResetForm(false);
      setShowModal(false);
      setShowResponsePopup(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleReset = async (selectedItem) => {
    console.log(JSON.stringify(selectedItem));
    const apiUrl = resetPassword;
    const postData = {
      Id: "0",
    };
    const headers = await GetApiHeaders();
    try {
      const response = await axios.post(apiUrl, postData, { headers });
      console.log("checking resetp", response);
      setsubmitResponse(response.data.message);
      setShowPasswordPopup(false);
      setShowModal(false);
      setShowResponsePopup(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleModalOpen = () => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId(0);
    setFirstName("");
    setUserType("");
    setRoleType("");
    setLocation("");
    setCostCenterID("");
    setCropID("");
    setSubCropID("");
    setMobileNumber("");
    setAddress("");
    setGender("");
    setDob("");
    setAlternateMobileNo("");
    setEmailId("");
    setLocation("");
    setManager("");
    setprofilePicUrl("");
    setDateOfJoining("");
    setModalData({
      id: "",
      firstName: "",
      lastName: "",
      userType: "",
      roleType: "",
      mobileNumber: "",
      dob: "",
      dateOfJoining: "",
      address: "",
      alternateMobileNo: "",
      emailId: "",
      gender: "",
      status: "",
    });
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };
  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowWarningPopup(false);
    // setShowMsg('');
  };
  const handleCancel_new = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(true);
  };
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleModalClose = () => {
    setShowModal(false);

    //  window.location.reload();
  };
  const updatedItems = (item) => {
    if (!item) {
      console.error("Item is undefined or null.");
      return;
    }
    console.log("item  check", item);
    setModalData(item);
    setId(item.id);
    // setFirstName(item.firstName);
    setUserType(item.userType);
    setRoleType(item.roleType);
    setMobileNumber(item.mobileNumber);
    const newDob = item.dob;
    let text = newDob;
    let dt = text.substring(0, 2);
    let mt = text.substring(3, 5);
    let yt = text.substring(6, 11);
    console.log("dob", yt + "-" + mt + "-" + dt);
    setDob(yt + "-" + mt + "-" + dt);

    setAddress(item.address);
    // setNewDateOfJoining(item.dateOfJoining);
    const newDateJoin = item.dateOfJoining;
    let text1 = newDateJoin;
    let date = text1.substring(0, 2);
    let month = text1.substring(3, 5);
    let year = text1.substring(6, 11);
    // setDateOfJoining(year + "-" + month + "-" + date);
    setDateOfJoining(year + "-" + month + "-" + date);
    setGender(
      item.gender == "Female" || item.gender == "female"
        ? "female"
        : item.gender == "Male" || item.gender == "male"
        ? "male"
        : ""
    );
    setLocation(item.locationName);
    setLocationId(item.locationId.toString());
    setSublocation(item.subLocationName);
    setSubLocationId(item.subLocationId.toString());
    setdmydob(item.dob);
    setNewDateOfJoining(item.dateOfJoining);
    setCostCenterID(item.costCenterID);
    setCropID(item.cropID);
    setCropName(item.cropName)
    setSubCropID(item.subCropID);
    setSubCropName(item.subCropName)
    setFunctionalLeadName(item.functionalLead);
    setManager(item.manager);
    setSelectedEmployee(item.firstName);
    setprofilePicUrlShow(item.profilePicUrl);
    setAlternateMobileNo(item.alternateMobileNo);
    setEmailId(item.emailId);
    setStatus(item.status);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(false);
  };

  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const fetchMobileNo = async () => { 
    const apiUrl = getEmployeeDetailsBasedOnName;
    const headers = await GetApiHeaders();
    const postData = {
      employeeName: selectedEmployee,
    };
    try {
      const respons = await axios.post(apiUrl, postData, { headers });
      console.log("respons----------->", respons.data.response);
      setFetchMobile(respons.data.response[0].mobileNumber);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (selectedEmployee) {
      fetchMobileNo();
    }
  }, [selectedEmployee]);
  useEffect(() => { 
    if (fetchMobile) {
      setMobileNumber(fetchMobile);
    }
  }, [fetchMobile]);
  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">User Management</h5>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text">User Master</h6>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Add User Master"
                      >
                        <Button
                          variant="primary"
                          className="buttons mr-1"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingLeft: 25, paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          {/* Add more options as needed */}
                        </select>
                        &nbsp;&nbsp;
                        <label> entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            placeholder="🔍 Type to Search..."
                            value={searchTerm}
                            onChange={(e) => handleFilter(e.target.value)}
                            style={{
                              height: "36px",
                              width: "160px",
                              paddingRight: "30px",
                            }}
                            className="mr-2"
                          />
                          {searchTerm && (
                            <button
                              className="btn btn-link"
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                zIndex: 1,
                              }}
                              onClick={() => handleFilter("")}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          )}
                        </div>
                        &nbsp;
                        {/* Add the Excel export button */}
                        <button
                          className="btn btn-secondary buttons-excel buttons-html5"
                          onClick={exportToExcel}
                        >
                          Download Excel
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="card data_tableCard">
                        <div
                          className="card-body"
                          style={{ overflowX: "auto" }}
                        >
                          <Table className="table table-bordered Content_data_table table-striped">
                            <Thead>
                              <Tr>
                                <Th className="text-center">Id</Th>
                                <Th className="text-center">Functional Lead</Th>
                                <Th className="text-center">Cost Center</Th>
                                <Th className="text-center">Manager</Th>
                                <Th className="text-center">Employee</Th>
                                <Th className="text-center">User Type</Th>
                                <Th className="text-center">Role Type</Th>
                                <Th className="text-center">Location Name</Th>
                                <Th className="text-center">Sub Location</Th>

                                <Th className="text-center">Crop</Th>
                                <Th className="text-center">Sub Crop</Th>
                                <Th className="text-center">Mobile Number</Th>
                                <Th className="text-center">DOB</Th>
                                <Th className="text-center">Date of Joining</Th>
                                <Th className="text-center">Address</Th>
                                <Th className="text-center">
                                  Alternate Mobile Number
                                </Th>
                                <Th className="text-center">Email ID</Th>
                                <Th className="text-center">Gender</Th>
                                <Th className="text-center">Profile Pic URL</Th>
                                <Th className="text-center">Status</Th>
                                <Th className="text-center">Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {currentItems.length === 0 ? ( // Check if no data is found
                                <Tr>
                                  <Td
                                    colSpan={19}
                                    className="text-center"
                                    style={{ padding: 195, fontSize: 18 }}
                                  >
                                    Data is not available
                                  </Td>
                                </Tr>
                              ) : (
                                currentItems != undefined &&
                                currentItems != null &&
                                currentItems.map((item, i) => {
                                  var parsedDate = moment(  item.dob, "DD-MM-YYYY" );
                                  var dob =parsedDate.isValid() ? parsedDate.format("DD-MMM-YYYY") : "";
                                  var joiningDate = moment( item.dateOfJoining,  "DD-MM-YYYY"  );
                                  var dateJoining = joiningDate.isValid()? joiningDate.format("DD-MMM-YYYY") : '';

                                  return (
                                    <Tr  >
                                      <Td className="text-left ">{(currentPage - 1) * itemsPerPage + i + 1}</Td>
                                      <Td className="text-center ">
                                        {" "}
                                        {item.functionalLead}{" "}
                                      </Td>
                                      <Td className="text-center">
                                        {item.costCenterName}
                                      </Td>
                                      <Td>{item.managerName}</Td>
                                      <Td className="text-center">
                                        {item.firstName}
                                      </Td>

                                      <Td>
                                        {item.userType === "1"
                                          ? "Requester"
                                          : item.userType === "2"
                                          ? "acceptor"
                                          : item.userType === "3"
                                          ? "Technician"
                                          : "none"}
                                      </Td>
                                      <Td>{item.roleTypeName}</Td>
                                      <Td>{item.locationName}</Td>
                                      <Td>{item.subLocationName}</Td>

                                      <Td>{item.cropName}</Td>
                                      <Td>{item.subCropName}</Td>
                                      <Td className="text-end">
                                        {item.mobileNumber}
                                      </Td>
                                      <Td>{dob}</Td>
                                      <Td>{dateJoining}</Td>
                                      <Td className="text-center">
                                        {item.address}
                                      </Td>
                                      <Td className="text-end">
                                        {item.alternateMobileNo}
                                      </Td>
                                      <Td>{item.emailId}</Td>
                                      <Td>{item.gender}</Td>
                                      <Td>
                                        {/* <img
                                          src={item.profilePicUrl}
                                          sizes="20px"
                                          style={{ width: 30, innerHeight: 40 }}
                                        />{" "} */}
                                        <img
                                          src={item.profilePicUrl}
                                          style={{
                                            width: "30px",
                                            height: "40px",
                                            cursor: "pointer",
                                            border: "1px solid black",
                                          }}
                                          onClick={() =>
                                            handleImageClick(item.profilePicUrl)
                                          }
                                        />
                                      </Td>
                                      <Td className="text-center">
                                        {item.status === "1" ? (
                                          <span style={{ color: "#2CA800" }}>
                                            Active
                                          </span>
                                        ) : (
                                          <span style={{ color: "#FF0000" }}>
                                            In Active
                                          </span>
                                        )}
                                      </Td>
                                      <Td className="text-center">
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faEdit}
                                            className="plus_icon mr-1 text-primary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Edit User Master"
                                            onClick={() => updatedItems(item)} // Handle the edit button click
                                          />
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="plus_icon mr-1 text-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Reset Password"
                                            onClick={() =>
                                              handleFaEyeIconClick(item)
                                            } // Handle the reset password button click
                                          />
                                          {/* Add button content here */}
                                        </div>
                                      </Td>
                                    </Tr>
                                    // }
                                  );
                                })
                              )}
                            </Tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                                loderimage={loaderImage}
                              />
                            )}
                          </Table>
                        </div>
                        {/* Pagination */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ borderRadius: 5 }}
                        >
                          <div>
                          Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp; of {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className={`borderleft-radius-5 ${
                                  currentPage === 1 ? "active" : ""
                                }`}
                                //  className='borderleft-radius-5'
                                // className='ul-pagination'
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Prev
                              </button>
                              <button
                                //  className='ul-pagination page-item'
                                //   style={{
                                //     backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                //     color: (currentPage == 1) ? 'white' : 'black'
                                //   }}
                                className={`ul-pagination page-item ${
                                  currentPage === 1 ? "active" : ""
                                }`}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className={`ul-pagination page-item ${
                                    currentPage === number ? "active" : ""
                                  }`}
                                  //className='ul-pagination page-item'
                                  //   style={{
                                  //     backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                  //     color: (currentPage == number) ? 'white' : 'black'
                                  //   }}
                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                  Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                  Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className={`ul-pagination page-item ${
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                      ? "active"
                                      : ""
                                  }`}
                                  // className='ul-pagination page-item'
                                  // style={{
                                  //   backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                  //   color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                  // }}
                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className={`borderright-radius-5 ${
                                  currentPage ===
                                  Math.ceil(totalItems / itemsPerPage)
                                    ? "active"
                                    : ""
                                }`}
                                //className='borderright-radius-5'
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body
                              className="pl-md-5 pr-md-5 pt-0"
                              style={{ overflowY: "auto", maxHeight: "85vh" }}
                            >
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">User Master</span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style  form-label  font-weight-600 mb-0"
                                  >
                                    Functional Lead
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={functionalLeadName}
                                      onChange={handleFunctionalLead}
                                    >
                                      <option value="" disabled selected>
                                        Select Functional Lead
                                      </option>
                                      {functionalLead.map((option) => (
                                        <option
                                          value={option.functionalLead}
                                          // post-name={option.name}
                                        >
                                          {option.functionalLead}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errfunctionalLead}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Cost Center
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={costcenterID}
                                      onChange={handleCostCenterTypeChange}
                                    >
                                      <option value="" disabled selected>
                                        Select CostCenter
                                      </option>
                                      {costCenterOptions.map((option) => (
                                        <option
                                          // key={option.id}
                                          value={option.name}
                                          // data-costcentrName={option.name}
                                        >
                                          {option.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorCostCenter}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Manager
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={manager}
                                      onChange={handleManagerTypeChange}
                                    >
                                      <option value="" disabled selected>
                                        Select Manager
                                      </option>
                                      {managerTypeOptions.map((option) => (
                                        <option
                                          // key={option.id}
                                          value={option.manager}
                                        >
                                          {option.manager}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorManager}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Employee
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={selectedEmployee}
                                      onChange={handleEmployee}
                                    >
                                      <option value="" disabled selected>
                                        Select User Type
                                      </option>
                                      {getEmployee.map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.employeeName}
                                        >
                                          {option.employeeName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorFirstName}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    User Type
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={userType}
                                      onChange={handleUserTypeChange}
                                    >
                                      <option value="" disabled selected>
                                        Select User Type
                                      </option>
                                      {dynamicOptions.map((option) => (
                                        <option
                                          key={option.id}
                                          value={option.id}
                                        >
                                          {option.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorUserType}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Role Type
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={roleType}
                                      onChange={handleRoleTypeChange}
                                    >
                                      <option value="" disabled selected>
                                        Select Role Type
                                      </option>
                                      {roleTypeOptions.map((option) =>
                                        option.status == 1 ? (
                                          <option
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.roleName}
                                          </option>
                                        ) : null
                                      )}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorRoleType}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Location
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={location}
                                      onChange={handleLocationTypeChange}
                                    >
                                      <option value="" disabled selected>
                                        Select Location
                                      </option>
                                      {locationTypeOptions.map((option) => (
                                        <option
                                          key={option.code}
                                          value={option.name}
                                          data-locationId={option.code}
                                        >
                                          {option.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorLocation}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Sub Location
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={sublocation}
                                      onChange={handleSubLocation}
                                    >
                                      <option value="" disabled selected>
                                        Select Sub Location
                                      </option>
                                      {subLocationTypeOptions.map((option) => (
                                        <option
                                          key={option.code}
                                          value={option.name}
                                          data-sublocationId={option.code}
                                        >
                                          {option.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errSubLocation}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Main Crop Function
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={cropID}
                                      onChange={handleCropTypeChange}
                                    >
                                      <option value="" disabled selected>
                                        Select Crop Type
                                      </option>
                                      {cropTypeOptions?.map((option) => (
                                        <option
                                          key={option.cropID}
                                          value={option.cropID}
                                          data-cropName ={option.cropName}
                                        >
                                          {option.cropName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorCrop}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="InputType"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Sub Crop Function
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={subCropID}
                                      onChange={handleSubCropTypeChange}
                                    >
                                      <option value="" disabled selected>
                                        Select Sub Crop
                                      </option>
                                      {subCropTypeOptions?.map((option) => (
                                        <option
                                          key={option.subCropNamesubCropID}
                                          value={option.subCropID}
                                          data-subcropName ={option.subCropName}
                                        >
                                          {option.subCropName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorSubCrop}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Mobile Number
                                  </label> 
                                  <div className="input-group input-group w-100"> 
                                    <input
                                      type="number"
                                      className="form-control p-2"
                                      placeholder="Mobile Number"
                                      value={mobileNumber} 
                                      onChange={handleMobileNbr}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorMobileNumber}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    htmlFor="exampleInputPassword1"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    DOB
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2"
                                      value={dob}
                                      onChange={handleDateChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorDob}
                                    {errorfutureDate}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    htmlFor="exampleInputPassword1"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Date Of Joining
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2"
                                      value={dateOfJoining}
                                      onChange={handleDateOfJoining}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errDateOfJoining}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="Inputnbr"
                                    class="form-label  font-weight-600 mb-0"
                                  >
                                    Alternate Mobile Number
                                  </label>

                                  <div className="input-group input-group w-100">
                                    <input
                                      type="number"
                                      className="form-control p-2"
                                      placeholder=""
                                      value={alternateMobileNo}
                                      onChange={handleChangeAlterMobileNumber}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorAlternateMobileNumber}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputEmail"
                                    class="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Email-Id
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Email-Id"
                                      value={emailId}
                                      onChange={handleEmailChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorEmailId}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="Input"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Address
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <textarea
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Address"
                                      value={address}
                                      onChange={handleAddressChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorAddress}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-3">
                                  <label
                                    htmlFor="exampleInputGender"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Gender
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <div className="form-check">
                                      <label className="form-check-label">
                                        <input
                                          type="radio"
                                          className="form-check-input"
                                          name="gender"
                                          value="male"
                                          onChange={handleGenderChange}
                                          checked={gender === "male"}
                                        />
                                        Male
                                      </label>
                                    </div>
                                    <div
                                      className="form-check"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <label className="form-check-label">
                                        <input
                                          type="radio"
                                          className="form-check-input"
                                          name="gender"
                                          value="female"
                                          onChange={handleGenderChange}
                                          checked={gender === "female"}
                                        />
                                        Female
                                      </label>
                                    </div>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorGender}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="Input"
                                    className="label-style form-label  font-weight-600 mb-0"
                                  >
                                    Status
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={status}
                                      onChange={handleStatus}
                                    >
                                      <option value="" disabled selected>
                                        Select Status
                                      </option>
                                      <option value="1">Active</option>
                                      <option value="0">In Active</option>
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorStatus}
                                  </span>
                                </div>
                                <div className=" col-md-6 col-12 mt-2">
                                  <label>
                                    User Profile{" "}
                                    <span style={{ color: "red" }}>*</span>
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept="image/*"
                                      className="mt-2"
                                    />
                                    <img
                                      src={UploadFile}
                                      style={{ height: 70 }}
                                    />
                                  </label>
                                  {profilePicUrlShow && (
                                    <div>
                                      <p>Uploaded Image :</p>
                                      <img
                                        src={profilePicUrlShow}
                                        alt="Selected"
                                        style={{
                                          maxHeight: "80px",
                                          maxWidth: "50%",
                                          border: "1px solid black",
                                        }}
                                      />
                                    </div>
                                  )}
                                  {errorProfilePicURL && (
                                    <p style={{ color: "red" }}>
                                      {errorProfilePicURL}
                                    </p>
                                  )}
                                </div>
                              </div>
                              &nbsp;
                             
                              <div className="d-flex justify-content-center col-md-12">
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() => handleSubmit()}
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  onClick={handleCancel_new}
                                  className="btnn btn btn-danger"
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">User Master</span>
                              </Modal.Title>
                              {/* 
                              <Button variant="link" className="close btn btn-danger btn-sm close_btn" onClick={handleCancel}>
                                X
                              </Button> */}

                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showWarningPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">User Master</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are you Sure, Do You want close Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">User Master</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showPasswordPopup}
                            centered
                            onHide={() => setShowPasswordPopup(false)}
                          >
                            <Modal.Header>
                              <Modal.Title>Reset Password</Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <p style={{ color: "green" }}>
                                  Are You Sure, do you want to reset the
                                  password?
                                </p>
                              </div>
                              {/* You can add more content or a password reset form here */}
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={() => handleReset(selectedItem)}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={() => setShowPasswordPopup(false)}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Footer>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResetForm}
                            centered
                            onHide={() => setShowResetForm(false)}
                          >
                            <Modal.Header>
                              <Modal.Title>Reset Password</Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <p style={{ color: "green" }}>
                                  Are You Sure, do you want to reset the
                                  password?
                                </p>
                              </div>
                              {/* You can add more content or a password reset form here */}
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={() =>
                                    handleResetPassword(selectedItem)
                                  }
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={() => setShowPasswordPopup(false)}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {loading &&
                  <div>
                    <p>Fetching data. Please wait...</p>
                    {/* <Loader type="Puff" color="#00BFFF" height={50} width={50} />
                    <Loader
                      height="80"
                      width="80"
                      radius="9"
                      color="green"
                      ariaLabel="loading"
                    />
                  </div>
                } */}
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default UserMaster;
