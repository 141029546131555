// import React, { createContext,useContext } from 'react';
// import ProductMaster from './ProductMaster';
 
//  const categories1 = [
//      { name: 'Category 1', code: '001' },
//      { name: 'Category 2', code: '002' },
//      { name: 'Category 3', code: '003' },
//      // ... dynamic category data
//    ];
// //const [categories1, setData] = useState([]);

// export const CategoryContext = React.createContext(categories1);
import React, { createContext } from 'react';

const categories1 = [
  { name: 'Category 1', code: '001' },
  { name: 'Category 2', code: '002' },
  { name: 'Category 3', code: '003' },
  // ... dynamic category data
];

export const CategoryContext = createContext(categories1);

