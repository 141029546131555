import React from 'react'

function FarmOperations() {
  return (
    <div>
      <h1>FarmOperations</h1>
    </div>
  )
}

export default FarmOperations
