
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import SideMenu from '../Pages/SideMenu';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Logout from '../Pages/Logout';
import { strings } from '../Utilities/strings'
import Loader from '../Utilities/Loader';
import * as XLSX from 'xlsx';
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//for modal
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from "sweetalert2";
import { MultiSelect } from "react-multi-select-component";
import Login from '../Login';
import { rmGetmasters, reportForRMTracking } from '../Utilities/URLCONSTANTS';
import { GetApiHeaders } from '../Utilities/NetworkUtilities';
import { filterObjects } from '../Utils';
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from "../../src/Utilities/utils";
import Loader_Bulk from '../Utilities/Loader_Bulk';
import { Button, Modal } from "react-bootstrap";

//ProductMaster

const RMReport = ({ }) => {
    const [thCount, setThCount] = useState(0);
    const [selectedSubLocation, setSelectedSubLocation] = useState("");
    const [loaderImage, setLoaderImage] = useState(require('../images/rmloader.gif'));
    const [isLoading, setIsLoading] = useState(false);
    const [operations, setOpeartions] = useState('');
    const [ActivityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [todayDate, settodayDate] = useState(new Date());


    //Drop downs
    //typeofwork
    const [getTypeOfWork, setGetTypeOfWork] = useState([]);
    const [selectedTypeOfWork, setSelectedTypeOfWork] = useState(""); 
    const [typeOfWork, setTypeWork] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [getData, setGetData] = useState([]);
    const [locationData, setlocationData] = useState("");
    const [locatinBasedsubLocation, setlocatinBasedsubLocation] = useState([]);
    //location
    const [sublocationDataOriginal, setsublocationDataOriginal] = useState([]);
    const [sublocationData, setsublocationData] = useState([]);
    const [errLocation, setErrLocation] = useState("");
    //sublocation
    const [subLocation, setSubLocation] = useState([]);
    //general List
    const [generalList, setGeneralList] = useState();
    const [errgeneralWork, setErrGeneralWork] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // For filtered results
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
    const [searchTerm, setSearchTerm] = useState('');
    const [totalItems, setTotalItems] = useState();;
    const [permitType, setpermitType] = useState('');
    const [wmy, setwmy] = useState("");
    const [location, setLocation] = useState('');
    const [SelectedLocation, setSelectedLocation] = useState('');
    const [WeekSelected, setWeekSelected] = useState('no');
    const [monthSelected, setmonthSelected] = useState('no');
    const [yearSelected, setYearSelected] = useState('no');
    const [allSelected, setAllSelected] = useState('yes');
    const [searchTermOption, setSearchTermOption] = useState('');
    const [searchTermRm, setSearchTermRm] = useState('');
    const [submitResponse, setsubmitResponse] = useState("");
    const [showMsg, setShowMsg] = useState("false");
    const [showResponsePopup, setShowResponsePopup] = useState(false);
    


    const handleFilter = (searchTerm) => {
        setSearchTerm(searchTerm);
        if (data != null && data != undefined) {
            const filteredResults = data.filter((item) =>
                (item.date && item.date.includes(searchTerm)) ||
                (item.requestId && item.requestId.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.location && item.location.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.subLocation && item.subLocation.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.typeOfWork && item.typeOfWork.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.requestedBy && item.requestedBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.requesterMobileNumber && item.requesterMobileNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.requestedDate && item.requestedDate.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.accepterRejected && item.accepterRejected.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.approvedBy && item.approvedBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.approvedDate && item.approvedDate.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.acceptedBy && item.acceptedBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.technicianAcceptedDate && item.technicianAcceptedDate.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.technicianStatus && item.technicianStatus.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.completedBy && item.completedBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.completedDate && item.completedDate.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredData(filteredResults);
        }
    };



    const getMasterData = async () => {
        const getData = rmGetmasters
        // const getData = getMasters;
        const headers = await GetApiHeaders();

        try {
            const responseGetData = await axios.get(getData, { headers })
            console.log("resssss,", responseGetData);
            setGetData(responseGetData.data.response)
            // setGeneralList(responseGetData.data.response.generalWorksList)
            setGetTypeOfWork(responseGetData.data.response.TypeOfWork)
            setlocationData(responseGetData.data.response.workLogLocation);
            setsublocationDataOriginal(responseGetData.data.response.workLogSubLocation);

        } catch (err) {
            console.log("getError", err)
        }
    }

  
    const handleSubLocation = (selectedSubLocation) => {
        const labelNames = selectedSubLocation.map(item => item.label).join(', ');
        console.log("valueArray", labelNames)
        setSubLocation(selectedSubLocation);
        setSelectedSubLocation(labelNames) 
    };


    let commaSeparatedString;
    if (Array.isArray(subLocation)) {
        commaSeparatedString = subLocation.join(',');
    } else if (typeof subLocation === 'string') {
        // If subLocation is a string, split it by comma to form an array
        commaSeparatedString = subLocation;
    } else {
        // Handle other cases or assign a default value
        commaSeparatedString = ''; // or any default value you want
        console.error('Error: a is not an array');
    }



    const filteredLocations = locatinBasedsubLocation.filter(item =>
        item.name.toLowerCase().includes(searchTermOption.toLowerCase())
    )

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allSubLocations = locatinBasedsubLocation.map(item => item.name);
            setSubLocation(allSubLocations); // Select all sub-locations
        } else {
            setSubLocation([]); // Deselect all sub-locations
        }
    };



    const handleLocation = (e) => { 
    const subLocationsArray = [];
    const selectedLocation =  e.target.options[e.target.selectedIndex].getAttribute("code");
    setSelectedLocation(selectedLocation)
    setLocation(e.target.value);  
    if (e.target.value > 0) {
        setErrLocation("")
    }
    if (e.target.value) {
      sublocationDataOriginal.find((item) => {
        if (item.locationId === parseInt(e.target.value)) {
          subLocationsArray.push(item);
        }
      }); 
      setsublocationData(subLocationsArray)
      setSubLocation([])
    }else{
        setsublocationData([])
        setSubLocation([])
    }
       
    };
    const handleClose = () => {
      setShowResponsePopup(false)
      };
 

    useEffect(() => {
        if (data != null && data != undefined) {
            var dtToday = new Date();
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();
            var maxDate = year + '-' + month + '-' + day;
            setFilteredData(data);
            setTotalItems(data.length);

        }
    }, [data]);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1); // Reset to the first page when changing items per page.
    };

    // const totalItems = data.length;

    const pageNumbers = [];
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1); i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleresponse = () => {
        setShowResponsePopup(false); 
        window.location.reload();
      };


    // const handlePrint = () => {
    //     window.print();

    // };

    const tableId = 'Content_data_table'; // Replace 'yourTableId' with the actual id of your table
    useEffect(() => {
        const table = document.getElementById(tableId);
        if (table) {
            const theadRow = table.querySelector('thead tr');
            const thCount = theadRow ? theadRow.querySelectorAll('th').length : 0;
            setThCount(thCount);
        } else {
            console.warn(`Table with id '${tableId}' not found.`);
        }
    }, [tableId]); // Run this effect only once when the component mounts


    const handlePrint = () => {
        const printWindow = window.open();
        const printContent = document.querySelector('.print-table');
        // Check if the printContent is available
        if (printContent) {
            printWindow.document.write('<html><head>' +
                '<meta charset="utf-8" />' +
                '<link rel="icon" href="/favicon.ico" />' +
                '<meta name="viewport" content="width=device-width, initial-scale=1" />' +
                '<meta name="theme-color" content="#000000" />' +
                '<meta name="description" content="Web site created using create-react-app" />' +
                '<title>R&M Reports</title>' +
                '<link href="css/sb-admin-2.min.css" rel="stylesheet">' +
                '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css">' +
                '<script defer src="/static/js/bundle.js"></script>' +
                '<style>' +
                '@media print {' +
                '  table {' +
                '    border-collapse: collapse;' +
                '    width: 100%;' +
                '    margin-bottom: 10px;' +
                '  }' +
                '  th, td {' +
                '    border: 1px solid #ddd;' +
                '    text-align: center;' +
                '  }' +
                '}' +
                '</style>' +
                '</head><body>');

            printWindow.document.write(printContent.innerHTML);
            printWindow.document.write('</body></html>');

            // Delay the print function to ensure styles are applied
            printWindow.setTimeout(() => {
                printWindow.print();
                printWindow.close();
            });
        } else {
            // Handle the case where printContent is not found  
            alert("print is not available");
        }
    }

    const exportToExcel = () => {
        const data = [

            [
                'S.no',
                'Request Id',
                'Location',
                'Sub Location',
                'Type Of Work',
                'Requested By',
                'Requested Mobile number',
                'Requested Date',
                'Acceptor Status',
                'Approved/Rejected By Acceptor',
                'Approved/Rejected Date',
                'Accepted By',
                'Accepted Date',
                'Technician Status',
                'Completed by',
                'Completed Date',
                'Requester Remarks',
                'Accepter Remarks',
                'Technician Remarks'
            ],

            // Custom headers
            // ...currentItems.map((item) => [
            ...filteredData.map((item, index) => [
                index + 1,
                item.requestId,
                item.location,
                item.subLocation,
                item.typeOfWork,
                item.requestedBy,
                item.requesterMobileNumber,
                item.requestedDate,
                item.acceptorStatus,
                item.approvedBy,
                item.approvedDate,
                item.acceptedByTechnician,
                item.technicianAcceptedDate,
                item.technicianStatus,
                item.completedBy,
                item.completionDate,
                item.requesterRemarks,
                item.accepterRemarks,
                item.technicianRemarks
            ]),
        ];





        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        // Save the file
        XLSX.writeFile(wb, 'R&M Reports.xlsx');
    };


    const handleTypeOfOeration = (e) => {
        const selectTractorType = e.target.value
        setOpeartions(selectTractorType)
    }

    const handleWMYChange = (e) => {
        $('.date_show_hide').hide();
        let chk_val = (e.target.value);
        setwmy(chk_val);
        if (chk_val === 'M' || chk_val === 'Y' || chk_val === 'W' || chk_val === 'All') { 
           setGeneralList('')
           setLocation('')
           setSubLocation([])
           setTypeWork([])
           setStartDate(new Date());
           setEndDate(new Date());
        }

        if (chk_val == 'W') {
            setWeekSelected('yes');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('no');
        }
        else if (chk_val == 'M') {
            setWeekSelected('no');
            setmonthSelected('yes');
            setYearSelected('no');
            setAllSelected('no');
        }
        else if (chk_val == 'Y') {
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('yes');
            setAllSelected('no');
        }
        else if (chk_val == 'All') {
            setwmy("");
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('yes');
            $('.date_show_hide').show();
        }
    }

    //for data table 


    function extractTimeFromDate(dateTimeString) {
        const dateObject = new Date(dateTimeString);
        const timeOnly = dateObject.toLocaleTimeString('en-US', { hour12: true });
        return timeOnly;
    }



    const tableRef = useRef(null);
    useEffect(() => {
        // Check if DataTable is already initialized
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            // Destroy the existing DataTable instance
            $(tableRef.current).DataTable().destroy();
        }

        // Initialize the DataTable
        $(tableRef.current).DataTable({
            ordering: false, // Disable sorting
        });
        // fetchData();
        // fetchDataReports();
        getMasterData();

    }, []);



    ////Data table api IntigrationN 

    const fetchData = async () => {
        // debugger;
        const apiUrl = reportForRMTracking;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);
        setIsLoading(true);
        axios.post(apiUrl, { headers }).then((response) => {
            // debugger;
            setIsLoading(false);
            setData(response.data.response.rmHistory);
            const respData = response.data.response.rmHistory;
            const activityType = [...new Set(respData.map(item => item.activityType))];
            setActivityData(activityType);
            setLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        });
        // }, 900);
    };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    const handleTypeOfWork = (selectedTypeOfWork) => {
        const labelNames = selectedTypeOfWork.map(item => item.label).join(', ');
        console.log("valueArray", labelNames)
        setTypeWork(selectedTypeOfWork);
        setSelectedTypeOfWork(labelNames);
    };

    const handleCheckboxChange = (e, itemName) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            // Add the selected checkbox value to the state
            setSelectedCheckboxes([...selectedCheckboxes, itemName]);
        } else {
            // Remove the deselected checkbox value from the state
            setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== itemName));
        }
    };
    const commaSeparatedTypeOfWorkString = selectedCheckboxes.join(',');


    const handleSearch = (value) => {
        setSearchTermRm(value);
    };

    // Function to handle the "Select All" checkbox
    const handleSelectAllTypeOfWork = (e) => {
        const checkboxes = document.querySelectorAll('.dropdown-menu input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = e.target.checked;
        });
    };


    const handleGeneralList = (e) => {
        setGeneralList(e.target.value)
        if (e.target.value) {
            setErrGeneralWork('')
        }
    }

    const fetchDataReports = async () => {
        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        const reqObj = {
            "WMY": "W",
            "startDate": "",
            "endDate": "",
            "permitType": "",
            "location": "",
            "subLocation": "",
            "typeOfWork": "",
            "reportType": "requests",
            "generalList": "",
            "equipment": ""
        }
        try {
            const response = await axios.post(fetchApi, reqObj, { headers })
            console.log("update Activity", response.data.response.requests)
            setData(response.data.response.requests)
        } catch (err) {
            console.log("err123", err)
        }
    }

    const handleSubmit = async (event) => {
        // debugger;
        if (!location) {
            setErrLocation("Please Select Location")
        }
        // if (!generalList) {
        //     setErrGeneralWork("Please Select General List")
           
        // }
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;

        if (startDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            formattedEndDate = endDate.toISOString().split('T')[0];
        }

        event.preventDefault();
        const reqObj = {
            "WMY": wmy,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "location": SelectedLocation,
            "subLocation": selectedSubLocation,
            "typeOfWork": selectedTypeOfWork,
            "reportType": "requests",
            "generalList": "",
            "equipment": ""
        } 
        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);
        try {
            // setIsLoading(true);
            // axios.post(fetchApi, reqObj, { headers }).then((response) => {
            //     if (response.data.response == "undefined" || response.data.response == "") {
            //         setData([]);
            //     }
            //     else
            //         setData(response.data.response.requests);
            //     console.log("submitted data is" + response.data.response.requests)
            //     setIsLoading(false);
            // }) 
                setIsLoading(true); 
                const res = await axios.post(fetchApi, reqObj,{headers})
                const rmData = res?.data?.response?.requests;
        
                if (rmData === undefined || rmData === "") {
                  setData([]);
                } else {
                  setData(rmData);
                }
                  setIsLoading(false);
                // }); 
        } catch (error) {
            console.log(error);
        }
      
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setpermitType(value);

    }

    const Sendmail = async (event) => {
        if (!location) {
            setErrLocation("Please Select Location")
        }
        // if (!generalList) {
        //     setErrGeneralWork("Please Select General List")
           
        // }
        // debugger;
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;

        if (startDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            formattedEndDate = endDate.toISOString().split('T')[0];
        }

        event.preventDefault();
        const reqObj = {
            "WMY": wmy,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "location": SelectedLocation,
            "subLocation": selectedSubLocation,
            "generalList": generalList,
            "typeOfWork": selectedTypeOfWork,
            "reportType": "requests"
        }
        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        
         try {
            if (location !== ""  ) { 
                setIsLoading(true);
                const response = await axios.post(fetchApi, reqObj,{headers})
                console.log('mail checking',response);
                setShowResponsePopup(true);
                setsubmitResponse(response.data.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            
        }
        // axios.post(fetchApi, reqObj, { headers }).then((response) => {
        //     setIsLoading(false);
        //     if (response.data.response == "undefined" || response.data.response == "" ) {
        //         setData([]);
        //     }
        //     else {
        //         Swal.fire({
        //             title: 'Success',
        //             type: 'success',
        //             text: 'Your work has been saved.',
        //             confirmButtonColor: '#3085d6'
        //         });
        //     }

        // })
    }

    function resetData() {
        setwmy("w");
        setTypeWork([]);
        setlocatinBasedsubLocation([]);
        setsublocationDataOriginal([]);
        setLocation("");
        setsublocationData([]);
        setSubLocation("");
        setGeneralList("");
        setStartDate(new Date());
        setEndDate(new Date());
        settodayDate(new Date()); 
        // fetchData();
        setTimeout(()=>{
            setErrGeneralWork('')
            setErrLocation('')
        },100)
        window.location.reload()
    }
 
    return (
        <body id="page-top">
            {/*  <!-- Page Wrapper --> */}
            <div id="wrapper">
                {/*  <!-- Sidebar --> */}
                <SideMenu />
                {/*  <!-- End of Sidebar --> */}

                {/*  <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/*  <!-- Main Content --> */}
                    <div id="content">
                        {/*  <!-- Topbar --> */}
                        <Header />
                        {/* ...Content Start... */}
                        <div>
                            <div className='container-fluid' style={{ backgroundColor: "#F7F8FA" }}>
                                <div className='card border-0' style={{ backgroundColor: "transparent" }}>
                                    <div className='card-body pt-4 pl-1'>
                                        <h5 className='Roles_text'>R&M Report</h5>
                                        <div className='mt-4'>
                                            <div className='d-flex gap-1 status_card'>
                                                <div className='card flex-grow-1' style={{
                                                    border: '1px solid #0000001A',
                                                    boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                                }}>
                                                    <div className='card-body' style={{ padding: "20px 20px" }}>

                                                        <form onSubmit={handleSubmit}>
                                                            <div className='row'>
                                                                <div className='col-md-3 col-12'>
                                                                    <label
                                                                        className="form-label font-weight-bold mt-1"
                                                                    >Select Date Duration :      </label>

                                                                    <div className="btn-group" id='filter-btn' role="group" aria-label="Basic radio toggle button group" onChange={handleWMYChange}>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={WeekSelected === 'yes'} value="W" />
                                                                        <label className="btn btn-outline-primary" for="btnradio1">Week</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked={monthSelected === 'yes'} value="M" />
                                                                        <label className="btn btn-outline-primary" for="btnradio2">Month</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={yearSelected === 'yes'} value="Y" />
                                                                        <label className="btn btn-outline-primary" for="btnradio3">Year</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autocomplete="off" checked={allSelected === 'yes'} value="All" />
                                                                        <label className="btn btn-outline-primary" for="btnradio4">All</label>
                                                                    </div>
                                                                </div>
{/* 
                                                                <div className='col-md-3 col-12'>
                                                                    <label
                                                                        className="form-label 
                                                                     font-weight-bold mt-1"
                                                                    >General List </label>

                                                                    <select className="form-select"
                                                                        value={generalList} onChange={handleGeneralList} >
                                                                        <option value="" >Select General Work</option>
                                                                        {
                                                                            getData.generalWorksList != undefined && getData.generalWorksList.map((items, index) => {
                                                                                return (
                                                                                    <option key={items.code}
                                                                                        value={items.name}>
                                                                                        {items.name}</option>
                                                                                )
                                                                            }) 
                                                                        }

                                                                    </select>
                                                                    <span style={{color:'red'}}>{errgeneralWork}</span>
                                                                </div> */}

                                                                <div className='col-md-3 col-12'>
                                                                    <label className="form-label font-weight-bold mt-1"
                                                                    >Location  </label>

                                                                    <select className="form-select"
                                                                        value={location}
                                                                        onChange={handleLocation}>
                                                                        <option value="" >Select Location</option>
                                                                        {
                                                                            getData.workLogLocation != undefined && getData.workLogLocation.map((items, itemsIndex) => {
                                                                                return (
                                                                                    <option
                                                                                        key={items.code}
                                                                                        code={items.name}
                                                                                        value={items.code}>{items.name}</option>
                                                                                )
                                                                            })

                                                                        }

                                                                    </select>
                                                                    <span style={{color:'red'}}>{errLocation}</span>
                                                                </div>

                                                                <div
                                                                    className='col-md-3 col-12'
                                                                    style={{  position: 'relative' }}>
                                                                    <label className="form-label font-weight-bold mt-1">Sub Location</label>
                                                                   
                                                                    <MultiSelect
                                                                        options={sublocationData.map((item) => ({
                                                                            label: item.name,
                                                                            value: item.code,
                                                                        }))}
                                                                        value={subLocation}
                                                                        onChange={handleSubLocation}
                                                                        labelledBy="Select"
                                                                        overrideStrings={{ selectSomeItems: 'Select Sub Location' }}
                                                                        renderValue={(selected) => (
                                                                            <div>
                                                                                {selected?.map((selectedItem, index) => (
                                                                                    <span key={index}>{selectedItem?.name}</span>
                                                                                ))}
                                                                            </div>
                                                                        )} />
                                                                </div> 
                                                                <div className='col-md-3 col-12 '  >
                                                                    <label className="form-label  font-weight-bold mt-1">Type Of Work</label>
                                                                     <MultiSelect
                                                                        options={getTypeOfWork.map((item) => ({
                                                                            label: item.name,
                                                                            value: item.code,
                                                                        }))}
                                                                        value={typeOfWork}
                                                                        onChange={handleTypeOfWork}
                                                                        labelledBy="Select"
                                                                        overrideStrings={{ selectSomeItems: 'Select Type Of Work' }}
                                                                        renderValue={(selected) => (
                                                                            <div>
                                                                                {selected?.map((selectedItem, index) => (
                                                                                    <span key={index}>{selectedItem?.name}</span>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </div>




                                                                <div className='col-sm-3 col-12 date_show_hide'
                                                                 style={{ marginLeft: "25%"  }} 
                                                                >
                                                                    <div class="mb-3">
                                                                        <label for="startdate"
                                                                            className="form-label font-weight-bold mt-1"
                                                                        >From Date</label>
                                                                        <DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select Start Date"
                                                                            selected={startDate}
                                                                            onChange={(date) => { setStartDate(date) }}
                                                                            maxDate={endDate}
                                                                            // dateFormat="dd/MM/yyyy"
                                                                            dateFormat="dd-MMM-yyyy"
                                                                            id="activitystartdate"
                                                                            name="activitystartdate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className='col-sm-3 col-12 date_show_hide'  
                                                                >
                                                                    <div class="mb-3">
                                                                        <label
                                                                            className="form-label font-weight-bold mt-1"
                                                                            for="enddate">To Date</label>
                                                                        <span><DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select End Date" selected={endDate}
                                                                            onChange={(date) => setEndDate(date)}
                                                                            // dateFormat="dd/MM/yyyy"
                                                                            dateFormat="dd-MMM-yyyy"
                                                                            minDate={startDate}
                                                                            maxDate={todayDate}
                                                                            name="activityenddate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='mt-2 d-flex w-full justify-content-center gap-2'>
                                                                <button class="btn btn-primary mr-0"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    type='submit'>Apply</button>

                                                                <button class="btn btn-danger"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={resetData}>Clear</button>

                                                                <button

                                                                    className="btn btn-secondary mr-0 buttons-excel buttons-html5"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={Sendmail}>Mail</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='card flex-grow-1 mt-2' style={{
                                            border: '1px solid #0000001A',
                                            boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                        }}>
                                            <div className='card-body' style={{ padding: "10px 10px" }}>

                                                <div className="d-flex justify-content-between align-items-center"
                                                    style={{ paddingLeft: 25, paddingBottom: 20 }}>
                                                    <div className="d-flex align-items-center">
                                                        <label className="mr-2">Show {' '}</label> &nbsp;&nbsp;
                                                        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            {/* Add more options as needed */}
                                                        </select>&nbsp;&nbsp;
                                                        <label>{' '} entries</label>
                                                    </div>

                                                    <div className="d-flex align-items-center" style={{ marginLeft: '5%' }}>

                                                        {/* Search Input */}
                                                        <input
                                                            type="search"
                                                            placeholder="🔍 Type to Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => handleFilter(e.target.value)}
                                                            style={{ height: "36px", borderRadius: '4px' }}
                                                            className="mr-1"
                                                        /> &nbsp;

                                                        <div className="my-2 d-flex w-full justify-content-end gap-2">
                                                            <button className="btn btn-secondary buttons-excel buttons-html5"
                                                                onClick={exportToExcel}>
                                                                Download Excel
                                                            </button>
                                                            <button
                                                                className="btn btn-primary"
                                                                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                                                                onClick={handlePrint}>
                                                                Print
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                    className="print-table"
                                                    style={{ overflowX: 'auto' }}>
                                                    <Table className="table table-bordered 
                                                    Content_data_table table-striped" id="Content_data_table"
                                                    >

                                                        <thead>
                                                            <tr>
                                                                <th className='text-center abc'>S.No</th>
                                                                <th className='text-center'>Request Id </th>
                                                                <th className='text-center'>Location </th>
                                                                <th className='text-center'>Sub Location </th>
                                                                <th className='text-center'>Type Of Work</th>
                                                                <th className='text-center'>Requested By </th>
                                                                <th className='text-center'>Requested Mobile number </th>
                                                                <th className='text-center'>Requested Date</th>
                                                                <th className='text-center'>Acceptor Status </th>
                                                                <th className='text-center'>Approved/Rejected By</th>
                                                                <th className='text-center'>Approved/Rejected Date</th>
                                                                <th className='text-center'>Accepted By </th>
                                                                <th className='text-center'>Accepted Date </th>
                                                                <th className='text-center'>Technician Status </th>
                                                                <th className='text-center'>Completed by  </th>
                                                                <th className='text-center'>Completed Date </th>
                                                                <th className='text-center'>Requester Remarks </th>
                                                                <th className='text-center'>Acceptor Remarks </th>
                                                                <th className='text-center'>Technician Remarks </th>

                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {currentItems && currentItems.length > 0 ? (
                                                                currentItems.map((item, index) => (
                                                                    <React.Fragment >
                                                                        <tr>
                                                                            <td className='text-center'  > {(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                            <td className='text-left'>{item.requestId}</td>
                                                                            <td className='text-left'>{item.location}</td>
                                                                            <td className='text-left'>{item.subLocation}</td>
                                                                            <td className='text-left'>{item.typeOfWork}</td>
                                                                            <td className='text-left'>{item.requestedBy}</td>
                                                                            <td className='text-left'>{item.requesterMobileNumber}</td>
                                                                            <td className='text-left'>{item.requestedDate}</td>
                                                                            <td className='text-left'>{item.acceptorStatus}</td>
                                                                            <td className='text-left'>{item.approvedBy || item.rejectedBy}</td>
                                                                            <td className='text-left'>{item.approvedDate}</td>
                                                                            <td className='text-left'>{item.acceptedByTechnician}</td>
                                                                            <td className='text-left'>{item.technicianAcceptedDate}</td>
                                                                            <td className='text-left'>{item.technicianStatus}</td>
                                                                            <td className='text-left'>{item.completedBy}</td>
                                                                            <td className='text-left'>{item.completionDate}</td>
                                                                            <td className='text-left'>{item.requesterRemarks}</td>
                                                                            <td className='text-left'>{item.accepterRemarks}</td>
                                                                            <td className='text-left'>{item.technicianRemarks}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={thCount} className='text-center'style={{padding:130}}>No Data Available</td>
                                                                </tr>
                                                            )}
                                                        </tbody> 
                                                    </Table> 
                                                </div>
                                                <div>
                                        <Modal
                                            show={showResponsePopup}
                                            centered
                                            backdrop="static"
                                            keyboard={false}
                                            size="md"
                                        >
                                            <Modal.Header className="border-0 d-flex justify-content-center">
                                            <Modal.Title>
                                                {" "}
                                                <span className="modal_title">R & M</span>
                                            </Modal.Title>
                                            <Button
                                                variant="link"
                                                className="close btn btn-danger close_btn"
                                                onClick={handleClose}
                                            >
                                                X
                                            </Button>
                                            </Modal.Header>
                                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                                            <div className="d-flex justify-content-center mb-3 font-weight-600">
                                                {showMsg ? (
                                                <p style={{ color: "green" }}>
                                                    {submitResponse}
                                                </p>
                                                ) : null}
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <Button
                                                variant="primary"
                                                className="d-flex justify-content-center cancel_button"
                                                onClick={handleresponse}
                                                >
                                                Okay
                                                </Button>
                                                &nbsp;&nbsp;
                                            </div>
                                            </Modal.Body>
                                        </Modal>
                                              </div>

                                                <div className='py-3'>
                                                    <div className='card data_tableCard'>
                                                        <div className='card-body'>

                                                            {/* Pagination */}

                                                            <div className="d-flex justify-content-between mb-10" style={{ borderRadius: 5, }}>
                                                                <div>
                                                                Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} 
                                                                &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                                                                of {totalItems} entries
                                                                </div>
                                                                <div>
                                                                    <div className="div-button text-center" >
                                                                        <button
                                                                            className='borderleft-radius-5'
                                                                            // className='ul-pagination'
                                                                            onClick={() => handlePageClick(currentPage - 1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            Previous
                                                                        </button>


                                                                        <button className='ul-pagination page-item'
                                                                            style={{
                                                                                backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                                                                color: (currentPage == 1) ? 'white' : 'black'
                                                                            }}
                                                                            key={1}
                                                                            onClick={() => handlePageClick(1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            {1}

                                                                        </button>
                                                                        {pageNumbers.map((number) => (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == number) ? 'white' : 'black'
                                                                                }}
                                                                                key={number}
                                                                                onClick={() => handlePageClick(number)}
                                                                                disabled={currentPage === number}
                                                                            >
                                                                                {number}
                                                                            </button>
                                                                        ))}
                                                                        {((Math.ceil(totalItems / itemsPerPage)) > 1 && currentPage !== (Math.ceil(totalItems / itemsPerPage) - 1) && currentPage !== (Math.ceil(totalItems / itemsPerPage))) && (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: 'white',
                                                                                    color: 'black'
                                                                                }}
                                                                            >
                                                                                ...
                                                                            </button>
                                                                        )}
                                                                        {(Math.ceil(totalItems / itemsPerPage)) > 1 &&
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                                                                }}
                                                                                key={(Math.ceil(totalItems / itemsPerPage))}
                                                                                onClick={() => handlePageClick((Math.ceil(totalItems / itemsPerPage)))}
                                                                                disabled={currentPage === (Math.ceil(totalItems / itemsPerPage))}
                                                                            >
                                                                                {(Math.ceil(totalItems / itemsPerPage))}

                                                                            </button>
                                                                        }
                                                                        <button className='borderright-radius-5'
                                                                            onClick={() => handlePageClick(currentPage + 1)}
                                                                            disabled={indexOfLastItem >= totalItems}
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        {isLoading && <Loader_Bulk loading={isLoading} message={"Fetching Data. Please Wait..!"} loderimage={loaderImage} />}
                    </div>
                    {/* ...End of Main Content...*/}
                    {/* ...Footer...*/}
                    <Footer />
                    {/* ... End of Footer...  */}
                </div>
            </div>
            {/*  <!-- End of Page Wrapper -->
        <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            {/*  <!-- Logout Modal--> */}
            <Logout />
            {/* {!isUserLoggedIn && <Login />} */}
        </body>
    )
}

export default RMReport;
