import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import Header from "./Header";
import Logout from "./Logout";
import { strings } from "../Utilities/strings";
// import 'datatables.net';
// import 'datatables.net-dt/css/jquery.dataTables.css';
// for Sidebar And Navbar
import Search from "../images/search.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import { addRoleMaster, getRoleMaster } from "../Utilities/URLCONSTANTS";
// import {Oval as Loader} from 'react-loader-spinner';
import Loader from "../Utilities/Loader";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import * as XLSX from "xlsx";

//UnitMeasureMaster
const RoleMaster = ({}) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/cs_loader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [id, setId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [Status, setStatus] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [errorName, setErrorName] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [sno, setSno] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close Unit Measure Master"
  );
  const [loading, setLoading] = useState(false);
  const [deleteItems, setDeleteItems] = useState("");
  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  // const [error, setError] = useState("");
  const [modalData, setModalData] = useState({
    id: "",
    roleName: "",
    description: "",
    status: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  // const totalItems = filteredData.length;
  // In your handleFilter function, update filteredData based on searchTerm
  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          item.roleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
      handlePageClick(1)
    }
  };

  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };
  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const exportToExcel = () => {
    const data = [
      ["S.no", "role Name", "Description", "Status"], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item) => [
        item.id,
        item.roleName,
        item.description,
        item.status === "1" ? "Active" : "InActive",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "RoleMaster.xlsx");
  };

  const handleCancel_new = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(true);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowMsg(true);
    // window.location.reload();
  };
  const handleClose = () => {
    //yes
    // alert("handleCancel");
    // setPopupText('Custom popup text here');
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  // ...more items
  const handleNameChange = (e) => {
    const input = e.target.value;
    if (input.length > 0) {
      setErrorName("");
    }
    const result = input.replace(/[^a-z_\s]/gi, "");
    setRoleName(result);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
    if (e.target.value) {
      setErrorStatus("");
    }
  };

  const handleDescriptionChange = (e) => {
    const input = e.target.value;
    setDescription(input);
    if (input.length > 0) {
      setErrorDescription("");
    }
  };

  const handleSubmit = async () => {
    if (roleName === "") {
      setErrorName("Please Enter   RoleName");
    }
    if (description === "") {
      setErrorDescription("Please Enter  Description");
    }
    if (Status === "") {
      setErrorStatus("Please Select Status");
    }

    try {
      if (roleName != "" && description != "" && Status != "") {
        const apiUrl = addRoleMaster;
        const dataToSend = {
          Id: { buttonText } == "Submit" ? "" : id,
          roleName: roleName,
          description: description,
          Status: Status,
        };
        const headers = await GetApiHeaders();
        // const headers = {
        //   "Content-Type": "application/json",
        //   "userId": "1",
        //   "mobileNumber": "9704164746",   //9704164746
        // };
        setIsLoading(true);
        // Make the POST request using Axios with headers
        const response = await axios.post(apiUrl, dataToSend, { headers });
        setsubmitResponse(response.data.response);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);
        // Handle the response data as needed
        console.log("Response:", response.data);

        // Proceed with data insertion if the API call was successful
        const newId = String(Date.now());
        const newItem = {
          id: newId,
          roleName: roleName,
          description: description,
          status: data.status,
        };

        setErrorName("");
        setErrorDescription("");
        setErrorStatus("");
        setIsSubmitted(true);
        // setData(prevData => [...prevData, newItem]);
      } else {
        setIsSubmitted(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitted(false);
      handleUpdate();
    } finally {
    }
  };

  const handleUpdate = () => {
    // Check if required fields are not empty
    if (id !== "" && roleName !== "" && description !== "" && Status !== "") {
      setErrorName("");
      setErrorDescription("");
      setErrorStatus("");
      // setData(prevData => {
      //   const newData = prevData.map(item => {
      //     if (item.id === modalData.id) {
      //       return {
      //         ...item,
      //         id: item.id,
      //         roleName: roleName,
      //         description: description,
      //         status: modalData.status
      //       };
      //     }
      //     return item;
      //   });

      //   return newData;
      // });
      setIsUpdated(true);
    } else {
      // Handle error if required fields are empty
      setIsUpdated(false);
      // setError("Please Enter Mandatory Fields");
    }
  };

  const fetchData = async () => {
    const apiUrl = getRoleMaster;
    const headers = await GetApiHeaders();
    console.log("Dynamic Headers::", headers);
    // const headers = {
    //   "Accept": "application/json",
    //   "Content-Type": "application/json",
    //   "userId": "1",
    //   "mobileNumber": "9704164746",//9704164746
    // };
    setIsLoading(true);
    // setTimeout(() => {
    // setLoading(true);
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setIsLoading(false);
        console.log("response", response);
        setData(response.data.response.roleMaster);
        setLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
    // }, 900);
  };

  useEffect(() => {
    fetchData();
  }, []);

  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }
    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
  }, []);

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalOpen = () => {
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId("");
    setRoleName("");
    setDescription("");
    setStatus("");
    setModalData({
      id: "",
      roleName: "",
      description: "",
      status: "",
    });
  };

  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };

  const updatedItems = (item) => {
    // setModalData(item)
    setId(item.id);
    setRoleName(item.roleName);
    setDescription(item.description);
    setStatus(item.status);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(false);
  };

  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">User Management</h5>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="mb-0 Sub_role_text"> Role Master</h6>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Add Role"
                      >
                        <Button
                          className="buttons"
                          variant="primary"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          {/* Add more options as needed */}
                        </select>
                        &nbsp;&nbsp;
                        <label> entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            placeholder="🔍 Type to Search..."
                            value={searchTerm}
                            onChange={(e) => handleFilter(e.target.value)}
                            style={{
                              height: "36px",
                              width: "160px",
                              paddingRight: "30px",
                            }}
                            className="mr-2"
                          />
                          {searchTerm && (
                            <button
                              className="btn btn-link"
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                zIndex: 1,
                              }}
                              onClick={() => handleFilter("")}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          )}
                        </div>
                        &nbsp;
                        {/* Add the Excel export button */}
                        <button
                          className="btn btn-secondary buttons-excel buttons-html5"
                          onClick={exportToExcel}
                        >
                          Download Excel
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className="table table-bordered Content_data_table table-striped">
                            {/* <ref={tableRef}/> */}
                            <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>
                                <Th className="text-center">Role Name</Th>
                                <Th className="text-center">Description</Th>
                                <Th className="text-center">Status</Th>
                                <Th className="text-center">Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {/* {data.map((item) => ( */}
                              {currentItems.length === 0 ? ( // Check if no data is found
                                <Tr>
                                  <Td
                                    colSpan={13}
                                    className="text-center"
                                    style={{ padding: 195, fontSize: 18 }}
                                  >
                                    Data is not available
                                  </Td>
                                </Tr>
                              ) : (
                                currentItems.map((item, index) => (
                                  <Tr>
                                    <Td className="text-left">{(currentPage - 1) * itemsPerPage + index + 1}</Td>
                                    <Td className="text-left">
                                      {item.roleName}
                                    </Td>
                                    <Td className="text-left">
                                      {item.description}
                                    </Td>
                                    <Td className="text-center">
                                      {item.status === "1" ? (
                                        <span style={{ color: "#2CA800" }}>
                                          Active
                                        </span>
                                      ) : (
                                        <span style={{ color: "#FF0000" }}>
                                          In Active
                                        </span>
                                      )}
                                    </Td>

                                    <Td
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className="mr-1 edit_delete_icon text-primary "
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit Role"
                                          onClick={() => updatedItems(item)}
                                        />
                                      </div>
                                    </Td>
                                  </Tr>
                                ))
                              )}
                            </Tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                                loderimage={loaderImage}
                              />
                            )}
                          </Table>
                          {/* Pagination */}
                          <div
                            className="d-flex justify-content-between"
                            style={{ borderRadius: 5 }}
                          >
                            <div>
                            Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp; of {totalItems} entries
                            </div>
                            <div>
                              <div className="div-button text-center">
                                <button
                                  className={`borderleft-radius-5 ${
                                    currentPage === 1 ? "active" : ""
                                  }`}
                                  //  className='borderleft-radius-5'
                                  // className='ul-pagination'
                                  onClick={() =>
                                    handlePageClick(currentPage - 1)
                                  }
                                  disabled={currentPage === 1}
                                >
                                  Prev
                                </button>
                                <button
                                  //  className='ul-pagination page-item'
                                  //   style={{
                                  //     backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                  //     color: (currentPage == 1) ? 'white' : 'black'
                                  //   }}
                                  className={`ul-pagination page-item ${
                                    currentPage === 1 ? "active" : ""
                                  }`}
                                  key={1}
                                  onClick={() => handlePageClick(1)}
                                  disabled={currentPage === 1}
                                >
                                  {1}
                                </button>
                                {pageNumbers.map((number) => (
                                  <button
                                    className={`ul-pagination page-item ${
                                      currentPage === number ? "active" : ""
                                    }`}
                                    //className='ul-pagination page-item'
                                    //   style={{
                                    //     backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                    //     color: (currentPage == number) ? 'white' : 'black'
                                    //   }}
                                    key={number}
                                    onClick={() => handlePageClick(number)}
                                    disabled={currentPage === number}
                                  >
                                    {number}
                                  </button>
                                ))}
                                {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                  currentPage !==
                                    Math.ceil(totalItems / itemsPerPage) - 1 &&
                                  currentPage !==
                                    Math.ceil(totalItems / itemsPerPage) && (
                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      ...
                                    </button>
                                  )}
                                {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                  <button
                                    className={`ul-pagination page-item ${
                                      currentPage ===
                                      Math.ceil(totalItems / itemsPerPage)
                                        ? "active"
                                        : ""
                                    }`}
                                    // className='ul-pagination page-item'
                                    // style={{
                                    //   backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                    //   color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                    // }}
                                    key={Math.ceil(totalItems / itemsPerPage)}
                                    onClick={() =>
                                      handlePageClick(
                                        Math.ceil(totalItems / itemsPerPage)
                                      )
                                    }
                                    disabled={
                                      currentPage ===
                                      Math.ceil(totalItems / itemsPerPage)
                                    }
                                  >
                                    {Math.ceil(totalItems / itemsPerPage)}
                                  </button>
                                )}
                                <button
                                  className={`borderright-radius-5 ${
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                      ? "active"
                                      : ""
                                  }`}
                                  //className='borderright-radius-5'
                                  onClick={() =>
                                    handlePageClick(currentPage + 1)
                                  }
                                  disabled={indexOfLastItem >= totalItems}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel_new}
                              >
                                {" "}
                                {/*  onClick={closeWarning} onClick={handleModalClose} */}
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">Role Master</span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label
                                    for="exampleInputPassword1"
                                    class="form-label text-md font-weight-600 mb-0"
                                  >
                                    Role Name
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    {/* <span className="input-group-text mcrc-input-group-text"
                                      id="inputGroup-sizing-sm">
                                      <img src={Search} alt='search' className='search_image'></img>
                                    </span> */}
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Role Name"
                                      value={roleName}
                                      inputMode="text"
                                      onChange={handleNameChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorName}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <div class="mb-3">
                                    <label
                                      for="exampleInputPassword1"
                                      class="form-label text-md font-weight-600 mb-0"
                                    >
                                      Description
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <textarea
                                      class="form-control"
                                      style={{ height: 115 }}
                                      id="textAreaExample1"
                                      rows="2"
                                      value={description}
                                      onChange={handleDescriptionChange}
                                    ></textarea>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorDescription}
                                  </span>
                                </div>
                                <div
                                  className="col-md-6 "
                                  style={{ marginTop: -85 }}
                                >
                                  <label
                                    for="Input"
                                    class="form-label text-md font-weight-600 mb-0"
                                  >
                                    Status
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <select
                                      className="form-select p-2"
                                      value={Status}
                                      onChange={handleStatus}
                                    >
                                      <option value="" disabled selected>
                                        Select Status
                                      </option>
                                      <option value="1">Active</option>
                                      <option value="0">In Active</option>
                                    </select>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorStatus}
                                  </span>
                                </div>
                                {/* {error && <span style={{ color: 'red' }} className="error-message">{error}</span>} */}
                              </div>
                              &nbsp;
                              <div className="row">
                                {isSubmitted ? (
                                  <p style={{ color: "green" }}>
                                    {strings.recordSubmitted}.
                                  </p>
                                ) : null}
                                {isUpdated ? (
                                  <p style={{ color: "green" }}>
                                    {strings.updatedSuccessfully}
                                  </p>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() =>
                                    handleSubmit({
                                      id,
                                      roleName,
                                      description,
                                      Status,
                                    })
                                  }
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleCancel_new}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            className="modalBlur"
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Role Master</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close this Window
                                    ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showWarningPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Role Master</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Role Master</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading && (
                    <div>
                      <p>Fetching data. Please wait...</p>
                      {/* <Loader type="Puff" color="#00BFFF" height={50} width={50} /> */}
                      <Loader
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default RoleMaster;
