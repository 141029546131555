 
import React, { useState, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getCurrentActivityUpdate } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

Chart.register(...registerables);

const FarmOperationActivityUpdate = () => {
  const [requestedCount, setRequestedCount] = useState([]);
  const [RequestDelvLabels, setRequestDelvLabels] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [hoveredPercentage, setHoveredPercentage] = useState(null); 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchData = async (type) => {
    const formattedStartDate = startDate ? formatDate(startDate) : null;
    const formattedEndDate = endDate ? formatDate(endDate) : null;
    const corepermitApi = getCurrentActivityUpdate;
    const postData = {
      // type: selectedValue,
      type: (startDate && endDate) ?'' : selectedValue, 
          startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    try {
      const response = await axios.post(corepermitApi, postData);
      console.log("check the response", response.data.labels);
      setRequestedCount(response.data.datasets);
      setRequestDelvLabels(response.data.labels);
    } catch (error) {
      console.log("RequestCount", error);
    }
  };
 

  useEffect(() => { 
    const shouldFetchData = selectedValue === "DTD" && startDate  && endDate; 
    if (shouldFetchData || selectedValue !== "DTD") {
      fetchData(selectedValue, startDate, endDate);
    }
  }, [startDate, endDate, selectedValue]);
 

  const data = {
 
    labels: RequestDelvLabels,
    datasets: [requestedCount],
  };
 
  const options = {
    plugins: {
        legend: {
            position: "top", 
            align: "start",
            labels: {
                boxWidth: 10,  
                padding: 10, 
              },
          },
      title: {
        display: true,
        text: " ",
        align: "start",
      },
     
    },
  };

  const getPercentageValue = () => {
    if (hoveredPercentage !== null && Array.isArray(requestedCount) && requestedCount.length > 0) {
      return requestedCount.reduce((sum, dataset) => sum + dataset.data[0], 0);
    }
    return "";
  };
  const handleSelect =(e)=>{
    setSelectedValue(e.target.value)
    setStartDate(null);
    setEndDate(null);
  }
  return (
    <>
      <div className="card p-4">
       <label className="chartname">Current Activity Update</label>
        <div className="d-flex  " id="filtersInputs">
          <select
            className="form-control w-100"
            value={selectedValue}
            onChange={handleSelect}
          >
          <option value="">ALL</option> 
          <option value="WTD">Weekly</option>
           <option value="MTD">Monthly</option>
           <option value="YTD">Yearly</option>
          <option value="DTD">Custom selection</option> 
          </select>
        </div> 
        {selectedValue === "DTD" && (
            <div className="d-flex" >
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                className="form-control  custom-datepicker"
                 
              /> 
               <DatePicker style={{marginLeft:'130px  '}}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                className="form-control  custom-date"
              /> 
            </div>
          )}
        <div
          className="chart-container"
          style={{
            position: "relative", 
            height: "260px",
            marginLeft: 'auto',
            marginTop :'-90px'
          }}
        > 
           {/* <div className="chart-container" style={{height:'244px',marginTop:'-53px', marginLeft:100}}></div> */}
           <Doughnut data={data} options={options} />
          <div
            style={{
              position: "absolute",
              top: "55%",
              left: "30%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "#000",
              fontSize: "17px",
              fontWeight: 900,
            }}
          >
          { getPercentageValue()}
          </div>
        </div>
      </div>
    </>
  );
};

export default FarmOperationActivityUpdate;
