import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../../Utilities/utils'

const currentDate = new Date().toISOString().split('T')[0];
function PermitIssuer(props) {
    const handleBasicData = (key, val) => {
        props.setPermitIssuer((prev) => ({ ...prev, [key]: val }));
    };

    // const handleDateChange = (date) => {
    //     if (date) {
    //         const formattedDate = new Date(date).toLocaleDateString();
    //         props.setPermitIssuer((prev) => ({ ...prev, date: formattedDate }));
    //         console.log(formattedDate);
    //     }
    // };

    const handleDateChange = (date) => {
        if (date) {
            const dateObj = new Date(date);
            const day = dateObj.getDate();
            const month = dateObj.toLocaleString('default', { month: 'short' });
            const year = dateObj.getFullYear();
    
            const formattedDate = `${day}-${month}-${year}`;
            props.setPermitIssuer((prev) => ({ ...prev, date: formattedDate }));
            console.log(formattedDate);
        }
    };
    

    return (
        <div className="m-2">
            <div className="row">
                {/* <div className="col-md-6 col-12"> */}
                <div>
                    <div>
                        <div className="form-group">
                            < label htmlFor="inputText">Permit Issuer <span style={{ color: "red" }}> *</span></label>
                            <div>
                                <input
                                    className="form-control"
                                    id="pi"
                                    placeholder=""
                                    value={props.data?.permitissuer || ""}
                                    // onChange={(e) => handleBasicData('permitissuer', e.target.value)}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const alphaValue = inputValue.replace(/[^a-zA-Z .]/g, ''); // Remove non-alphabet characters
                                        handleBasicData('permitissuer', alphaValue);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputText">Date <span style={{ color: "red" }}> *</span></label>
                            <div>
                                {/* <input
                                        className="form-control"
                                        id="dt"
                                        type="date"
                                        placeholder=""
                                        value={props.data?.date || ""}
                                        min={currentDate}
                                        onChange={(e) => handleBasicData('permitissuerdate', e.target.value)}
                                    />    
                                                                    */}

                                <DatePicker
                                  
                                    // readOnly={props.approvalStatus}
                                    value={props.data?.date || ""}
                                    onChange={(date) => handleDateChange(date)}
                                    dateFormat="dd-MMM-yyyy"
                                    placeholderText="Click to select a date"
                                    showYearDropdown
                                    showMonthDropdown
                                    customInput={<CustomDatePickerInput />}
                                    closeOnSelect
                                    minDate={new Date(currentDate)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    );
}

export default PermitIssuer;
