import React, { useState, useEffect, useRef } from "react";
import { NoteConstant } from "../../Utilities/cranePermit-JSON";
function Note(props) {
   
    const handleImportantCheckBox = (index, val, listName, key) => {
        let list = props.data[listName]
        const updateArr =list.map((i, j)=>{
          if(j == index){
            const obj = {...i}
            obj[key] = val
            if(key =='cbYes'){
              obj['cbNA'] = 'false'
            } else {
              obj['cbYes'] = 'false'
            }
            return obj
          } else {
            return i
          }
        }) 
        console.log(updateArr,'up')
       
          props.setNote((prev)=> ({...prev, [listName]:updateArr}))
      }
    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                   
                    {props.data.noteList.map((item, index) => (
                        <div class="form-group" key={index} >
                            <label for={`inputQuestion${item.id}`}>
                                {item.cbTitle}
                            </label>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    checked={item.cbYes == 'true'} onClick={()=>{
                                        handleImportantCheckBox(index, item.cbYes == 'true' ? 'false': 'true', 'noteList', 'cbYes')
                                      }}
                                    class="form-check-input"
                                    id={`notListItemYesSS${index}`}
                                />
                                <label class="form-check-label" for={`notListItemYesSS${index}`}>
                                    YES
                                </label>
                            </div>
                          <div class="form-check">
                                <input
                                    type="checkbox"
                                    checked={item.cbNA == 'true'} onClick={()=>{
                                        handleImportantCheckBox(index, item.cbNA == 'true' ? 'false': 'true', 'noteList', 'cbNA')
                                      }} 
                                    class="form-check-input"
                                    id={`peopleListItemNoSS${index}`}
                                />
                                <label class="form-check-label" for={`peopleListItemNoSS${index}`}>
                                    N/A
                                </label>
                            </div>
                        </div>
                    ))}

                </div>   

            </div>
        </div>

    )

};
export default Note;


