
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { CustomDatePickerInputforReviewwdwork } from '../../Utilities/utils'

function ReviedWork(props) {
  const [tableData, setTableData] = useState([props.data.list1, props.data.list2, props.data.list3, props.data.list4,]);



  const handleInputChange = (rowIndex, colIndex, value) => {
    const newData = [...tableData];
    newData[rowIndex][colIndex].description = value;
    setTableData(newData);
  };
  useEffect(() => {
    const dataList = {
      ...props.data,
      list1: tableData[0],
      list2: tableData[1],
      list3: tableData[2],
      list4: tableData[3],
    }
    props.onDataChange(dataList)
  }, [tableData]);


  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (


    <div>
      <div style={{ overflowX: 'auto' }}>
        <table className="table" style={{ width: '100%', fontSize: "12px" }}>
          <thead className="table tablecrane">
            <tr>
              <th scope="col">User signature(s) </th>
              <th scope="col">Fall protection trained </th>
              <th scope="col">Date  </th>
              <th scope="col">User signature(s)</th>
              <th scope="col">Fall protection trained</th>
              <th scope="col">Date  </th>
            </tr>
          </thead>



          <tbody>
            {tableData?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, colIndex) => (
                  <td key={colIndex}>
                   
                   
                    {colIndex === 2 || colIndex === 5 ? (
                      <DatePicker  
                        // className="form-control col-12"
                        
                        placeholder="dd-mm-yyyy"
                        selected={cell.description ? new Date(cell.description) : null}
                        onChange={(date) =>
                          handleInputChange(rowIndex, colIndex, date)
                        }
                        dateFormat="dd-MMM-yyyy"
                        todayButton="Today"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                     

                        // Set minimum date specific to colIndex
                        minDate={colIndex === 5 ? new Date() : colIndex === 2 ? new Date(/* Provide your min date logic for colIndex 2 */) : null}
                        customInput={<CustomDatePickerInputforReviewwdwork />}
                      />
                    ) : (
                      <input
                        className="form-control col-12"
                        placeholder=""
                        value={cell.description}
                        onChange={(e) =>
                          handleInputChange(rowIndex, colIndex, e.target.value)
                        }
                        type="text"
                      />
                    )}


                  </td>
                ))}
              </tr>
            ))}
          </tbody>


        </table>
      </div>
    </div>

  );
}

export default ReviedWork;
