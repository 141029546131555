


export const MOBILE_NUMBER = undefined;
export const USER_PROFILE = "USER_PROFILE";
export const COREPERMIT_MOBILE_NUMBER = "COREPERMIT_MOBILE_NUMBER";
export const COREPERMIT_VENDOR_NAME = "COREPERMIT_VENDOR_NAME";
export const COREPERMIT_DATE = "COREPERMIT_DATE";
export const FIRST_Time = "firstTime";
export const CorePermitM2_PERMITID = "CorePermitM2_PERMITID";
export const ADDITIONAL_PERMITID = "ADDITIONAL_PERMITID";
export const PERMIT_NAME = "PERMIT_NAME";



export async function storeData(key, value) {
  var isStored = false;
  try {
    if (value == "") {
      if (key == undefined) {
        // await localStorage.setItem(key, value);
        await sessionStorage.setItem(key, value);
      }
    }
    else {
      //await localStorage.setItem(key, JSON.stringify(value));
      await sessionStorage.setItem(key, JSON.stringify(value));
    }
    isStored = true;
    return isStored;
  } catch (error) {
    return isStored;
  }
}

export async function retrieveData(key) {
  //const value = await localStorage.getItem(key);
  const value = await sessionStorage.getItem(key);
  if (value !== null) {
    // We have data!!
    return JSON.parse(value);
  }
  else
    return "";
}


export async function filterObjects(array, field, code) {
  console.warn("crop filterting herer", array.length + "--" + field + "--" + code)
  return array.filter(data => data[field] == code);

}

export function extractDateAsDDMMYYYY(timestamp) {
  const dateObject = new Date(timestamp);
  // Get day, month, and year from the date object
  const day = dateObject.getDate().toString().padStart(2, '0'); // Ensure two digits with leading zero
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = dateObject.getFullYear();
  return `${day}-${month}-${year}`;
}


function isNotEmpty(value) {
  return value !== null && value !== undefined && value !== "";
}


// export function checkForEmptyValues(data) {
//   // debugger;
//   for (const parentKey in data) {
//     if (data.hasOwnProperty(parentKey)) {
//       const parentObject = data[parentKey];
//       let hasNonEmptyChild = false;
//       for (const childKey in parentObject) {
//         if (parentObject.hasOwnProperty(childKey) && !isNotEmpty(parentObject[childKey])) {
//           continue;
//         } else {
//           hasNonEmptyChild = true;
//           break;
//         }
//       }
//       if (!hasNonEmptyChild) {
//         return parentKey;
//       }
//     }
//   }
//   return null;
// }
//..............
export function checkForEmptyValues(data) {
  // debugger;
  for (const parentKey in data) {
    if (data.hasOwnProperty(parentKey)) {
      const parentObject = data[parentKey];
      let hasEmptyValue = false;
      for (const key in parentObject) {
        if (parentObject.hasOwnProperty(key) && !isNotEmpty(parentObject[key])) {
          hasEmptyValue = true;
          break;
        }
      }
      if (hasEmptyValue) {
        return parentKey; // Return the parentKey if any child has an empty value
      }
    }
  }
  return null; // Return null if no child within any parentObject is empty
}

// export function checkForEmptyValue(data) {
//   // debugger;
//   for (const parentKey in data) {
//     if (data.hasOwnProperty(parentKey)) {
//       const parentObject = data[parentKey];
//       let hasNonEmptyChild = false;
//       for (const childKey in parentObject) {
//         if (parentObject.hasOwnProperty(childKey) && !isNotEmpty(parentObject[childKey])) {
//           continue;
//         } else {
//           hasNonEmptyChild = true;
//           break;
//         }
//       }
//       if (!hasNonEmptyChild) {
//         return parentKey;
//       }
//     }
//   }
//   return null;
// }


export function checkForEmptyValue(data) {
  // debugger;
  for (const parentKey in data) {
    if (data.hasOwnProperty(parentKey)) {
      const parentObject = data[parentKey];
      let hasNonEmptyChild = false;
      for (const key in parentObject) {
        if (parentObject.hasOwnProperty(key) && !isNotEmpty(parentObject[key])) {
          hasNonEmptyChild = true;
          break;
        }
      }
      if (hasNonEmptyChild) {
        return parentKey;
      }
    }
  }
  return null;
}
