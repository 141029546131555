import React, { useState } from 'react'
import { useEffect } from 'react';
import { getNewListWithModifyChecked } from '../../Utilities/utils';

function FallRescue(props) {
    const handleFallRescue = (key, val, index, mainKey, subKey='') => {
        const fallRescueObj = {...props.data}
        if(mainKey == 'fallList'){
            const newList = fallRescueObj[mainKey].map((i,j)=>{
                if(j == index){
                    return {...i, [key]:val}
                } else {
                    return i
                }
            });
            props.setFallRescue(prev=> ({...prev, [mainKey]:newList}))

        } else if(mainKey == 'method'){
            const newObj = fallRescueObj[mainKey]
            const newList = fallRescueObj[mainKey].list.map((i,j)=>{
                if(j == index){
                    return {...i, [key]:val}
                } else {
                    return i
                }
            });
            newObj['list'] = newList

            props.setFallRescue(prev=> ({...prev, [mainKey]:newObj}))
        }
        else if(mainKey == 'rescueEquipment') {
            const newObj = fallRescueObj[mainKey]
            const newList = fallRescueObj[mainKey][subKey].map((i,j)=>{
                if(j == index){
                    return {...i, [key]:val}
                } else {
                    return i
                }
            });
            newObj[subKey] = newList

            props.setFallRescue(prev=> ({...prev, [mainKey]:newObj}))
        }
    }

    

  

    return (
        <div>
        {/* <div className="Corper-subtxt" style={{ backgroundColor: "rgb(255,0,0)", height: "37px" }}>
            <p className="card-subheading">People</p>
        </div> */}
        <div className="col-12" style={{ marginTop: "-20px" }}>
            <div className="Corper-subtxt criti-fr-color">
              <p className="card-subheading criti-fr-color" style={{marginRight:"-13px",marginLeft:"-13px"}}>People <span className='required'> *</span></p>
            </div>
          </div>
        {props.data.fallList.map((fItem, index) => (
            <div class="form-group">
            <label for={`fallList123${index}`}>{fItem.cbTitle}  </label>
            <div>
                <input class="form-control" type="text" id={`fallList123${index}`} placeholder={fItem.cbTitle}
                value={fItem.description}
                onChange={(e)=>handleFallRescue('description', e.target.value, index, 'fallList')}
                />
            </div>
        </div>
        ))}
        
     

        <div class="form-group row">
            <label >Method of Contacting Rescuer:</label>
            
            {props.data.method.list.map((mItem, index)=>(
                <>
                {mItem.checked !== undefined && 
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" checked={mItem.checked} onChange={(e)=>{
                                handleFallRescue('checked', !mItem.checked, index, 'method')
                            }} type="checkbox" id={`methodListFor${index}`} value="option2" />
                            <label class="form-check-label mt-2" for={`methodListFor${index}`}>{mItem.cbTitle}</label>
                        </div>
                    </div>
                }
                    {mItem.description !== undefined &&
                    <div class="form-group">
                        <div>
                            <input class="form-control" type="text" id="empt1" value={mItem.description} placeholder={''}
                            onChange={(e)=>handleFallRescue('description', e.target.value, index, 'method')}    
                            />
                        </div>
                    </div>
                }   
                </>
            ))}
        
            <div className="col-12">
            <div className="Corper-subtxt criti-fr-color">
              <p className="card-subheading criti-fr-color">Rescue Equipment</p>
            </div>
          </div>
            {props.data.rescueEquipment.list.map((mItemL1, index)=>(
                <>
                {mItemL1.checked !== undefined && 
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" checked={mItemL1.checked} onChange={(e)=>{
                                handleFallRescue('checked', !mItemL1.checked, index, 'rescueEquipment','list')
                            }} type="checkbox" id={`rescueEquipmentList1${index}`} value="option2" />
                            <label class="form-check-label mt-2" for={`rescueEquipmentList1${index}`}>{mItemL1.cbTitle}</label>
                        </div>
                    </div>
                }
                    {mItemL1.description != undefined &&
                    <div class="form-group">
                        <div>
                            <input class="form-control" type="text" id="empt1" value={mItemL1.description} placeholder={''}
                            onChange={(e)=>handleFallRescue('description', e.target.value, index, 'rescueEquipment','list')}    
                            />
                        </div>
                    </div>
                }   
                </>
            ))}

            {props.data.rescueEquipment.list2.map((mItemL2, index)=>(
                <>
                {mItemL2.checked !== undefined && 
                   <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" checked={mItemL2.checked} onChange={(e)=>{
                                handleFallRescue('checked', !mItemL2.checked, index, 'rescueEquipment','list2')
                            }} type="checkbox" id={`rescueEquipmentList23${index}`} value="option2" />
                            <label class="form-check-label mt-2" for={`rescueEquipmentList23${index}`}>{mItemL2.cbTitle}</label>
                        </div>
                    </div>
                }
                    {mItemL2.description !== undefined &&
                    <div class="form-group">
                        
                        <label class="form-check-label mt-2" for={`rescueEquipmentList23${index}`}>{mItemL2.cbTitle}</label>
                        <div>
                            <input class="form-control" type="text" id="empt1" value={mItemL2.description} placeholder={''}
                            onChange={(e)=>handleFallRescue('description', e.target.value, index, 'rescueEquipment', 'list2')}    
                            />
                        </div>
                    </div>
                }   
                </>
            ))}
          
        </div>
    </div >
             
  )
}

export default FallRescue;