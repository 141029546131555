import React, { useState, useEffect } from "react";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllPermitsByCorePermitID } from "../Utilities/URLCONSTANTS";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
//Header images
import Factory from "../images/Factory-header-corepermit.png";
import CortevaImage from "../images/corteva-logo.png";
import HouseAndLighthouse from "../images/HouseAndLighthouse.png";
import Labtubeheadercorepermit from "../images/Labtubeheadercorepermit.png";
import shakinghandsHeadersCorepermit from "../images/shakinghandsHeadersCorepermit.png";
import ImageheaderCorepermit from "../images/ImageheaderCorepermit.png";
import DroneHeaderCorepermit from "../images/DroneHeaderCorepermit.png";
import Suit from "../images/suit-man-envi-corepermit.png";
import cortevalogo from "../images/cortevalogo-white.png";
import Loader from "../../src/Utilities/Loader";

const CriticalElevatedWork_Web = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );

  const [emergency, setEmergency] = useState();
  const [assemble, setassemble] = useState();
  const [scopeOfElevatedWork, setScopeOfElevatedWork] = useState();
  //1.SCOPE OF ELEVATED WORK
  const [location, setLocation] = useState();
  const [company, setCompany] = useState();
  const [elevatedWorkScopDesc, setElevatedWorkScopDesc] = useState();
  const [seeScopOnCorePermit, setSeeScopOnCorePermit] = useState();
  const [scopeofElevatedDescription, setscopeofElevatedDescription] =
    useState();
  //2. HAZARD IDENTIFICATION
  const [hazardIdentificationSpecific, sethazardIdentificationSpecific] =
    useState();
  const [
    hazardIdentificationSpecificFalling,
    sethazardIdentificationSpecificFalling,
  ] = useState();
  const [wallOpening, setWallOpening] = useState();
  const [wallOpeningDesc, setWallOpeningDesc] = useState();

  const [flooropening, setflooropening] = useState();
  const [flooropeningDesc, setflooropeningDesc] = useState();

  const [unprotectededge, setUnprotectededge] = useState();
  const [unprotectededgeDesc, setUnprotectededgeDesc] = useState();

  const [unstableOrUnusualWorkingSurface, setUnstableOrUnusualWorkingSurface] =
    useState();
  const [
    unstableOrUnusualWorkingSurfaceDesc,
    setUnstableOrUnusualWorkingSurfaceDesc,
  ] = useState();

  const [other, setother] = useState();
  const [otherDesc, setotherDesc] = useState();

  //CORE Permit #:
  const [initialsOne, setInitialsOne] = useState("");
  const [initialsTwo, setInitialsTwo] = useState("");
  const [initialsThree, setInitialsThree] = useState("");

  const [dateInputOne, setDateInputOne] = useState("");

  const [permitsOne, setPermitsOne] = useState("");
  const [permitsTwo, setPermitsTwo] = useState("");

  //3. HAZARD MITIGATION
  // a)Work Access:
  const [hazardMitigationWork, sethazardMitigationWork] = useState();
  // b)The Precations used to control or eliminate the hazards(select one or more):
  const [hazardMiFallResistanceCheck, sethazardMiFallResistanceCheck] =
    useState();
  const [hazardMiFallResistanceInput, sethazardMiFallResistanceInput] =
    useState();

  const [hazardMiTraficCheck, sethazardMiTraficCheck] = useState();
  const [hazardMiTraficInput, sethazardMiTraficInput] = useState();

  const [hazardMiArrestCheck, sethazardMiArrestCheck] = useState();
  const [hazardMiArrestInput, sethazardMiArrestInput] = useState();

  const [hazardMiOtherCheck, sethazardMiOtherCheck] = useState();
  const [hazardMiOthertInput, sethazardMiOtherInput] = useState();

  const [hazardMiTemporaryCheck, sethazardMiTemporaryCheck] = useState();
  const [hazardMiTemporaryInput, sethazardMiTemporaryInput] = useState();

  const [hazardMiSafetyCheck, sethazardMiSafetyCheck] = useState();
  const [hazardMiSafetyInput, sethazardMiSafetyInput] = useState();

  const [hazardMiSpecificCheck, sethazardMiSpecificCheck] = useState();
  const [hazardMiSpecificInput, sethazardMiSpecificInput] = useState();

  //c)Anchorage Points(if required):
  const [ancPointsArrestCheck, setancPointsArrestCheck] = useState();
  const [ancPointsArrestInput, setancPointsArrestInput] = useState();

  const [ancPointsResistantCheck, setancPointsResistantCheck] = useState();
  const [ancPointsResistantInput, setancPointsResistantInput] = useState();

  const [ancPointsMobileCheck, setancPointsMobileCheck] = useState();
  const [ancPointsMobileInput, setancPointsMobileInput] = useState();

  const [ancPointsOtherCheck, setancPointsOtherCheck] = useState();
  const [ancPointsOtherInput, setancPointsOtherInput] = useState();
  //d)Falling object control(select all that apply):
  const [fallingbarricadesCheck, setfallingbarricadesCheck] = useState();
  const [fallingbarricadesInput, setfallingbarricadesInput] = useState();

  const [fallingbagsCheck, setfallingbagsCheck] = useState();
  const [fallingbagsInput, setfallingbagsInput] = useState();

  const [fallingToeCheck, setfallingToeCheck] = useState();
  const [fallingToeInput, setfallingToeInput] = useState();

  const [fallingToolCheck, setfallingToolCheck] = useState();
  const [fallingToolInput, setfallingToolInput] = useState();

  const [fallingOtherCheck, setfallingOtherCheck] = useState();
  const [fallingOtherInput, setfallingOtherInput] = useState();
  //ROOF ACCESS
  const [roofAccess, setroofAccess] = useState();
  const [stor0, setstor0] = useState();
  const [stor1, setstor1] = useState();

  const [wts0, setwts0] = useState();
  const [wts1, setwts1] = useState();

  const [mdf0, setmdf0] = useState();
  const [mdf1, setmdf1] = useState();
  const [mdf2, setmdf2] = useState();

  const [hsoh0, sethsoh0] = useState();
  const [hsoh1, sethsoh1] = useState();
  const [hsoh2, sethsoh2] = useState();
  const [hsoh3, sethsoh3] = useState();
  const [hsoh4, sethsoh4] = useState();
  const [hsoh5, sethsoh5] = useState();
  const [hsohInput5, sethsohInput5] = useState();
  //I HAVE REVIED AND UNDERSTOOD THE ABOVE CRITICAL ELEVATED WORK PERMIT
  //Terms
  const [l0U0, setl0U0] = useState();
  const [l0F0, setl0F0] = useState();
  const [l0D0, setl0D0] = useState();
  const [l0U1, setl0U1] = useState();
  const [l0F1, setl0F1] = useState();
  const [l0D1, setl0D1] = useState();

  const [l1U0, setl1U0] = useState();
  const [l1F0, setl1F0] = useState();
  const [l1D0, setl1D0] = useState();
  const [l1U1, setl1U1] = useState();
  const [l1F1, setl1F1] = useState();
  const [l1D1, setl1D1] = useState();

  const [l2U0, setl2U0] = useState();
  const [l2F0, setl2F0] = useState();
  const [l2D0, setl2D0] = useState();
  const [l2U1, setl2U1] = useState();
  const [l2F1, setl2F1] = useState();
  const [l2D1, setl2D1] = useState();

  const [l3U0, setl3U0] = useState();
  const [l3F0, setl3F0] = useState();
  const [l3D0, setl3D0] = useState();
  const [l3U1, setl3U1] = useState();
  const [l3F1, setl3F1] = useState();
  const [l3D1, setl3D1] = useState();

  const [hazardMitigation, setHazardMitigation] = useState();
  // const [roofAccess, setRoofAccess] = useState();
  const [fallRescue, setFallRescue] = useState();
  const [additionalComments, setAdditionalComments] = useState();

  const [description, setdescription] = useState();

  const [terms, setTerms] = useState();
  const [signature, setSignature] = useState(); //-----------------------------------------------Signature

  const [criticalElevated, setCriticalElevated] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  //const [description, setDescription] = useState();
  const [title, settitle] = useState();

  // SIGNATURES
  const [sigdate, setsigdate] = useState();
  const [todate, setTodate] = useState("");
  const [sigWorkPlanner, setsigWorkPlanner] = useState();
  const [sigWorkPlannerPhoneNumber, setsigWorkPlannerPhoneNumber] = useState();
  const [
    sigCriticalElevatedWorkPermitApprover,
    setsigCriticalElevatedWorkPermitApprover,
  ] = useState();
  const [
    sigCriticalElevatedWorkPermitApproverPhoneNumber,
    setsigCriticalElevatedWorkPermitApproverPhoneNumber,
  ] = useState();
  const [
    sigStructuralEngineerApprovalAnchorage,
    setsigStructuralEngineerApprovalAnchorage,
  ] = useState();
  const [
    sigStructuralEngineerApprovalAnchoragePhoneNumber,
    setsigStructuralEngineerApprovalAnchoragePhoneNumber,
  ] = useState();
  const [sigEHSSLeaderApproval, setsigEHSSLeaderApproval] = useState();
  const [
    sigEHSSLeaderApprovalPhoneNumber,
    setsigEHSSLeaderApprovalPhoneNumber,
  ] = useState();

  //FALL RESUE
  const [contacts, setcontacts] = useState();
  const [rescurs, setrescurs] = useState();
  const [standby, setstandby] = useState();
  //Method of Contacting Rescuer:
  const [mcr0, setmcr0] = useState();
  const [mcr1, setmcr1] = useState();
  const [mcrInput1, setmcrInput1] = useState();
  const [mcr2, setmcr2] = useState();
  const [mcrInput2, setmcrInput2] = useState();
  //Rescue Equipment
  const [mcre0, setmcre0] = useState();
  const [mcre1, setmcre1] = useState();
  const [mcre2, setmcre2] = useState();
  const [mcre3, setmcre3] = useState();
  const [mcrInput3, setmcrInput3] = useState();

  const [mcreInput0, setmcreInput0] = useState();
  const [mcreInput1, setmcreInput1] = useState();
  const [mcreInput2, setmcreInput2] = useState();
  const [mcreInput3, setmcreInput3] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  // const IdFromURL = searchParams.get('corePermitID');
  const IdFromURLwithEDit = searchParams.get("corePermitID");
  var IdFromURL;
  var additional_permit_id;
  if (IdFromURLwithEDit == null) {
  } else {
    IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
    additional_permit_id = IdFromURLwithEDit.toString().split("/")[1];
  }

  const [apiStatus, setApiStatus] = useState("");
  const [showApproved, setShowApproved] = useState(false);
  useEffect(() => {
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0,
      additional_permit_id != undefined &&
        additional_permit_id != null &&
        additional_permit_id != ""
        ? additional_permit_id
        : 0
    );
  }, []);

  const getFormData = async (id, additional_permit_id) => {
    console.log(id);
    try {
      setIsLoading(true);
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: "",
        name: "",
        PermitID: id,
      });
      setIsLoading(false);
      console.log("id:::", id);
      console.log("response data:", response.data);
      // Check if the response contains data
      if (response.data && response.data.response) {
        // const dataLast = response?.data?.response?.critical_elevated_permit || []
        // alert("hello");
        const elevated =
          response?.data?.response?.critical_elevated_permit || [];
        // alert(JSON.stringify( elevated));
        const dataLast =
          elevated.find((item) => item.permitID === additional_permit_id)
            ?.permitObject || null;
        console.log("filteredArray checking", JSON.stringify(dataLast));

        // Set state values based on the retrieved data
        if (dataLast) {
          setInitialsOne(dataLast?.permitInitials.initialsOne);
          setInitialsTwo(dataLast?.permitInitials.initialsTwo);
          setInitialsThree(dataLast?.permitInitials.initialsThree);

          setDateInputOne(
            dataLast?.permitInitials.dateInputOne
              ? moment(dataLast.permitInitials.dateInputOne).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );

          setPermitsOne(dataLast?.permitInitials.permitsOne);
          setPermitsTwo(dataLast?.permitInitials.permitsTwo);

          setEmergency(dataLast?.emergency.phoneNumber);
          setassemble(dataLast?.emergency.assemblyLocation);

          //1. SCOPE OF ELEVATED WORK
          // alert(dataLast?.scopeOfElevatedWork.Location(building/area/equipment))
          const loc = Object.values(dataLast?.scopeOfElevatedWork);
          setCompany(dataLast?.scopeOfElevatedWork.Company);
          setLocation(loc[0]);
          setElevatedWorkScopDesc(loc[2]);
          if (dataLast?.scopeOfElevatedWork.checked == true) {
            setSeeScopOnCorePermit("checked");
          }

          //ADDITIONAL COMMENTS & MODIFICATIONS
          setAdditionalComments(dataLast?.additionalComments.description);

          //alert(dataLast?.scopeOfElevatedWork.description);
          setscopeofElevatedDescription(
            dataLast?.scopeOfElevatedWork.description
          );

          //2. HAZARD IDENTIFICATION
          const hazard = Object.values(dataLast?.hazardIdentification);
          sethazardIdentificationSpecific(hazard[0]);
          sethazardIdentificationSpecificFalling(hazard[1]);
          if (dataLast?.hazardIdentification.hazard["0"].checked == true) {
            setWallOpening("checked");
            setWallOpeningDesc(
              dataLast?.hazardIdentification.hazard["0"].description
            );
          }
          if (dataLast?.hazardIdentification.hazard["1"].checked == true) {
            setflooropening("checked");
            setflooropeningDesc(
              dataLast?.hazardIdentification.hazard["1"].description
            );
          }
          if (dataLast?.hazardIdentification.hazard["2"].checked == true) {
            setUnprotectededge("checked");
            setUnprotectededgeDesc(
              dataLast?.hazardIdentification.hazard["2"].description
            );
          }
          if (dataLast?.hazardIdentification.hazard["3"].checked == true) {
            setUnstableOrUnusualWorkingSurface("checked");
            setUnstableOrUnusualWorkingSurfaceDesc(
              dataLast?.hazardIdentification.hazard["3"].description
            );
          }
          if (dataLast?.hazardIdentification.hazard["4"].checked == true) {
            setother("checked");
            setotherDesc(
              dataLast?.hazardIdentification.hazard["4"].description
            );
          }
          // alert(Object.values((dataLast?.scopeOfElevatedWork)));

          //3. HAZARD MITIGATION
          // a)Work Access:
          sethazardMitigationWork(
            dataLast?.hazardMitigation.workAccess["0"].description
          );
          // b)The Precations used to control or eliminate the hazards(select one or more):
          if (dataLast?.hazardMitigation.Hazard["0"].checked == true) {
            sethazardMiFallResistanceCheck("checked");
            sethazardMiFallResistanceInput(
              dataLast?.hazardMitigation.Hazard["0"].description
            );
          }
          if (dataLast?.hazardMitigation.Hazard["1"].checked == true) {
            sethazardMiTraficCheck("checked");
            sethazardMiTraficInput(
              dataLast?.hazardMitigation.Hazard["1"].description
            );
          }
          if (dataLast?.hazardMitigation.Hazard["2"].checked == true) {
            sethazardMiArrestCheck("checked");
            sethazardMiArrestInput(
              dataLast?.hazardMitigation.Hazard["2"].description
            );
          }
          if (dataLast?.hazardMitigation.Hazard["3"].checked == true) {
            sethazardMiOtherCheck("checked");
            sethazardMiOtherInput(
              dataLast?.hazardMitigation.Hazard["3"].description
            );
          }
          if (dataLast?.hazardMitigation.Hazard["4"].checked == true) {
            sethazardMiTemporaryCheck("checked");
            sethazardMiTemporaryInput(
              dataLast?.hazardMitigation.Hazard["4"].description
            );
          }
          if (dataLast?.hazardMitigation.Hazard["5"].checked == true) {
            sethazardMiSafetyCheck("checked");
            sethazardMiSafetyInput(
              dataLast?.hazardMitigation.Hazard["5"].description
            );
          }
          if (dataLast?.hazardMitigation.Hazard["6"].checked == true) {
            sethazardMiSpecificCheck("checked");
            sethazardMiSpecificInput(
              dataLast?.hazardMitigation.Hazard["5"].description
            );
          }
          //c)Anchorage Points(if required):
          if (dataLast?.hazardMitigation.anchoragePoint["0"].checked == true) {
            setancPointsArrestCheck("checked");
            setancPointsArrestInput(
              dataLast?.hazardMitigation.anchoragePoint["0"].description
            );
          }
          if (dataLast?.hazardMitigation.anchoragePoint["1"].checked == true) {
            setancPointsResistantCheck("checked");
            setancPointsResistantInput(
              dataLast?.hazardMitigation.anchoragePoint["1"].description
            );
          }

          // if (dataLast?.hazardMitigation.anchoragePoint['1'].checked == true) {
          //     setancPointsResistantCheck('checked');
          //     setancPointsResistantInput(dataLast?.hazardMitigation.Hazard['1'].description);
          // }
          if (dataLast?.hazardMitigation.anchoragePoint["2"].checked == true) {
            setancPointsMobileCheck("checked");
            setancPointsMobileInput(
              dataLast?.hazardMitigation.anchoragePoint["2"].description
            );
          }
          if (dataLast?.hazardMitigation.anchoragePoint["3"].checked == true) {
            setancPointsOtherCheck("checked");
            setancPointsOtherInput(
              dataLast?.hazardMitigation.anchoragePoint["3"].description
            );
          }
          //d)Falling object control(select all that apply):
          if (
            dataLast?.hazardMitigation.fallingObjectCount["0"].checked == true
          ) {
            setfallingbarricadesCheck("checked");
            setfallingbarricadesInput(
              dataLast?.hazardMitigation.fallingObjectCount["0"].description
            );
          }
          if (
            dataLast?.hazardMitigation.fallingObjectCount["1"].checked == true
          ) {
            setfallingbagsCheck("checked");
            setfallingbagsInput(
              dataLast?.hazardMitigation.fallingObjectCount["1"].description
            );
          }
          if (
            dataLast?.hazardMitigation.fallingObjectCount["2"].checked == true
          ) {
            setfallingToeCheck("checked");
            setfallingToeInput(
              dataLast?.hazardMitigation.fallingObjectCount["2"].description
            );
          }
          if (
            dataLast?.hazardMitigation.fallingObjectCount["3"].checked == true
          ) {
            setfallingToolCheck("checked");
            setfallingToolInput(
              dataLast?.hazardMitigation.fallingObjectCount["3"].description
            );
          }
          if (
            dataLast?.hazardMitigation.fallingObjectCount["4"].checked == true
          ) {
            setfallingOtherCheck("checked");
            setfallingOtherInput(
              dataLast?.hazardMitigation.fallingObjectCount["4"].description
            );
          }
          //ROOF ACCESS
          if (dataLast?.roofAccess.checked == true) {
            setroofAccess("checked");
          }
          if (dataLast?.roofAccess.slopeType.list["0"].checked == true) {
            setstor0("checked");
          }

          if (dataLast?.roofAccess.slopeType.list["0"].checked == true) {
            setstor1("checked");
          }

          if (dataLast?.roofAccess.workType.list["0"].checked == true) {
            setwts0("checked");
          }
          if (dataLast?.roofAccess.workType.list["1"].checked == true) {
            setwts1("checked");
          }
          if (dataLast?.roofAccess.distance.list["0"].checked == true) {
            setmdf0("checked");
          }
          if (dataLast?.roofAccess.distance.list["1"].checked == true) {
            setmdf1("checked");
          }
          if (dataLast?.roofAccess.distance.list["2"].checked == true) {
            setmdf2("checked");
          }
          if (dataLast?.roofAccess.distance.list["3"].checked == true) {
            sethsoh0("checked");
          }
          if (dataLast?.roofAccess.distance.list["4"].checked == true) {
            sethsoh1("checked");
          }
          if (dataLast?.roofAccess.distance.list["5"].checked == true) {
            sethsoh2("checked");
          }
          if (dataLast?.roofAccess.distance.list["6"].checked == true) {
            sethsoh3("checked");
          }
          if (dataLast?.roofAccess.distance.list["7"].checked == true) {
            sethsoh4("checked");
          }
          if (dataLast?.roofAccess.distance.list["8"].checked == true) {
            sethsoh5("checked");
          }

          sethsohInput5(dataLast?.roofAccess.distance.list["8"].description);
          //I HAVE REVIED AND UNDERSTOOD THE ABOVE CRITICAL ELEVATED WORK PERMIT
          setl0U0(dataLast?.terms.list1["0"].description);
          setl0F0(dataLast?.terms.list1["1"].description);

          setl0D0(
            dataLast?.terms.list1["2"].description
              ? moment(dataLast?.terms.list1["2"].description).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );
          setl0U1(dataLast?.terms.list1["3"].description);
          setl0F1(dataLast?.terms.list1["4"].description);

          setl0D1(
            dataLast?.terms.list1["5"].description
              ? moment(dataLast?.terms.list1["5"].description).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );

          setl1U0(dataLast?.terms.list2["0"].description);
          setl1F0(dataLast?.terms.list2["1"].description);

          setl1D0(
            dataLast?.terms.list2["2"].description
              ? moment(dataLast?.terms.list2["2"].description).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );
          setl1U1(dataLast?.terms.list2["3"].description);
          setl1F1(dataLast?.terms.list2["4"].description);
          // setl1D1(
          //   moment(dataLast?.terms.list2["5"].description).format("DD-MMM-YYYY")
          // );

          setl1D1(
            dataLast?.terms.list2["5"].description
              ? moment(dataLast?.terms.list2["5"].description).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );

          setl2U0(dataLast?.terms.list3["0"].description);
          setl2F0(dataLast?.terms.list3["1"].description);
          // setl2D0(
          //   moment(dataLast?.terms.list3["2"].description).format("DD-MMM-YYYY")
          // );

          setl2D0(
            dataLast?.terms.list3["2"].description
              ? moment(dataLast?.terms.list3["2"].description).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );
          setl2U1(dataLast?.terms.list3["3"].description);
          setl2F1(dataLast?.terms.list3["4"].description);
          // setl2D1(dataLast?.terms.list3['5'].description);
          // setl2D1(
          //   moment(dataLast?.terms.list3["5"].description).format("DD-MMM-YYYY")
          // );
          setl2D1(
            dataLast?.terms.list3["5"].description
              ? moment(dataLast?.terms.list3["5"].description).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );

          setl3U0(dataLast?.terms.list4["0"].description);
          setl3F0(dataLast?.terms.list4["1"].description);
          // setl3D0(dataLast?.terms.list4['2'].description);
          // setl3D0(
          //   moment(dataLast?.terms.list4["2"].description).format("DD-MMM-YYYY")
          // );

          setl3D0(
            dataLast?.terms.list4["2"].description
              ? moment(dataLast?.terms.list4["2"].description).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );
          setl3U1(dataLast?.terms.list4["3"].description);
          setl3F1(dataLast?.terms.list4["4"].description);
          // setl3D1(dataLast?.terms.list4['5'].description);
          // setl3D1(
          //   moment(dataLast?.terms.list4["5"].description).format("DD-MMM-YYYY")
          // );

          setl3D1(
            dataLast?.terms.list4["5"].description
              ? moment(dataLast?.terms.list4["5"].description).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );

          //FALL RESUE
          setcontacts(dataLast?.fallRescue.fallList["0"].description);
          setrescurs(dataLast?.fallRescue.fallList["1"].description);
          setstandby(dataLast?.fallRescue.fallList["2"].description);

          //SIGNATURES
          // setsigdate(
          //   moment(dataLast?.signature.dates["0"].fromDate).format(
          //     "DD-MMM-YYYY"
          //   )
          // );

          setsigdate(
            dataLast?.signature.dates["0"].fromDate
              ? moment(dataLast?.signature.dates["0"].fromDate).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );

          // setTodate(
          //   moment(dataLast?.signature.dates["0"].toDate).format("DD-MMM-YYYY")
          // );
          setTodate(
            dataLast?.signature.dates["0"].toDate
              ? moment(dataLast?.signature.dates["0"].toDate).format(
                  "DD-MMM-YYYY"
                )
              : ""
          );

          setsigWorkPlanner(dataLast?.signature.workPlanner.description);
          setsigWorkPlannerPhoneNumber(
            dataLast?.signature.workPlanner.phoneNumber
          );
          setsigCriticalElevatedWorkPermitApprover(
            dataLast?.signature.criticalElevated.description
          );
          setsigCriticalElevatedWorkPermitApproverPhoneNumber(
            dataLast?.signature.criticalElevated.phoneNumber
          );
          setsigStructuralEngineerApprovalAnchorage(
            dataLast?.signature.structuralEngineer.description
          );
          setsigStructuralEngineerApprovalAnchoragePhoneNumber(
            dataLast?.signature.structuralEngineer.phoneNumber
          );
          setsigEHSSLeaderApproval(dataLast?.signature.ehss.description);
          setsigEHSSLeaderApprovalPhoneNumber(
            dataLast?.signature.ehss.phoneNumber
          );

          //Method of Contacting Rescuer:
          if (dataLast?.fallRescue.method.list["0"].checked == true) {
            setmcr0("checked");
          }
          if (dataLast?.fallRescue.method.list["1"].checked == true) {
            setmcr1("checked");
          }
          if (dataLast?.fallRescue.method.list["2"].checked == true) {
            setmcr2("checked");
          }
          setmcrInput1(dataLast?.fallRescue.method.list["1"].description);
          setmcrInput2(dataLast?.fallRescue.method.list["2"].description);

          //Rescue Equipment
          if (dataLast?.fallRescue.rescueEquipment.list["0"].checked == true) {
            setmcre0("checked");
          }
          if (dataLast?.fallRescue.rescueEquipment.list["1"].checked == true) {
            setmcre1("checked");
          }
          if (dataLast?.fallRescue.rescueEquipment.list["2"].checked == true) {
            setmcre2("checked");
          }
          if (dataLast?.fallRescue.rescueEquipment.list["3"].checked == true) {
            setmcre3("checked");
          }
          setmcre3(dataLast?.fallRescue.rescueEquipment.list["3"].description);
          setmcreInput0(
            dataLast?.fallRescue.rescueEquipment.list2["0"].description
          );
          setmcreInput1(
            dataLast?.fallRescue.rescueEquipment.list2["1"].description
          );
          setmcreInput2(
            dataLast?.fallRescue.rescueEquipment.list2["2"].description
          );
          setmcreInput3(
            dataLast?.fallRescue.rescueEquipment.list2["3"].description
          );
        }
      } else {
        // Handle the case where the response does not contain the expected data
        console.log("Response data is missing or invalid.");
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {}, [scopeOfElevatedWork]);
  return (
    <body id="page-top">
      <div style={{ textAlign: "right" }}>
        <button onClick={handlePrint} className="btn btn-primary print-hidden ">
          Print
        </button>
      </div>
      <div id="wrapper">
        {/* <SideMenu /> */}
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            {/* <Header /> */}
            {/* <form className="CorePermit_Web-form cew"> */}
            <form className="CorePermit_Web-form">
              <div>
                <div className="container">
                  <div>
                    <div>
                      <div>
                        <div className="row">
                          <div
                            className="d-flex flex-row"
                            style={{
                              margin: "0px",
                              padding: "2px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                backgroundColor: "#2373BE",
                              }}
                            >
                              <div
                                className="d-flex flex-row"
                                style={{
                                  backgroundColor: "#E6E8E9",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  className="d-flex flex-column"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    width: "47%",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "bolder",
                                      fontSize: "30px",
                                      color: "#000000",
                                      padding: "0px 20px",
                                      margin: "2px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    CRITICAL ELEVATED WORK
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "13px",
                                      color: "#000000",
                                      padding: "0px 20px",
                                      margin: "-10px 2px 2px",
                                    }}
                                  >
                                    Core Permit Add-on for Critical Elevated
                                    Work
                                  </div>
                                </div>
                                <div
                                  className="d-flex flex-row"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    width: "20%",
                                    backgroundColor: "#2373BE",
                                  }}
                                >
                                  <div className="d-flex flex-column">
                                    <div
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: "30px",
                                        color: "#000000",
                                        padding: "0px 20px",
                                        margin: "0px",
                                        color: "#fff",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      PERMIT
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        color: "#000000",
                                        padding: "0px 20px",
                                        margin: "-10px 2px 2px",
                                        color: "#fff",
                                      }}
                                    >
                                      FALL PROTECTION
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      padding: "0px",
                                      margin: "13px 0px 0px -10px",
                                      color: "#fff",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <img
                                      src={Suit}
                                      alt="img"
                                      style={{ width: "40px", height: "40px" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "30%",
                                    backgroundColor: "#2373BE",
                                  }}
                                >
                                  <div
                                    className="d-flex flex-row"
                                    style={{
                                      backgroundColor: "#ffffff",
                                      margin: "5px",
                                      padding: "5px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#000",
                                        fontSize: "12px",
                                        margin: "0px 10px",
                                        fontWeight: "bolder",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          padding: "0px 5px",
                                          margin: "0px 10px 0px 0px",
                                        }}
                                      >
                                        Critical
                                        <br />
                                        Permit#:
                                      </p>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div className="mr-2">
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsOne}
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsTwo}
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={initialsThree}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          Initials
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div className="d-flex flex-row">
                                          <div className="mr-2">
                                            <input
                                              type=""
                                              className="microsite-txtbxxdate"
                                              value={dateInputOne}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          Date
                                        </label>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="row">
                                        <div>
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={permitsOne}
                                          />
                                          <input
                                            type="text"
                                            className="header-txtbx"
                                            value={permitsTwo}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <label className="col-12 col-form-label header-lbl">
                                          #permits
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-1">
                          <div
                            className="d-flex flex-row"
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            <div
                              style={{ backgroundColor: "red", width: "40%" }}
                            >
                              <div
                                className="d-flex flex-row"
                                style={{ padding: "5px" }}
                              >
                                <div>
                                  <i className="phonerining m-1 p-1"></i>
                                </div>
                                <div>
                                  <label
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      padding: "5px",
                                    }}
                                  >
                                    Emergency
                                  </label>
                                </div>
                                <div
                                  className="d-flex flex-row"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    backgroundColor: "#ffffff",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <label
                                    class="col-3"
                                    style={{ fontSize: "9px" }}
                                  >
                                    Phone <br />
                                    Number
                                  </label>
                                  <input
                                    type="text"
                                    class="col-3"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#DEE5FF",
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                    }}
                                    value={emergency}
                                  />
                                  <label
                                    class="col-3"
                                    style={{ fontSize: "9px" }}
                                  >
                                    Assembly <br />
                                    Location
                                  </label>
                                  <input
                                    type="text"
                                    class="col-3"
                                    style={{
                                      border: "none",
                                      backgroundColor: "#DEE5FF",
                                      padding: "0px",
                                      marginTop: "7px",
                                      marginBottom: "7px",
                                    }}
                                    value={assemble}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                margin: "2px",
                                padding: "2px",
                                width: "40%",
                              }}
                            >
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #F4673C" }}
                              >
                                <img
                                  src={Factory}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #E0A350" }}
                              >
                                <img
                                  src={HouseAndLighthouse}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #34B85F" }}
                              >
                                <img
                                  src={Labtubeheadercorepermit}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #4275BA" }}
                              >
                                <img
                                  src={shakinghandsHeadersCorepermit}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #FFC85B" }}
                              >
                                <img
                                  src={ImageheaderCorepermit}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                className="header-icons"
                                style={{ border: "1px solid #793637" }}
                              >
                                <img
                                  src={DroneHeaderCorepermit}
                                  alt="img"
                                  className="header-img"
                                />
                              </span>
                              <span
                                style={{
                                  display: "inline-block",
                                  border: "none",
                                  padding: "3px",
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  width: "35px",
                                  height: "35px",
                                  marginright: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                <img
                                  src={CortevaImage}
                                  alt="img"
                                  style={{ width: "120px", height: "23px" }}
                                />
                              </span>
                            </div>

                            <div
                              style={{
                                margin: "2px",
                                padding: "2px",
                                display: "none",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    display: "inline-block",
                                    border: "none",
                                    padding: "3px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                    width: "35px",
                                    height: "35px",
                                    marginright: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  <img
                                    src={CortevaImage}
                                    alt="img"
                                    style={{ width: "170px", height: "37px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div
                            className="card"
                            style={{
                              border: "1px solid #000",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="row">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontWeight: "bold",
                                  backgroundColor: "#000",
                                  color: "white",
                                  margin: "0px",
                                }}
                              >
                                <p style={{ margin: "5px" }}>
                                  SCOPE OF ELEVATED WORK
                                </p>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              <div className="row">
                                <div style={{ padding: "0px 0px 0px 12px" }}>
                                  <div>
                                    <div class="form-group row frm-grp-mb">
                                      <label class="col-3 col-form-label lbl-bg-color-cew label-height-cew">
                                        Location (building/area/equipment)
                                      </label>
                                      <div className="col-4">
                                        {/* {scopeOfElevatedWork !== undefined && scopeOfElevatedWork["Location(building/area/equipment)"] !== undefined && (
                                                                                        <input class="col-12" value={scopeOfElevatedWork["Location(building/area/equipment)"]} />
                                                                                    )} */}
                                        <input
                                          class="col-12"
                                          type="text"
                                          value={location}
                                        />
                                      </div>
                                      <label class="col-1 col-form-label lbl-bg-color-cew  label-height-cew">
                                        Company
                                      </label>
                                      <div className="col-4">
                                        {/* {scopeOfElevatedWork !== undefined && scopeOfElevatedWork["Company"] !== undefined && (
                                                                                        <input class="col-12" value={scopeOfElevatedWork["Company"]} />
                                                                                    )} */}
                                        <input
                                          class="col-12"
                                          type="text"
                                          value={company}
                                        />
                                      </div>
                                    </div>

                                    <div class="form-group row frm-grp-mb">
                                      <label class="col-3 col-form-label lbl-bg-color-cew  label-height-cew">
                                        Elevated work scope/description
                                      </label>
                                      <div className="col-6">
                                        <input
                                          className="col-12"
                                          value={elevatedWorkScopDesc}
                                        />
                                      </div>
                                      <div class="col-2 form-check form-check-inline d-flex align-items-center">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          name="answer1"
                                          id="option1"
                                          checked={seeScopOnCorePermit}
                                        />
                                        <label class="form-check-label">
                                          See Scope on Core Permit
                                        </label>
                                      </div>
                                    </div>
                                    <div class="form-group row frm-grp-mb">
                                      <div className="pl-0">
                                        <textarea
                                          class="col-12"
                                          rows={12}
                                          value={scopeofElevatedDescription}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* sai */}
                      <div className="row mt-3">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #000",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#000",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>
                                HAZARD IDENTIFICATION
                              </p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div>
                              <div className="row">
                                <label
                                  class="col-12 col-form-label lbl-bg-color-cew lbl-bg-color"
                                  style={{
                                    padding: "15px",
                                    margin: "0px",
                                    lineHeight: "1px",
                                  }}
                                >
                                  Specific fall hazards:
                                  {hazardIdentificationSpecific}
                                </label>

                                {/* <div className='col-12'> */}
                                <div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={wallOpening}
                                        />
                                        <label class="form-check-label">
                                          Wall Opening(s):
                                        </label>
                                      </div>
                                      <div className="col-10">
                                        <input
                                          class="col-12"
                                          value={wallOpeningDesc}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={flooropening}
                                        />
                                        <label class="form-check-label">
                                          Floor Opening(s):
                                        </label>
                                      </div>
                                      <div className="col-10">
                                        <input
                                          class="col-12"
                                          value={flooropeningDesc}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={unprotectededge}
                                        />
                                        <label class="form-check-label">
                                          Unprotected edge:
                                        </label>
                                      </div>
                                      <div className="col-10">
                                        <input
                                          class="col-12"
                                          value={unprotectededgeDesc}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-3 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={
                                            unstableOrUnusualWorkingSurface
                                          }
                                        />
                                        <label class="form-check-label">
                                          Unstable or unusual Working surface:
                                        </label>
                                      </div>
                                      <div className="col-9">
                                        <input
                                          class="col-12"
                                          value={
                                            unstableOrUnusualWorkingSurfaceDesc
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-9 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={other}
                                        />
                                        <label class="form-check-label">
                                          other(i.e. engaging lift drive tires
                                          while elevated of use of a forklift
                                          man-basket,non permanently installed
                                          fall anchor point):
                                        </label>
                                      </div>
                                      <div className="col-3">
                                        <input
                                          class="col-12"
                                          value={otherDesc}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div class="form-group row frm-grp-mb">
                                                                        <div className="d-flex flex-row">

                                                                            <div className="col-12">
                                                                                <input class="col-12" />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-3 col-form-label lbl-bg-color-cew  label-height-cew">
                                      Specific falling object hazards:
                                    </label>
                                    <div className="col-9">
                                      <input
                                        class="col-12"
                                        type="text"
                                        value={
                                          hazardIdentificationSpecificFalling
                                        }
                                      />
                                    </div>
                                  </div>

                                  {/* <div class="form-group row frm-grp-mb">
                                                                        <div className="d-flex flex-row">

                                                                            <div className="col-12">
                                                                                <input class="col-12" />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #DF3A1C",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#DF3A1C",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>HAZARD MITIGATION</p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div>
                              <div className="row">
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-3 col-form-label criti-hm-bg-color">
                                    Work Access:
                                  </label>
                                  <label class="col-3 col-form-label lbl-bg-color-cew">
                                    How personnel will access he work area:
                                  </label>
                                  <div className="col-6">
                                    <input
                                      class="col-12"
                                      value={hazardMitigationWork}
                                    />
                                  </div>
                                </div>

                                {/* <div class="form-group row frm-grp-mb">
                                                                    <div className="col-12">
                                                                        <input class="col-12" />
                                                                    </div>
                                                                </div> */}

                                <div class="form-group row frm-grp-mb ">
                                  <label class="col-12 col-form-label criti-hm-bg-color">
                                    The Precations used to control or eliminate
                                    the hazards(select one or more):
                                  </label>
                                </div>

                                {/* <div class="form-group row frm-grp-mb">
                                                                                <div className="d-flex flex-row p-0">
                                                                                    <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                                                                        <input class="form-check-input m-2 ml-0" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                                        <label class="form-check-label" >Wall Opening(s):</label>
                                                                                    </div>
                                                                                    <div className="col-10">
                                                                                        <input class="col-12" />
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                {/* <div className="row">













                                                                <div className="col-6"> */}
                                <div class="form-group row frm-grp-mb">
                                  <div className="d-flex flex-row p-0">
                                    <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                      <input
                                        class="form-check-input m-2 ml-0"
                                        type="checkbox"
                                        name="answer1"
                                        id="option1"
                                        value="option1"
                                        checked={hazardMiFallResistanceCheck}
                                      />
                                      <label class="form-check-label">
                                        Fall restraint:
                                      </label>
                                    </div>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        value={hazardMiFallResistanceInput}
                                      />
                                    </div>
                                    <div class="col-3 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                      <input
                                        class="form-check-input  m-2 ml-0"
                                        type="checkbox"
                                        name="answer1"
                                        id="option1"
                                        value="option1"
                                        checked={hazardMiTraficCheck}
                                      />
                                      <label class="form-check-label">
                                        Traffic:(pedestrain,vehicle,etc)
                                      </label>
                                    </div>
                                    <div className="col-3">
                                      <input
                                        class="col-12"
                                        value={hazardMiTraficInput}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group row frm-grp-mb">
                                  <div className="d-flex flex-row p-0">
                                    <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                      <input
                                        class="form-check-input  m-2 ml-0"
                                        type="checkbox"
                                        name="answer1"
                                        id="option1"
                                        value="option1"
                                        checked={hazardMiArrestCheck}
                                      />
                                      <label class="form-check-label">
                                        Fall arrest:
                                      </label>
                                    </div>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        value={hazardMiArrestInput}
                                      />
                                    </div>
                                    <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                      <input
                                        class="form-check-input  m-2 ml-0"
                                        type="checkbox"
                                        name="answer1"
                                        id="option1"
                                        value="option1"
                                        checked={hazardMiOtherCheck}
                                      />
                                      <label class="form-check-label">
                                        Other:
                                      </label>
                                    </div>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        value={hazardMiOthertInput}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <div className="d-flex flex-row p-0">
                                    <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                      <input
                                        class="form-check-input  m-2 ml-0"
                                        type="checkbox"
                                        name="answer1"
                                        id="option1"
                                        value="option1"
                                        checked={hazardMiTemporaryCheck}
                                      />
                                      <label class="form-check-label">
                                        Temporary warning line:Distance from
                                        edge:
                                      </label>
                                    </div>
                                    <div className="col-2">
                                      <input
                                        class="col-12"
                                        value={hazardMiTemporaryInput}
                                      />
                                    </div>
                                    <div class="col-3 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                      <input
                                        class="form-check-input  m-2 ml-0"
                                        type="checkbox"
                                        name="answer1"
                                        id="option1"
                                        value="option1"
                                        checked={hazardMiSafetyCheck}
                                      />
                                      <label class="form-check-label">
                                        Safety Attendant name:
                                      </label>
                                    </div>
                                    <div className="col-3">
                                      <input
                                        class="col-12"
                                        value={hazardMiSafetyInput}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group row frm-grp-mb">
                                  <div className="d-flex flex-row p-0">
                                    <div class="col-8 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                      <input
                                        class="form-check-input  m-2 ml-0"
                                        type="checkbox"
                                        name="answer1"
                                        id="option1"
                                        value="option1"
                                        checked={hazardMiSpecificCheck}
                                      />
                                      <label class="form-check-label">
                                        Specific fall Arrest/Restraint Equipment
                                        to be Utilized:(i.e
                                        harness,SRL,lanyard,etc)
                                      </label>
                                    </div>
                                    <div className="col-4">
                                      <input
                                        class="col-12"
                                        value={hazardMiSpecificInput}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <label
                                    class="col-12 col-form-label criti-hm-bg-color"
                                    style={{
                                      padding: "15px",
                                      margin: "0px",
                                      lineHeight: "1px",
                                    }}
                                  >
                                    Anchorage Points(if required):
                                  </label>
                                </div>
                                <div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-12 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={ancPointsArrestCheck}
                                        />
                                        <label class="form-check-label">
                                          Fall Arrest Anchorage(must be capable
                                          of supporting 5000lbs(2267kg)per
                                          employee attched or shall be designed
                                          with a safety factor of at least 2.)
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div className="col-10">
                                        <input
                                          class="col-12"
                                          value={ancPointsArrestInput}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-12 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={ancPointsResistantCheck}
                                        />
                                        <label class="form-check-label">
                                          Fall restraint Anchorage(must be
                                          capable of supporting twice the
                                          potential impact load or
                                          3000lbs(1360.8kg),which ever is
                                          greater)
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div className="col-10">
                                        <input
                                          class="col-12"
                                          value={ancPointsResistantInput}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div class="form-group row frm-grp-mb">
                                                                                <div className="d-flex flex-row cew-padding-hi">
                                                                                    <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                                                                        <input class="form-check-input m-2 ml-0" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                                        <label class="form-check-label" >Wall Opening(s):</label>
                                                                                    </div>
                                                                                    <div className="col-10">
                                                                                        <input class="col-12" />
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          checked={ancPointsMobileCheck}
                                        />
                                        <label class="form-check-label">
                                          Mobile Crane hook or load line:(req
                                          EHS&S Leader Approved)
                                        </label>
                                      </div>
                                      <div className="col-6">
                                        <input
                                          class="col-12"
                                          value={ancPointsMobileInput}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row cew-padding-hi">
                                      <div class="col-5 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          checked={ancPointsOtherCheck}
                                        />
                                        <label class="form-check-label">
                                          Other:(req.Structrual Engineer
                                          Approval){" "}
                                        </label>
                                      </div>
                                      <div className="col-7">
                                        <input
                                          class="col-12"
                                          value={ancPointsOtherInput}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label
                                      class="col-12 col-form-label criti-hm-bg-color"
                                      style={{
                                        padding: "15px",
                                        margin: "0px",
                                        lineHeight: "1px",
                                      }}
                                    >
                                      Falling object control(select all that
                                      apply):
                                    </label>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          name="answer1"
                                          id="option1"
                                          value="option1"
                                          checked={fallingbarricadesCheck}
                                        />
                                        <label class="form-check-label">
                                          Barricades:
                                        </label>
                                        <input
                                          class="col-6 cb-txtbx-border-none"
                                          value={fallingbarricadesInput}
                                        />
                                      </div>
                                      {/* <div className="col-2">
                                                                                    <input class="col-12" />
                                                                                </div> */}
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          name="answer1"
                                          id="option1"
                                          value="option1"
                                          checked={fallingbagsCheck}
                                        />
                                        <label class="form-check-label">
                                          Bags/pouches:
                                        </label>
                                        <input
                                          class="col-6 cb-txtbx-border-none"
                                          value={fallingbagsInput}
                                        />
                                      </div>
                                      {/* <div className="col-2">
                                                                                    <input class="col-12" />
                                                                                </div> */}
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          name="answer1"
                                          id="option1"
                                          value="option1"
                                          checked={fallingToeCheck}
                                        />
                                        <label class="form-check-label">
                                          Toe boards:
                                        </label>
                                        <input
                                          class="col-6 cb-txtbx-border-none"
                                          value={fallingToeInput}
                                        />
                                      </div>
                                      {/* <div className="col-1">
                                                                                    <input class="col-12" />
                                                                                </div> */}
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          name="answer1"
                                          id="option1"
                                          value="option1"
                                          checked={fallingToolCheck}
                                        />
                                        <label class="form-check-label">
                                          Tool landyards:
                                        </label>
                                        <input
                                          class="col-6 cb-txtbx-border-none"
                                          value={fallingToolInput}
                                        />
                                      </div>
                                      {/* <div className="col-2">
                                                                                    <input class="col-12" />
                                                                                </div> */}
                                      <div class="col-8 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input  m-2 ml-0"
                                          type="checkbox"
                                          name="answer1"
                                          id="option1"
                                          value="option1"
                                          checked={fallingOtherCheck}
                                        />
                                        <label class="form-check-label">
                                          Other:
                                        </label>
                                        <input
                                          class="col-10 cb-txtbx-border-none"
                                          value={fallingOtherInput}
                                        />
                                      </div>
                                      {/* <div className="col-2">
                                                                                    <input class="col-12" />
                                                                                </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pagebreak"></div>

                      {/* Euip and Chem end */}
                      {/* ROOF ACCESS */}

                      <div className="row mt-3">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #000",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              className={`d-flex justify-content-between  align-items-center`}
                              style={{ backgroundColor: "#FF7E51" }}
                            >
                              <div>
                                {" "}
                                <p
                                  style={{
                                    margin: "5px",
                                    color: "#fff",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ROOF ACCESS
                                </p>
                              </div>
                              {/* <div class="form-check form-check-inline form-checks d-flex align-items-center" style={{ backgroundColor: "#fff", color: "#000", fontWeight: 'bold' }}>
                                                                <input class="form-check-input" type="checkbox" checked={roofAccess} />
                                                                <label class="form-check-label" >N/A</label>
                                                            </div> */}
                            </div>
                            {/* <div className="d-flex flex-row align-content-between" style={{ fontWeight: 'bold', backgroundColor: '#4E74C2', color: 'white', marginTop: '0px' }}>
                                                                <div> <p style={{ margin: '5px' }}>ROOF ACCESS</p></div>
                                                                <div>

                                                                    <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                                                        <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                        <label class="form-check-label" >N/A</label>
                                                                    </div>

                                                                </div>
                                                            </div> */}
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div className="row">
                              {/* <div class="form-group row frm-grp-mb">
                                                                                <div className="d-flex flex-row cew-padding-hi">
                                                                                    <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                                                                        <input class="form-check-input m-2 ml-0" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                                        <label class="form-check-label" >Wall Opening(s):</label>
                                                                                    </div>
                                                                                    <div className="col-10">
                                                                                        <input class="col-12" />
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}

                              <div className="">
                                <div class="form-group row frm-grp-mb lbl-bg-color-cew">
                                  <div className="d-flex flex-row pl-0">
                                    <div className="col-6 p-0 lbl-bg-color">
                                      <label
                                        class="col-12 col-form-label"
                                        style={{
                                          padding: "15px",
                                          margin: "0px",
                                          lineHeight: "1px",
                                        }}
                                      >
                                        Slope type of roof(select one):
                                      </label>
                                    </div>
                                    <div className="col-6 d-flex flex-row cew-padding-hi">
                                      <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={stor0}
                                        />
                                        <label class="form-check-label">
                                          {
                                            "Flat/low slope(<4  in 12)(<33% grade)"
                                          }
                                        </label>
                                      </div>
                                      <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={stor1}
                                        />
                                        <label class="form-check-label">
                                          {"Steep slope(>4 in 12)(>33% grade)"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb lbl-bg-color-cew">
                                  <div className="d-flex flex-row pl-0">
                                    <div className="col-6 p-0 lbl-bg-color">
                                      <label
                                        class="col-12 col-form-label"
                                        style={{
                                          padding: "15px",
                                          margin: "0px",
                                          lineHeight: "1px",
                                        }}
                                      >
                                        Work type(select one):
                                      </label>
                                    </div>
                                    <div className="col-6 d-flex flex-row cew-padding-hi">
                                      <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={wts0}
                                        />
                                        <label class="form-check-label">
                                          Roofing
                                        </label>
                                      </div>
                                      <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={wts1}
                                        />
                                        <label class="form-check-label">
                                          All other work
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb  lbl-bg-color-cew">
                                  <div className="d-flex flex-row pl-0">
                                    <div className="col-4 p-0 lbl-bg-color">
                                      <label
                                        class="col-12 col-form-label"
                                        style={{
                                          padding: "15px",
                                          margin: "0px",
                                          lineHeight: "1px",
                                        }}
                                      >
                                        Minimum distance from the edge(select
                                        one):
                                      </label>
                                    </div>
                                    <div className="col-8 d-flex flex-row cew-padding-hi">
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={mdf0}
                                        />
                                        <label class="form-check-label">
                                          {"<6ft.(1.83m)"}
                                        </label>
                                      </div>
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={mdf1}
                                        />
                                        <label class="form-check-label">
                                          {"Between 6ft.(1.83m)&15ft.(4.57m)"}
                                        </label>
                                      </div>
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={mdf2}
                                        />
                                        <label class="form-check-label">
                                          {">15ft.(4.57m)"}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb lbl-bg-color-cew">
                                  <div className="d-flex flex-row pl-0">
                                    <div class="col-12 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input m-2 ml-0"
                                        type="checkbox"
                                        checked={hsoh0}
                                      />
                                      <label class="form-check-label">
                                        Hatches,skylights,or other holes in the
                                        roof have been covered or
                                        gaurded(Crictical guard)
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb lbl-bg-color-cew">
                                  <div className="d-flex flex-row pl-0">
                                    <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input m-2 ml-0"
                                        type="checkbox"
                                        checked={hsoh1}
                                      />
                                      <label class="form-check-label">
                                        Tank roof has been evaluated for the
                                        intended load(UT,service life,etc)
                                      </label>
                                    </div>
                                    <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input m-2 ml-0"
                                        type="checkbox"
                                        checked={hsoh2}
                                      />
                                      <label class="form-check-label">
                                        Lighting is appropriate
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb lbl-bg-color-cew">
                                  <div className="d-flex flex-row pl-0">
                                    <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input m-2 ml-0"
                                        type="checkbox"
                                        checked={hsoh3}
                                      />
                                      <label class="form-check-label">
                                        Vent,relief,exhaust and intake hazards
                                        are communicated and controlled
                                      </label>
                                    </div>
                                    <div class="col-6 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input m-2 ml-0"
                                        type="checkbox"
                                        checked={hsoh4}
                                      />
                                      <label class="form-check-label">
                                        No inclement weather
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <div className="d-flex flex-row pl-0">
                                    <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center lbl-bg-color-cew">
                                      <input
                                        class="form-check-input m-2 ml-0"
                                        type="checkbox"
                                        checked={hsoh5}
                                      />
                                      <label class="form-check-label">
                                        Flammables & controls(list):
                                      </label>
                                    </div>
                                    <div className="col-8">
                                      <input
                                        class="col-12"
                                        value={hsohInput5}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <div class="form-group row frm-grp-mb">
                                                                    <div className="d-flex flex-row">

                                                                        <div className="col-12">
                                                                            <input class="col-12" />
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #34B85F",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#34B85F",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>FALL RESUE</p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div className="row">
                              <div style={{ padding: "0px 0px 0px 12px" }}>
                                <div class="form-group row frm-grp-mb">
                                  <div className="d-flex flex-row pl-0">
                                    <div className="col-12 p-0 lbl-bg-color">
                                      <label
                                        class="col-12 col-form-label criti-fr-bg-color"
                                        style={{
                                          padding: "15px",
                                          margin: "0px",
                                          lineHeight: "1px",
                                        }}
                                      >
                                        People
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-3 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Contacts :
                                  </label>
                                  <div className="col-9">
                                    <input
                                      class="col-12"
                                      type="text"
                                      value={contacts}
                                    />
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-3 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Rescuer(s):
                                  </label>
                                  <div className="col-9">
                                    <input
                                      class="col-12"
                                      type="text"
                                      value={rescurs}
                                    />
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-3 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Standby Personnel(if applicable):
                                  </label>
                                  <div className="col-9">
                                    <input
                                      class="col-12"
                                      type="text"
                                      value={standby}
                                    />
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb ">
                                  <div className="d-flex flex-row pl-0">
                                    <div className="col-6 p-0 lbl-bg-color">
                                      <label
                                        class="col-12 col-form-label lbl-bg-color-cew"
                                        style={{
                                          padding: "15px",
                                          margin: "0px",
                                          lineHeight: "1px",
                                        }}
                                      >
                                        Method of Contacting Rescuer:
                                      </label>
                                    </div>
                                    <div className="col-6 d-flex flex-row cew-padding-hi">
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={mcr0}
                                        />
                                        <label class="form-check-label">
                                          Verbal/Face to Face
                                        </label>
                                      </div>
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={mcr1}
                                        />
                                        <label class="form-check-label">
                                          Radio(Channel):
                                        </label>
                                        <input
                                          type="text"
                                          className="cb-txtbx-border-none"
                                          value={mcrInput1}
                                        />
                                      </div>
                                      <div class="col-4 form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input m-2 ml-0"
                                          type="checkbox"
                                          checked={mcr2}
                                        />
                                        <label class="form-check-label">
                                          Other:
                                        </label>
                                        <input
                                          type="text"
                                          className="cb-txtbx-border-none"
                                          value={mcrInput2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb ">
                                  <div className="d-flex flex-row pl-0">
                                    <div className="col-12 p-0 lbl-bg-color">
                                      <label
                                        class="col-12 col-form-label criti-fr-bg-color"
                                        style={{
                                          padding: "15px",
                                          margin: "0px",
                                          lineHeight: "1px",
                                        }}
                                      >
                                        Rescue Equipment
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group row frm-grp-mb">
                                  <div className="d-flex flex-row">
                                    <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={mcre0}
                                      />
                                      <label class="form-check-label">
                                        Ladder
                                      </label>
                                    </div>
                                    <div class="col-2 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={mcre1}
                                      />
                                      <label class="form-check-label">
                                        Aerial Life Device
                                      </label>
                                    </div>
                                    <div class="col-3 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={mcre2}
                                      />
                                      <label class="form-check-label">
                                        Alternative Lifting/Lowering Device
                                      </label>
                                    </div>
                                    <div class="    col-2 form-check form-check-inline form-checks d-flex align-items-center">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={mcre3}
                                      />
                                      <label class="form-check-label">
                                        Other:
                                      </label>
                                      <input
                                        type="text"
                                        className="cb-txtbx-border-none"
                                        value={mcrInput2}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Equipment has been located prior to
                                    work,Loaction:
                                  </label>
                                  <div className="col-8">
                                    <input
                                      class="col-12"
                                      type="text"
                                      value={mcreInput0}
                                    />
                                  </div>
                                </div>

                                <div class="form-group row frm-grp-mb">
                                  <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Anchor Point(if applicable):
                                  </label>

                                  <div className="col-8">
                                    <input class="col-12" value={mcreInput1} />
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Rescue landing Area(if applicable):
                                  </label>

                                  <div className="col-8">
                                    <input class="col-12" value={mcreInput2} />
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Rescue Obstructions/Hazards(if applicable)
                                  </label>

                                  <div className="col-8">
                                    <input class="col-12" value={mcreInput3} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #000",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#000",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>
                                ADDITIONAL COMMENTS & MODIFICATIONS
                              </p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div className="row">
                              <div style={{ padding: "0px 0px 0px 0px" }}>
                                <div class="form-group row frm-grp-mb">
                                  <div className="col-12">
                                    <input
                                      class="col-12"
                                      value={additionalComments}
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #A1A2A5",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#A1A2A5",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>
                                I HAVE REVIED AND UNDERSTOOD THE ABOVE CRITICAL
                                ELEVATED WORK PERMIT
                              </p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div className="row">
                              <div>
                                <div class="form-group row frm-grp-mb label-height-cew">
                                  <label class="col-2 col-form-label lbl-bg-color-cew">
                                    User signature(s)
                                  </label>
                                  <label class="col-2 col-form-label lbl-bg-color-cew">
                                    Fail protection trained
                                  </label>
                                  <label class="col-2 col-form-label lbl-bg-color-cew">
                                    Date
                                  </label>
                                  <label class="col-2 col-form-label lbl-bg-color-cew">
                                    user signatue(s)
                                  </label>
                                  <label class="col-2 col-form-label lbl-bg-color-cew label-height-cew">
                                    Fail protection trained
                                  </label>
                                  <label class="col-2 col-form-label lbl-bg-color-cew">
                                    Date
                                  </label>
                                </div>
                                <div class="form-group row frm-grp-mb label-height-cew">
                                  <input class="col-2" value={l0U0} />
                                  <input class="col-2" value={l0F0} />
                                  <input class="col-2" value={l0D0} />
                                  <input class="col-2" value={l0U1} />
                                  <input class="col-2" value={l0F1} />
                                  <input class="col-2" value={l0D1} />
                                </div>
                                <div class="form-group row frm-grp-mb label-height-cew">
                                  <input class="col-2" value={l1U0} />
                                  <input class="col-2" value={l1F0} />
                                  <input class="col-2" value={l1D0} />
                                  <input class="col-2" value={l1U1} />
                                  <input class="col-2" value={l1F1} />
                                  <input class="col-2" value={l1D1} />
                                </div>
                                <div class="form-group row frm-grp-mb label-height-cew">
                                  <input class="col-2" value={l2U0} />
                                  <input class="col-2" value={l2F0} />
                                  <input class="col-2" value={l2D0} />
                                  <input class="col-2" value={l2U1} />
                                  <input class="col-2" value={l2F1} />
                                  <input class="col-2" value={l2D1} />
                                </div>
                                <div class="form-group row frm-grp-mb label-height-cew">
                                  <input class="col-2" value={l3U0} />
                                  <input class="col-2" value={l3F0} />
                                  <input class="col-2" value={l3D0} />
                                  <input class="col-2" value={l3U1} />
                                  <input class="col-2" value={l3F1} />
                                  <input class="col-2" value={l3D1} />
                                </div>
                                <div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #000",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#000",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>SIGNATURES</p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div className="row">
                              <div style={{ padding: "0px 0px 0px 12px" }}>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-3 col-form-label lbl-bg-color-cew  label-height-cew">
                                    DATE(s)OF PROPOSED USAGE:
                                  </label>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                      From
                                    </label>
                                    <div className="col-9">
                                      <input class="col-12" value={sigdate} />
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                      To
                                    </label>
                                    <div className="col-9">
                                      <input class="col-12" value={todate} />
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Work Planner:
                                  </label>
                                  <div className="col-2">
                                    <input
                                      class="col-12"
                                      value={sigWorkPlanner}
                                    />
                                  </div>
                                  <label class="col-2 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Phone number:
                                  </label>
                                  <div className="col-4">
                                    <input
                                      class="col-12"
                                      value={sigWorkPlannerPhoneNumber}
                                    />
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Critical Elevated Work Permit Approver
                                  </label>
                                  <div className="col-2">
                                    <input
                                      class="col-12"
                                      value={
                                        sigCriticalElevatedWorkPermitApprover
                                      }
                                    />
                                  </div>
                                  <label class="col-2 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Phone number:
                                  </label>
                                  <div className="col-4">
                                    <input
                                      class="col-12"
                                      value={
                                        sigCriticalElevatedWorkPermitApproverPhoneNumber
                                      }
                                    />
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Structural engineer Approval of Anchorage(if
                                    necessary)
                                  </label>
                                  <div className="col-2">
                                    <input
                                      class="col-12"
                                      value={
                                        sigStructuralEngineerApprovalAnchorage
                                      }
                                    />
                                  </div>
                                  <label class="col-2 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Phone number:
                                  </label>
                                  <div className="col-4">
                                    <input
                                      class="col-12"
                                      value={
                                        sigStructuralEngineerApprovalAnchoragePhoneNumber
                                      }
                                    />
                                  </div>
                                </div>
                                <div class="form-group row frm-grp-mb">
                                  <label class="col-4 col-form-label lbl-bg-color-cew  label-height-cew">
                                    EHS&S Leader Approval*(if necessary)
                                  </label>
                                  <div className="col-2">
                                    <input
                                      class="col-12"
                                      value={sigEHSSLeaderApproval}
                                    />
                                  </div>
                                  <label class="col-2 col-form-label lbl-bg-color-cew  label-height-cew">
                                    Phone number:
                                  </label>
                                  <div className="col-4">
                                    <input
                                      class="col-12"
                                      value={sigEHSSLeaderApprovalPhoneNumber}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div
                          className="card"
                          style={{
                            border: "1px solid #2373BE",
                            borderRadius: "0px",
                          }}
                        >
                          <div className="row">
                            <div
                              className="d-flex justify-content-between  align-items-center"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#2373BE",
                                color: "white",
                                margin: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>v0.0</p>
                              <p style={{ margin: "5px" }}>
                                <img
                                  src={cortevalogo}
                                  alt="img"
                                  style={{ width: "190px", height: "40px" }}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="card-body" style={{ padding: "0px" }}>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* work envi end */}
                  </div>
                  {/*  Clseout end */}
                </div>
              </div>

              {/* <Footer /> */}
            </form>
            {/* ... End of Footer...  */}
          </div>
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
      </div>
      {isLoading && (
        <Loader
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default CriticalElevatedWork_Web;
