import React, { useState, useEffect } from 'react'
import { coreEnergyConstant } from '../../Utilities/corePermit.constant';

function CorePermitM2Energy(props) {

    const [energyData, setEnergyData] = useState(props.data)
    const [isChecked, setIsChecked] = useState(false);



    const handleCheckboxChange = (event) => {
       
        // debugger;
        const isChecked = event.target.checked;
        setIsChecked(isChecked);
        setIsChecked(event.target.checked);
        var object = { ...energyData }; // Make a copy of energyData
        object.answer = event.target.checked ? "true" : "";
        if (event.target.checked) {
            var energyList = energyData.energyList;
            const updatedEnergyList = energyList.map(energy => ({ ...energy, checked: false }));
            object.energyList = updatedEnergyList;
        }
        setEnergyData(object);
    };


    useEffect(() => {
        setEnergyData(props.data)
    }, [])

    useEffect(() => {
        props.onDataChange(energyData)
    }, [energyData])

    const handleCheckBox = (id) => {
        
        // debugger;
        const updatedEnergyList = energyData.energyList.map(opItem =>
            ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
        setEnergyData((prev) => ({ ...prev, energyList: updatedEnergyList }))
    }
    const handleInput = (id, list, value) => {
        const updatedArmsBodyList = energyData.energyList.map(opItem => {
            if (id == opItem.id) {
                return { ...opItem, 'inputValue': value }
            } else {
                return opItem
            }
        })
        setEnergyData((prev) => ({ ...prev, energyList: updatedArmsBodyList }))
    }

    return (
        <div>
            <div class="form-group row">
                <label class="col-9 col-form-label">For the scope of work, check the appropriate permit below. Fill out the checklist or permit and attach it to this CORE Permit using the same number as the CORE permit.</label>
                <div class="col-3">
                    <div class="form-check form-check-inline d-flex align-items-center mt-3">
                        <input class="form-check-input mt-0"
                         disabled={props.approvalStatus}
                            type="checkbox" id="inlineCheckbox2"
                            onClick={handleCheckboxChange} 
                            checked={energyData.answer != "" ?
                             energyData.answer == "true" ? true : false : ""} 
                             value={energyData.answer != "" ?
                             energyData.answer == "true" ? true : false : ""} />
                        <label class="form-check-label mt-0" for="inlineCheckbox2">N/A</label>
                    </div>
                    {/* <input class="form-check-input mt-0" type="checkbox"  value="option1"
                        checked={props.data.na} onClick={() => {
                            handleGeneralInfo(props.data.na ? false : true, 'na', )
                        }} /> */}
                </div>
            </div>
            <div class="col-12"
                style={{
                    display: "flex", flexDirection: "row",
                    flexWrap: 'wrap', justifyContent: 'space-between'
                }}>
                {
                    energyData.energyList.map(item => (
                        <div class="form-group row" key={item.id}>
                            <div class="" style={{ display: "flex", flexDirection: "row" }}>
                                <div class="col-12 form-check form-check-inline d-flex align-items-center">
                                    <input
                                     readOnly={props.approvalStatus}
                                        class="form-check-input mt-0"
                                        checked={item.checked}
                                        onChange={() => { handleCheckBox(item.id, 'headFace') }}
                                        type="checkbox"
                                        id={`headFaceList${item.id}`}
                                        value="option1"
                                        disabled={energyData.answer == 'true'}
                                    />
                                    <label class="form-check-label mt-0" for={`headFaceList${item.id}`}>
                                        {item.cbTitle}</label>
                                    {
                                        item.inputAvaiable &&
                                        <div class="col-6 mt-2">
                                            <input
                                             readOnly={props.approvalStatus}
                                                type='text'
                                                value={item.inputValue}
                                                onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(item.id, 'headFace', e.target.value)
                                                }}
                                                class="form-control"
                                                id="inputAvil"
                                                placeholder=""
                                                disabled={energyData.answer == 'true'}
                                            />
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    ))
                }

            </div>

        </div>

    )
}

export default CorePermitM2Energy