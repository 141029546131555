import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { getAssembleLocation } from "../../Utilities/URLCONSTANTS";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomTimePickerInput } from "../../Utilities/utils";
import Select from "react-select";

function GeneralInformation(props) {
  const [assemblyLocations, setAssemblyLocations] = useState([]);

  const handleGeneralInfo = (val, key, type, index = null) => {
    if (type == 1) {
      console.log(val);
      if (key === "assemblyLocation" && val != "Select") {
        props.setgenaralInformation((prev) => ({ ...prev, [key]: val }));
        const filteredObject = assemblyLocations.filter((obj) => {
          if (obj.name == val) return obj.mobileNumber;
        });
        props.setgenaralInformation((prev) => ({
          ...prev,
          phoneNumber: filteredObject[0].mobileNumber,
        }));
      }

      props.setgenaralInformation((prev) => ({ ...prev, [key]: val }));
    } else if (type == 2) {
      const newArr = props.data[key];
      newArr[index].value = val;
      console.log("newArr", newArr);
      props.setgenaralInformation((prev) => ({ ...prev, [key]: newArr }));
    }
  };

  const getLocations = async () => {
    const apiUrl = getAssembleLocation;

    axios
      .get(apiUrl)
      .then((response) => {
        console.log("responseLocation", response);
        setAssemblyLocations(response.data.response.assembleLocations);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    getLocations();
    console.log(props.data, "GETDATA");
  }, []);

  const [time, setTime] = useState(new Date());
  const [SelectTime, SetSelectTime] = useState("");

  const handleTimeChange = (key) => (selectedTime) => {
    SetSelectTime(selectedTime);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedTime = new Date(selectedTime).toLocaleTimeString(
      "en-US",
      options
    );
    props.setgenaralInformation((prev) => ({ ...prev, [key]: formattedTime }));
    setTime(selectedTime);
  };

  //End Time
  const [timeOne, setTimeOne] = useState("");
  const handleTimeChangeOne = (key, index) => (selectedTime) => {
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedTime = new Date(selectedTime).toLocaleTimeString(
      "en-US",
      options
    );
    props.setgenaralInformation((prev) => ({ ...prev, [key]: formattedTime }));
    setTimeOne(selectedTime);
  };

  function calculateMinEndTime(startTime) {
    if (startTime) {
      const selectedTime = new Date(startTime);
      selectedTime.setMinutes(selectedTime.getMinutes() + 1);
      return selectedTime;
    } else {
      return new Date();
    }
  }

  return (
    <div>
      <div>
        <div className="card-body" style={{ padding: "10px" }}>
          <div>
            <div>
              <div className="col-12">
                <div>
                  <div>
                    <div class="form-group">
                      <div className="form-group">
                        <label>
                          Assembly Location <span className="required"> *</span>
                        </label>

                        <div>
                          <Select
                            value={{
                              value: props.data.assemblyLocation,
                              label: props.data.assemblyLocation,
                            }}
                            onChange={(selectedOption) =>
                              handleGeneralInfo(
                                selectedOption.value,
                                "assemblyLocation",
                                1
                              )
                            }
                            options={assemblyLocations.map((location) => ({
                              value: location.name,
                              label: location.name,
                            }))}
                            placeholder="Select Assembly Location && Search..."
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>
                        EMERGENCY NUMBER <span className="required"> *</span>
                      </label>
                      <div>
                        <input
                          class="form-control"
                          id="ph"
                          type="text"
                          maxLength={12}
                          value={props.data.phoneNumber}
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="d-flex align-content-center">
                        <label class="col-10">
                          Authorized Procedure#/Name:
                        </label>
                        <div class="form-check form-check-inline d-flex align-content-center">
                          <input
                            class="form-check-input mt-0"
                            type="checkbox"
                            id="na_General"
                            checked={props.data.na}
                            onClick={() => {
                              handleGeneralInfo(
                                props.data.na ? false : true,
                                "na",
                                1
                              );
                            }}
                          />
                          <label
                            class="form-check-label mt-0"
                            for="inlineCheckbox1"
                          >
                            N/A
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <div>
                        <input
                          class="form-control col-12"
                          type="text"
                          value={
                            props.data.na ? "" : props.data.authorized[0].value
                          }
                          disabled={props.data.na}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const alphaOnly = inputValue.replace(
                              /[^a-zA-Z .]/g,
                              ""
                            );
                            handleGeneralInfo(alphaOnly, "authorized", 2, 0);
                          }}
                        />
                      </div>
                    </div>

                    <div className="Corper-subtxt">
                      <p className="card-subheading">PERMIT EXPERATION</p>
                    </div>
                    <div class="form-group">
                      <label>Start time</label>
                      <div>
                        <DatePicker
                          className="form-control time-input"
                          selected={time}
                          value={props.data.startTime}
                          onChange={handleTimeChange("startTime")}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          dateFormat="h:mm aa"
                          customInput={<CustomTimePickerInput />}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>End time </label>
                      <div>
                        <DatePicker
                          className="form-control time-input"
                          selected={timeOne}
                          value={props.data.endTime}
                          onChange={handleTimeChangeOne("endTime")}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          dateFormat="h:mm aa"
                          minTime={calculateMinEndTime(SelectTime)}
                          maxTime={
                            new Date(new Date().setHours(23, 59, 59, 999))
                          }
                          customInput={<CustomTimePickerInput />}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>PERMIT ISSUER NAME</label>

                      <div>
                        <input
                          class="form-control"
                          type="text"
                          value={props.data.permitIssuerName}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const alphaOnly = inputValue.replace(
                              /[^a-zA-Z .]/g,
                              ""
                            );
                            handleGeneralInfo(alphaOnly, "permitIssuerName", 1);
                          }}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>PERMIT ISSUER SIGNATURE</label>
                      <div>
                        <input
                          class="form-control"
                          type="text"
                          value={props.data.permitIssuerSignature}
                          onChange={(e) => {
                            handleGeneralInfo(
                              e.target.value,
                              "permitIssuerSignature",
                              1
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>PERMIT RECEIVER NAME</label>

                      <div>
                        <input
                          class="form-control"
                          id="inputPassword"
                          value={props.data.permitReceiverName}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const alphaOnly = inputValue.replace(
                              /[^a-zA-Z .]/g,
                              ""
                            );
                            handleGeneralInfo(
                              alphaOnly,
                              "permitReceiverName",
                              1
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>SIGNATURE</label>
                      <div>
                        <input
                          class="form-control"
                          id="inputPassword"
                          placeholder=""
                          value={props.data.signature}
                          onChange={(e) => {
                            handleGeneralInfo(e.target.value, "signature", 1);
                          }}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>COMPANY</label>
                      <div>
                        <input
                          class="form-control"
                          id="inputPassword"
                          value={props.data.company}
                          onChange={(e) => {
                            handleGeneralInfo(e.target.value, "company", 1);
                          }}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>PHONE/RADIO CH.#</label>

                      <div>
                        <input
                          class="form-control"
                          id="ph"
                          type="text"
                          maxLength={10}
                          value={props.data.phone_radio_ch}
                          onChange={(e) => {
                            const inputText = e.target.value;
                            const sanitizedInput = inputText.replace(
                              /[^0-9]/g,
                              ""
                            );
                            const validPhoneNumber = /^[^6789]/.test(
                              sanitizedInput
                            )
                              ? ""
                              : sanitizedInput;

                            handleGeneralInfo(
                              validPhoneNumber,
                              "phone_radio_ch",
                              1
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default GeneralInformation;
