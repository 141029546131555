// MyContext.js
import React from 'react';

const MyContext = React.createContext();

export default MyContext;

const contextData = {
       value1: "abc",
       value2:  "xyz",
       value3: "123"     
     };