import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import { strings } from "../Utilities/strings";
import Loader from "../Utilities/Loader";
import * as XLSX from "xlsx";
// for Sidebar And Navbar
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  fatrash,
} from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import Login from "../Login";
import {
  FarmOperation_getMasters,
  rmActivity,
  RmhistoryGet,
  deleteActivity,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import closedProducts from "../images/closed-products.png";
import delete_icon from "../images/ic_delete.png";
import user from "../images/user.png";
import productrequestImage from "../images/product_request.png";
import issuedProducts from "../images/issued-products.png";
import PendingProducts from "../images/Pending_products.png";
import Delete from "../images/ic_delete.png";
import { date } from "yup";
import moment from "moment";
import UploadFile from "../images/uploadfile.png";

//ProductMaster

const FarmOperationsTractors = ({}) => {
  const [loaderImage, setLoaderImage] = useState(
    require("../images/fo_loader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [id, setId] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationDropDownData, setlocationDropDownData] = useState([]);
  const [getTractorData, setGetTractorData] = useState([]);
  const [selectedRegistrationNo, setSelectedRegistrationNo] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [date, setDate] = useState("");
  const [tractorsID, setTractorsID] = useState("");
  const [tractorType, setTractorType] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [spares, setSpares] = useState("");
  const [fuel, setfuel] = useState("");
  const [cost, setCost] = useState("");
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState("");
  const [errstartTime, setErrStartTime] = useState("");
  const [errendTime, setErrEndTime] = useState("");
  const [errtractorsID, setErrTractorsID] = useState("");
  const [errregistrationNo, setErrRegistrationNo] = useState("");
  const [errorService, setErrorService] = useState("");
  const [errorTractorType, setErrorTractorType] = useState("");
  const [Errorlocation, setErrorlocation] = useState("");
  const [ErrorSprayersID, setErrorSprayersID] = useState("");
  const [Errorspares, setErrorSpares] = useState("");
  const [Errorfuel, setErrorfuel] = useState("");
  const [errorcost, setErrorCost] = useState("");
  const [errorremarks, setErrorRemarks] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [showPopup, setShowPopup] = useState(false);
  const [errorETime, setErrorETime] = useState("");
  const [errorSTime, setErrorSTime] = useState("");
  const [popupText, setPopupText] = useState(
    "Do you want to close Spray Service"
  );
  const [getActivityType, setGetActivityType] = useState([]);
  const [showMsg, setShowMsg] = useState("false");
  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [TractorList, setTractorList] = useState([]);
  const [modalData, setModalData] = useState({
    serverRecordId: "",
    activityType: "",
    requiredDate: "",
    locationId: "",
    tractorType: "",
    locationName: "",
    registrationNo: "",
    service: "",
    tractorsID: "",
    repair: "",
    cost: "",
    startTime: "",
    endTime: "",
    remarks: "",
    submittedDate: "",
  });

  const [isUpdate, setIsUpdate] = useState(null);
  console.log("isUpdate", isUpdate);
  const [data, setData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState("");
  const [showWarningPopup, setShowWarningPopup] = useState(false);

  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [locationDropDownDataOriginal, setlocationDropDownDataOriginal] =
    useState([]);
  const [TractorRego, setTracterRegno] = useState();
  const [selectedLocationIdName, setSelectedLocationIdName] = useState();
  const [tractorArray, setTractorArray] = useState([]);
  const [tractorTypeIdName, setTractorTypeIdName] = useState();
  const [profilePicUrlShow, setProfilePicUrlShow] = useState([]);
  const [profilePicUrl, setProfilePicUrl] = useState([]);
  const [meterReading, setMeterReading] = useState([]);

  // const totalItems = filteredData.length;
  // In your handleFilter function, update filteredData based on searchTerm
  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter((item) => {
        return (
          item.requiredDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.locationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.service.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.tractorTypeId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.tractorTypeName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.repair.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.cost
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.remarks.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(filteredResults);
      handlePageClick(1);
      console.log("searchTerm", filteredData);
    }
  };
  useEffect(() => {
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "Required Date",
        "Location Name",
        "Tractor Type Name",
        "Registraion No",
        "Service",
        "Spares",
        "MeterReading",
        "Fuel",
        "Start Time",
        "End Time",
        "Cost",
        "Remarks",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.requiredDate,
        item.locationName,
        item.tractorTypeName,
        item.registrationNumber,
        item.service,
        item.spares,
        item.meterReading,
        item.fuel,
        item.startTime,
        item.endTime,
        item.cost,
        item.remarks,
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    // Save the file
    XLSX.writeFile(wb, "tractors.xlsx");
  };
  const handleCancel_new = () => {
    setShowModal(true);
    setShowPopup(true);
  };
  const handleCancel = () => {
    //no
    setShowModal(true);
    setShowPopup(false);
  };
  const handleClose = () => {
    //yes
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handlelocationChange = (e) => {
    setSelectedLocation(e.target.value);
    const TractorsArray = [];
    const locationsID =
      e.target.options[e.target.selectedIndex].getAttribute("data-locationId");
    setSelectedLocationIdName(locationsID);
    getTractorData.find((item) => {
      if (item.locationId === locationsID || item.locationId === "0") {
        TractorsArray.push(item);
      }
    });
    setTractorList(TractorsArray);
  };
  const handleClear = () => {
    setSearchTerm("");
  };

  const handleTractorType = (e) => {
    setIsUpdate(false);
    console.log(e.target.value);
    const selectTractorType = e.target.value;
    const tractorRegno =
      e.target.options[e.target.selectedIndex].getAttribute("data-regno");
    setTracterRegno(tractorRegno);
    const selectedTractortypeIdName =
      e.target.options[e.target.selectedIndex].getAttribute(
        "data-tractorIdName"
      );
    setTractorTypeIdName(selectedTractortypeIdName);
    setTractorType(selectTractorType);
    if (selectTractorType) {
      setErrorTractorType("");
    }
  };
  const handleServiceChange = (e) => {
    const input = e.target.value;
    const result = input.replace(/[^a-z_\s]/gi, "");
    setServiceId(result);
    // if (input) {
    //   setErrorService("");
    // }
  };
  const handleRegistraionNoChange = (e) => {
    const input = e.target.value;

    setRegistrationNo(input);
  };
  const handleTractorsIDChange = (e) => {
    const input = e.target.value;
    //const result = input.replace(/[^a-z_\s]/gi, '');
    setTractorsID(input);
    if (input) {
      setErrTractorsID("");
    }
  };
  const handleStartTime = (e) => {
    // const newStartTime = e.target.value;
    // setStartTime(newStartTime);
    // if (newStartTime==='') {
    //   setErrStartTime('Please Select Time')
    // }else{
    //   setErrStartTime('')
    // }
    const newStartTime = e.target.value;
    setStartTime(newStartTime);
    setErrStartTime("");

    // Check if both start and end times are selected
    if (newStartTime && endTime) {
      validateTime(newStartTime, endTime);
    }
  };
  const handleEndTime = (e) => {
    // const newEndTime = e.target.value;
    // setEndTime(newEndTime);
    // if (newEndTime) {
    //   setErrEndTime('')
    // }
    const newEndTime = e.target.value;
    setEndTime(newEndTime);
    setErrEndTime("");

    // Check if both start and end times are selected
    if (startTime && newEndTime) {
      validateTime(startTime, newEndTime);
    }
  };
  const validateTime = (start, end) => {
    const startTimeObj = new Date(`2000-01-01T${start}`);
    const endTimeObj = new Date(`2000-01-01T${end}`);

    if (startTimeObj >= endTimeObj) {
      // setErrorSTime("Start time must be earlier than end time");
      setErrorETime("End time must be later than start time");
    } else {
      setErrorSTime("");
      setErrorETime("");
    }
  };
  const handledateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    if (selectedDate) {
      setErrorDate("");
    }
  };

  const handleSparesChange = (e) => {
    const inputSpares = e.target.value;
    setSpares(inputSpares);
    // if (inputSpares) {
    //   setErrorSpares("");
    // }
  };
  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
  const handlefuelChange = (e) => {
    const inputfuel = e.target.value;
    setfuel(inputfuel);
    if (!isNaN(inputfuel)) {
      setfuel(inputfuel);
    }
    if (inputfuel) {
      setErrorfuel("");
    }
  };
  const handleCostChange = (e) => {
    const inputunitCost = e.target.value;
    if (!isNaN(inputunitCost)) {
      setCost(inputunitCost);
    }
    if (inputunitCost) {
      setErrorCost("");
    }
  };
  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleSubmit = async (index) => {
    const apiUrl = rmActivity;
    const headers = await GetApiHeaders();

    const dataToSend = {
      serverRecordId: { buttonText } == "Submit" ? 0 : parseInt(id),
      activityType: getActivityType[3].name,
      requiredDate: moment.utc(date).format("YYYY-MM-DD HH:mm:ss.SSS"), //moment(date, "YYYY-MM-DD HH:mm:ss.SSS") ,//date,
      locationId: selectedLocationIdName,
      locationName: selectedLocation,
      tractorTypeName: tractorType,
      tractorTypeId: tractorTypeIdName,
      service: serviceId,
      registrationNumber: registrationNo ? registrationNo : TractorRego,
      spares: spares,
      meterReading: meterReading,
      cost: parseInt(cost),
      startTime: startTime,
      imageList: [{ image: profilePicUrl.toString() }],
      endTime: endTime,
      remarks: remarks,
      fuel: parseInt(fuel),
      submittedDate: moment.utc(date).format("YYYY-MM-DD HH:mm:ss.SSS"), //moment(date, "YYYY-MM-DD HH:mm:ss.SSS")  //date
    };

    try {
      // if (cost   && spares  && tractorsID  &&
      // date  && serviceId  && selectedLocation   && fuel  && cost ) {
      if (
        cost !== "" &&
        selectedLocation != "" &&
        date != "" &&
        tractorType != "" &&
        fuel != "" &&
        startTime != "" &&
        endTime != ""
      ) {
        setIsLoading(true);
        const response = await axios.post(apiUrl, dataToSend, { headers });
        console.log("response submit:: ", response);
        setsubmitResponse(response.data.message);
        setShowModal(false);
        setShowResponsePopup(true);
        setIsLoading(false);

        setIsSubmitted(true);
        // setErrorCost('');
        // setErrorDate('');
        // setErrorSprayersID('');
        // setErrorSpares('');
        // setfuel('');
        // setErrorService('');
        // setErrorlocation('');
      } else {
        setErrorlocation(
          selectedLocation === "" ? "Please Select a Location" : ""
        );
        setErrorTractorType(
          tractorType === "" ? "Please Select Tractor Type" : ""
        );
        // setErrorService(serviceId === "" ? "Please Enter a Service" : "");
        // setErrorSpares(spares === "" ? "Please Enter Spares" : "");
        setErrorDate(date === "" ? "Please Enter a Date" : "");
        setErrorCost(cost === "" ? "Please Enter Cost" : "");
        setErrorfuel(fuel === "" ? "Please Enter Fuel" : "");
        setErrEndTime(endTime === "" ? "Please Select End Time" : "");
        setErrStartTime(startTime === "" ? "Please Select Start Time" : "");
      }
    } catch (error) {
      console.error("Error:", error);
      // setIsSubmitted(false);
    }
  };

  const fetchData = async () => {
    const apiUrl = RmhistoryGet;
    const headers = await GetApiHeaders();
    const getDataObject = {
      activityType: "tractors",
      endDate: "",
      mobileNumber: headers.mobileNumber,
      startDate: "",
      userId: headers.userId,
    };

    setIsLoading(true);
    axios
      .post(apiUrl, getDataObject, { headers })
      .then((response) => {
        console.log(
          "main table response ::;;",
          JSON.stringify(response.data.response.rmHistory)
        );
        setData(response.data.response.rmHistory);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    fetchData();
    fetchLocationDropdownData();
    fetchTractorData();
  }, [modalData]);
  const handleModalOpen = () => {
    if (locationDropDownDataOriginal.length > 0) {
      const uniqueLocationNames = new Set();
      const uniqueDripSystemMaster = locationDropDownDataOriginal.filter(
        (item) => {
          if (!uniqueLocationNames.has(item.locationName)) {
            uniqueLocationNames.add(item.locationName);
            return true;
          }
          return false;
        }
      );
      setlocationDropDownData(uniqueDripSystemMaster);
    }
    setIsUpdate(false);
    console.log("isUpdate", isUpdate);
    setButtonText("Submit");
    setIsSubmitted(false);
    setIsUpdated(false);
    setShowModal(true);
    setId(0);
    setSelectedLocation("");
    setServiceId("");
    setTractorsID("");
    setSpares("");
    setfuel("");
    setCost("");
    setRemarks("");
    setDate("");
    setModalData({
      id: "",
      activityType: "",
      requiredDate: "",
      locationId: "",
      locationName: "",
      tractorTypeId: "",
      registrationNumber: "",
      tractorTypeName: "",
      service: "",
      SprayersID: "",
      repair: "",
      cost: "",
      startTime: "",
      endTime: "",
      remarks: "",
      submittedDate: "",
      serverRecordId: "",
    });
  };
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);

    setShowWarningPopup(false);
    window.location.reload();
  };
  const fetchLocationDropdownData = async () => {
    const apiUrl = FarmOperation_getMasters;
    const headers = await GetApiHeaders();
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("responseLocation", response.data.response.subSiteMaster);
        setlocationDropDownDataOriginal(response.data.response.subSiteMaster);
        setSelectedRegistrationNo(response.data.response.tractorMaster);
        setGetActivityType(response.data.response.rmActivitiesMaster);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const fetchTractorData = async () => {
    const apiUrl = FarmOperation_getMasters;
    const headers = await GetApiHeaders();
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        console.log("responseLocation", response);
        setGetTractorData(response.data.response.tractorMaster);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const [selectedLocationOption, setSelectedLocationOption] = useState("");
  const updatedItems = (item) => {
    console.log("items", item);
    setSelectedLocationOption(item.locationName);
    setlocationDropDownData(locationDropDownDataOriginal);
    setTractorList(getTractorData);
    if (locationDropDownDataOriginal.length > 0) {
      const uniqueLocationNames = new Set();
      const uniqueDripSystemMaster = locationDropDownDataOriginal.filter(
        (item) => {
          if (!uniqueLocationNames.has(item.locationName)) {
            uniqueLocationNames.add(item.locationName);
            return true;
          }
          return false;
        }
      );
      setlocationDropDownData(uniqueDripSystemMaster);
    }
    setIsUpdate(true);
    setModalData(item);
    setId(item.serverRecordId);
    setDate(
      item.submittedDate !== "" && item.submittedDate !== null
        ? item.submittedDate.toString().split(" ")[0]
        : ""
    );
    setSelectedLocation(item.locationName);
    setServiceId(item.service);
    setRegistrationNo(item.registrationNumber);
    setSpares(item.spares);
    setMeterReading(item.meterReading);
    setfuel(item.fuel);
    setCost(item.cost);
    setStartTime(item.startTime);
    setTractorType(item.tractorTypeName);
    setEndTime(item.endTime);
    setRemarks(item.remarks);
    setProfilePicUrlShow(item.image);
    setProfilePicUrl(item.image);
    setShowModal(true);
    setButtonText("Update");
    setIsSubmitted(false);
    setIsUpdated(false);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const deleteItem = async (item) => {
    // alert(JSON.stringify(item));
    const apiUrl = deleteActivity;
    const headers = await GetApiHeaders();

    const dataToSend = {
      id: item.serverRecordId,
    };
    //alert(JSON.stringify(dataToSend));
    // Make the POST request using Axios with headers
    setIsLoading(true);
    const response = await axios.post(apiUrl, dataToSend, { headers });
    console.log("response submit:: ", response);
    setsubmitResponse(response.data.message);
    setShowResponsePopup(true);
    setIsLoading(false);
  };

  const setMinDateToToday = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const handleFileChange = (event) => {
    const selectedImages = event.target.files;

    if (selectedImages && selectedImages.length > 0) {
      const updatedProfilePicUrlShow = [];
      const updatedProfilePicUrl = [];

      Array.from(selectedImages).forEach((image, index) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const dataURL = reader.result;
          updatedProfilePicUrlShow[index] = dataURL;

          // Remove the data URL prefix
          const base64 = dataURL.split(",")[1];
          updatedProfilePicUrl[index] = base64;

          setProfilePicUrlShow([...updatedProfilePicUrlShow]);
          setProfilePicUrl([...updatedProfilePicUrl]);
        };

        reader.onerror = () => {
          // Handle error reading the selected image
        };

        reader.readAsDataURL(image);
      });
    } else {
      // No new images selected, retain existing image URLs
      // You might want to adjust this based on your specific use case
      setProfilePicUrlShow([...profilePicUrlShow]);
      setProfilePicUrl([...profilePicUrl]);
    }
  };
  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <h5 className="Roles_text">Farm Operations</h5>
                <div>
                  <h5 className="mb-0 Sub_role_text">Tractors </h5>
                </div>
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5 className="mb-0 Sub_role_text"></h5>
                      </div>
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-toggle="tooltip"
                        title="Add Tractors"
                      >
                        <Button
                          variant="primary"
                          className="buttons"
                          onClick={handleModalOpen}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="plus_icon mr-1"
                          />
                          Add
                        </Button>
                      </span>
                    </div>
                    <div className="mcrc_hrline "></div>
                    <div>
                      <div
                        className="card flex-grow-1 "
                        style={{
                          border: "1px solid #0000001A",
                          boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div className="card-body " style={{ padding: "20px" }}>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ paddingBottom: 20 }}
                          >
                            <div className="d-flex align-items-center">
                              <label className="mr-2">Show </label> &nbsp;&nbsp;
                              <select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                {/* Add more options as needed */}
                              </select>
                              &nbsp;&nbsp;
                              <label> entries</label>
                            </div>
                            <div className="d-flex align-items-center">
                              <div style={{ position: "relative" }}>
                                <input
                                  type="text"
                                  placeholder="🔍 Type to Search..."
                                  value={searchTerm}
                                  onChange={(e) => handleFilter(e.target.value)}
                                  style={{
                                    height: "36px",
                                    width: "160px",
                                    paddingRight: "30px",
                                  }}
                                  className="mr-2"
                                />
                                {searchTerm && (
                                  <button
                                    className="btn btn-link"
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "10px",
                                      transform: "translateY(-50%)",
                                      zIndex: 1,
                                    }}
                                    onClick={() => handleFilter("")}
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                )}
                              </div>
                              &nbsp;
                              {/* Add the Excel export button */}
                              <button
                                className="btn btn-secondary buttons-excel buttons-html5"
                                onClick={exportToExcel}
                              >
                                Download Excel
                              </button>
                            </div>
                          </div>
                          <div style={{ overflowX: "auto", width: "100%" }}>
                            <Table className="table table-bordered Content_data_table table-striped">
                              {" "}
                              {/*ref={tableRef}*/}
                              <Thead>
                                <Tr>
                                  <Th className="text-center">S.No</Th>
                                  <Th className="text-center">Date</Th>
                                  <Th className="text-center">Location</Th>
                                  {/* <Th className='text-center'>Tractor ID</Th> */}
                                  <Th className="text-center">Tractor Type</Th>
                                  <Th className="text-center">
                                    Registration No
                                  </Th>
                                  <Th className="text-center">Service</Th>
                                  <Th className="text-center">Spares</Th>
                                  <Th className="text-center">MeterReading</Th>
                                  <Th className="text-center">
                                    Fuel(in Liters)
                                  </Th>
                                  <Th className="text-center">Cost</Th>
                                  <Th className="text-center">Start Time</Th>
                                  <Th className="text-center">End Time</Th>
                                  <Th className="text-center">Remarks</Th>
                                  <Th className="text-center">Images</Th>
                                  <Th className="text-center">Actions</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {currentItems.length === 0 ? ( // Check if no data is found
                                  <Tr>
                                    <Td
                                      colSpan={13}
                                      className="text-center"
                                      style={{ padding: 190 }}
                                    >
                                      Data is not available
                                    </Td>
                                  </Tr>
                                ) : (
                                  currentItems.map((item, index) => {
                                    var requiredDate = moment(
                                      item.requiredDate
                                    ).format("DD-MMM-YYYY   ");

                                    return (
                                      // {currentItems != undefined && currentItems != null && currentItems.map((item,index) => (
                                      <Tr>
                                        <Td className="text-left">
                                          {(currentPage - 1) * itemsPerPage +
                                            index +
                                            1}
                                        </Td>
                                        <Td className="text-left">
                                          {requiredDate}
                                        </Td>
                                        <Td className="text-left">
                                          {item.locationName}
                                        </Td>
                                        {/* <Td className='text-left'>{item.tractorTypeId}</Td> */}
                                        <Td className="text-left">
                                          {item.tractorTypeName}
                                        </Td>
                                        <Td className="text-left">
                                          {item.registrationNumber}
                                        </Td>
                                        <Td className="text-left">
                                          {item.service}
                                        </Td>
                                        <Td className="text-left">
                                          {item.spares}
                                        </Td>
                                        <Td className="text-left">
                                          {item.meterReading}
                                        </Td>
                                        <Td className="text-left">
                                          {item.fuel}
                                        </Td>
                                        <Td className="text-left">
                                          {item.cost}
                                        </Td>
                                        <Td className="text-left">
                                          {item.startTime}
                                        </Td>
                                        <Td className="text-left">
                                          {item.endTime}
                                        </Td>
                                        <Td className="text-right">
                                          {item.remarks}
                                        </Td>
                                        <Td>
                                          {item.image.map((image, index) => (
                                            <img
                                              key={index}
                                              src={image}
                                              style={{
                                                width: "30px",
                                                height: "40px",
                                                cursor: "pointer",
                                                border: "1px solid black",
                                                marginRight: "5px",
                                              }}
                                              onClick={() =>
                                                handleImageClick(image)
                                              }
                                            />
                                          ))}
                                        </Td>
                                        <Td className="text-center">
                                          <div>
                                            <FontAwesomeIcon
                                              icon={faEdit}
                                              className="plus_icon mr-1 text-primary"
                                              data-placement="top"
                                              title="Edit User Master"
                                              onClick={() => updatedItems(item)}
                                            />
                                            <img
                                              src={Delete}
                                              alt="downloadImg"
                                              style={{
                                                height: "15px",
                                                marginTop: -5,
                                                marginLeft: 3,
                                              }}
                                              onClick={() => deleteItem(item)}
                                            />

                                            {/* Add button content here */}
                                          </div>
                                        </Td>
                                      </Tr>
                                    );
                                  })
                                )}
                              </Tbody>
                              {isLoading && (
                                <Loader
                                  loading={isLoading}
                                  message={"Fetching Data. Please Wait..!"}
                                  loderimage={loaderImage}
                                />
                              )}
                            </Table>
                          </div>
                          <div
                            className="d-flex justify-content-between mb-10"
                            style={{ borderRadius: 5 }}
                          >
                            <div>
                              Showing{" "}
                              {Math.min(
                                (currentPage - 1) * itemsPerPage + 1,
                                totalItems
                              )}
                              &nbsp;to{" "}
                              {Math.min(
                                (currentPage - 1) * itemsPerPage +
                                  currentItems.length,
                                totalItems
                              )}
                              &nbsp; of {totalItems} entries
                            </div>
                            <div>
                              <div className="div-button text-center">
                                <button
                                  className="borderleft-radius-5"
                                  // className='ul-pagination'
                                  onClick={() =>
                                    handlePageClick(currentPage - 1)
                                  }
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>

                                <button
                                  className="ul-pagination page-item"
                                  style={{
                                    backgroundColor:
                                      currentPage == 1 ? "#0056b3" : "white",
                                    color: currentPage == 1 ? "white" : "black",
                                  }}
                                  key={1}
                                  onClick={() => handlePageClick(1)}
                                  disabled={currentPage === 1}
                                >
                                  {1}
                                </button>
                                {pageNumbers.map((number) => (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage == number
                                          ? "#0056b3"
                                          : "white",
                                      color:
                                        currentPage == number
                                          ? "white"
                                          : "black",
                                    }}
                                    key={number}
                                    onClick={() => handlePageClick(number)}
                                    disabled={currentPage === number}
                                  >
                                    {number}
                                  </button>
                                ))}
                                {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                  currentPage !==
                                    Math.ceil(totalItems / itemsPerPage) - 1 &&
                                  currentPage !==
                                    Math.ceil(totalItems / itemsPerPage) && (
                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      ...
                                    </button>
                                  )}
                                {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor:
                                        currentPage ==
                                        Math.ceil(totalItems / itemsPerPage)
                                          ? "#0056b3"
                                          : "white",
                                      color:
                                        currentPage ==
                                        Math.ceil(totalItems / itemsPerPage)
                                          ? "white"
                                          : "black",
                                    }}
                                    key={Math.ceil(totalItems / itemsPerPage)}
                                    onClick={() =>
                                      handlePageClick(
                                        Math.ceil(totalItems / itemsPerPage)
                                      )
                                    }
                                    disabled={
                                      currentPage ===
                                      Math.ceil(totalItems / itemsPerPage)
                                    }
                                  >
                                    {Math.ceil(totalItems / itemsPerPage)}
                                  </button>
                                )}
                                <button
                                  className="borderright-radius-5"
                                  onClick={() =>
                                    handlePageClick(currentPage + 1)
                                  }
                                  disabled={indexOfLastItem >= totalItems}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger  close_btn"
                                onClick={handleCancel_new}
                              >
                                {" "}
                                {/*closeWarning */}X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">Tractors</span>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Date <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="date"
                                      className="form-control p-2"
                                      placeholder="Select Date"
                                      onChange={handledateChange}
                                      value={date}
                                      min={setMinDateToToday()}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorDate}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputName"
                                    className="form-label text-sm font-weight-600 mt-2"
                                  >
                                    Location{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <select
                                    id="dropdown"
                                    type="dropdown"
                                    className="form-select p-2"
                                    value={selectedLocation}
                                    onChange={handlelocationChange}
                                  >
                                    <option value="">
                                      {selectedLocation === ""
                                        ? "Select Location Name"
                                        : selectedLocation}
                                    </option>
                                    {locationDropDownDataOriginal.map((item) => (
                                      //  item.code === '0' && item.locationId ==='0' && (
                                      <option
                                        key={item.code}
                                        value={item.name}
                                        data-locationId={item.code}
                                        selected={
                                          item.locationName ===
                                          selectedLocationOption
                                        }
                                      >
                                        {item.name}
                                      </option>
                                      //  )
                                    ))}
                                  </select>
                                  <span style={{ color: "red" }}>
                                    {Errorlocation}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputName"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Tractor Type{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <select
                                    id="dropdown"
                                    type="dropdown"
                                    className="form-select p-2"
                                    value={tractorType}
                                    onChange={handleTractorType}
                                  >
                                    <option value="">
                                      {tractorType === ""
                                        ? " Select Tractor Type"
                                        : tractorType}
                                    </option>

                                    {TractorList.map((item) => (
                                      <option
                                        key={item.code}
                                        data-regno={item.tracRegistrationNo}
                                        data-tractorIdName={item.code}
                                        value={item.name}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                  <span style={{ color: "red" }}>
                                    {errorTractorType}
                                  </span>
                                </div>

                                <div className="col-md-6 col-12">
                                  <label
                                    for="InputName"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Registration No{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control p-2 "
                                    placeholder="Registration No"
                                    value={
                                      tractorType === "Others-N/A"
                                        ? registrationNo
                                        : isUpdate
                                        ? registrationNo
                                        : TractorRego
                                    }
                                    disabled={tractorType !== "Others-N/A"}
                                    onChange={(e) =>
                                      setRegistrationNo(e.target.value)
                                    }
                                  />
                                  {/* <select
                                    id="dropdown"
                                    type="dropdown"
                                    className="form-control p-2"
                                    value={registrationNo}
                                    onChange={handleRegistraionNoChange}
                                  >
                                    <option value="">Select Registration No</option>
                                    {selectedRegistrationNo.map((item) => (
                                      <option key={item.code} value={item.code}>
                                        {item.tracRegistrationNo}
                                      </option>
                                    ))}
                                  </select> */}
                                  {/* <span style={{ color: 'red' }}>{errorTractorType}</span> */}
                                </div>

                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Service{" "}
                                    {/* <span style={{ color: "red" }}>*</span>{" "} */}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="Service"
                                      value={serviceId}
                                      onChange={handleServiceChange}
                                    />
                                  </div>
                                  {/* <span style={{ color: "red" }}>
                                    {errorService}
                                  </span> */}
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Spares{" "}
                                    {/* <span style={{ color: "red" }}>*</span>{" "} */}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2 "
                                      placeholder="Spares"
                                      value={spares}
                                      onChange={handleSparesChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Meter Reading{" "}
                                    {/* <span style={{ color: "red" }}>*</span>{" "} */}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="number"
                                      className="form-control p-2 "
                                      placeholder="Meter Reading"
                                      value={meterReading}
                                      step="any"
                                      onChange={(e) =>
                                        setMeterReading(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Fuel (In Liters){" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="number"
                                      className="form-control p-2 "
                                      placeholder="fuel"
                                      value={fuel}
                                      onChange={handlefuelChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {Errorfuel}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Cost <span style={{ color: "red" }}>*</span>{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="number"
                                      className="form-control p-2"
                                      placeholder="Cost"
                                      value={cost}
                                      onChange={handleCostChange}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errorcost}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Start Time{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="time"
                                      step="60"
                                      pattern="[0-9]{2}:[0-9]{2} [APap][mM]"
                                      className="form-control p-2"
                                      placeholder="Start-Time"
                                      onChange={handleStartTime}
                                      value={startTime}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errstartTime}{" "}
                                  </span>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorSTime}{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    End Time{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="time"
                                      step="60"
                                      className="form-control p-2"
                                      placeholder="End-Time"
                                      value={endTime}
                                      onChange={handleEndTime}
                                    />
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errendTime}{" "}
                                  </span>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {errorETime}{" "}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label
                                    for="exampleInputPassword1"
                                    className="form-label text-sm font-weight-600 mt-1"
                                  >
                                    Remarks{" "}
                                  </label>
                                  <div className="input-group input-group w-100">
                                    <input
                                      type="text"
                                      className="form-control p-2"
                                      placeholder="Remarks"
                                      value={remarks}
                                      inputMode="text"
                                      onChange={handleRemarksChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-12 mt-2">
                                  <label>
                                    Upload Image
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      multiple
                                    />{" "}
                                    <img
                                      src={UploadFile}
                                      style={{ height: 70 }}
                                      alt="Upload Preview"
                                    />{" "}
                                  </label>
                                  {profilePicUrlShow.map((url, index) => (
                                    <div key={index}>
                                      <img
                                        src={url}
                                        alt={`Selected ${index + 1}`}
                                        style={{
                                          maxHeight: "80px",
                                          maxWidth: "50%",
                                          border: "1px solid black",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>{" "}
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() => {
                                    setModalData({
                                      id: id,
                                      requiredDate: date,
                                      locationId: selectedLocation.code,
                                      locationName: selectedLocation,
                                      service: serviceId,
                                      tractorTypeId: tractorsID,
                                      spares: spares,
                                      fuel: fuel,
                                      startTime: startTime,
                                      endTime: endTime,
                                      cost: cost,
                                      remark: remarks,
                                      submittedDate: data,
                                      serverRecordId: "",
                                    });
                                    handleSubmit(modalData);
                                  }}
                                >
                                  {buttonText}
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleCancel_new}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showPopup}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Tractors</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    Are You Sure, Do You want close the Window ?
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleClose}
                                >
                                  Yes
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                  variant="danger"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleCancel}
                                >
                                  No
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Tractors </span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleCancel}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default FarmOperationsTractors;
