
import React, { useState } from 'react'
import { useEffect } from 'react'
import DatePicker from "react-datepicker";
import { CustomTimePickerInputforFireWatch } from '../../Utilities/utils';

function FireWatch(props) {
    const handleGeneralInfo = (val, key, subKey, type, index = null) => {

        if (type == 1) {
            props.setAirMonitoring(prev => ({ ...prev, [key]: val }))
        } else if (type == 2) {
            // if (subKey == 'startTime') {
            //     const formattedTime = new Date(val).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            //     const newArr = props.data[key]
            //     newArr[index][subKey] = formattedTime
            //    props.setFireWatch(prev => ({ ...prev, [key]: newArr }))
            // }else if(subKey == 'endTime'){
            //     const formattedTime = new Date(val).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            //     const newArr = props.data[key]
            //     newArr[index][subKey] = formattedTime
            //     props.setFireWatch(prev => ({ ...prev, [key]: newArr }))
            // } else {
            const newArr = props.data[key]
            newArr[index][subKey] = val
            props.setFireWatch(prev => ({ ...prev, [key]: newArr }))
            //}

        }
    }



    const [time, setTime] = useState(new Date());
    const [SelectTime, SetSelectTime] = useState('');
    const handleTimeChange = (val, keyval, key, subKey, type, index = null) => (selectedTime) => {
        if (type == 1) {
            props.setAirMonitoring(prev => ({ ...prev, [key]: val }))
        } else if (type == 2) {
            const newArr = props.data[key]
            newArr[index][subKey] = val
            props.setFireWatch(prev => ({ ...prev, [key]: newArr }))
        }
        SetSelectTime(selectedTime);
        const formattedTime = new Date(selectedTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        props.setFireWatch((prev) => ({ ...prev, [key]: formattedTime }));
        setTime(selectedTime);
    };

    //End Time
    const [timeOne, setTimeOne] = useState('');
    const handleTimeChangeOne = (val, keyval, key, subKey, type, index = null) => (selectedTime) => {
        if (type == 1) {
            props.setAirMonitoring(prev => ({ ...prev, [key]: val }))
        } else if (type == 2) {
            const newArr = props.data[key]
            newArr[index][subKey] = val
            props.setFireWatch(prev => ({ ...prev, [key]: newArr }))
        }
        const formattedTime = new Date(selectedTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        props.setFireWatch((prev) => ({ ...prev, [key]: formattedTime }));
        setTimeOne(selectedTime);
    };

    function calculateMinEndTime(startTime) {
        if (startTime) {
            const selectedTime = new Date(startTime);
            selectedTime.setMinutes(selectedTime.getMinutes() + 1);
            return selectedTime;
        } else {
            return new Date(); // If no start time is selected, set it to the current time
        }
    }

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                        <div class="form-group">
                            <label >FIRE WATCH <span  className='required'> *</span> </label>

                            <div className="col-11">
                                {
                                    props.data.fireWatch.map((i, index) => (
                                        <div class="form-check m-2">
                                            <input class="form-check-input" checked={i.cbValue == 'true'} onClick={() => {
                                                handleGeneralInfo(i.cbValue == 'true' ? 'false' : 'true', 'fireWatch', 'cbValue', 2, index)
                                            }} type="checkbox" name="answer2" id={`fireWatch_${index}`} value="option2" />
                                            <label class="form-check-label" for={`fireWatch_${index}`}>{i.cbTitle}</label>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                        <div class="form-group">
                            <label >SURVEY</label>
                            <div>
                                <div class="form-check m-2">
                                    {/* <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                    <label class="form-check-label" for="option1">3-hour periodic/High Risk:very combustible material,crvasse,or hidden pockets,Periodic review of location after Fire watch ends for 3 hours,walking by occasionally to look for smoldering fire or smell of smoke</label> */}
                                    <input class="form-check-input" type="checkbox" checked={props.data.survy[0].cbValue == 'true'} onClick={() => {
                                        handleGeneralInfo(props.data.survy[0].cbValue == 'true' ? 'false' : 'true', 'survy', 'cbValue', 2, 0)
                                    }} name="answer1" id="survey3handle" value="option1" />
                                    <label class="form-check-label" for="survey3handle">3-hour periodic/High Risk:very combustible material,crvasse,or hidden pockets,Periodic review of location after Fire watch ends for 3 hours,walking by occasionally to look for smoldering fire or smell of smoke</label>
                                </div>
                            </div>
                        </div>

                        <div style={{ overflowX: "auto" }}>
                            <table class="table hwptbl" style={{ width: "100%" }}>
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">
                                            <label class="col-12 col-form-label">Fire Watch Location</label>
                                        </th>
                                        <th scope="col" colSpan={2}>
                                            <label class="col-12 col-form-label">Fire Watch Start Time</label>
                                        </th>
                                        <th scope="col" colSpan={2}>
                                            <label class="col-12 col-form-label">Fire Watch End Time </label>
                                        </th>
                                        <th scope="col">
                                            <label class="col-12 col-form-label">Fire Watch Name</label>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody   >

                                    {props.data.fireWatchLoc.map((i, index) => (
                                        <tr key={index}>
                                            <td>
                                                <input
                                                    className="form-control col-12"
                                                    id="inputPassword"
                                                    placeholder=""
                                                    value={i.fireWatchLocation}
                                                    onChange={(e) => {
                                                        handleGeneralInfo(e.target.value, 'fireWatchLoc', 'fireWatchLocation', 2, index)
                                                    }}
                                                />
                                            </td>

                                            <td>
                                                <label htmlFor="input" className="col-8 col-form-label">START TIME</label>
                                            </td>

                                            <td >
                                                <DatePicker className="form-control time-input "

                                                    customInput={<CustomTimePickerInputforFireWatch />}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    dateFormat="h:mm aa"
                                                    id="inputStartTime"
                                                    selected={
                                                        typeof i.startTime === 'string'
                                                            ? (() => {
                                                                // Convert i.startTime from a string to a Date
                                                                const iso8601Date = new Date(i.startTime);

                                                                // Check if the conversion resulted in a valid Date object
                                                                if (!isNaN(iso8601Date.getTime())) {
                                                                    return iso8601Date;
                                                                }

                                                                // If the conversion to ISO 8601 Date failed, use a default Date
                                                                //   return new Date();
                                                            })()
                                                            : i.startTime // Use i.startTime directly if it's already a Date object
                                                    }
                                                    onChange={(date) => {
                                                        handleGeneralInfo(date, "fireWatchLoc", "startTime", 2, index);
                                                    }}
                                                />
                                            </td>

                                            <td>
                                                <label className="col-12 col-form-label">END TIME</label>
                                            </td>

                                            <td>
                                                <DatePicker className="form-control time-input "
                                                    // showIcon
                                                    customInput={<CustomTimePickerInputforFireWatch />}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    dateFormat="h:mm aa"
                                                    id="inputEndTime"
                                                    selected={
                                                        typeof i.endTime === 'string'
                                                            ? (() => {
                                                                // Convert i.startTime from a string to a Date
                                                                const iso8601Date = new Date(i.endTime);

                                                                // Check if the conversion resulted in a valid Date object
                                                                if (!isNaN(iso8601Date.getTime())) {
                                                                    return iso8601Date;
                                                                }

                                                                // If the conversion to ISO 8601 Date failed, use a default Date
                                                                //return new Date();
                                                            })()
                                                            : i.endTime // Use i.startTime directly if it's already a Date object
                                                    }
                                                    onChange={(date) => {
                                                        handleGeneralInfo(date, "fireWatchLoc", "endTime", 2, index);
                                                    }}
                                                    minTime={i.startTime || calculateMinEndTime(SelectTime)}
                                                    maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
                                                />
                                            </td>

                                            <td>
                                                {/* <input
                                                    className="form-control col-12"
                                                    id="inputPassword"
                                                    placeholder=""
                                                    value={i.fireWatchName}
                                                    onChange={(e) => {
                                                        handleGeneralInfo(e.target.value, 'fireWatchLoc', 'fireWatchName', 2, index)
                                                    }}
                                                /> */}
                                                <input
                                                    className="form-control col-12"
                                                    id="inputPassword"
                                                    placeholder=""
                                                    value={i.fireWatchName}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        const alphabetsOnly = inputValue.replace(/[^A-Za-z ]/g, ''); // This regex allows only alphabets
                                                        handleGeneralInfo(alphabetsOnly, 'fireWatchLoc', 'fireWatchName', 2, index);
                                                    }}
                                                />

                                            </td>
                                        </tr>
                                    ))}



                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FireWatch;
