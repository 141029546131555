import React, { useState} from "react";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../../Utilities/utils'

const currentDate = new Date().toISOString().split('T')[0];
function PermitReceiver(props) {
    const handleBasicData = (key, val) => {
        props.setPermitReceiver((prev) => ({ ...prev, [key]: val }));
    };

    // const handleDateChange = (date) => {
    //     if (date) {
    //         const formattedDate = new Date(date).toLocaleDateString();
    //         props.setPermitReceiver((prev) => ({ ...prev, date: formattedDate }));
    //         console.log(formattedDate);
    //         // setIsOpen(false);
    //     }
    // };
    const handleDateChange = (date) => {
        if (date) {
            const dateObj = new Date(date);
            const day = dateObj.getDate();
            const month = dateObj.toLocaleString('default', { month: 'short' });
            const year = dateObj.getFullYear();
    
            const formattedDate = `${day}-${month}-${year}`;
            props.setPermitReceiver((prev) => ({ ...prev, date: formattedDate }));
            console.log(formattedDate);
        }
    };
    


    return (
        <div className="m-2">
            <div className="row">
                {/* <div className="col-md-6 col-12"> */}
                    <div>
                        <div>
                            <div className="form-group">
                                <label htmlFor="inputText">Permit Receiver <span style={{ color: "red" }}> *</span></label>
                                <div>
                                    <input
                                        className="form-control"
                                        id="pr"
                                        placeholder=""
                                        value={props.data?.permitreceiver || ""}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphaValue = inputValue.replace(/[^a-zA-Z .]/g, ''); // Remove non-alphabet characters
                                            handleBasicData('permitreceiver', alphaValue);
                                        }}
                                    />

                                 {/* onChange={(e) => handleBasicData('permitreceiver', e.target.value)} */}
                                    
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputText">Date <span style={{ color: "red" }}> *</span></label>
                                <div>
                                    {/* <input
                                        className="form-control"
                                        id="dt"
                                        type="date"
                                        placeholder=""
                                        value={props.data?.permitreceiverdate || ""}
                                        min={currentDate}
                                        onChange={(e) => handleBasicData('permitreceiverdate', e.target.value)}
                                    /> */}

                                    
                                <DatePicker
                                    //selected={selectedDate}
                                    // readOnly={props.approvalStatus}
                                    value={props.data?.date || ""}
                                    onChange={(date) => handleDateChange(date)}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Click to select a date"
                                    showYearDropdown
                                    showMonthDropdown
                                    customInput={<CustomDatePickerInput />}
                                    closeOnSelect
                                    minDate={new Date(currentDate)}
                                />
                                </div>
                            </div>                                                                        
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </div>
    );
}

export default PermitReceiver;
