import React, { useEffect, useState } from "react";
function HazardMitigation(props) {
  const handlePeopleData = (key, val) => {
    props.setHazardMitigation((prev) => ({ ...prev, [key]: val }));
  };

  const handleHazardList = (id, val) => {
    const updatedPeopleList = props.data.workAccess.map((opItem, index) => ({
      ...opItem,
      checked: index == id ? val : opItem.checked,
    }));
    handlePeopleData("hazard", updatedPeopleList);
  };
  const updateInputs = (id, val) => {
    const updatedPeopleList = props.data.workAccess.map((opItem, index) => ({
      ...opItem,
      description: index == id ? val : opItem.description,
    }));
    handlePeopleData("workAccess", updatedPeopleList);
  };


  const handlePersonalList = (id, val) => {
    const updatedPeopleList = props.data.Hazard.map((opItem, index) => ({
      ...opItem,
      checked: index == id ? val : opItem.checked,
    }));
    handlePeopleData("Hazard", updatedPeopleList);
  };
  const updatePersonalInputs = (id, val) => {
    const updatedPeopleList = props.data.Hazard.map((opItem, index) => ({
      ...opItem,
      description: index == id ? val : opItem.description,
    }));
    handlePeopleData("Hazard", updatedPeopleList);
  };

  const handleAnchorList = (id, val) => {
    const updatedPeopleList = props.data.anchoragePoint.map((opItem, index) => ({
      ...opItem,
      checked: index == id ? val : opItem.checked,
    }));
    handlePeopleData("anchoragePoint", updatedPeopleList);
  };
  const updateAnchorInputs = (id, val) => {
    const updatedPeopleList = props.data.anchoragePoint.map((opItem, index) => ({
      ...opItem,
      description: index == id ? val : opItem.description,
    }));
    handlePeopleData("anchoragePoint", updatedPeopleList);
  };

  const handleFallingList = (id, val) => {
    const updatedPeopleList = props.data.fallingObjectCount.map((opItem, index) => ({
      ...opItem,
      checked: index == id ? val : opItem.checked,
    }));
    handlePeopleData("fallingObjectCount", updatedPeopleList);
  };
  const updateFallingInputs = (id, val) => {
    const updatedPeopleList = props.data.fallingObjectCount.map((opItem, index) => ({
      ...opItem,
      description: index == id ? val : opItem.description,
    }));
    handlePeopleData("fallingObjectCount", updatedPeopleList);
  };

  return (
    <div>
      <div className="col-12 col-lg-2">
        <div>
          {/* <div
            className="Corper-subtxt"
            style={{ backgroundColor: "#EE9C8D" }}
          >
            <p className="card-subheading">Work Access::</p>
          </div> */}
          <div className="col-12" style={{ marginTop: "-20px" }}>
            <div className="Corper-subtxt criti-hm-bg-color">
              <p className="card-subheading criti-hm-color">Work Access:: <span  className='required'> *</span> </p>
            </div>
          </div>
          {props.data.workAccess.map((item, index) => (
            <div class="form-group">
              <label >
                How personnel will access he work area:
              </label>
              <div>
                <input
                  class="form-control"
                  onChange={(e) => {
                    updateInputs(index, e.currentTarget.value);
                  }}
                  value={item.description}
                  id="inputPassword"
                  placeholder=""
                />
              </div>
            </div>
          ))}
          <div>
            {/* <div className="Corper-subtxt criti-hm-bg-color">
              <p className="card-subheading criti-hm-color">
                The Precations used to control or eliminate the hazards(select
                one or more):
              </p>
            </div> */}
            <div className="col-12">
              <div className="Corper-subtxt criti-hm-bg-color">
                <p className="card-subheading criti-hm-color" style={{ marginTop: "-10px" }}>The Precations used to control or eliminate the hazards(select one or more):</p>
              </div>
            </div>
            {props.data.Hazard.map((item, index) => (<>
              <div class="form-group row mt-2">
                <div
                  class="col-12"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div class="col-12 form-check form-check-inline">
                    <input
                      class="form-check-input  mt-1"
                      checked={item.checked}
                      onChange={(e) => {
                        handlePersonalList(
                          index,
                          e.target.checked
                        );
                      }}
                      type="checkbox"
                      id={`hazardList21323${index}`}
                      value="option1"
                    />
                    <label class="form-check-label  mt-0" for={`hazardList21323${index}`}>
                      {item.cbTitle}:
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-1">
                <div>
                  <input
                    class="form-control col-12"
                    id="inputPassword"
                    onChange={(e) => {
                      updatePersonalInputs(index, e.currentTarget.value);
                    }}
                    value={item.description}
                    placeholder=""
                  />
                </div>
              </div></>))}
            {/* <div
              className="Corper-subtxt"
              style={{ backgroundColor: "#EE9C8D" }}
            >
              <p className="card-subheading">Anchorage Points(if required):</p>
            </div> */}
            <div className="col-12">
              <div className="Corper-subtxt criti-hm-bg-color">
                <p className="card-subheading criti-hm-color" style={{ marginTop: "-10px" }}>Anchorage Points(if required):</p>
              </div>
            </div>
            {props.data.anchoragePoint.map((item, index) => (<>
              <div class="form-group row mt-2">
                <div
                  class="col-12"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div class="col-12 form-check form-check-inline">
                    <input
                      class="form-check-input  mt-1"
                      checked={item.checked}
                      onChange={(e) => {
                        handleAnchorList(
                          index,
                          e.target.checked
                        );
                      }}
                      type="checkbox"
                      id={`AnchorList${index}`}
                      value="option1"
                    />
                    <label class="form-check-label  mt-0" for={`AnchorList${index}`}>
                      {item.cbTitle}:
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-1">
                <div>
                  <input
                    class="form-control col-12"
                    onChange={(e) => {
                      updateAnchorInputs(index, e.currentTarget.value);
                    }}
                    value={item.description}
                    id="inputPassword"
                    placeholder=""
                  />
                </div>
              </div>
            </>))}
          </div>

          {/* <div
            className="Corper-subtxt"
            style={{ backgroundColor: "#EE9C8D", height: "56px" }}
          >
            <p className="card-subheading">
              Falling object control(select all that apply):
            </p>
          </div> */}
          <div className="col-12">
              <div className="Corper-subtxt criti-hm-bg-color">
                <p className="card-subheading criti-hm-color" style={{ marginTop: "-10px" }}>Falling object control(select all that apply):</p>
              </div>
            </div>
          {props.data.fallingObjectCount.map((item, index) => (<> <div class="form-group row mt-2">
            <div
              class="col-12"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div class="col-12 form-check form-check-inline">
                <input
                  class="form-check-input  mt-1"
                  type="checkbox"
                  checked={item.checked}
                  onChange={(e) => {
                    handleFallingList(
                      index,
                      e.target.checked
                    );
                  }}

                  id={`FallingList${index}`}
                  value="option1"
                />
                <label class="form-check-label  mt-0" for={`FallingList${index}`}>
                  {item.cbTitle}:
                </label>
              </div>
            </div>
          </div>
            <div class="form-group row mt-1">
              <div>
                <input
                  class="form-control col-12"
                  id="inputPassword"
                  onChange={(e) => {
                    updateFallingInputs(index, e.currentTarget.value);
                  }}
                  value={item.description}
                  placeholder=""
                />
              </div>
            </div></>))}
        </div>
      </div>
    </div>
  );
}

export default HazardMitigation;
