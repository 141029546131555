import React, { useState, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  RequestDeliverdCountAPI,
  getMasters,
} from "../../Utilities/URLCONSTANTS";
import axios from "axios";
import { GetApiHeaders } from "../../Utilities/NetworkUtilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

Chart.register(...registerables);

const RequestDeliverdChart = () => {
  const [RequestDelvCount, setRequestDelvCount] = useState([]);
  const [RequestDelvLabels, setRequestDelvLabels] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [SelectedType, setSelectedType] = useState("");
  const [error, setError] = useState("");
  const [hoveredPercentage, setHoveredPercentage] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");

  const fetchData = async (type) => {
    const formattedStartDate = startDate ? formatDate(startDate) : "";
    const formattedEndDate = endDate ? formatDate(endDate) : "";
    const RequestDeliverdRequest = RequestDeliverdCountAPI;
    const requObj = {
      type: startDate && endDate ? "" : selectedValue,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    try {
      const response = await axios.post(RequestDeliverdRequest, requObj);
      console.log(response, "rk");
      setRequestDelvCount(response.data.datasets);
      setRequestDelvLabels(response.data.labels);
    } catch (error) {
      console.log("RequestDeliverdRequest", error);
    }
  };

  useEffect(() => {
    const shouldFetchData = selectedValue === "DTD" && startDate && endDate;
    if (shouldFetchData || selectedValue !== "DTD") {
      fetchData(selectedValue, startDate, endDate);
    }
  }, [startDate, endDate, selectedValue]);

  const data = {
    labels: RequestDelvLabels,
    datasets: [RequestDelvCount],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "left",
        labels: {
          boxWidth: 10, // Set box width for each legend item
          padding: 10, // Set padding between legend items
        },
      },

      title: {
        display: true,
        text: "",
        align: "start",
      },
    },
  };

  const handleSelect = (e) => {
    setSelectedValue(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="card p-4 ">
        <label className="chartname">Requested & Delivered</label>
        <div className="d-flex" id="filtersInputs">
          <select
            className="form-control w-100"
            value={selectedValue}
            onChange={handleSelect}
          >
            <option value="">All</option>
            <option value="MTD">Monthly</option>
            <option value="YTD">Yearly</option>
            <option value="DTD">Custom Selection</option>
          </select>
        </div>

        {selectedValue === "DTD" && (
          <div className="d-flex">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="form-control  custom-datepicker"
            />
            <DatePicker
              style={{ marginLeft: "130px  " }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="form-control  custom-date"
            />
          </div>
        )}

        <div style={{ color: "red", fontSize: 12, marginLeft: 11 }}>
          {" "}
          {error}
        </div>
        <div
          className="chart-container"
          style={{
            position: "relative",
            height: "220px",
            marginLeft: "170px",
            marginTop: "-75px",
          }}
        >
          {/* <div className="chart-container" style={{height:'244px',marginTop:'-53px', marginLeft:100}}></div> */}
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </>
  );
};

export default RequestDeliverdChart;
