

// // import { data } from 'jquery'
// import React, { useState } from 'react'
// import { useEffect } from 'react'

// function PartONE(props) {

//     const handleExavatedWork = (key, val, index) => {

//         props.setPartOne((prev) => {
//             const list = prev.list.map((dataItem, indexVal) => 
//             ({ ...dataItem, [key]: indexVal == index ? val : dataItem[key] }))
//             return ({ list })
//         })
//     };
//     return (
//         <div className="m-2">
//             <div className="row">
//                 <div className="col-md-6 col-12">
//                     <div>
//                         <div style={{ fontWeight: "600" }}>
//                             <p>Excavation Competent Person Completes this section<span className='required'> *</span> </p>
//                         </div>



//                         {
//                             props.data.list.map((item, index) => (
//                                 <div className="form-group" key={index}>
//                                     <label htmlFor={`inputText${index}`}>{item.cbTitle}</label>
//                                     <div>
//                                         <input
//                                             className="form-control"
//                                             id={`inputPartOne${index}`}
//                                             placeholder=""
//                                             type={item.cbTitle.includes('date') || item.cbTitle.includes('Date') ? 'date' : 'text'}
//                                             value={item.value}
//                                             minLength={item.cbTitle.includes('Phone') ? 10 : null}
//                                             maxLength={item.cbTitle.includes('Phone') ? 10 : null}

//                                             onKeyPress={(e) => {
//                                                 if (item.cbTitle.includes('Phone')) {
//                                                     const key = e.key;
//                                                     const inputText = e.target.value + key;
//                                                     const mobileNumberRegex = /^[6-9]\d*$/;

//                                                     if (!(key >= '0' && key <= '9') || !mobileNumberRegex.test(inputText)) {
//                                                         e.preventDefault();
//                                                     }
//                                                 } else if (item.cbTitle.includes('Number of Personnel entering Excavation')) {
//                                                     if (!/^\d+$/.test(e.key)) {
//                                                         e.preventDefault();
//                                                     }
//                                                 } else if (!/^[a-zA-Z.\s]+$/.test(e.key)) {
//                                                     e.preventDefault();
//                                                 }
//                                             }}

//                                             onChange={(e) => {
//                                                 const inputValue = e.target.value;
//                                                 const isFutureDateAllowed = item.cbTitle === 'Permit Expiration Date';

//                                                 // Validate if it's a future date or today's date
//                                                 if (isFutureDateAllowed) {
//                                                     const currentDate = new Date();
//                                                     const enteredDate = new Date(inputValue);

//                                                     // Set the time portion of both dates to midnight (00:00:00) to compare only the date part
//                                                     currentDate.setHours(0, 0, 0, 0);
//                                                     enteredDate.setHours(0, 0, 0, 0);

//                                                     if (enteredDate < currentDate) {
//                                                         console.log('Please enter a future date.'); // Log to console instead of alert
//                                                         return; // Prevent setting the value if it's not a future date or today's date
//                                                     }
//                                                 }

//                                                 handleExavatedWork('value', inputValue, index);
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                             ))
//                         }


//                         <div>

//                         </div>
//                     </div>
//                 </div>

//             </div>
//         </div>

//     )
// }

// export default PartONE;

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomDatePickerInput } from '../../Utilities/utils'

function PartONE(props) {
    const currentDate = new Date().toISOString().split('T')[0];
    const handleExavatedWork = (key, val, index) => {
        props.setPartOne((prev) => {
            const list = prev.list.map((dataItem, indexVal) => ({
                ...dataItem,
                [key]: indexVal === index ? val : dataItem[key],
            }));
            return { list };
        });
    };

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                        <div style={{ fontWeight: '600' }}>
                            <p>Excavation Competent Person Completes this section<span className="required"> *</span> </p>
                        </div>

                        {props.data.list.map((item, index) => (
                            <div className="form-group" key={index}>
                                <label htmlFor={`inputText${index}`}>{item.cbTitle}</label>
                                <div>
                                    {item.cbTitle.includes('date') || item.cbTitle.includes('Date') ? (
                                        <DatePicker
                                            minDate={new Date(currentDate)}
                                            customInput={<CustomDatePickerInput />}
                                            className="form-control"
                                            dateFormat="dd-MMM-yyyy"
                                            selected={item.value ? new Date(item.value) : null}
                                            onChange={(date) => {
                                                // Adjust the timezone offset
                                                const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                                            
                                                // Format the adjusted date
                                                const formattedDate = adjustedDate.toISOString().split('T')[0];
                                                
                                                handleExavatedWork('value', formattedDate, index);
                                            }}
                                            
                                        />
                                    ) : (
                                        <input
                                            className="form-control"
                                            id={`inputPartOne${index}`}
                                            placeholder=""
                                            type={item.cbTitle.includes('Phone') ? 'tel' : 'text'}
                                            value={item.value}
                                            minLength={item.cbTitle.includes('Phone') ? 10 : null}
                                            maxLength={item.cbTitle.includes('Phone') ? 10 : null}
                                            onKeyPress={(e) => {
                                                if (item.cbTitle.includes('Phone')) {
                                                    const key = e.key;
                                                    const inputText = e.target.value + key;
                                                    const mobileNumberRegex = /^[6-9]\d*$/;

                                                    if (!(key >= '0' && key <= '9') || !mobileNumberRegex.test(inputText)) {
                                                        e.preventDefault();
                                                    }
                                                } else if (item.cbTitle.includes('Number of Personnel entering Excavation')) {
                                                    if (!/^\d+$/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                } else if (!/^[a-zA-Z.\s]+$/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const isFutureDateAllowed = item.cbTitle === 'Permit Expiration Date';

                                                if (isFutureDateAllowed) {
                                                    const currentDate = new Date();
                                                    const enteredDate = new Date(inputValue);

                                                    currentDate.setHours(0, 0, 0, 0);
                                                    enteredDate.setHours(0, 0, 0, 0);

                                                    if (enteredDate < currentDate) {
                                                        console.log('Please enter a future date.');
                                                        return;
                                                    }
                                                }

                                                handleExavatedWork('value', inputValue, index);
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PartONE;
