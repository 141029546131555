
import React,{useEffect, useState} from 'react'
function CoreRoofAccess(props) {  

    const handleRoofAccess = (key, val, index, mainKey, subKey='') => {
        const roofObj = {...props.data}
        const newObj = roofObj[mainKey]
        const newList = roofObj[mainKey][subKey].map((i,j)=>{
            if(j == index){
                return {...i, [key]:val}
            } else {
                return i
            }
        });
        newObj[subKey] = newList

        props.setRoofAccess(prev=> ({...prev, [mainKey]:newObj}))
    }
  return (
    <div>
    <div className="col-12 col-lg-2">
        <div>
            <div>
                {/* <div className="Corper-subtxt" style={{ backgroundColor: "rgb(255,0,0)", height: "40px" }}>
                    <p className="card-subheading">Slope type of roof(select one):</p>
                </div> */}
                <div className="col-12" style={{}}>
                <div className="Corper-subtxt wenv-bg-color">
                    <p className="card-subheading wenv-bg-color" style={{marginRight:"-23px",marginLeft:"-23px",}}>Slope type of roof(select one):<span className='required'> *</span> </p>
                </div>
                </div>
                {props.data.slopeType.list.map((slopeItem, index)=>(
                <>
                {slopeItem.checked !== undefined && 
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" checked={slopeItem.checked} onChange={(e)=>{
                                handleRoofAccess('checked', !slopeItem.checked, index, 'slopeType','list')
                            }} type="checkbox" id={`slopeItemList${index}`} value="option2" />
                            <label class="form-check-label mt-2" for={`slopeItemList${index}`}>{slopeItem.cbTitle}</label>
                        </div>
                    </div>
                }
                    {slopeItem.description != undefined &&
                    <div class="form-group">
                        <div>
                            <input class="form-control" type="text" id="empt1" value={slopeItem.description} placeholder={''}
                            onChange={(e)=>handleRoofAccess('description', e.target.value, index, 'slopeType','list')}    
                            />
                        </div>
                    </div>
                }   
                </>
            ))}
               
                {/* <div className="Corper-subtxt" style={{ backgroundColor: "rgb(255,0,0)", height: "40px" }}>
                    <p className="card-subheading">Work type(select one):</p>
                </div> */}
                <div className="col-12" style={{}}>
                <div className="Corper-subtxt wenv-bg-color">
                    <p className="card-subheading wenv-bg-color" style={{marginRight:"-23px",marginLeft:"-23px",}}>Work type(select one):</p>
                </div>
                </div>
                {props.data.workType.list.map((workItem, index)=>(
                <>
                {workItem.checked !== undefined && 
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" checked={workItem.checked} onChange={(e)=>{
                                handleRoofAccess('checked', !workItem.checked, index, 'workType','list')
                            }} type="checkbox" id={`workItemList${index}`} value="option2" />
                            <label class="form-check-label mt-2" for={`workItemList${index}`}>{workItem.cbTitle}</label>
                        </div>
                    </div>
                }
                    {workItem.description != undefined &&
                    <div class="form-group">
                        <div>
                            <input class="form-control" type="text" id="empt1" value={workItem.description} placeholder={''}
                            onChange={(e)=>handleRoofAccess('description', e.target.value, index, 'workType','list')}    
                            />
                        </div>
                    </div>
                }   
                </>
            ))}
                {/* <div class="form-group mt-2">
                    <div class="col-12">
                        <div class="col-12 form-check form-check-inline">
                            <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                            <label class="form-check-label  mt-0" for="inlineCheckbox1"><p>Roofing</p></label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="col-12 form-check form-check-inline">
                            <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                            <label class="form-check-label  mt-0" for="inlineCheckbox1">All other work</label>
                        </div>
                    </div>
                </div> */}
{/* 

                <div className="Corper-subtxt" style={{ backgroundColor: "rgb(255,0,0)", height: "57px" }}>
                    <p className="card-subheading">Minimum distance from the edge(select one):</p>
                </div> */}
                <div className="col-12" style={{}}>
                <div className="Corper-subtxt wenv-bg-color">
                    <p className="card-subheading wenv-bg-color" style={{marginRight:"-23px",marginLeft:"-23px",}}>Minimum distance from the edge(select one):</p>
                </div>
                </div>

                {props.data.distance.list.map((distanceItem, index)=>(
                <>
                {distanceItem.checked !== undefined && 
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" checked={distanceItem.checked} onChange={(e)=>{
                                handleRoofAccess('checked', !distanceItem.checked, index, 'distance','list')
                            }} type="checkbox" id={`distanceItemList${index}`} value="option2" />
                            <label class="form-check-label mt-2" for={`distanceItemList${index}`}>{distanceItem.cbTitle}</label>
                        </div>
                    </div>
                }
                    {distanceItem.description != undefined &&
                    <div class="form-group">
                        <div>
                            <input class="form-control" type="text" id="empt1" value={distanceItem.description} placeholder={''}
                            onChange={(e)=>handleRoofAccess('description', e.target.value, index, 'distance','list')}    
                            />
                        </div>
                    </div>
                }   
                </>
            ))}

                {/* <div class="form-group mt-2">
                    <div class="col-12">
                        <div class="col-12 form-check form-check-inline">
                            <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                            <label class="form-check-label  mt-0" for="inlineCheckbox1"><p>{"6ft.(1.83m)"}</p></label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="col-12 form-check form-check-inline">
                            <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                            <label class="form-check-label  mt-0" for="inlineCheckbox1">{"Between 6ft.(1.83m)&15ft.(4.57m)"}</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="col-12 form-check form-check-inline">
                            <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                            <label class="form-check-label  mt-0" for="inlineCheckbox1">{">15ft.(4.57m)"}</label>
                        </div>
                    </div>
                </div> */}
                {/* <div class="form-group row">
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <label class="form-check-label mt-2" for="inlineCheckbox2">Hatches,skylights,or other holes in the roof have been covered or gaurded(Crictical guard)</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <label class="form-check-label mt-2" for="inlineCheckbox2">Tank roof has been evaluated for the intended load(UT,service life,etc)</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <label class="form-check-label mt-2" for="inlineCheckbox2">Lighting is appropriate</label>
                        </div>
                    </div>
                </div> */}
                {/* <div class="form-group row">
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <label class="form-check-label mt-2" for="inlineCheckbox2">Vent,relief,exhaust and intake hazards are communicated and controlled</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <label class="form-check-label mt-2" for="inlineCheckbox2">No inclement weather</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input mt-3" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <label class="form-check-label mt-2" for="inlineCheckbox2">Flammables & controls(list):</label>
                        </div>
                    </div>
                </div> */}
                {/* <div class="form-group row mt-1">
                    <div>
                        <input class="form-control col-12" id="inputPassword" placeholder="" />
                    </div>
                </div> */}

            </div>
        </div>
    </div>
</div>
  )
}

export default CoreRoofAccess;