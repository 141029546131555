import React, { useState } from "react";
import { saveVendorDetails } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  CorePermitM2_PERMITID,
  FIRST_Time,
} from "../Utils";
import Loader_MS from "../Utilities/Loader_MS";

const CorePermitOne = () => {
  sessionStorage.setItem(CorePermitM2_PERMITID, null);
  sessionStorage.setItem(FIRST_Time, null);
  sessionStorage.setItem(COREPERMIT_MOBILE_NUMBER, null);
  sessionStorage.setItem(COREPERMIT_VENDOR_NAME, null);
  sessionStorage.setItem(COREPERMIT_DATE, null);
  const [Name, setName] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [submitResponse, setsubmitResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [error, setError] = useState("");

  const handleMobileNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, "");
    if (/^[6-9]\d{0,9}$|^$/.test(inputNumber)) {
      setMobileNumber(inputNumber);
    }
    console.log(inputNumber);
    if (inputNumber.length > 0) {
      setError("");
    }
  };

  const handleNameBeChange = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, "");
    setName(alphaValue);
    console.log(alphaValue);
    if (inputValue.length > 0) {
      setError("");
    }
  };

  const handleSubmit = async () => {
    console.log("Regular Expressions Start");

    const mobileNumberPattern =
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([6-9]\d{9})$/;
    const nameRegex = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;

    if (Name == null || Name.trim() === "") {
      setError("Please Enter Name");
      return;
    }
    if (MobileNumber === " ") {
      setError("Please Enter Mobile Number");
      return;
    }

    if (MobileNumber !== "" && MobileNumber.length != 10) {
      setError("Mobile Number Should be 10 Digits ");
      return;
    }
    if (!mobileNumberPattern.test(MobileNumber)) {
      setError("Please Enter Mobile Number");
      console.log("need valid number");
      return;
    }

    console.log("Regular Expressions End");

    if (Name !== "" && MobileNumber !== "") {
      const dataToSend = {
        name: Name,
        mobileNumber: MobileNumber,
      };
      var URL = saveVendorDetails;
      setIsLoading(false);

      try {
        var response = await axios.post(URL, dataToSend);
        setIsLoading(false);
        console.log("saveVendorDetails :", response.data.response);

        if (
          response.data !== undefined &&
          response.data !== null &&
          response.data.response !== undefined &&
          response.data.response != null &&
          response.data.response.vendorDetails.length > 0
        ) {
          console.log(
            "direct data:",
            response.data.response.vendorDetails[0].mobileNumber,
            " : ",
            response.data.response.vendorDetails[0].vendorName,
            " : ",
            response.data.response.vendorDetails[0].vendorEntryDate
          );
          sessionStorage.setItem(
            COREPERMIT_MOBILE_NUMBER,
            response.data.response.vendorDetails[0].mobileNumber
          );
          sessionStorage.setItem(
            COREPERMIT_VENDOR_NAME,
            response.data.response.vendorDetails[0].vendorName
          );
          sessionStorage.setItem(
            COREPERMIT_DATE,
            response.data.response.vendorDetails[0].vendorEntryDate
          );
          sessionStorage.setItem(
            FIRST_Time,
            response.data.response.vendorDetails[0].firstTime
          );
          window.location.href = "/AdditionalPermitOptions";
        }
      } catch (error) {
        // Handle network errors and server-side issues
        console.error("Network Error:", error);
        if (error.response && error.response.status == 500) {
          alert("Something went wrong, Please try again later.");
        } else if (error.response && error.response.status == 502) {
          alert("Something went wrong, Please try after sometime.");
        } else {
          alert("Something went wrong, Please try after sometime.");
        }
      }
    }
  };

  return (
    <body id="page-top" style={{ paddingTop: "10px", margin: "10px" }}>
      <div id="wrapper">
        <div
          id="content-wrapper"
          className="d-flex flex-column"
          style={{ backgroundColor: "white" }}
        >
          <div id="content">
            <div>
              <div className="container-fluid">
                <div className="d-flex justify-content-between"></div>
                <div>
                  {/* <form> */}
                  <div className="row">
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="col-md-6 col-12">
                      <label
                        for="exampleInputPassword1"
                        className="form-label text-sm font-weight-600 mt-2"
                      >
                        Name <span style={{ color: "red" }}>*</span>
                      </label>

                      <div className="input-group input-group w-100">
                        <input
                          type="text"
                          className="form-control p-2"
                          placeholder="Enter Name"
                          value={Name}
                          onChange={handleNameBeChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <label
                        for="exampleInputPassword1"
                        className="form-label text-sm font-weight-600 mt-2"
                      >
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="input-group input-group w-100">
                        <input
                          type="number"
                          className="form-control p-2"
                          placeholder="Enter Mobile Number"
                          value={MobileNumber}
                          onChange={handleMobileNumberChange}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <button
                    type="submit"
                    class="btn btn-primary col-12"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
      {isLoading && (
        <Loader_MS
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
        />
      )}
    </body>
  );
};
export default CorePermitOne;
