
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomDatePickerInput } from '../../Utilities/utils'

function Signatures(props) {
    const currentDate = new Date().toISOString().split('T')[0];
    const handleSignData = (key, val, type = '') => {
        if (type == 'dates') {
            props.setSignature((prev) => {
                const newDate = prev.dates;
                newDate[0][key] = val;
                return {
                    ...prev,
                    dates: newDate
                }
            });
        } else {
            props.setSignature((prev) => {
                const newData = prev[type];
                newData[key] = val;
                return {
                    ...prev,
                    [type]: newData
                }
            });
        }
    }


    const handleFromDateChange = (date) => {
        if (date) {
            const formattedDate = new Date(date).toLocaleDateString();
            props.setSignature((prev) => ({ ...prev, date: formattedDate }));
            console.log(formattedDate);
        }
    };



    const handleToDateChange = (date) => {
        if (date) {
            const formattedDate = new Date(date).toLocaleDateString();
            props.setSignature((prev) => ({ ...prev, date: formattedDate }));
            console.log(formattedDate);
        }
    };

    function getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }



    return (
        <div>

            <div>

                <div className="col-12" style={{ marginTop: "-20px" }}>
                    <div className="Corper-subtxt criti-fr-color" style={{ backgroundColor: "#D4D4D4", display: "none" }}>
                        <p className="card-subheading criti-fr-color" style={{ marginRight: "-13px", marginLeft: "-13px", backgroundColor: "#D4D4D4" }}>DATE(s)OF PROPOSED USAGE:</p>
                    </div>


                    <div style={{ backgroundColor: "#D4D4D4", marginRight: "-35px", marginLeft: "-35px", color: "#000" }}>

                        <div className="col-12">
                            <div className="form-group" style={{ margin: "5px", padding: "5px" }}>
                                <label>DATE(s)OF PROPOSED USAGE: </label><br />
                                <label>From<span className='required'> *</span></label>
                                <div>
                                    {/* <input type="date" class="form-control col-12"
                                        placeholder="Select From Date"
                                        value={props.data?.dates[0].fromDate || ''}
                                        onChange={(e) => handleSignData('fromDate', e.target.value, 'dates')}
                                        min={getCurrentDate()}

                                    /> */}
                                    <DatePicker
                                        selected={props.data?.dates[0]?.fromDate ? new Date(props.data.dates[0].fromDate) : null}
                                        onChange={(date) => handleSignData('fromDate', date, 'dates')}
                                        dateFormat="dd-MMM-yyyy"
                                        placeholderText="Select To Date"
                                        minDate={new Date(getCurrentDate())}
                                        customInput={<CustomDatePickerInput />}
                                    />


                                </div>

                                <label>To<span className='required'> *</span></label>


                                <div>
                                    {/* <input
                                        type="date"
                                        className="form-control col-12"
                    
                                        placeholder="Select To Date"
                                        value={props.data?.dates[0].toDate || ''}
                                        min={getCurrentDate()}
                                        onChange={(e) => handleSignData('toDate', e.target.value, 'dates')}
                                        customInput={<CustomDatePickerInput />}
                                    /> */}
                                    <DatePicker
                                        selected={props.data?.dates[0]?.toDate ? new Date(props.data.dates[0].toDate) : null}
                                        onChange={(date) => handleSignData('toDate', date, 'dates')}
                                        dateFormat="dd-MMM-yyyy"
                                        placeholderText="Select To Date"
                                        minDate={new Date(getCurrentDate())}
                                        customInput={<CustomDatePickerInput />}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>


                    <div style={{ backgroundColor: "#D4D4D4", marginRight: "-35px", marginLeft: "-35px", color: "#000" }}>

                        <div className="col-12">
                            <div className="form-group" style={{ margin: "5px", padding: "5px" }}>
                                <label>Work Planner</label>

                                <div>
                                    <input
                                        type="text"
                                        value={props.data?.workPlanner.description || ''}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphaValue = inputValue.replace(/[^a-zA-Z .]/g, '');
                                            handleSignData('description', alphaValue, 'workPlanner');
                                        }}
                                        className="form-control col-12"

                                        placeholder=""
                                    />
                                </div>

                                <label>Phone number</label>
                                <div>
                                    <input class="form-control col-12" placeholder=""

                                        value={props.data?.workPlanner.phoneNumber || ''}

                                        maxLength={10}
                                        minLength={10}
                                        onInput={(e) => {
                                            // Remove non-numeric characters from the input value
                                            e.target.value = e.target.value.replace(/\D/g, ''); // \D matches any non-digit character

                                            // Ensure the number starts with 6, 7, 8, or 9
                                            if (e.target.value.length >= 1 && !['6', '7', '8', '9'].includes(e.target.value.charAt(0))) {
                                                e.target.value = ''; // Clear the input if it doesn't start with the required digits
                                            }
                                        }}
                                        onChange={(e) => handleSignData('phoneNumber', e.target.value, 'workPlanner')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#D4D4D4", marginRight: "-35px", marginLeft: "-35px", color: "#000" }}>
                        <div className="col-12">
                            <div class="form-group" style={{ margin: "5px", padding: "5px" }}>
                                <label>Critical Elevated Work Permit Approver</label>
                                <div>
                                    <input class="form-control col-12"
                                        value={props.data?.criticalElevated.description || ''}

                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphaValue = inputValue.replace(/[^a-zA-Z .]/g, '');
                                            handleSignData('description', alphaValue, 'criticalElevated');
                                        }}

                                        placeholder="" />
                                </div>
                                <label>Phone number</label>
                                <div>


                                    <input class="form-control col-12" placeholder=""

                                        value={props.data?.criticalElevated.phoneNumber || ''}

                                        maxLength={10}
                                        minLength={10}
                                        onInput={(e) => {
                                            // Remove non-numeric characters from the input value
                                            e.target.value = e.target.value.replace(/\D/g, ''); // \D matches any non-digit character

                                            // Ensure the number starts with 6, 7, 8, or 9
                                            if (e.target.value.length >= 1 && !['6', '7', '8', '9'].includes(e.target.value.charAt(0))) {
                                                e.target.value = ''; // Clear the input if it doesn't start with the required digits
                                            }
                                        }}
                                        onChange={(e) => handleSignData('phoneNumber', e.target.value, 'criticalElevated')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#D4D4D4", marginRight: "-35px", marginLeft: "-35px", color: "#000" }}>
                        <div className="col-12">
                            <div class="form-group" style={{ margin: "5px", padding: "5px" }}>
                                <label>Structural engineer Approval of Anchorage(if necessary)</label>
                                <div>
                                    <input class="form-control col-12" placeholder=""
                                        value={props.data?.structuralEngineer.description || ''}
                                        //    onChange={(e) => handleSignData('description', e.target.value, 'structuralEngineer')}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphaValue = inputValue.replace(/[^a-zA-Z .]/g, '');
                                            handleSignData('description', alphaValue, 'structuralEngineer');
                                        }}
                                    />
                                </div>
                                <label>Phone number</label>
                                <div>


                                    <input class="form-control col-12" placeholder=""

                                        value={props.data?.structuralEngineer.phoneNumber || ''}

                                        maxLength={10}
                                        minLength={10}
                                        onInput={(e) => {
                                            // Remove non-numeric characters from the input value
                                            e.target.value = e.target.value.replace(/\D/g, ''); // \D matches any non-digit character

                                            // Ensure the number starts with 6, 7, 8, or 9
                                            if (e.target.value.length >= 1 && !['6', '7', '8', '9'].includes(e.target.value.charAt(0))) {
                                                e.target.value = ''; // Clear the input if it doesn't start with the required digits
                                            }
                                        }}
                                        onChange={(e) => handleSignData('phoneNumber', e.target.value, 'structuralEngineer')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#D4D4D4", marginRight: "-35px", marginLeft: "-35px", color: "#000" }}>
                        <div className="col-12">
                            <div class="form-group" style={{ margin: "5px", padding: "5px" }}>
                                <label>EHS&S Leader Approval*(if necessary)</label>
                                <div>
                                    <input class="form-control col-12" value={props.data?.ehss.description || ''}
                                        // onChange={(e) => handleSignData('description', e.target.value, 'ehss')} 
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const alphaValue = inputValue.replace(/[^a-zA-Z .]/g, '');
                                            handleSignData('description', alphaValue, 'ehss');
                                        }}
                                        placeholder="" />
                                </div>
                                <label>Phone number</label>
                                <div>

                                    <input class="form-control col-12" placeholder=""

                                        value={props.data?.ehss.phoneNumber || ''}

                                        maxLength={10}
                                        minLength={10}
                                        onInput={(e) => {
                                            // Remove non-numeric characters from the input value
                                            e.target.value = e.target.value.replace(/\D/g, ''); // \D matches any non-digit character

                                            // Ensure the number starts with 6, 7, 8, or 9
                                            if (e.target.value.length >= 1 && !['6', '7', '8', '9'].includes(e.target.value.charAt(0))) {
                                                e.target.value = ''; // Clear the input if it doesn't start with the required digits
                                            }
                                        }}
                                        onChange={(e) => handleSignData('phoneNumber', e.target.value, 'ehss')} />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
export default Signatures;