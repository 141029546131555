import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../src/images/corteva-logo.png';
import Menu from './images/Menu.png';
import CentralLogo from './images/CentralLogo.png';
import FarmLogo from './images/FarmLogo.png';
import Tracking from './images/Tracking.png';
import Search from './images/search.png';


import researchOperation from './images/research_operation.png';
import productrequestImage from './images/product_request.png'
import issuedProducts from './images/issued-products.png';
import PendingProducts from './images/Pending_products.png';
import closedProducts from './images/closed-products.png';
import RMtracking from './images/R-M-tracking.png';
import noun from './images/noun.png';

// R-M-tracking
import user from './images/user.png';
import centralStore from './images/central-store.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SideMenu from './Pages/SideMenu';
import Header from './Pages/Header';
import Footer from './Pages/Footer';
import Logout from './Pages/Logout';
import { MOBILE_NUMBER, retrieveData, storeData } from './Utils';
import { dashboardCountSummary } from "./Utilities/URLCONSTANTS";
import { GetApiHeaders } from "./Utilities/NetworkUtilities";
import axios from 'axios';


const Dashboard=()=> {
  
    //const BASE =sessionStorage.getItem("BASE");
  // console.log("Base64::",BASE);
  const [issueStock, setissueStock] = useState();
  const [respNotification, setrespNotification] = useState();
  const [pendingRequest, setpendingRequest] = useState();
  const [acceptedRequest, setacceptedRequest] = useState();
  const [fOAcceptedRequest, setfOAcceptedRequest] = useState();
  const [fOPendingRequest, setfOPendingRequest] = useState();
  
  

    const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");

    const changeStyle = () => {
        if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
        }
        else {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    };
    const changeStyle1 = () => {
        if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
        }
        else {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    };
    const fetchData = async () =>{
        const notificationApi = dashboardCountSummary
        const headers = await GetApiHeaders();
        const postData ={
            "userType":"Admin",

            "mainAsset":"rmTracking,centralStore"
        }
    
        try{
            const response = await axios.post(notificationApi, postData, {headers})
            console.log("cs notification",response)
       
            setrespNotification(response.data.response.centralStore['1'].notificationSummary.stockInWard)
            setissueStock(response.data.response.centralStore['1'].notificationSummary.issuedStock)
            setpendingRequest(response.data.response.rmTracking['1'].notificationSummary.approvalPending)
            setacceptedRequest(response.data.response.rmTracking['1'].notificationSummary.totalRequestsAccepted)
      
        }catch(error){
            console.log("Notification", error)
        }
     }
     useEffect(()=>{
        fetchData()
     }, [])
    
    return (
        <div>
            {/* {(isUserLoggedIn != undefined && isUserLoggedIn.toString() != 'false' && isUserLoggedIn.toString() != '' &&  isUserLoggedIn!=null) && */}
                <body id="page-top">
                    {/*  <!-- Page Wrapper --> */}
                    <div id="wrapper">
                        {/*  <!-- Sidebar --> */}
                        <SideMenu />
                        {/*  <!-- End of Sidebar --> */}

                        {/*  <!-- Content Wrapper --> */}
                        <div id="content-wrapper" className="d-flex flex-column">

                            {/*  <!-- Main Content --> */}
                            <div id="content">
                                {/*  <!-- Topbar --> */}
                                <Header />
                                {/* ...Content Start... */}
                                <div className='mr-2'>
                                    <div className='container-fluid mt-2 '>
                                        <div className='card shadow border-0 research_card'>
                                            <div className='card-body'>
                                                <div className='d-lg-flex d-sm-block gap-3 flex-grow-1'>
                                                    <div className='research_text pt-2 pl-2 w-full md:w-10/12'>
                                                        <h6 className='MCRC_text '>MCRC Research Operations</h6>
                                                        <p>MCRC Research Operations, a critical element of the supply chain, is the tracking of Central
                                                            store from manufacturers to warehouses and from these facilities to a
                                                            point of sale. The goal of Central store management is to have the right
                                                            products in the right place at the right time.</p>
                                                        {/* <div className='btn btn-primary btn-md'>Take a tour
                                                            <FontAwesomeIcon icon={faArrowRight} className='arrow_icon ml-1' />
                                                        </div> */}
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        <img src={researchOperation} alt="Login" className='research_card_image m-auto pr-5' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            <div className='d-flex gap-1 status_card'>
                                                {/* <div className='card flex-grow-1'>
                                                    <div className='card-body p-1'>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <img src={productrequestImage} className='img-fluid status-image' alt="StatusImage" />
                                                            <div className='d-flex flex-column'>
                                                                <span className='status_text'>product Request</span>
                                                                <span className='status_value'><b>1000</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div className='card flex-grow-1'>
                                                    <div className='card-body p-1'>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <img src={issuedProducts} className='img-fluid status-image' alt="StatusImage" />
                                                            <div className='d-flex flex-column'>
                                                                <span className='status_text'>Issued Products</span>
                                                                <span className='status_value'><b>1000</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <div className='card flex-grow-1'>
                                                    <div className='card-body p-1'>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <img src={PendingProducts} className='img-fluid status-image' alt="StatusImage" />
                                                            <div className='d-flex flex-column'>
                                                                <span className='status_text'>Pending Products</span>
                                                                <span className='status_value'><b>1000</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <div className='card flex-grow-1'>
                                                    <div className='card-body p-1'>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <img src={closedProducts} className='img-fluid status-image' alt="StatusImage" />
                                                            <div className='d-flex flex-column'>
                                                                <span className='status_text'>Closed Requests</span>
                                                                <span className='status_value'><b>1000</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <div className='card flex-grow-1'>
                                                    <div className='card-body p-1'>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <img src={user} className='img-fluid status-image' alt="StatusImage" style={{ width: '33px', height: '46px' }} />
                                                            <div className='d-flex flex-column'>
                                                                <span className='status_text'>Users</span>
                                                                <span className='status_value'><b>1000</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            <div className='row'>
                                                <div className='col-lg-6 col-12 mb-2'>
                                                    <div className='card cenralStore_card shadow border-0'>
                                                        <div className='card-body pb-5 px-4 pt-4'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='text-white'>
                                                                    <h4 className='text-white'>Central Store</h4>

                                                                                <table>
                                                                                <tr className='store_text'><td>Overall Stock</td><td>:</td><td>{respNotification}</td></tr>
                                                                                <tr className='store_text'><td>issue Stock</td><td>:</td><td>{issueStock}</td></tr>
                                                                            </table>
                                                                       
                                                                        
                                                                </div>
                                                                <img src={centralStore} className='img-fluid status-image' alt="StatusImage"
                                                                    style={{ height: '42px' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-12 mb-2'>
                                                    <div className='card flex-grow-1 farmerOptions_card shadow border-0'>
                                                        <div className='card-body pb-5 px-4 pt-4'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='text-white'>
                                                                    <h4 className='text-white'>Farm Operations </h4>
                                                                    <table>
                                                                        <tr className='store_text'><td>Accepted Request</td><td>:</td><td>0</td></tr>
                                                                        <tr className='store_text'><td>Pending Request</td><td>:</td><td>0</td></tr>
                                                                    </table>
                                                                </div>
                                                                <img src={FarmLogo} className='img-fluid status-image' alt="StatusImage"
                                                                    style={{ height: '42px', color:'white' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-lg-6 col-12 mb-2'>
                                                    <div className='card flex-grow-1 RM_tracking_card shadow border-0'>
                                                        <div className='card-body pb-5 px-4 pt-4'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='text-white'>
                                                                    <h4 className='text-white'>R & M Tracking</h4>
                                                                    <table>
                                                                        <tr className='store_text'><td>Pending Request</td><td>:</td><td>{pendingRequest}</td></tr>
                                                                        <tr className='store_text'><td>Accepted Request</td><td>:</td><td>{acceptedRequest}</td></tr>
                                                                    </table>
                                                                </div>
                                                                <img src={RMtracking} className='img-fluid status-image' alt="StatusImage"
                                                                    style={{ height: '42px' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-12 mb-2'>

                                                    <div className='card flex-grow-1 cenralStore_cards shadow border-0'>
                                                        <div className='card-body pb-5 px-4 pt-4'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='text-white'>
                                                                    <h4 className='text-white'>Man Power</h4>
                                                                    <table>
                                                                        <tr className='store_text'><td>Accepted Request</td><td>:</td><td>0</td></tr>
                                                                        <tr className='store_text'><td>Pending Request</td><td>:</td><td>0</td></tr>
                                                                    </table>
                                                                </div>
                                                                <img src={noun} className='img-fluid status-image' alt="StatusImage"
                                                                    style={{ height: '42px' }} />

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/*   <!-- End of Main Content -->

                               {/* ...Footer...*/}
                            <Footer />
                            {/* ... End of Footer...  */}

                        </div>

                    </div>
                    {/*  <!-- End of Page Wrapper -->

                                <!-- Scroll to Top Button--> */}
                    <a className="scroll-to-top rounded" href="#page-top">
                        <i className="fas fa-angle-up"></i>
                    </a>

                    {/*  <!-- Logout Modal--> */}
                    <Logout />

                </body>
            {/* } */}
        </div>
    )
}

export default Dashboard;
