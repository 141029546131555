import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../src/Pages/SideMenu";
import Header from "../../src/Pages/Header";
import Footer from "../../src/Pages/Footer";
import Logout from "../../src/Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import { USER_PROFILE } from "../Utils";
import {
  getMasters,
  rmComplaintsAcceptReject,
  rmComplaints,
  rmGetmasters,
} from "../Utilities/URLCONSTANTS";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import ModalImage from "react-modal-image";
import Loader_Bulk from "../Utilities/Loader_Bulk";
import moment from "moment";

const RepairMaintenace = (category, index) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [estDate, setEstDate] = useState("");
  const [corePermit, setCorePermit] = useState("");
  const [assignWork, setAssignWork] = useState("");
  const [isBoxVisible, setBoxVisible] = useState(false);
  const [requestedDate, setRequestedDate] = useState([]);
  const [getData, setGetData] = useState([]);
  const [getAccName, setGetAccName] = useState([]);
  const [submitResponse, setsubmitResponse] = useState("");
  const [showMsg, setShowMsg] = useState("false");
  const [errAssignTo, setErrAssignTo] = useState("");
  const [errEstimated, setErrEstimated] = useState("");
  const [errcorepermit, setErrCorepermit] = useState("");
  const [errRemark, setErrRemark] = useState("");
  const [code, setCode] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );

  const handleRadioChange = (value) => {
    // debugger;
    console.log("event", value);
    setCorePermit(value);
    if (value) {
      setErrCorepermit("");
    }
  };
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const getMasterData = async () => {
    const getData = rmGetmasters;
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const responseGetData = await axios.get(getData, { headers });
      console.log("GettResponse,", responseGetData);
      setGetData(responseGetData.data.response.employees);
      setIsLoading(false);
    } catch (err) {
      console.log("getError", err);
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleImageClick = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };
  const handleAprove = async (index, itemsIndex) => {
    console.log("index", index);
    const inputData = {
      requiredDate: data[index].requiredDate,
      requesterName: data[index].requesterName,
      requesterMobileNumber: data[index].requesterMobileNumber,
      status: "Approved",
      requesterType: data[index].requesterType,
      function: data[index].function,
      location: data[index].location,
      subLocation: data[index].subLocation,
      acceptorName: getAccName.firstName + " " + getAccName.lastName,
      acceptorID: getAccName.userId,
      acceptorMobileNumber: getAccName.mobileNumber,
      serverRecordId: data[index].serverRecordId,
      typeOfWork: data[index].typeOfWork,
      typeOfWorkId: data[index].typeOfWorkId,
      functionId: data[index].functionId,
      locationId: data[index].locationId,
      subLocationId: data[index].subLocationId,
      requesterRemarks: data[index].requesterRemarks,
      image: data[index].image,
      corePermitRequired: corePermit,
      assignWorkToId: code,
      assignWorkToName: assignWork,
      assignWorkToMobileNumber: mobileNo,
      estimatedCompletionDate: estDate,
      accepterRemarks: remark,
      submittedDate: data[index].submittedDate,
      requestId: data[index].requestId,
    };
    console.log("inputData", inputData);
    if (corePermit === "") {
      setErrCorepermit("Please Select Core Permit");
    } else {
      setErrCorepermit("");
    }
    if (assignWork === "") {
      setErrAssignTo("Please Select Assign To Work");
    } else {
      setErrAssignTo("");
    }
    if (estDate === "") {
      setErrEstimated("Please Select Estimated Date");
    } else {
      setErrEstimated("");
    }
    const aproveApi = rmComplaintsAcceptReject;
    const headers = await GetApiHeaders();
    try {
      if (estDate != "" && assignWork != "" && corePermit != "") {
        setIsLoading(true);
        const responseData = await axios.post(aproveApi, inputData, {
          headers,
        });
        console.log("responseData", responseData);

        if (responseData.data.statusCode == 200) {
          setShowResponsePopup(true);
          setsubmitResponse(responseData.data.message);
        }
        if (responseData.data.statusCode == 500) {
          setShowResponsePopup(true);
          setsubmitResponse(responseData.data.message);
        } else {
        }
        setErrAssignTo("");
        setErrCorepermit("");
        setErrEstimated("");
        setIsSubmitted(true);
        setIsLoading(false);
      } else {
        setIsSubmitted(false);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const closeBox = () => {
    setBoxVisible(false);
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
  };
  const handleDateChange = (e) => {
    setEstDate(e.target.value);
    if (e.target.value) {
      setErrEstimated("");
    }
  };
  const closeWarning = () => {
    setShowWarningPopup(false);
  };
  const handleAssignTo = (e) => {
    debugger;
    setAssignWork(e.target.value);
    const CodeID =
      e.target.options[e.target.selectedIndex].getAttribute("data-code");
    const Mbno =
      e.target.options[e.target.selectedIndex].getAttribute("data-mbno");
    setCode(CodeID);
    setMobileNo(Mbno);
    if (e.target.value) {
      setErrAssignTo("");
    }
  };
  const handleRemark = (e) => {
    setRemark(e.target.value);
    if (e.target.value) {
      setErrRemark("");
    }
  };
  const handleReject = async (index, itemsIndex) => {
    const inputData = {
      requiredDate: data[index].requiredDate,
      requesterName: data[index].requesterName,
      // requestedDate : requestedDate.requestedDate,
      requesterMobileNumber: data[index].requesterMobileNumber,
      status: "Rejected",
      requesterType: data[index].requesterType,
      function: data[index].function,
      location: data[index].location,
      subLocation: data[index].subLocation,
      acceptorName: getAccName.firstName + " " + getAccName.lastName,
      acceptorID: getAccName.userId,
      acceptorMobileNumber: getAccName.mobileNumber,
      serverRecordId: data[index].serverRecordId,
      requesterId: "",
      typeOfWork: data[index].typeOfWork,
      typeOfWorkId: data[index].typeOfWorkId,
      functionId: data[index].functionId,
      locationId: data[index].locationId,
      subLocationId: data[index].subLocationId,
      requesterRemarks: data[index].requesterRemarks,
      image: data[index].image,
      corePermitRequired: "",
      assignWorkToId: 0,
      assignWorkToName: "",
      assignWorkToMobileNumber: "",
      estimatedCompletionDate: "",
      accepterRemarks: remark,
      submittedDate: data[index].submittedDate,
      requestId: data[index].requestId,
    };
    const aproveApi = rmComplaintsAcceptReject;
    const headers = await GetApiHeaders();
    if (remark === "") {
      setErrRemark("Please Enter Remarks");
    } else {
      setErrRemark("");
    }

    try {
      if (remark != "") {
        setIsLoading(true);
        const response = await axios.post(aproveApi, inputData, { headers });
        if (response.data.statusCode == 200) {
          setShowResponsePopup(true);
          setsubmitResponse(response.data.message);
        }
        if (response.data.statusCode == 500) {
          setShowResponsePopup(true);
          setsubmitResponse(response.data.message);
        }
        setErrRemark("");
        setIsSubmitted(true);
        setIsLoading(false);
      } else {
        setIsSubmitted(false);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const fetchData = async () => {
    const getApi = rmComplaints;
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const response = await axios.post(getApi, {}, { headers });
      setData(response.data.response.rmComplaints);
      console.log("res:", response);
      setIsLoading(false);
    } catch (err) {
      console.error("error", err);
    }
  };
  useEffect(() => {
    fetchData();
    getMasterData();

    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetAccName(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);

  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text">R & M</h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline"></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2">R & M</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {data.map((item, index) => {
                                var newDateFormat = moment(
                                  item.submittedDate  ).format("DD-MMM-YYYY hh:mm:ss A");
                                var parsedDate = moment( item.requiredDate,  "DD-MM-YYYY"  );

                                var requiredDate = parsedDate.format("DD-MMM-YYYY"); 
                                return (
                                  <Tr key={index}>
                                    <Td className="pl-1">{index + 1}</Td>
                                    <Td>
                                      <Accordion
                                        defaultActiveKey="0"
                                        className="acc mt-3 mb-2"
                                        id="dns"
                                      >
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                            <div
                                              style={{
                                                fontSize: 14,
                                                width: "100%",
                                              }}
                                              className="ProductName d-flex"
                                            >
                                              <span
                                                className=""
                                                style={{ width: "17%" }}
                                              >
                                                <b className="">
                                                  Request Id &nbsp; &nbsp;:
                                                </b>{" "}
                                                <span>
                                                  {item.serverRecordId}
                                                </span>
                                              </span>
                                              <span
                                                className=""
                                                style={{ width: "31%" }}
                                              >
                                                <b className="">
                                                  {" "}
                                                  Requested Date &nbsp;
                                                  &nbsp;:&nbsp;
                                                </b>{" "}
                                                <span>{newDateFormat}</span>
                                              </span>
                                              <span
                                                className=""
                                                style={{ width: "30%" }}
                                              >
                                                <b className="quantty">
                                                  {" "}
                                                  Requester Name &nbsp;&nbsp;:{" "}
                                                  &nbsp;
                                                </b>{" "}
                                                <span>
                                                  {item.requesterName}
                                                </span>
                                              </span>

                                              <span
                                                className=""
                                                style={{ width: "22.7%" }}
                                              >
                                                <b>
                                                  Status &nbsp; &nbsp;: &nbsp;
                                                </b>
                                                <span>
                                                  {item.status === "Pending" ? (
                                                    <span
                                                      style={{
                                                        color: "orange",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Pending
                                                    </span>
                                                  ) : (
                                                    item.status
                                                  )}
                                                </span>
                                                &nbsp; &nbsp;
                                              </span>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <>
                                            <div  style={{  fontSize: 14,  width: "100%",  }}
                                                className="ProductNameForRandM d-flex" >
                                                  <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Requester Type
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>{item.requesterType}</span>
                                              </span>
                                                  <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Function
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>{item.function}</span>
                                              </span>
                                                  <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Type of work
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>{item.typeOfWork}</span>
                                              </span> 
                                              </div>

                                            <div  style={{  fontSize: 14,  width: "100%",  }}
                                                className="ProductNameForRandM d-flex mt-3" >
                                              <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Location
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>{item.location}</span>
                                              </span> 
                                                    <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Sub Location
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>{item.subLocation}</span>
                                              </span> 
                                               <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                  Core Permit required ?
                                                  </span> 
                                                    <span >  :&nbsp; 
                                                    </span>  
                                                 <span style={{  width:'60%',display:'inline-block' }}>
                                                 <label>
                                                  <input type="radio"  name="corePermit" value={corePermit}  onChange={() => handleRadioChange( "Yes" ) } /> 
                                                      Yes
                                                    </label> 
                                                    &nbsp; &nbsp; &nbsp;
                                                    <label>
                                                      <input type="radio"  name="corePermit"  value={corePermit}  onChange={() =>  handleRadioChange(  "No" ) }  /> 
                                                      No
                                                    </label> 
                                                  </span>
                                                  <div>
                                                    <span  style={{ color: "red" }}  >
                                                      {errcorepermit}
                                                    </span>
                                                  </div>
                                              </span> 
                                              </div>
                                             <div  style={{  fontSize: 14,  width: "100%",  }}
                                                className="ProductNameForRandM d-flex mt-3" >
                                               <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Required Date
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>{requiredDate}</span>
                                               </span>
                                               <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Assign Work to
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>
                                                 <select  value={  data[index].assignWork }
                                                      style={{ width: 130,  height: 32, paddingLeft: 3, borderBlockColor:  "silver",  borderRadius: 5,  }}  onChange={handleAssignTo} >
                                                      <option>select</option>
                                                      {getData.map(
                                                        (items, itemsIndex) => {
                                                          return (
                                                            <option  key={items.code}
                                                              data-code={ items.code } data-mbno={ items.mobileNumber  } value={items.name} >
                                                              {items.name}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </select> 
                                                  </span>
                                                   <div>
                                                    <span  style={{  color: "red"  }}  >
                                                      {errAssignTo}
                                                    </span>
                                                  </div>
                                               </span>
                                               <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Estimated   Date
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>
                                                <input type="date"  style={{  width: 108, height: 32, }}
                                                 min={getCurrentDate()}  value={ data[index].estDate }  onChange={ handleDateChange  } />
                                                </span>
                                                <div>
                                                    <span  style={{ color: "red" }} >  {errEstimated} </span>
                                                </div>
                                               </span> 
                                              </div>
                                              <div  style={{  fontSize: 14,  width: "100%",  }}
                                                className="ProductNameForRandM d-flex mt-3" >
                                                   <span  style={{width:'33.4%' }}>
                                                 <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                 Images
                                                 </span> 
                                                  <span >  :&nbsp; 
                                                  </span>  
                                                <span style={{  width:'60%',display:'inline-block' }}>
                                                {item.image.map(
                                                      (imageUrl, imgIndex) => (
                                                        <div key={imgIndex}
                                                          style={{  marginRight: "10px", display:  "inline-block",
                                                            border: "1px solid", }}  >
                                                          <div  style={{ width: "60%",  }} >
                                                            <img  key={imgIndex}  src={imageUrl}  alt={`Image ${imgIndex}`}
                                                              style={{  width: "30px",  height: "40px", cursor: "pointer",  }} 
                                                               onClick={() =>  handleImageClick( imageUrl  )  }/>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </span>
                                                  </span> 
                                                {
                                                  item.requesterRemarks !== ""?(
                                                    <span  style={{width:'33.4%' }}>
                                                    <span style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                    Requester Remarks
                                                    </span> 
                                                     <span >  :&nbsp; 
                                                     </span>  
                                                   <span style={{  width:'60%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.requesterRemarks}</span>
                                                 </span>
                                                  ):""
                                                }
                                                 <span  style={{width:'33.4%' }}>
                                                    <div style={{  width:'40%',display:'inline-block',fontWeight:600}} > 
                                                      Remarks
                                                    </div> 
                                                     <span >  :&nbsp; 
                                                     </span>  
                                                   <div style={{  width:'60%',display:'inline-flex',whiteSpace:"break-spaces" }}>
                                                   <textarea
                                                      type="text"
                                                      style={{ borderColor: "silver",  borderRadius: 5,     }}
                                                      value={data[index].remark}
                                                      onChange={handleRemark}  />
                                                    </div>
                                                    <div>
                                                    <span  style={{  color: "red",  marginLeft: 160 }}  >
                                                      {errRemark}
                                                    </span>
                                                  </div>
                                                 </span>
                                                 
                                              </div>
                                              <span className="mt-0 d-flex justify-content-end mt-2">
                                                <span>
                                                  <button
                                                    onClick={() =>
                                                      handleAprove(index)
                                                    }
                                                    className="btns"
                                                  >
                                                    Approve
                                                  </button>
                                                </span>
                                                <span
                                                  className="btn-group "
                                                  data-toggle="tooltip"
                                                  title="Reject"
                                                >
                                                  <button
                                                    className="btnsReject  ml-2 mb-2"
                                                    onClick={() =>
                                                      handleReject(index)
                                                    }
                                                  >
                                                    Reject
                                                  </button>
                                                </span>
                                              </span>
                                            </>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="md"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">R & M</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleClose}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading && (
                <Loader_Bulk
                  loading={isLoading}
                  message={"Fetching Data. Please Wait..!"}
                  loderimage={loaderImage}
                />
              )}
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default RepairMaintenace;
