// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-image {
    background-size: cover;
    background-position: center;
}

`, "",{"version":3,"sources":["webpack://./src/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B","sourcesContent":[".bg-image {\r\n    background-size: cover;\r\n    background-position: center;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
