import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import { strings } from "../Utilities/strings";
// import 'datatables.net';
// import 'datatables.net-dt/css/jquery.dataTables.css';
// for Sidebar And Navbar
import Search from "../images/search.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faEye,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import { getAllRecievedRequestsForUpdateActivity ,getMasters} from "../Utilities/URLCONSTANTS";
// import {Oval as Loader} from 'react-loader-spinner';
import Loader from "../Utilities/Loader";
import ViewIcon from "../images/viewIcon.png";
import closedProducts from "../images/closed-products.png";
import user from "../images/user.png";
import productrequestImage from "../images/product_request.png";
import issuedProducts from "../images/issued-products.png";
import PendingProducts from "../images/Pending_products.png";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import moment from "moment";
import * as XLSX from "xlsx";
//UnitMeasureMaster
const UpdateActivity = ({}) => {
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState(
    "Do you want to close Unit Measure Master"
  );
  const [loading, setLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/fo_loader.gif")
  );
  const [showDetails, setShowDetails] = useState({});
  ///modal popup
  const [showModal, setShowModal] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState();
  const [getMasterData, setGetMasterData] = useState([])
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      const filteredResults = data.filter(
        (item) =>
          (item.submittedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.typeOfOperationName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.requiredDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.cropFunctionName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.plotNoLocationName
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.serverRequestId.toLowerCase().includes(searchTerm.toLowerCase())) 
          // && 
          // (selectedLocation === "" || item.subSiteName === selectedLocation)
      );
      setFilteredData(filteredResults);
      handlePageClick(1)
    } 
  };

  const fetchGetMaster = async () => {
    const getData = getMasters;
    const headers = await GetApiHeaders(); 
    try {
      const responseGetData = await axios.post(getData, null, { headers }); 
      setGetMasterData(responseGetData.data.response.storeLocation)
    } catch (err) {
      console.log("getError", err);
    }
  };

  useEffect(() => {
    fetchGetMaster()
    if (data != null && data != undefined) {
      //  const totalItems = data.length;
      // setFilteredData(data);
      setTotalItems(data.length);
    }
  }, [data]);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const filteredDat = data.filter((item) => {
    console.log('data filetered',item);
    return (
      selectedLocation === "" || item.subSiteName === selectedLocation
   
    );
  });
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDat.slice(indexOfFirstItem, indexOfLastItem);

 
  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const handleCancel_new = () => {
    //no
    // alert("handleCancel");
    setShowModal(true);
    setShowPopup(true);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleCancel = () => {
    setShowModal(true);
    setShowPopup(false);
    setShowMsg(true);
    // window.location.reload();
  };
  const handleClose = () => {
    //yes
    // alert("handleCancel");
    // setPopupText('Custom popup text here');
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }
    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    fetchData();
  }, []);

  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
    window.location.reload();
  };
  const toggleDetails = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const fetchData = async () => {
    const fetchApi = getAllRecievedRequestsForUpdateActivity;
    const headers = await GetApiHeaders();

    try {
      setIsLoading(true);
      const response = await axios.get(fetchApi, { headers }); 
      setData(response.data.response.activitiesList);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleClear = () => {
    setSearchTerm("");
  };
 
 
  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <h5 className="Roles_text">Farm Operations</h5>
              
                <div>
                  <h5 className="mb-0 Sub_role_text"> Update Activity</h5>
                </div>
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between">
                      
                    </div>

                    <div className="mcrc_hrline ">
                      <div
                        className="card flex-grow-1 "
                        style={{
                          border: "1px solid #0000001A",
                          boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div className="card-body " style={{ padding: "10px" }}>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{}}
                          >
                            <div className="d-flex align-items-center">
                              <label className="mr-2">Show </label> &nbsp;&nbsp;
                              <select
                              // value={itemsPerPage} onChange={handleItemsPerPageChange}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                {/* Add more options as needed */}
                              </select>
                              &nbsp;&nbsp;
                              <label> entries</label>
                            </div>
                            <div className="d-flex align-items-center">
                              <div style={{ position: "relative" }}>  
                               <select  className="selector border border-info mt-2"  id="dropdown" type="dropdown"
                          onChange={(e) => setSelectedLocation(e.target.value)}
                          value={selectedLocation}
                        >
                          <option>select Sub site</option>
                          {getMasterData != undefined &&
                            getMasterData.map((items, itemsIndex) => {
                              return (
                                <option key={items.code} value={items.name}>
                                  {items.name}
                                </option>
                              );
                            })}
                        </select>
                                {/* <input
                                  type="text"
                                  placeholder="🔍 Type to Search..."
                                  value={searchTerm}
                                  onChange={(e) => handleFilter(e.target.value)}
                                  style={{
                                    height: "36px",
                                    width: "160px",
                                    paddingRight: "30px",
                                  }}
                                  className="mr-2"
                                />
                                {searchTerm && (
                                  <button
                                    className="btn btn-link"
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "10px",
                                      transform: "translateY(-50%)",
                                      zIndex: 1,
                                    }}
                                    onClick={() => handleFilter("")}
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                )} */}
                              </div>
                              &nbsp;
                            
                            </div>
                          </div>
                          <div></div>
                        </div>
                        <div>
                          <div className="card data_tableCard mr-2 ml-1">
                            <div className="card-body ">
                              <Table className="table table-bordered Content_data_table table-striped ">
                                {/* <ref={tableRef}/> */}
                                <Thead>
                                  <Tr>
                                    <Th className="text-center">S.No</Th>
                                    <Th className="text-center">Request Id</Th>
                                    <Th className="text-center">
                                      Requester Name
                                    </Th>
                                    <Th className="text-center">
                                      Type Of Operation
                                    </Th>
                                    <Th className="text-center">
                                      Submitted Date
                                    </Th>
                                    {/* <Th className='text-center'>Required Date </Th> */}
                                    {/* <Th className='text-center'>Crop Function</Th> */}

                                    <Th className="text-center">Status</Th>
                                    <Th className="text-center">Action</Th>
                                  </Tr>
                                </Thead>
                                {currentItems.length === 0 ? (
                                  <Tr>
                                    <Td colSpan={8} className="text-center">
                                      Data is not available
                                    </Td>
                                  </Tr>
                                ) : (
                                  currentItems.map((item, index) => {
                                    var newDateFormat = moment(
                                      item.requiredDate
                                    ).format("DD-MMM-YYYY ");
                                    var SubmitedDate = moment( item.submittedDate ).format("DD-MMM-YYYY hh:mm:ss A ");
                                    return (
                                      <Tbody>
                                        <Tr key={index}>
                                          <Td className="text-left">
                                          {(currentPage - 1) * itemsPerPage + index + 1}
                                          </Td>
                                          <Td className="text-left">
                                            {item.serverRequestId}
                                          </Td>
                                          <Td className="text-left">
                                            {item.requesterName}
                                          </Td>
                                          <Td className="text-left">
                                            {item.typeOfOperationName}
                                          </Td>
                                          <Td className="text-left">
                                            {SubmitedDate}
                                          </Td>
                                          {/* <Td className='text-left'>{item.plotNoLocationName}</Td> */}
                                          {/* <Td className='text-center'>{newDateFormat} </Td> */}
                                          {/* <Td className='text-left'>{item.cropFunctionName}</Td>                                   */}
                                          <Td >
                                            {item.status === "InProgress"?(<span style={{color:'orange',  fontWeight: 600}}>InProgress</span>)
                                            :item.status === "Approved" ?(<span style={{color:'#008000',  fontWeight: 600}}>Approved</span>):item.status}
                                          </Td>
                                          <Td
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              <FontAwesomeIcon
                                                icon={faEye}
                                                className="plus_icon mr-1 text-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Details"
                                                onClick={() =>
                                                  toggleDetails(index)
                                                }
                                              />
                                              {/* <img src={ViewIcon} onClick={toggleDetails} alt='viewIcon'style={{height:10 ,cursor:"pointer", color:"#007AFF"}}  /> */}
                                            </div>
                                          </Td>
                                        </Tr>
                                        {selectedRowIndex === index && (
                                          <Tr>
                                            <Td
                                              style={{ padding: 24 }}
                                              colSpan="9"
                                            >
                                              <div className="request-card">
                                              <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                              <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Main Site
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.mainSiteName}</span>
                                               </span>
                                              <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Sub Site
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.subSiteName}</span>
                                               </span>
                                              <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Plot No/Location
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plotNoLocationName}</span>
                                               </span> 
                                                </div>

                                                <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Plot Acreage
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plotAcreageName}</span>
                                               </span> 
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Plot Area
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.plantedArea}</span>
                                               </span> 
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Crop In Field
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.actualCropName}</span>
                                               </span> 
                                                  
                                                </div>
                                                <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Function
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.functionName}</span>
                                               </span> 
                                                <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Sub Function
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.subFunctionName}</span>
                                               </span> 
                                                {/* <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Crop Function
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.cropFunctionName}</span>
                                               </span>   */}
                                               <span className=" mt-3"style={{width:'33.4%',  }}>
                                                  <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                  Required Date
                                                  </span> 
                                                   <span  >  :&nbsp; 
                                                   </span>  
                                                 <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{newDateFormat}</span>
                                               </span> 
                                                </div>
                                                <div style={{ fontSize: 14,  width: "100%", }} className="ProductNameForRandM d-flex" >
                                                
                                                  
                                                  {item.requesterRemarks !==
                                                  "" ? (
                                                    <span className=" mt-3"style={{width:'33.4%',  }}>
                                                    <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                    Requester Remarks
                                                    </span> 
                                                     <span  >  :&nbsp; 
                                                     </span>  
                                                   <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.requesterRemarks}</span>
                                                 </span> ) : (
                                                    ""
                                                  )}
                                                  {item.acceptorRemarks !==
                                                  "" ? (
                                                    <span className=" mt-3"style={{width:'33.4%',  }}>
                                                    <span style={{  width:'45%',display:'inline-block', fontWeight:600}} > 
                                                    Acceptor Remarks
                                                    </span> 
                                                     <span  >  :&nbsp; 
                                                     </span>  
                                                   <span style={{  width:'55%',display:'inline-flex',whiteSpace:"break-spaces" }}>{item.acceptorRemarks}</span>
                                                 </span> ) : (
                                                    ""
                                                  )}
                                                </div>
                                                
                                                <div>
                                                  {
                                                    <div
                                                      className="mt-4 d-flex"
                                                      style={{ gap: "8rem" }}
                                                    >
                                                      <div className="w-50">
                                                        <span className="fw-bold">
                                                          {item.fertilizers
                                                            .length > 0
                                                            ? "Fertilizers :"
                                                            : ""}
                                                        </span>

                                                        {item.fertilizers
                                                          .length > 0 && (
                                                          <div className="d-flex justify-content-between mt-3">
                                                            <div className="d-flex flex-column">
                                                              <span className="custome-text-md fw-bold ">
                                                                Name
                                                              </span>
                                                              {item.fertilizers.map(
                                                                (
                                                                  fertilizer,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="custome-text-md font-weight-300 mt-2"
                                                                  >
                                                                    {fertilizer.name ||
                                                                      "No Name"}
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>

                                                            <div className="d-flex flex-column">
                                                              <span className="custome-text-md fw-bold ">
                                                                Dosage
                                                              </span>
                                                              {item.fertilizers.map(
                                                                (
                                                                  fertilizer,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="custome-text-md font-weight-300 mt-2"
                                                                  >
                                                                    {
                                                                      fertilizer.dosage
                                                                    }
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>

                                                      <div className="w-50">
                                                        <span className="fw-bold">
                                                          {item.chemicals
                                                            .length > 0
                                                            ? "Chemicals :"
                                                            : ""}
                                                        </span>

                                                        {item.chemicals.length >
                                                          0 && (
                                                          <div className="d-flex justify-content-between mt-3">
                                                            <div className="d-flex flex-column">
                                                              <span className="custome-text-md fw-bold ">
                                                                Name
                                                              </span>
                                                              {item.chemicals.map(
                                                                (
                                                                  chemical,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="custome-text-md font-weight-300 mt-2"
                                                                  >
                                                                    {chemical.name ||
                                                                      "No Name"}
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>

                                                            <div className="d-flex flex-column">
                                                              <span className="custome-text-md fw-bold ">
                                                                Dosage
                                                              </span>
                                                              {item.chemicals.map(
                                                                (
                                                                  chemical,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    className="custome-text-md font-weight-300 mt-2"
                                                                  >
                                                                    {
                                                                      chemical.dosage
                                                                    }
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  }
                                                </div>
                                              </div>
                                            </Td>
                                          </Tr>
                                        )}
                                      </Tbody>
                                    );
                                  })
                                )}

                                {isLoading && (
                                  <Loader
                                    loading={isLoading}
                                    message={"Fetching Data. Please Wait..!"}
                                    loderimage={loaderImage}
                                  />
                                )}
                              </Table>
                              <div
                                className="d-flex justify-content-between mb-10"
                                style={{ borderRadius: 5, padding: 15 }}
                              >
                                <div>
                                Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} 
                         &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                          of {totalItems} entries
                                </div>
                                <div>
                                  <div className="div-button text-center">
                                    <button
                                      className="borderleft-radius-5"
                                      // className='ul-pagination'
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      Previous
                                    </button>

                                    <button
                                      className="ul-pagination page-item"
                                      style={{
                                        backgroundColor:
                                          currentPage == 1
                                            ? "#0056b3"
                                            : "white",
                                        color:
                                          currentPage == 1 ? "white" : "black",
                                      }}
                                      key={1}
                                      onClick={() => handlePageClick(1)}
                                      disabled={currentPage === 1}
                                    >
                                      {1}
                                    </button>
                                    {pageNumbers.map((number) => (
                                      <button
                                        className="ul-pagination page-item"
                                        style={{
                                          backgroundColor:
                                            currentPage == number
                                              ? "#0056b3"
                                              : "white",
                                          color:
                                            currentPage == number
                                              ? "white"
                                              : "black",
                                        }}
                                        key={number}
                                        onClick={() => handlePageClick(number)}
                                        disabled={currentPage === number}
                                      >
                                        {number}
                                      </button>
                                    ))}
                                    {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                      currentPage !==
                                        Math.ceil(totalItems / itemsPerPage) -
                                          1 &&
                                      currentPage !==
                                        Math.ceil(
                                          totalItems / itemsPerPage
                                        ) && (
                                        <button
                                          className="ul-pagination page-item"
                                          style={{
                                            backgroundColor: "white",
                                            color: "black",
                                          }}
                                        >
                                          ...
                                        </button>
                                      )}
                                    {Math.ceil(totalItems / itemsPerPage) >
                                      1 && (
                                      <button
                                        className="ul-pagination page-item"
                                        style={{
                                          backgroundColor:
                                            currentPage ==
                                            Math.ceil(totalItems / itemsPerPage)
                                              ? "#0056b3"
                                              : "white",
                                          color:
                                            currentPage ==
                                            Math.ceil(totalItems / itemsPerPage)
                                              ? "white"
                                              : "black",
                                        }}
                                        key={Math.ceil(
                                          totalItems / itemsPerPage
                                        )}
                                        onClick={() =>
                                          handlePageClick(
                                            Math.ceil(totalItems / itemsPerPage)
                                          )
                                        }
                                        disabled={
                                          currentPage ===
                                          Math.ceil(totalItems / itemsPerPage)
                                        }
                                      >
                                        {Math.ceil(totalItems / itemsPerPage)}
                                      </button>
                                    )}
                                    <button
                                      className="borderright-radius-5"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={indexOfLastItem >= totalItems}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <Modal
                              className="modalBlur"
                              show={showPopup}
                              onHide={handleModalClose}
                              centered
                              backdrop="static"
                              keyboard={false}
                              size="ml"
                            >
                              <Modal.Header className="border-0 d-flex justify-content-center">
                                <Modal.Title>
                                  {" "}
                                  <span className="modal_title">
                                    Role Master
                                  </span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                                <div className="d-flex justify-content-center mb-3 font-weight-600">
                                  {showMsg ? (
                                    <p style={{ color: "green" }}>
                                      Are You Sure, Do You want close this
                                      Window ?
                                    </p>
                                  ) : null}
                                </div>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    variant="primary"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleClose}
                                  >
                                    Yes
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    variant="danger"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </Button>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>

                          <div>
                            <Modal
                              show={showResponsePopup}
                              centered
                              backdrop="static"
                              keyboard={false}
                              size="ml"
                            >
                              <Modal.Header className="border-0 d-flex justify-content-center">
                                <Modal.Title>
                                  {" "}
                                  <span className="modal_title">
                                    Role Master
                                  </span>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                                <div className="d-flex justify-content-center mb-3 font-weight-600">
                                  {showMsg ? (
                                    <p style={{ color: "green" }}>
                                      {submitResponse}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    variant="primary"
                                    className="d-flex justify-content-center cancel_button"
                                    onClick={handleresponse}
                                  >
                                    Okay
                                  </Button>
                                  &nbsp;&nbsp;
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading && (
                    <div>
                      <p>Fetching data. Please wait...</p>
                      {/* <Loader type="Puff" color="#00BFFF" height={50} width={50} /> */}
                      <Loader
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default UpdateActivity;
