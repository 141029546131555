import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../../Pages/SideMenu";
import Header from "../../Pages/Header";
import Footer from "../../Pages/Footer";
import Logout from "../../Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllRecievedReturns } from "../../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../Utilities/Loader";
import { GetApiHeaders } from "../../Utilities/NetworkUtilities";
import { submitRecievedReturns } from "../../Utilities/URLCONSTANTS";
import moment from "moment";
import { USER_PROFILE } from "../../Utils";

const ReturnOrderHistory = (category, index) => {
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResponsePopup, setShowResponsePopup] = useState(false);

  const [orderData, setOrderData] = useState([]);
  const [remark, setRemark] = useState();
  const [showModal, setShowModal] = useState(false);
  // const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [productReturnedRecivedQuantity, setProductReturnedRecivedQuantity] =
    useState();
  const [productCategories, setProductCategories] = useState([]);
  const [submittedDate, setSubmittedDate] = useState();
  const [serverRecordID, setServerRecordID] = useState();
  const [requesterName, setRequesterName] = useState();
  const [requestId, setRequestId] = useState();
  const [totalOrderValue, setTotalOrderValue] = useState();
  const [productRequiredLocation, setProductRequiredLocation] = useState();
  const [acceptorID, setAcceptorID] = useState();
  const [acceptorName, setAcceptorName] = useState();
  const [remarks, setRemarks] = useState();
  const [errRemark, setErrRemark] = useState();

  const [getLogin, setGetLogin] = useState([]);

  const [requestSelfOthers, setRequestSelfOthers] = useState();
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [employeeMobileNumber, setEmployeeMobileNumber] = useState();
  const [requesterId, setRequesterId] = useState();
  const [employeeUserID, setEmployeeUserID] = useState();
  const [showMsg, setShowMsg] = useState("false");

  const [returnDate, setReturnDate] = useState();

  const [issuedQuantity, setIssuedQuantity] = useState(0);
  const [returnerName, setReturnerName] = useState();
  const [returnerId, setReturnerId] = useState();
  const [returnerMobileNumber, setReturnerMobileNumber] = useState();
  const [productReturnedDate, setProductReturnedDate] = useState();
  const [productReturnedLocation, setProductReturnedLocation] = useState();
  const [requestStatus, setRequestStatus] = useState();
  const [sendToGatePass, setSendToGatePass] = useState();
  const [errorDate, setErrorDate] = useState();
  const [requestOrder, setRequestOrder] = useState([
    {
      employeeName: "",
      productRequiredDate: "",
      requesterId: -1,
      totalOrderValue: 0,
      requestSelfOthers: "",
      submittedDate: "",
      serverRecordID: -1,
      productCategories: [
        {
          categoryName: "",
          categoryId: 1,
          products: [
            {
              productRequiredQuantity: "",
              productId: -1,
              productUnitPrice: "",
              serverRecordIdChild: -1,
              productName: "",
              productBatchNumber: "",
              issuedQuantity: "",
            },
          ],
        },
      ],
    },
  ]);

  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowWarningPopup(false);
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  const fetchData = async () => {
    const apiUrl = getAllRecievedReturns;

    try {
      // const headers = await GetApiHeaders();
      setIsLoading(true);
      const response = await axios.post(apiUrl, null, {
        // headers,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          userId: 1,
          mobileNumber: 9704164746,
          // "deviceType":"Web"
        },
      });

      setIsLoading(false);
      const receivedRequests = response.data.response.receivedReturns;
      // setProductCategories(receivedRequests[0].productCategories);
      console.log("CATEGORIES_!", JSON.stringify(productCategories));

      setOrderData(receivedRequests);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
      paging: true,
      lengthChange: false,
    });
    fetchData();

    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetLogin(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);

  useEffect(() => {
    console.log("orderData", orderData);
  }, [orderData]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalOpen = (item, index) => {
    setShowModal(true);
    setSelectedItem(item);
    setSelectedItemIndex(index);
  };
  const handleClose = () => {
    setShowModal(false);
    setShowPopup(false);
  };
  const handleRemark = (e) => {
    setRemark(e.target.value);
    if (e.target.value) {
      setErrRemark("");
    }
  };
  const handleAccept = async (item, index) => {
    const urlApi = submitRecievedReturns;
    var mainList = orderData;
    console.warn(mainList);

    console.log("Check after map: ", JSON.stringify(orderData));

    //  console.log(list)
    const postData = {
      productCategories: orderData[index].productCategories,
      serverRecordID: orderData[index].serverRecordID,
      requestId: orderData[index].requestId,
      returnerName: orderData[index].returnerName,
      returnerId: orderData[index].returnerId,
      returnerMobileNumber: parseInt(orderData[index].returnerMobileNumber),
      productReturnedDate: orderData[index].productReturnedDate,
      totalOrderValue: orderData[index].totalOrderValue,
      productReturnedLocation: orderData[index].productReturnedLocation,
      acceptorName: getLogin.firstName + " " + getLogin.lastName,
      acceptorID: getLogin.userId,
      remarks: "",
      requestStatus: "Accept",
      sendToGatePass: orderData[index].sendToGatePass,
      returnDate: returnDate,
      submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
    };
    console.log("FINAL:JSON:::", postData);
    // const headers = {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    //   userId: "Emp12345",
    //   mobileNumber: "9699719190",
    //   // "deviceType": "Web"
    // };
    const headers = await GetApiHeaders();
    try {
      if (returnDate == "") {
        setErrorDate("please select Date");
      }
      setIsLoading(true);
      const response = await fetch(urlApi, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(postData),
      });

      const responseData = await response.json();
      console.log("response:", responseData);

      if (responseData.statusCode == 200) {
        // alert("aproved")
        setShowResponsePopup(true);
        setsubmitResponse(responseData.message);
        // window.location.reload();
        // alert(responseData.message)
      }
      if (responseData.statusCode == 500) {
        setShowResponsePopup(true);
        setsubmitResponse(responseData.message);
      }
      if (responseData.statusCode == 201) {
        setShowResponsePopup(true);
        setsubmitResponse(responseData.message);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleProductChange = (index, pcIndex, productIndex, field, value) => {
    const updatedProductData = [...orderData];

    var productRequiredQuantity =
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].productRequiredQuantity;

    var alreadyIssuedQuantity =
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].issuedQuantity;

    if (
      Number.parseInt(value) <=
      Number.parseInt(productRequiredQuantity) -
        Number.parseInt(alreadyIssuedQuantity)
    ) {
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].productReturnedRecivedQuantity = Number.parseInt(value);
    } else if (value.length === 0) {
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].productReturnedRecivedQuantity = 0;
    } else {
      updatedProductData[index].productCategories[pcIndex].products[
        productIndex
      ].productReturnedRecivedQuantity = 0;

      // alert("alert_quantity_exceed");
    }

    setOrderData(updatedProductData);
    // console.log("AJAY",Number.parseInt(productRequiredQuantity) - Number.parseInt(alreadyIssuedQuantity))
    console.log("AJAY", index);

    console.log("===========>", index, "<====>", JSON.stringify(orderData));
  };

  const handleSubmit = async (selectedItem, selectedItemIndex) => {
    var catLst = productCategories;
    for (let pcIndex = 0; pcIndex < catLst.length; pcIndex++) {
      const categoryObj = productCategories[pcIndex];

      for (
        let productIndex = 0;
        productIndex < categoryObj.products.length;
        productIndex++
      ) {
        const productObj = categoryObj.products[productIndex];
        productObj.productReturnedRecivedQuantity = 0;
      }
    }
    setProductCategories(catLst);
    console.log("updatedProducts:", catLst);

    const rejectApi = submitRecievedReturns;
    //  const headers = {
    //  "Accept": "application/json",
    //  "Content-Type": "application/json",
    //   userId: "1",
    //   mobileNumber: "9704164746",
    //  };
    const headers = await GetApiHeaders();
    const postDt = {
      productCategories: orderData[selectedItemIndex].productCategories,
      serverRecordID: orderData[selectedItemIndex].serverRecordID,
      requestId: orderData[selectedItemIndex].requestId,
      returnerName: orderData[selectedItemIndex].returnerName,
      returnerId: orderData[selectedItemIndex].returnerId,
      returnerMobileNumber: parseInt(
        orderData[selectedItemIndex].returnerMobileNumber
      ),
      productReturnedDate: orderData[selectedItemIndex].productReturnedDate,
      totalOrderValue: orderData[selectedItemIndex].totalOrderValue,
      productReturnedLocation:
        orderData[selectedItemIndex].productReturnedLocation,
      acceptorName: getLogin.firstName + " " + getLogin.lastName,
      acceptorID: getLogin.userId,
      remarks: remark,
      requestStatus: "Reject",
      sendToGatePass: orderData[selectedItemIndex].sendToGatePass,
      returnDate: returnDate,
      submittedDate: moment(new Date()).format("YYYY-MM-DD HH:MM:SS.SSS"),
    };
    console.log("FINAL:JSON reject:::", postDt);
    // setOrderData(postDt)
    try {
      if (remark) {
        setIsLoading(true);
        const response = await fetch(rejectApi, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(postDt),
        });
        const responseDt = await response.json();
        console.log("reject:", responseDt);
        if (responseDt.statusCode == 200) {
          // alert("aproved")
          setShowModal(false);
          setShowResponsePopup(true);
          setsubmitResponse(responseDt.message);
          setIsLoading(false);
        }
        if (responseDt.statusCode == 500) {
          setShowModal(false);
          setShowResponsePopup(true);
          setsubmitResponse(responseDt.message);
          setIsLoading(false);
        }
        if (responseDt.statusCode == 201) {
          // alert("aproved")
          setShowModal(false);
          setShowResponsePopup(true);
          setsubmitResponse(responseDt.message);
          setIsLoading(false);

          // alert(responseData.message)
        }
      } else {
        setErrRemark("Please Enter Remarks");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />

            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text ">Returns</h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline"></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.no</Th>
                                <Th className="text-center p-2">Returns</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {orderData.map((item, index) => {
                                var newDateFormat = moment(
                                  item.submittedDate
                                ).format("DD-MMM-YYYY hh:mm:ss A");
                                console.log("saai", index);
                                return (
                                  <Tr key={index}>
                                    <Td className="pl-1">{index + 1}</Td>
                                    <Td>
                                      <Accordion
                                        defaultActiveKey="0"
                                        className="acc mt-3 mr-1"
                                      >
                                        <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                            <div
                                              key={index}
                                              style={{
                                                fontSize: 14,
                                                width: "100%",
                                              }}
                                              className="ProductName d-flex"
                                            >
                                              <span
                                                className=" "
                                                style={{ width: "30%" }}
                                              >
                                                <b className="">
                                                  Return Date &nbsp;:
                                                </b>
                                                <span>
                                                  {" "}
                                                  &nbsp;{newDateFormat}{" "}
                                                </span>
                                              </span>
                                              <span
                                                className=" "
                                                style={{ width: "20%" }}
                                              >
                                                <b className="">
                                                  {" "}
                                                  Return ID &nbsp;:{" "}
                                                </b>
                                                <span>
                                                  {" "}
                                                  &nbsp;{
                                                    item.requestId
                                                  }{" "}
                                                </span>
                                              </span>
                                              <span
                                                className=""
                                                style={{ width: "40%" }}
                                              >
                                                {" "}
                                                <b className="">
                                                  {" "}
                                                  Returner Name &nbsp;:{" "}
                                                </b>
                                                <span>
                                                  {" "}
                                                  &nbsp;{item.returnerName}
                                                </span>
                                              </span>
                                              <span
                                                className="  "
                                                style={{ width: "20%" }}
                                              >
                                                {" "}
                                                <b> Status &nbsp;:</b>
                                                <span>
                                                  &nbsp;&nbsp;
                                                  {item.requestStatusCode ===
                                                  2 ? (
                                                    <span
                                                      style={{
                                                        color: "#00CB0E",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Accepted
                                                    </span>
                                                  ) : item.requestStatusCode ===
                                                    3 ? (
                                                    <span
                                                      style={{
                                                        color: "#D50101",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Rejected
                                                    </span>
                                                  ) : item.requestStatusCode ===
                                                    0 ? (
                                                    <span
                                                      style={{
                                                        color: "Orange",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Pending
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        color: "orange",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      Partially Accept
                                                    </span>
                                                  )}
                                                </span>{" "}
                                                &nbsp; &nbsp;
                                              </span>
                                            </div>
                                          </Accordion.Header>

                                          <Accordion.Body>
                                            {item.productCategories.map(
                                              (category, pcIndex) => {
                                                return (
                                                  <>
                                                    <div
                                                      key={pcIndex}
                                                      className="mt-3"
                                                    >
                                                      <span></span>
                                                      <p
                                                        style={{ fontSize: 16 }}
                                                      >
                                                        <b
                                                          style={{
                                                            fontSize: 18,
                                                            color: "orange",
                                                          }}
                                                        >
                                                          Category
                                                        </b>{" "}
                                                        <span
                                                          style={{
                                                            marginLeft: 48,
                                                          }}
                                                        >
                                                          :{" "}
                                                        </span>{" "}
                                                        &nbsp;
                                                        {category.categoryName}
                                                      </p>

                                                      {category.products.map(
                                                        (
                                                          product,
                                                          productIndex
                                                        ) => {
                                                          return (
                                                            <>
                                                          <div  style={{  fontSize: 14,  width: "100%",  }}  className="ProductNameForRandM d-flex"   >
                                                              <span  style={{width:'33.4%' }}>
                                                              <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                              Product Name
                                                              </span> 
                                                                <span >  :&nbsp; &nbsp;
                                                                </span>  
                                                              <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>{product.productName}</span>
                                                            </span>
                                                            <span  style={{width:'33.4%' }}>
                                                              <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                              Returned  Quantity 
                                                              </span> 
                                                                <span >  :&nbsp; &nbsp;
                                                                </span>  
                                                              <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>{product.productRequiredQuantity}</span>
                                                            </span>
                                                            <span  style={{width:'33.4%' }}>
                                                              <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                              Issued  Quantity 
                                                              </span> 
                                                                <span >  :&nbsp; &nbsp;
                                                                </span>  
                                                              <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>{product.issuedQuantity}</span>
                                                            </span> 
                                                           </div>

                                                           <div  style={{  fontSize: 14,  width: "100%",  }}  className="ProductNameForRandM d-flex mt-3"   >
                                                           <span  style={{width:'33.4%' }}>
                                                              <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                              Unit price 
                                                              </span> 
                                                                <span >  :&nbsp; &nbsp;
                                                                </span>  
                                                              <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>{product.productUnitPrice}</span>
                                                            </span> 
                                                           
                                                          </div>
                                                              
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                              <div  style={{  fontSize: 14,  width: "100%",  }}  className="ProductNameForRandM d-flex mt-3"   >
                                                {
                                                   item.requestorRemarks !== ""?(
                                                    <span  style={{width:'33.4%' }}>
                                                      <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                        Requestor Remarks 
                                                         </span> 
                                                        <span >  :&nbsp; &nbsp;</span>  
                                                        <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>{ item.requestorRemarks}</span>
                                                    </span> 
                                                   ):""
                                                }
                                                {
                                                  item.acceptorRemarks !== "" ? (
                                                    <span  style={{width:'33.4%' }}>
                                                       <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                        Acceptor Remarks 
                                                      </span> 
                                                       <span >  :&nbsp; &nbsp;</span>  
                                                      <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>{ item.acceptorRemarks}</span>
                                                     </span> 
                                                  ) :""
                                                }   
                                                 </div>
                                            <div style={{  fontSize: 14,  width: "100%",  }} className=" d-flex ProductNameForRandM justify-content-between  mr-4">
                                              <span  style={{width:'33.4%' }}>
                                                <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                  Total Order Value
                                                     </span> 
                                                     <span >  :&nbsp; &nbsp;
                                                     </span>  
                                                  <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>{ item.totalOrderValue}</span>
                                                </span> 
                                                <span  style={{width:'33.4%' }}>
                                                              <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                              Return Date 
                                                              </span> 
                                                                <span >  :&nbsp; &nbsp;
                                                                </span>  
                                                              <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>
                                                              <input type="date"  onChange={( e ) => setReturnDate(  e.target.value ) }  required />
                                                                 </span>
                                                            </span>      
                                                            
                                                            <span  style={{width:'33.4%' }}>
                                                              <span style={{  width:'36%',display:'inline-block',fontWeight:600}} > 
                                                              Returned  Location 
                                                              </span> 
                                                                <span >  :&nbsp; &nbsp;
                                                                </span>  
                                                              <span style={{  width:'64%',display:'inline-flex',whiteSpace:"break-spaces" }}>{ item.productReturnedLocation}</span>
                                                            </span>             
                                            </div>
                                            <div>
                                            <span
                                                className="d-flex justify-content-end mt-4"
                                                // style={{ marginLeft: 538 }}
                                              >
                                                <button
                                                  onClick={() =>
                                                    handleAccept(item, index)
                                                  }
                                                  className="btns"
                                                >
                                                  Accept
                                                </button>
                                                <span>
                                                  <button
                                                    className="btnsReject  ml-2"
                                                    onClick={() =>
                                                      handleModalOpen(
                                                        item,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Reject
                                                  </button>
                                                </span>
                                              </span>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                              />
                            )}
                          </Table>
                        </div>

                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="md"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> </Modal.Title>
                              {/* <button
                                variant="link"
                                className="close btn btn-danger btn-sm close_btn"
                                onClick={handleClose}
                              >
                                X
                              </button> */}
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleClose}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                <span className="modal_title">Remarks</span>
                              </div>
                              <div>
                                <div className="col-md-6 ">
                                  <div class="mb-3">
                                    <label
                                      for="InputText"
                                      class="form-label text-sm font-weight-600"
                                    >
                                      Remarks
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <textarea
                                      class="form-control"
                                      id="textAreaExample1"
                                      rows="2"
                                      value={remark}
                                      onChange={handleRemark}
                                    ></textarea>
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {errRemark}
                                  </span>
                                </div>

                                <br />
                              </div>

                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>

                                <Button
                                   className="btns"
                                  onClick={() => handleSubmit(selectedItem, selectedItemIndex)}>
                                  Submit
                                </Button>

                                <div style={{ width: "20px" }}></div>

                                <Button className="btnsReject" variant="danger" onClick={handleClose}>
                                  Cancel
                                </Button>
                              </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className="btnn"
                                  onClick={() =>
                                    handleSubmit(
                                      selectedItem,
                                      selectedItemIndex
                                    )
                                  }
                                >
                                  Submit
                                </Button>
                                <div style={{ width: "20px" }}></div>
                                <Button
                                  variant="danger"
                                  className="btnn"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                        <div>
                          <Modal
                            show={showResponsePopup}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="ml"
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title>
                                {" "}
                                <span className="modal_title">Return</span>
                              </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={closeWarning}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body className="pl-md-5 pr-md-5 pt-0">
                              <div className="d-flex justify-content-center mb-3 font-weight-600">
                                {showMsg ? (
                                  <p style={{ color: "green" }}>
                                    {submitResponse}
                                  </p>
                                ) : null}
                              </div>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="d-flex justify-content-center cancel_button"
                                  onClick={handleresponse}
                                >
                                  Okay
                                </Button>
                                &nbsp;&nbsp;
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default ReturnOrderHistory;
