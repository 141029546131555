import React, { useState, useEffect } from 'react'
import { coreEquipmentConstant } from '../../Utilities/corePermit.constant';

function CorePermitM2Equipment(props) {

    const [equipment, setEquipment] = useState(props.data)
    const [isChecked, setIsChecked] = useState(false);



    const handleCheckboxChange = (event) => {        
        setIsChecked(event.target.checked);
        var object = { ...equipment }; // Make a copy of energyData
        object.answer = event.target.checked ? "true" : "";
        if (event.target.checked) {
            var equipmentList = equipment.equipmentList;
            const updatedEquipmentList = equipmentList.map(equip => ({ ...equip, checked: false }));
            object.equipmentList = updatedEquipmentList;
        }
        setEquipment(object);
    };


    useEffect(() => {
        setEquipment(props.data)
    }, [])

    useEffect(() => {
        props.onDataChange(equipment)
    }, [equipment])

    const handleCheckBox = (id) => {
        const updatedEquipmentList = equipment.equipmentList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
        setEquipment((prev) => ({ ...prev, equipmentList: updatedEquipmentList }))
    }

    const handleInput = (id, list, value) => {
        const updatedArmsBodyList = equipment.equipmentList.map(opItem => {
            if (id == opItem.id) {
                return { ...opItem, 'inputValue': value }
            } else {
                return opItem
            }
        })
        setEquipment((prev) => ({ ...prev, equipmentList: updatedArmsBodyList }))
    }

    return (<div>
        <div class="form-group row">
            <label  class="col-9 col-form-label">List the hazards of the area, the work and the equipment for the specific task.</label>
            <div class="col-3">
                <div class="form-check form-check-inline">
                  
                     <input class="form-check-input mt-0"
                            type="checkbox" id="inlineCheckbox2"
                            disabled={props.approvalStatus}
                            onClick={handleCheckboxChange} checked={equipment.answer != "" ?
                            equipment.answer == "true" ? true : false : false} value={equipment.answer != "" ?
                            equipment.answer == "true" ? true : false : false} />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">N/A</label>
                </div>
            </div>
        </div>
        {/* <div class="col-12" style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}> */}


            {/* {equipment.equipmentList.map(item => (<>
                            {
                                item.inputAvaiable ? <div class="form-group row mt-2">
                                <div>
                                    <input value={item.inputValue}
                                     onChange={(e)=>{
                                        handleInput(item.id, '', e.target.value)
                                    }} class="form-control col-12" id="inputPassword" placeholder="" />
                                </div>
                            </div> :  <div class="form-group row col-6">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input  mt-1"  checked={item.checked === true} 
                        onChange={()=>{handleCheckBox(item.id)}}
                         type="checkbox" id={`coreEquipmentList${item.id}`} value="option1" />
                        <label class="form-check-label  mt-0" for={`coreEquipmentList${item.id}`}>{item.cbTitle}</label>
                    </div>
            </div>}</>))} */}


            {
                equipment.equipmentList.map(item => (
                    <>
                        <div class="form-group row" key={item.id}>
                            <div class="" style={{ display: "flex", flexDirection: "row" }}>
                                <div class="col-12 form-check form-check-inline d-flex align-items-center ">
                                    <input class="form-check-input  mt-0"
                                        // checked={item.checked === true}
                                        checked={item.checked}
                                        readOnly={props.approvalStatus}
                                        onChange={() => { handleCheckBox(item.id, 'equipmentList') }}
                                        type="checkbox" 
                                        id={`coreEquipmentList${item.id}`}
                                        value="option1"
                                        disabled={equipment.answer == 'true'}
                                        />
                                       <label class="form-check-label  mt-0"
                                        for={`coreEquipmentList${item.id}`}>{item.cbTitle}</label>
                                    {
                                        item.inputAvaiable &&
                                        <div class="col-8 mt-2">
                                            
                                                <input type='text' value={item.inputValue}
                                                        readOnly={props.approvalStatus}
                                                    onChange={(e) => {
                                                        console.log(e, e.target.value)
                                                        handleInput(item.id, '', e.target.value)
                                                    }} class="col-12 form-control" id="inputPassword" 
                                                    placeholder="" 
                                                    disabled={equipment.answer == 'true'}
                                                    />
                                        
                                        </div>}

                                </div>
                            </div>
                        </div>
                        {/* {
                            item.inputAvaiable &&
                            <div class="form-group row mt-0">
                                <div>
                                    <input type='text' value={item.inputValue}
                                        onChange={(e) => {
                                            console.log(e, e.target.value)
                                            handleInput(item.id, '', e.target.value)
                                        }} class="col- form-control" id="inputPassword" placeholder="" />
                                </div>
                            </div>} */}


                    </>
                ))
            }



        {/* </div> */}





        {/* <div class="form-group row">
            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                    <label class="form-check-label  mt-0" for="inlineCheckbox1">Noise</label>
                </div>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">Pinch Points</label>
                </div>
            </div>
        </div> */}
        {/* <div class="form-group row">
            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                    <label class="form-check-label  mt-0" for="inlineCheckbox1">Thermal burn</label>
                </div>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">Sharp edges</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                    <label class="form-check-label  mt-0" for="inlineCheckbox1">Flash fire</label>
                </div>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">Heat stress</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                    <label class="form-check-label  mt-0" for="inlineCheckbox1">Pressure extreme</label>
                </div>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">Flying debris</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                    <label class="form-check-label  mt-0" for="inlineCheckbox1">Fails</label>
                </div>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">Inert atmosphere</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input  mt-1" type="checkbox" id="inlineCheckbox1" value="option1" />
                    <label class="form-check-label  mt-0" for="inlineCheckbox1">Electrical/high voltage</label>
                </div>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">Asbestos</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">Radiation</label>
                </div>
                <div class="col-6 form-check form-check-inline">
                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                    <label class="form-check-label mt-0" for="inlineCheckbox2">Dust type</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div class="form-group col-6">
                    <div>
                        <input class="form-control col-12" id="inputPassword" />
                    </div>
                </div>
                <div class="form-group col-6">
                    <div>
                        <input class="form-control col-12" id="inputPassword" />
                    </div>
                </div>
            </div>
        </div> */}
    </div>
    )
}

export default CorePermitM2Equipment