export const corePermitInitialsConst = {
  "initialsOne":"",
  "initialsTwo":"",
  "initialsThree":"",
  "dateInputOne":"",
  "dateInputTwo":"",
  "dateInputThree":"",
  "dateInputFour":"",
  "dateInputFive":"",
  "dateInputSix":"",
  "permitsOne" :"",
  "permitsTwo":""  
}


export const EmergencyConst ={

  "phoneNumber": "",

  "assemblyLocation": ""

}
export const PartOneExavation = {
    list: [
      {
        cbTitle: "Work Performed By",
        value: "",
        extravalue: "",
      },
      {
        cbTitle: "Underground Reference Drawing Available:Y/N(contact name if Y)",
        value: "",
        extravalue: "",
      },
      {
        cbTitle: "reason For Excavation",
        value: "",
        extravalue: "",
      },
      {
        cbTitle:
          "Location of Excavation:(e.g.coordinates,column numbers,detailed description)",
        value: "",
        extravalue: "",
      },
      {
        cbTitle: "Start date",
        value: "",
        extravalue: "",
      },
      {
        cbTitle: "Permit Expiration Date",
        value: "",
        extravalue: "",
      },
      {
        cbTitle: "Competent Person Name",
        value: "",
        extravalue: "",
      },
      {
        cbTitle: "Date",
        value: "",
        extravalue: "",
      },
      {
        cbTitle: "Phone",
        value: "",
        extravalue: "",
      },
      {
        cbTitle: "Number of Personnel entering Excavation",
        value: "",
        extravalue: "",
      },
    ],
  };
  export const PartTwoExavation = {
    basic: [
      {
        cbTitle:
          "Have all sub-surface obstacles been identified?(if No is checked,a contingency plan shall be prepared.)",
        title1yes: "false",
        title1no: "false",
      },
    ],
    indicate: [
      {
        question:
          "Please indicate which two forms of evidence will be used to show confidence that all underground obstacles were found",
        list: [
          {
            cbTitle: "Underground Reference Drawing",
            checked: "",
            description: "",
          },
          {
            cbTitle: "Ground Penetrating Radar",
            checked: "",
            description: "",
          },
          {
            cbTitle: "Hydro Excavation",
            checked: "",
            description: "",
          },
          {
            cbTitle: "Utility Probong",
            checked: "",
            description: "",
          },
          {
            cbTitle: "Other",
            checked: "",
            description: "",
          },
        ],
      },
    ],
    explain: {
      cbTitle:
        "Explain how the two methods whrere used to locate utilities or subsurface obstacles",
      cbValue: "",
    },
    haveAll: {
      cbTitle:
        "have all utilities been identified and shared with the excavtion crew?(if NO is checked,the xcavtion Competent Person shall share the results of the sub-surface investigation with the excavtion crew)",
      cbYes: "",
      cbNo: "",
    },
    utilities: {
      title: "Utilities Present In Excavation Area",
      list: [
        {
          cbTitle: "Water",
          isChecked: "false",
        },
        {
          cbTitle: "Electrical",
          isChecked: "false",
        },
        {
          cbTitle: "Steam/Condensate",
          isChecked: "false",
        },
        {
          cbTitle: "Fire Lines",
          isChecked: "false",
        },
        {
          cbTitle: "Equipment",
          isChecked: "false",
        },
        {
          cbTitle: "Sewer",
          isChecked: "false",
        },
        {
          cbTitle: "Gas",
          isChecked: "false",
        },
        {
          cbTitle: "Process",
          isChecked: "false",
        },
        {
          cbTitle: "Telecommunications/Data",
          isChecked: "false",
        },
      ],
    },
    canThese: {
      cbTitle:
        "can these utilities/services be de-energied and isolated?(if NO is checked,a contingency plan shall be discussed.)",
      cbYes: "",
      cbNo: "",
    },
    doesThis: {
      cbTitle:
        "Does this area have any Environmental concerns by previous activities?",
      cbYes: "",
      cbNo: "",
      cbNA: "",
    },
    whatPrecautuions: {
      cbTitle:
        "What precautions/control measures are needed for the contaminated area?",
      cbValue: "",
    },
    isAir: {
      cbTitle:
        "Is air monitoring required for the excavation? (If Yes follow Cortevas PRCS Procedure)",
      cbYes: "",
      cbNo: "",
    },
    special: [
      {
        question:
          "Special Precautions and Safety Requirements (check those required on the excavation site)",
        list: [
          {
            cbTitle: "Electrical Observer",
            checked: false,
            description: "",
          },
          {
            cbTitle: "Grounding of tools",
            checked: false,
            description: "",
          },
          {
            cbTitle: "Testing for Fume or Gas",
            checked: false,
            description: "",
          },
          {
            cbTitle: "Standby Person",
            checked: false,
            description: "",
          },
          {
            cbTitle: "Barricades",
            checked: false,
            description: "",
          },
          {
            cbTitle: "Explosion Testing",
            checked: false,
            description: "",
          },
          {
            cbTitle: "Special Clothing",
            checked: false,
            description: "",
          },
          {
            cbTitle: "Competent Person (See Reverse Side)",
            checked: false,
            description: "",
          },
          {
            cbTitle: "",
            checked: false,
            description: "",
          },
          {
            cbTitle: "",
            checked: false,
            description: "",
          },
        ],
        hasProfessionalDeeper: {
          cbTitle:
            "Has a Professional Registered Engineer approved all protection systems for excavations deeper than 20ft(6m)?",
          cbYes: "",
          cbNo: "",
          cbNA: "",        
        },
        comments:{
          description: "",
        },
        hasProfessionalReduce: {
          cbTitle:
            "Has a Professional Registered Engineer approve all protection systems for excavations that will reduce support of existing foundations regardless of depth?",
          cbYes: "",
          cbNo: "",
          cbNA: "",
          
        },
        escavationApproval: {
          list: [
            {
              cbTitle1: "Excavation Competent Person",
              "PhoneNumber1:": "false",
            },
            {
              cbTitle2: "Corteva Representative",
              "PhoneNumber2:": "false",
            },
            {
              cbTitle3: "Excavation Permit Issuer",
              "PhoneNumber3:": "false",
            },
            {
              cbTitle4:
                "Environmental Resource:(only if there are environment concerns)",
              "PhoneNumber4:": "false",
            },
          ],
        },
      },
    ],
  };

  

  export const PartThreeExavation = {
    title: "Excavation Competent Person completes this section",
    soilClasification: {
      StableRock:
        "Is the excavation close to utilities, buildings, footings, pilings, or sources of vibration?",
      typeA: "",
      typeB: "",
      typeC: "",
    },
    isTheescavation: {
      cbTitle:
        "Is the excavation close to utilities, buildings, footings, pilings, or sources of vibration?",
      cbYes: "",
      cbNo: "",
      cbNA: "",
    },
    haveTheOwners: {
      cbTitle:
        "Have the owners of utility, service, and transmission piping been contacted?",
      cbYes: "",
      cbNo: "",
      cbNA: "",
    },
    hasDetection: {
      cbTitle:
        "Has detection equipment been used to locate all underground and/or above ground interferences?",
      cbYes: "",
      cbNo: "",
      cbNA: "",
    },
    hasACheck: {
      cbTitle: "Has a check been made for previously disturbed ground?",
      cbYes: "",
      cbNo: "",
      cbNA: "",
    },
    adeqacy: {
      cbTitle:
        "Has the adeqacy and availability of all equipment been checked,including personal protective quipment,shoring material,signs,barricades,and machinery?",
      cbYes: "",
      cbNo: "",
      cbNA: "",
    },
    checkBeen: {
      cbTitle:
        "Has a check been made for other obstructions(e.g.,footing concrete encasements)?",
      cbYes: "",
      cbNo: "",
      cbNA: "",
    },
    obstructions: {
      cbTitle: "If other obstructions have been found, list them.",
      description: "",
    },
    allowable: {
      cbTitle: "Allowable slope:",
      description: "",
    },
    describe: {
      cbTitle: "Describe in detail the risk control measure for the excavation.",
      description: "",
    },
    sizeofexcavation:{
      cbTitle:"Depth",
      depthval:"",
      cbTitle:"Width",
      widthval:"",
      cbTitle:"Height",
      heightval:"",
    },
    list: [
      {
        cbTitle:
          "Have ground conditions been checked, particularly after rainfall?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle:
          "Is air monitoring necessary to check for possible oxygen deficiency or gaseous conditions?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle: "Has the adequacy of shoring and/or sloping been checked?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle:
          "Do vehicular and machinery operation patterns need to be changed?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle: "Are water removal operations or equipment needed?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle:
          "Has the adequacy of portable trench boxes or trench shields been checked?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle:
          "Entrance and exit facilities (one every 25ft.(7.6m) for a trench 4ft.(1.2m) or deeper)",
        Stairway: "",
        Ladder: "",
        Ramp: "",
      },
    ],
    depthOfEscavation: [
      {
        cbTitle:
          "Have ground conditions been checked, particularly after rainfall?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle:
          "Is air monitoring necessary to check for possible oxygen deficiency or gaseous conditions?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle: "Has the adequacy of shoring and/or sloping been checked?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle:
          "Do vehicular and machinery operation patterns need to be changed?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle: "Are water removal operations or equipment needed?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle:
          "Has the adequacy of portable trench boxes or trench shields been checked?",
        cbYes: "",
        cbNo: "",
        cbNA: "",
      },
      {
        cbTitle:
          "Entrance and exit facilities (one every 25ft.(7.6m) for a trench 4ft.(1.2m) or deeper)",
        Stairway: "",
        Ladder: "",
        Ramp: "",
      },
    ],
    depthOfExcavation: {
      title:
        "If the depth of the excavation is 5 feet (1.5 meters) or more, check the applicable OSHA appendix below:",
      list: [
        {
          cbTitle: "B - Sloping and Benching",
          "cbChecked:": "false",
        },
        {
          cbTitle: "C - Timber Shoring for Trenches",
          "cbChecked:": "false",
        },
        {
          cbTitle: "D - Aluminum Hydraulic Shoring for Trenching",
          "cbChecked:": "false",
        },
        {
          cbTitle: "E - Alternatives to Timber Shoring",
          "cbChecked:": "false",
        },
        {
          cbTitle: "F - Selection of Protective Systems",
          "cbChecked:": "false",
        },
      ],
    },
    note: "Protective systems for excavations deeper than 20 feet (6 meters) deep must be designed by a registered professional engineer.",
    escavationtitle:
      "The excavation requires no protective systems for the following reasons (list)",
    escavationval: "",
    compitentPerson: 
    "Competent Person (Please print your name)",

    inputValue: "",

    date: "",
    status: "Success",
  };
  