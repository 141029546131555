
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import SideMenu from '../Pages/SideMenu';
import Footer from '../Pages/Footer';
import Header from '../Pages/Header';
import Logout from '../Pages/Logout';
import { strings } from '../Utilities/strings'
import DatePicker from "react-datepicker";

// for Sidebar And Navbar
import Search from '../images/search.png';
//for fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

//for modal
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import { FarmOperationAcceptHistory, FarmOperationReOpenActivity, FarmOperationRMHistoryAll, RmhistoryGet } from '../Utilities/URLCONSTANTS';
import Loader from '../Utilities/Loader';
import { GetApiHeaders } from '../Utilities/NetworkUtilities';
import * as XLSX from 'xlsx';
import { extractDateAsDDMMYYYY } from '../Utils';
import moment from 'moment';
import { CustomDatePickerInput } from '../../src/Utilities/utils'
//UnitMeasureMaster
const FarmOperationHistoryAll = ({ }) => {

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState();
  const [loaderImage, setLoaderImage] = useState(require('../images/cs_loader.gif'))
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState('Do you want to close Unit Measure Master');
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showMsg, setShowMsg] = useState('false');
  const [ActivityData, setActivityData] = useState([]);
  const [selectedMachine, setSelectedOption] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showtable, setShowtable] = useState(false);
  const [filteredDatalength, setFilteredDatalength] = useState('');


  /// For edit modal 
  const [item, setItem] = useState('');
  // const [remarks, setRemarks] = useState('');

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  // const totalItems = data.length;

  const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
  for (let i = Math.max(2, currentPage - 1); i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1); i++) {
    pageNumbers.push(i);
  }

  const handleModalOpen = () => {
    setSelectedOption('');
    setSelectedStartDate('');
    setSelectedEndDate('');
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const closeWarning = () => {
    setShowModal(false);
  }
  useEffect(() => {
    if (data != null && data != undefined) {
      setFilteredData(data);
      setTotalItems(data.length);
      setIsLoading(false);
    }
  }, [data]);


  function extractTimeFromDate(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const timeOnly = dateObject.toLocaleTimeString('en-US', { hour12: true });
    return timeOnly;
  }

  const handleFilter = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (data != null && data != undefined) {
      debugger;
      const filteredResults = data.filter(
        (item) => 
          item.serverRequestId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          moment(item.submittedDate).format("DD-MMM-YYYY hh:mm:ss A").toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.submittedDate.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.plotNoLocationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.cropFunctionName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.typeOfOperationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.subSiteName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.modifiedOn.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filteredResults);
      handlePageClick(1)
    }
    console.log("fdsafaGFH", filteredData);
  };


  // const handleFilter = (searchTerm) => {
  //   debugger;
  //   setSearchTerm(searchTerm);
  //   if (data.length > 0) {
  //     const filteredResults = data.filter((item) =>
  //       item.plotNoLocationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.cropFunctionName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.typeOfOperationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.submittedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       item.status.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //     setFilteredData(filteredResults);
  //     // const FilteredDatalength = filteredResults.length
  //     // setFilteredDatalength(FilteredDatalength);
  //   }
  // };


  // niharika   
  const handleSubmit = async (event) => {
    if (event != undefined) {
      event.preventDefault();
    }
    //debugger;
    if (startDate == null || endDate == null) {
      alert("please select date");
    } else {
      setShowtable(true)
      let formattedStartDate = startDate;
      let formattedEndDate = endDate;

      if (startDate) {
        formattedStartDate = startDate.toLocaleDateString('en-US', startDate).replace(/\//g, '-');
      }
      if (endDate) {
        formattedEndDate = endDate.toLocaleDateString('en-US', endDate).replace(/\//g, '-');
      }

      const reqObj = {
        "startDate": formattedStartDate,
        "endDate": formattedEndDate
      }
      const apiUrl = FarmOperationAcceptHistory;
      const headers = await GetApiHeaders();

      console.log("Dynamic Headers::", headers);
      setIsLoading(true);


      axios.post(apiUrl, reqObj, { headers }).then((response) => {
        setData(response.data.response.updateHistory);
        setIsLoading(false);
      })
    }

  }
  // reset
  function resetData(event) {
    //debugger;
    event.preventDefault();
    setStartDate(null);
    setEndDate(null);
  }

  // print 
  const handlePrint = () => {
    const printWindow = window.open();
    const printContent = document.querySelector('.print-table');
    // Check if the printContent is available
    if (printContent) {
      printWindow.document.write('<html><head>' +
        '<meta charset="utf-8" />' +
        '<link rel="icon" href="/favicon.ico" />' +
        '<meta name="viewport" content="width=device-width, initial-scale=1" />' +
        '<meta name="theme-color" content="#000000" />' +
        '<meta name="description" content="Web site created using create-react-app" />' +
        '<title>MCRC</title>' +
        '<link href="css/sb-admin-2.min.css" rel="stylesheet">' +
        '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css">' +
        '<script defer src="/static/js/bundle.js"></script>' +
        '<style>' +
        '@media print {' +
        '  table {' +
        '    border-collapse: collapse;' +
        '    width: 100%;' +
        '    margin-bottom: 10px;' +
        '  }' +
        '  th, td {' +
        '    border: 1px solid #ddd;' +
        '  }' +
        '}' +
        '</style>' +
        '</head><body>');

      printWindow.document.write(printContent.innerHTML);
      printWindow.document.write('</body></html>');

      // Delay the print function to ensure styles are applied
      printWindow.setTimeout(() => {
        printWindow.print();
        printWindow.close();
      });
    } else {
      // Handle the case where printContent is not found  
      alert("print is not available");
    }
  }

  ////modal open
  const handleViewClick = (item) => {
    setItem(item)
    setShowModal(true); ////modal open 
  };

  ///Inprogress 
  async function Inprogress(item) {
    // debugger;
    try {
      item.status = 'InProgress'
      const apiUrl = FarmOperationReOpenActivity;
      const headers = await GetApiHeaders();
      console.log("Dynamic Headers::", headers);
      setIsLoading(true);
      axios.post(apiUrl, item, { headers }).then((response) => {
        // console.log("NIHARIKA", JSON.stringify(response));
        setIsLoading(false);
      })
      setShowModal(false);
      handleSubmit();
    } catch (error) {
      console.error('Error reopening activity:', error);
    }
  }

  const exportToExcel = () => {
    const data = [
      ['S.no', 'Request ID', 'Location', 'Plot Number',   'Activity Type', 'Date', 'Time', 'Requester Remarks','Acceptor Remarks'], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.serverRequestId,
        item.subSiteName,
        item.plotNoLocationName,
        
        item.typeOfOperationName,
        extractDateAsDDMMYYYY(item.submittedDate),
        extractTimeFromDate(item.submittedDate),
        item.requesterRemarks,
        item.acceptorRemarks
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    XLSX.writeFile(wb, 'FormOperationHistory.xlsx');
  };
  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">

          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className='container-fluid'>
                <div className='card border-0' id="farmOperations">
                  <div className='card-body pt-0 pl-1'>
                    <h5 className='Roles_text mt-2'>Farm Operations</h5>
                    <h5 className='Roles_text mt-2'>History</h5>
                    <div className='mt-4'>
                      <div className='d-flex gap-1 status_card'>
                        <div className='card flex-grow-1' style={{
                          border: '1px solid #0000001A',
                          boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                        }}>
                          <div className='card-body' style={{ padding: "20px 20px" }}>
                            <form>
                              <div className='row'>
                                <div className='col-sm-4 col-12'>
                                  <div className="mb-3">
                                    <label htmlFor="startdate" className="form-label farm-labels">From Date<span style={{ color: "red" }}> *</span></label>
                                    <DatePicker className='form-control w-100 bg-white'
                                      placeholderText="Select Start Date"
                                      selected={startDate}
                                      onChange={(date) => { setStartDate(date) }}
                                      dateFormat="dd/MM/yyyy"
                                      customInput={<CustomDatePickerInput />} />
                                  </div>
                                </div>
                                <div className='col-sm-4 col-12'>
                                  <div className="mb-3">
                                    <label className="form-label  farm-labels" htmlFor="enddate">To Date<span style={{ color: "red" }}> *</span>  </label>
                                    <DatePicker className='form-control w-100'
                                      placeholderText="Select End Date"
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      dateFormat="dd/MM/yyyy"
                                      minDate={startDate}
                                      customInput={<CustomDatePickerInput />}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='mt-2 d-flex w-full justify-content-center gap-4'>
                                <button className="btn btn-primary" type='submit' onClick={handleSubmit}
                                  style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                                  Submit</button>
                                <button className="btn btn-danger" onClick={resetData}
                                  style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                                  Clear All</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    {showtable && (<>

                      <hr />
                      <div className='my-2 d-flex w-full justify-content-end gap-4'>

                        <button className="btn btn-primary"
                          style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                          onClick={handlePrint}
                        > Print
                        </button>

                      </div>
                      <div className='card p-3'>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <label className="mr-2">Show {' '}</label> &nbsp;&nbsp;
                            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              {/* Add more options as needed */}
                            </select>&nbsp;&nbsp;
                            <label>{' '} entries</label>
                          </div>
                          <div className="d-flex align-items-center">
                            {/* Search Input */}
                            <input
                              type="search"
                              placeholder="🔍 Type to Search..."
                              value={searchTerm}
                              onChange={(e) => handleFilter(e.target.value)}
                              style={{ height: "36px", width: "160px", borderRadius: "4px" }}
                              className="mr-2 border mb-2" />

                            <button className="mb-2 btn btn-secondary buttons-excel buttons-html5"
                              onClick={exportToExcel}>Download Excel
                            </button>
                          </div>
                        </div>
                        <div>
                          <div className='card data_tableCard mt-2'>
                            <div className='card-body'>
                              <div class="table-responsive print-table">
                                <Table className="table table-bordered table-bg farmoperationall-table" >
                                  {/* <ref={tableRef}/> */}
                                  <Thead>
                                    <Tr>
                                      <Th className='text-center'>S.No</Th>
                                      <Th className='text-left'>Request ID</Th>
                                      <Th className='text-left'>Location</Th>
                                      <Th className='text-left'>Plot Number</Th> 
                                      <Th className='text-left'>Activity Type</Th>
                                      <Th className='text-left'>Date / Time</Th>
                                      <Th className='text-left'>Acceptor Remarks</Th>
                                      <Th className='text-left'>Requester Remarks</Th>
                                      <Th className='text-left'>Status</Th>
                                      <Th className='text-left'>View</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>

                                    {currentItems && currentItems.length > 0 ? (


                                      currentItems.map((item, index) => {
                                        var submittedDate = moment(item.submittedDate).format("DD-MMM-YYYY hh:mm:ss A");


                                        return (
                                          <React.Fragment  >
                                            <Tr >
                                              <Td className='text-center' > {(currentPage - 1) * itemsPerPage + index + 1}</Td>
                                              <Td className='text-left'>{item.serverRequestId}</Td>
                                              <Td className='text-left'>{item.subSiteName}</Td>
                                              <Td className='text-left'>{item.plotNoLocationName}</Td> 
                                              <Td className='text-left'>{item.typeOfOperationName}</Td>
                                              <Td className='text-left'> {submittedDate} </Td>
                                              <Td className='text-left'>{item.acceptorRemarks}</Td>
                                              <Td className='text-left'>{item.requesterRemarks}</Td>
                                              <Td className='text-left' style={{ color: item.status === "InProgress" ? 'orange' : item.status === "Rejected" ? 'red' : 'green' }}>{item.status}</Td>
                                              <Td className='text-left'>
                                                <FontAwesomeIcon icon={faEye} id={"editdetails" + item.id}
                                                  onClick={() => handleViewClick(item)}
                                                  style={{
                                                    color: '#0d6efd', cursor: 'pointer'
                                                  }} />
                                              </Td>
                                            </Tr>


                                          </React.Fragment>
                                        )
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="10" className='text-center'>No Data Available</td>
                                      </tr>
                                    )}

                                    <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static"
                                      keyboard={false} size="lg">

                                      <Modal.Header className="border-0 d-flex justify-content-center">
                                        <Modal.Title> </Modal.Title>
                                        <Button variant="link" className="close btn btn-danger close_btn"
                                          onClick={() => setShowModal(false)}> X
                                        </Button>
                                      </Modal.Header>
                                      <Modal.Body className='pl-md-5 pr-md-5 pt-0'>
                                        <div className='d-flex justify-content-center mb-3 font-weight-600'>
                                          <span className='modal_title'>History</span>
                                        </div>

                                        <div className='row' style={{ rowGap: "20px" }}>

                                          {item.requesterName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">
                                                Requester Name</div>
                                              <div className='text-xs font-weight-normal'>: {item.requesterName}</div>
                                            </div>)}

                                          {item.requesterMobileNo && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Mobile No
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.requesterMobileNo}</div>
                                            </div>)}


                                          {item.serverRequestId && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Request Id
                                              </div>
                                              <div className='text-xs font-weight-normal' style={{ wordBreak: "break-all" }}>: {item.serverRequestId}</div>
                                            </div>)}

                                          {item.mainSiteName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Main site
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.mainSiteName}</div>
                                            </div>)}

                                          {item.subSiteName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Sub site
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.subSiteName}</div>
                                            </div>)}



                                          {item.subSiteName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Location
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.subSiteName}</div>
                                            </div>)}

                                          {item.plotNoLocationName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Plot Number
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.plotNoLocationName}</div>
                                            </div>)}

                                          {item.plotAcreageName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Plot Acreage
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.plotAcreageName}</div>
                                            </div>)}


                                          {item.plantedArea && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Planted Area
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.plantedArea}</div>
                                            </div>)}

                                          {item.actualCropName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Crop in Field
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.actualCropName}</div>
                                            </div>)}

                                          {item.typeOfOperationName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Activity type
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.typeOfOperationName}</div>
                                            </div>)}


                                          {item.cropFunctionName && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Crop Function
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {item.cropFunctionName}</div>
                                            </div>
                                          )}

                                          {item.submittedDate && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 
                                              farmoperation-textwidth">Date
                                              </div>
                                              <div className='text-xs font-weight-normal'>:
                                                {extractDateAsDDMMYYYY(item.submittedDate)}
                                              </div>
                                            </div>)}

                                          {item.submittedDate && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Time
                                              </div>
                                              <div className='text-xs font-weight-normal'>: {extractTimeFromDate(item.submittedDate)}</div>
                                            </div>)}

                                          {item.status && (
                                            <div className='col-lg-4 col-12 d-flex'>
                                              <div className="custome-text-md font-weight-600 farmoperation-textwidth">Status </div>
                                              <div style={{ color: item.status === "InProgress" ? 'orange' : item.status === "Rejected" ? 'red' : 'green' }}>: {item.status}</div>
                                            </div>
                                          )}
                                        </div>

                                        {item != undefined && item.fertilizers != undefined &&
                                          item.fertilizers.length > 0 &&
                                          item.chemicals != undefined && item.chemicals.length > 0 && (
                                            <div className='mt-4 d-sm-flex' style={{ gap: '6rem' }}>

                                              <div className='w-sm-50'>
                                                <span className='fw-bold'>Fertilizers :</span>
                                                <div className='d-flex justify-content-between align-items-center w-100 mt-2'>
                                                  <span className='custome-text-md fw-bold
                                                   text-decoration-underline w-75'>
                                                    Name
                                                  </span>
                                                  <span className='custome-text-md fw-bold text-decoration-underline w-25 text-center'>
                                                    Dosage
                                                  </span>
                                                </div>
                                                {item.fertilizers.map((fertilizer, index) => (
                                                  <div className='d-flex align-items-center w-100'>
                                                    <div className='custome-text-md font-weight-600 mt-3 w-75'>{fertilizer.name}</div>
                                                    <div className='custome-text-md font-weight-600 mt-3 w-25 text-center'>{fertilizer.uom.includes("Kgs") ? fertilizer.dosage + " " + "(gm/kg)" : fertilizer.dosage + " " + "(ml/ltr)"}</div>
                                                  </div>
                                                ))}
                                              </div>

                                              <div className='w-sm-50 mt-sm-0 mt-4'>
                                                <span className='fw-bold'>Chemicals :</span>
                                                <div className='d-flex justify-content-between align-items-center w-100 mt-2'>
                                                  <span className='custome-text-md fw-bold
                                                   text-decoration-underline w-75'>
                                                    Name
                                                  </span>
                                                  <span className='custome-text-md fw-bold text-decoration-underline w-25 text-center'>
                                                    Dosage
                                                  </span>
                                                </div>
                                                {item.fertilizers.map((chemical, index) => (
                                                  <div className='d-flex align-items-center w-100'>
                                                    <div className='custome-text-md font-weight-600 mt-3 w-75'>{chemical.name}</div>
                                                    <div className='custome-text-md font-weight-600 mt-3 w-25 text-center'>{chemical.uom.includes("Kgs") ? chemical.dosage + " " + "(gm/kg)" : chemical.dosage + " " + "(ml/ltr)"}</div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          )}

                                        {item != undefined && item.updatedWorks != undefined &&
                                          item.updatedWorks.length > 0 && (
                                            <div className='row mt-4' style={{ rowGap: "10px", maxHeight: "250px", overflow: "auto" }}>
                                              <span className='fw-bold'>Updated activity details :</span>
                                              {item.updatedWorks.map((updatedWork, index) =>
                                                <div className='col-12'>
                                                  <div className='card w-full p-2'
                                                    style={{ backgroundColor: "#FFF6E5" }}>

                                                    {updatedWork.updateDate && (
                                                      <div className="custome-text-md font-weight-600 d-flex 
                                              justify-content-between px-3">Date :
                                                        <span className='text-xs font-weight-normal'>
                                                          {updatedWork.updateDate}</span>
                                                      </div>)}

                                                    {updatedWork.manpowerRequired !== '' && (
                                                      <div className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">Manpower Usage :
                                                        <span className='text-xs font-weight-normal'>{updatedWork.manpowerRequired}
                                                        </span>
                                                      </div>
                                                    )}

                                                    {updatedWork.updatedRemarks && (<div className="px-3 custome-text-md font-weight-600 d-flex 
                                              justify-content-between mt-2">Remarks : <span className='text-xs font-weight-normal'> {updatedWork.updatedRemarks}</span>
                                                    </div>)}

                                                    {updatedWork != undefined && updatedWork.fertilizers
                                                      != undefined && updatedWork.fertilizers.length > 0 &&
                                                      updatedWork.chemicals != undefined &&
                                                      updatedWork.chemicals.length > 0 && (

                                                        <div className=''>
                                                          <div className='px-3 mt-2'>
                                                            <span className='fw-bold'>Fertilizers :</span>
                                                            <div className='d-flex justify-content-between mt-2'>
                                                              <div className='d-flex flex-column'>
                                                                <span className='custome-text-md fw-bold 
                                                      text-decoration-underline'>
                                                                  Name
                                                                </span>
                                                                {updatedWork.fertilizers.map((fertilizer, index) => (
                                                                  <span className='custome-text-md font-weight-600 mt-2'>
                                                                    {fertilizer.name}
                                                                  </span>
                                                                ))}
                                                              </div>
                                                              <div className='d-flex flex-column'>
                                                                <span className='custome-text-md fw-bold text-decoration-underline'>
                                                                  Dosage
                                                                </span>
                                                                {updatedWork.fertilizers.map((fertilizer, index) => (
                                                                  <span className='custome-text-md font-weight-600 mt-2'>
                                                                   {fertilizer.uom.includes("Kgs") ? fertilizer.dosage + " " + "(gm/kg)" : fertilizer.dosage + " " + "(ml/ltr)"}
                                                                  </span>))}
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className='px-3 mt-3'>
                                                            <span className='fw-bold'>Chemicals :</span>
                                                            <div className='d-flex justify-content-between mt-2'>
                                                              <div className='d-flex flex-column'>
                                                                <span className='custome-text-md fw-bold 
                                                      text-decoration-underline'>
                                                                  Name
                                                                </span>
                                                                {updatedWork.chemicals.map((chemical, index) => (
                                                                  <span className='custome-text-md font-weight-600 mt-2'>
                                                                    {chemical.name}</span>
                                                                ))}
                                                              </div>
                                                              <div className='d-flex flex-column'>
                                                                <span className='custome-text-md fw-bold text-decoration-underline'>
                                                                  Dosage
                                                                </span>
                                                                {updatedWork.chemicals.map((chemical, index) => (
                                                                  <span className='custome-text-md font-weight-600 mt-2'>
                                                                    {chemical.uom.includes("Kgs") ? chemical.dosage + " " + "(gm/kg)" : chemical.dosage + " " + "(ml/ltr)"}</span>
                                                                ))}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          )}

                                        <div className="mt-4 d-flex justify-content-center">
                                          {item.status === "Closed" && (
                                            <Button
                                              variant="primary"
                                              className="d-flex justify-content-center custom-width-btn"
                                              onClick={() => Inprogress(item)}>
                                              Reopen
                                            </Button>
                                          )}

                                        </div>
                                      </Modal.Body>
                                    </Modal>

                                  </Tbody>
                                  {isLoading && <Loader loading={isLoading} message={"Fetching Data. Please Wait..!"} loderimage={loaderImage} />}
                                </Table>
                              </div>

                              {/* Pagination */}
                              <div className="d-flex justify-content-between mb-10" style={{ borderRadius: 5, }}>
                                <div>Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} 
                         &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                          of {totalItems} entries
                                </div>

                                <div>
                                  <div className="div-button text-center" >
                                    <button
                                      className='borderleft-radius-5'
                                      onClick={() => handlePageClick(currentPage - 1)}
                                      disabled={currentPage === 1} >
                                      Previous
                                    </button>

                                    <button className='ul-pagination page-item'
                                      style={{
                                        backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                        color: (currentPage == 1) ? 'white' : 'black'
                                      }}
                                      key={1}
                                      onClick={() => handlePageClick(1)}
                                      disabled={currentPage === 1}
                                    >
                                      {1}

                                    </button>



                                    {pageNumbers.map((number) => (
                                      <button className='ul-pagination page-item'
                                        style={{
                                          backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                          color: (currentPage == number) ? 'white' : 'black'
                                        }}
                                        key={number}
                                        onClick={() => handlePageClick(number)}
                                        disabled={currentPage === number}
                                      >
                                        {number}
                                      </button>
                                    ))}


                                    {((Math.ceil(totalItems / itemsPerPage)) > 1 && currentPage !== (Math.ceil(totalItems / itemsPerPage) - 1) && currentPage !== (Math.ceil(totalItems / itemsPerPage))) && (
                                      <button className='ul-pagination page-item'
                                        style={{
                                          backgroundColor: 'white',
                                          color: 'black'
                                        }}
                                      >
                                        ...
                                      </button>
                                    )}
                                    
                                    {(Math.ceil(totalItems / itemsPerPage)) > 1 &&
                                      <button className='ul-pagination page-item'
                                        style={{
                                          backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                          color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                        }}
                                        key={(Math.ceil(totalItems / itemsPerPage))}
                                        onClick={() => handlePageClick((Math.ceil(totalItems / itemsPerPage)))}
                                        disabled={currentPage === (Math.ceil(totalItems / itemsPerPage))}
                                      >
                                        {(Math.ceil(totalItems / itemsPerPage))}

                                      </button>
                                    }



                                    <button className='borderright-radius-5'
                                      onClick={() => handlePageClick(currentPage + 1)}
                                      disabled={indexOfLastItem >= totalItems}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </>)}


                  </div>
                  {loading &&
                    <div>
                      <p>Fetching data. Please wait...</p>
                      {/* <Loader type="Puff" color="#00BFFF" height={50} width={50} /> */}
                      <Loader
                        height="80"
                        width="80"
                        radius="9"
                        color="green"
                        ariaLabel="loading"
                      />
                    </div>
                  }
                </div>
              </div>
            </div>

          </div>
          {/* ...End of Main Content...*/}



          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}

        </div>

      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body >
  )
}

export default FarmOperationHistoryAll
