import React, { useState, useEffect, useRef } from "react";
function Important(props) {
console.log("props",JSON.stringify(props))
    const handleImportantCheckBox = (index, val, listName, key) => {
        let list = props.data[listName]
        if (listName == 'cerificationsList') {
            list = props.data.cerifications.cerificationsList
        }
        const updateArr = list.map((i, j) => {
            if (j == index) {
                const obj = { ...i }
                obj[key] = val
                if (key == 'cbYes') {
                    obj['cbNA'] = 'false'
                } else {
                    obj['cbYes'] = 'false'
                }
                return obj
            } else {
                return i
            }
        })
        console.log(updateArr, 'up')
        if (listName == 'cerificationsList') {
            const obj = {
                ...props.data.cerifications
            }
            obj['cerificationsList'] = updateArr
            props.setImportant((prev) => ({ ...prev, ['cerifications']: obj }))
        } else {
            props.setImportant((prev) => ({ ...prev, [listName]: updateArr }))
        }
    }

    const handleBasicData = (key, val) => {
        props.setImportant((prev) => ({ ...prev, [key]: val }));
    };


    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                        {/* <div className="Corper-subtxt" style={{height:"116px"}}> */}
                        <div>
                            <p style={{ color: "#000000", fontWeight: "600" }}>
                                Review this checklist with crew completing crane 
                                operations.All answers must be answered Yes or N/A.
                                If no then take proper corective measures.
                            <span style={{ color: "red" }}> *</span> 
                            </p>
                        </div>
                        {
                            props.data.importantList.map((item, index) => (

                             
                                <div class="form-group" key={index}>

                                    <p>{item.cbTitle}</p>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div class="form-check m-2">
                                            <input class="form-check-input" checked={item.cbYes == 'true'} onClick={() => {
                                                handleImportantCheckBox(index, item.cbYes == 'true' ? 'false' : 'true', 'importantList', 'cbYes')
                                            }} type="checkbox" name="answer1" id={`importListIndex${index}`} value="option1" />
                                            <label class="form-check-label" for={`importListIndex${index}`}>YES</label>
                                        </div>
                                        <div class="form-check m-2">
                                            <input class="form-check-input" type="checkbox" checked={item.cbNA == 'true'} onClick={() => {
                                                handleImportantCheckBox(index, item.cbNA == 'true' ? 'false' : 'true', 'importantList', 'cbNA')
                                            }} name="answer2" id={`importListIndexNA${index}`} value="option2" />
                                            <label class="form-check-label" for={`importListIndexNA${index}`}>N/A</label>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        
                        <div>
                            {/* <p style={{ color: "#000000", fontWeight: "600" }}>IF YES: % of capacity</p> */}

                            <div class="form-group">
                                <label  style={{ color: "#000000", fontWeight: "600" }}>IF YES: % of capacity </label>
                                <div>
                                    {/* <input class="form-control col-12" id="inputPassword" placeholder="" /> */}
                                    <input
                                        className="form-control"
                                        id="cp"
                                        placeholder=""
                                        value={props.data?.capacity || ""}
                                        onChange={(e) => handleBasicData('capacity', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>


                        <div>
                            <p style={{ color: "#000000", fontWeight: "600" }}>Are certificates,qualifications and training in place prior to work beginning?(All Individuals)</p>
                        </div>
                        {
                            props.data.cerifications.cerificationsList.map((item, index) => (
                                <div class="form-group" key={index}>
                                    <p>{item.cbTitle}</p>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div class="form-check m-2">
                                            <input class="form-check-input" checked={item.cbYes == 'true'} onClick={() => {
                                                handleImportantCheckBox(index, item.cbYes == 'true' ? 'false' : 'true', 'cerificationsList', 'cbYes')
                                            }} type="checkbox" name="answer1" id={`importListIndexCER${index}`} value="option1" />
                                            <label class="form-check-label" for={`importListIndexCER${index}`}>YES</label>
                                        </div>
                                        <div class="form-check m-2">
                                            <input class="form-check-input" type="checkbox" checked={item.cbNA == 'true'} onClick={() => {
                                                handleImportantCheckBox(index, item.cbNA == 'true' ? 'false' : 'true', 'cerificationsList', 'cbNA')
                                            }} name="answer2" id={`importListIndexCERNA${index}`} value="option2" />
                                            <label class="form-check-label" for={`importListIndexCERNA${index}`}>N/A</label>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {/* <div class="form-group">
                            <p>National Commision certified Crane Opeation(NCCCO)or national equivalent</p>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                    <label class="form-check-label" for="option1">YES</label>
                                </div>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                    <label class="form-check-label" for="option2">N/A</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <p>Certified Signal Person</p>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                    <label class="form-check-label" for="option1">YES</label>
                                </div>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                    <label class="form-check-label" for="option2">N/A</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <p>Qualified Rigger(S)</p>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                    <label class="form-check-label" for="option1">YES</label>
                                </div>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                    <label class="form-check-label" for="option2">N/A</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <p>Lead Rigger Idenitified</p>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                    <label class="form-check-label" for="option1">YES</label>
                                </div>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                    <label class="form-check-label" for="option2">N/A</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <p>Will a Critical Lift take place? If yes, a critical lift meeting is required.</p>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                    <label class="form-check-label" for="option1">YES</label>
                                </div>
                                <div class="form-check m-2">
                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                    <label class="form-check-label" for="option2">N/A</label>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>

            </div>
        </div>

    )

};
export default Important;


