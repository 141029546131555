
import React, { useState, useEffect, useRef } from "react";

// function HotWorkInformation(props) {
//     const handleBasicData = (key, val) => {
//         props.setBasicDetails((prev) => ({ ...prev, [key]: val }));
//     };
function HotWorkInformation(props) {

    const handleGeneralInfo = (val, key, type, index = null) => {
        if (type == 1) {
            props.setHotWorkInformation(prev => ({ ...prev, [key]: val }))
        } else if (type == 2) {
            const newArr = props.data[key]
            newArr[index].value = val
            props.setHotWorkInformation(prev => ({ ...prev, [key]: newArr }))
        }
    }

    return (
              
            <div >
                <div className="col-md-6 col-12">
                    <div>
                        <div>
                            <div class="form-group">
                                <label>HOT WORK LOCATION <span  className='required'> *</span></label>
                                <div>
                                    <input class="form-control" value={props.data.hotWorkLocation} onChange={(e) => {
                                        handleGeneralInfo(e.target.value, 'hotWorkLocation', 1)
                                    }} />
                                </div>
                            </div>
                            <div class="form-group">
                                <label >HOT WORK Task</label>
                                <div>
                                    {/* <textarea class="form-control" rows={3}></textarea> */}
                                    <textarea class="form-control" rows={3} value={props.data.hotWorkTask}
                                        onChange={(e) => {
                                            handleGeneralInfo(e.target.value, 'hotWorkTask', 1)
                                        }} />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>EQUIPMENT</label>
                                <div>
                                    <input class="form-control" value={props.data.equipment} onChange={(e) => {
                                        handleGeneralInfo(e.target.value, 'equipment', 1)
                                    }} />
                                </div>
                            </div>
                            <div class="form-group">
                                <label >FUEL HAZARD(S)</label>
                                <div>
                                  
                                    {
                                        props.data.fuelHazard.map((i, index) => (
                                            <div class="form-check m-2">
                                                <input class="form-check-input" type="checkbox" checked={i.value} onClick={() => {
                                                    handleGeneralInfo(i.value == true ? false : true, 'fuelHazard', 2, index)
                                                }} name="answer2" id={`fuelHazardId_${index}`} value="option2" />
                                                <label class="form-check-label" for={`fuelHazardId_${index}`}>{i.cbTitle}</label>
                                            </div>
                                        ))
                                    }
                                    <label>TYPE OF HOT WORK</label>
                                    {
                                        props.data.typeOfHotWork.map((i, index) => (
                                            <div class="form-check m-2">
                                                <input class="form-check-input" type="checkbox" checked={i.value} onClick={() => {
                                                    handleGeneralInfo(i.value == true ? false : true, 'typeOfHotWork', 2, index)
                                                }} name="answer2" id={`typeOfHotWorkId_${index}`} value="option2" />
                                                <label class="form-check-label" for={`typeOfHotWorkId_${index}`}>{i.cbTitle}</label>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                           

                        </div>
                    </div>
                </div>
            </div>
        
    );
}

export default HotWorkInformation;
