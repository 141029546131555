import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import $ from 'jquery';

import { strings } from '../Utilities/strings';

import 'datatables.net';

import 'datatables.net-dt/css/jquery.dataTables.css';

import SideMenu from './SideMenu';

import Header from './Header';

import Footer from './Footer';

import Logout from './Logout';

//for fontawesome icons

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

//for data table

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

//for modal

import 'bootstrap/dist/css/bootstrap.min.css';

import { Button, Modal } from 'react-bootstrap';
import { getRackMaster , addRackMaster} from '../Utilities/URLCONSTANTS';

//Main Rack  Master

const RackMaster = ({ }) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [rackCode, setRackCode] = useState('');
    const [rackName, setRackName] = useState('');
    const [storeName, setStoreName] = useState('');
    const [storeLocation, setStoreLocation] = useState("");
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [contactMobileNumber, setContactMobileNumber] = useState("");
    const [status, setStatus] = useState("");
    const [sno, setSno] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [buttonText, setButtonText] = useState(strings.recordSubmitted);
    const [showPopup, setShowPopup] = useState(false);
    const [showWarningPopup, setShowWarningPopup] = useState(false);
    const [showMsg, setShowMsg] = useState('false');
    const [errorFirstName, setErrorFirstName] = useState('');
    const [errorLastName, setErrorLastName] = useState('');
    const [errorStoreLocation, setErrorStoreLocation] = useState('');
    const [errorUserID, setErrorUserID] = useState('');
    const [errorUserName, setErrorUserName] = useState('');
    const [errorMobileNumber, setErrorMobileNumber] = useState('');
    const [errorStatus, setErrorStatus] = useState('');
    const [errorRackCode, setErrorRackCode] = useState('');
    const [errorRackName, setErrorRackName] = useState('');
    const [errorStoreName, setErrorStoreName] = useState('');
    const [modalData, setModalData] = useState({
        id: '',
        firstName: '',
        lastName: '',
        rackCode: '',
        rackName: '',
        storeName: '',
        storeLocation: '',
        userId: '',
        name: '',
        contactPerson: '',
        contactMobileNumber: '',
        status: ''
    });
    const [data, setData] = useState([]);

    // ...more items

    const handleNameChange = (e) => {
        const input = e.target.value;
        if (input.length > 0) {
            setErrorFirstName('')
        }
        const result = input.replace(/[^a-z_\s]/gi, '');
        setFirstName(result);
    };

    const handleLastNameChange = (e) => {
        const input = e.target.value;
        if (input.length > 0) {
            setErrorLastName('')
        }
        const result = input.replace(/[^a-z_\s]/gi, '');
        setLastName(result);
    };

    const handleUserIdChange = (e) => {

        const userInput = e.target.value;
        if (userInput.length > 0) {
            setErrorUserID('')
        }

        if (!isNaN(userInput)) {

            setUserId(userInput);

        }

    };


    const handleUserNameChange = (e) => {

        const name = e.target.value;

        if (name.length > 0) {
            setErrorUserName('')
        }

        const result = name.replace(/[^a-z_\s]/gi, '');

        setName(result);

    };

    const handleRackNameChange = (e) => {
        const input = e.target.value;
        if (input.length > 0) {
            setErrorRackName('')
        }
        const result = input.replace(/[^a-z_\s]/gi, '');
        setRackName(result);
    };


    const handleRackCodeChange = (e) => {
        const rackCode = e.target.value;
        if (rackCode.length > 0) {
            setErrorRackCode('')
        }

        if (!isNaN(rackCode)) {

            setRackCode(rackCode);

        }

    };

    const handleStoreNameChange = (e) => {
        if (storeName.length > 0) {
            setErrorStoreName('')
        }

        setStoreName(e.target.value);

    }


    const handleStoreLocationChange = (e) => {

        if (storeLocation.length > 0) {
            setErrorStoreLocation('')
        }

        setStoreLocation(e.target.value);

    };

    const handleMobileNbr = (e) => {

        const input = e.target.value;

        let result = input.replace(/[^0-9]/g, ""); // Remove non-digit characters

        if (contactMobileNumber.length > 0) {
            setErrorMobileNumber('')
        }


        if (result.length > 10) {

            // Trim the number to 10 digits

            result = result.substr(0, 10);

        }


        if (/^[6-9]/.test(result)) {

            // Check if the number starts with 6, 7, 8, or 9

           setContactMobileNumber(result);

            // setError(""); // Clear any previous error message

        } else {

            setContactMobileNumber(""); // Clear the mobile number if it doesn't meet the criteria


        }

    };


    const handleStatus = (e) => {

        if (status.length > 0) {
            setErrorStatus('')
        }
        setStatus(e.target.value);

    }

    const handleSubmit = () => {

        // console.log("ITEM", item.name, item.name !== '')

        // if (item.name !== '' && item.userID !== '', item.userName !== '' &&

        //     item.storeLocation !== '' && item.mobileNumber !== '') 



        if (modalData.firstName === '') {

            setErrorFirstName('Please Enter a Name');

        }


        if (modalData.rackCode === '') {

            setErrorRackCode('Please Enter Rack Code');
        }

        if (modalData.rackName === '') {

            setErrorRackName('Please Enter Rack Name');
        }


        if (modalData.storeName === '') {

            setErrorStoreName('Please Enter StoreName');

        }
        if (modalData.storeLocation === '') {

            setErrorStoreLocation('Please Enter StoreLocation');

        }

        if (modalData.userId === '') {

            setErrorUserID('Please Enter UserId');

        }

        if (modalData.name === '') {

            setErrorUserName('Please Enter Name');

        }
        if (modalData.contactMobileNumber === '') {

            setErrorMobileNumber('Please Enter start with 6,7,8,9  MobileNumbers');

        }
        if (modalData.status === '') {

            setErrorStatus('Please Enter status');

        }

        if (firstName !== '' && lastName !== '' && rackCode !== '' && storeName !== '' && storeLocation !== '' && userId !== '' && name !== '' && contactMobileNumber !== '' && status !== '') {
            if (modalData.id == '') {
                alert("Submit")
                // Generate a new unique id for the new item

                const newId = String(Date.now());

                const newItem = {
                    id: newId,
                    firstName: firstName,
                    lastName: lastName,
                    rackCode: rackCode,
                    rackName: rackName,
                    storeName: storeName,
                    storeLocation: storeLocation,
                    userId: userId,
                    name: name,
                    contactMobileNumber: contactMobileNumber,
                    status: status

                };

                setIsSubmitted(true);
                // handleUpdate();
                setData(prevData => [...prevData, newItem]);
                handlePostData();
                setErrorFirstName('');
                setErrorLastName('');
                setErrorStoreLocation('');
                setErrorUserID('');
                setErrorUserName('');
                setErrorMobileNumber('');
                setErrorStatus('');
                setErrorRackCode('');
                setErrorStoreName('');

            } else {
                alert("")
                setIsSubmitted(false);
                handleUpdate();
            }
        }
    };

    const handleUpdate = () => {
        setData(prevData => {
            console.log("pre", JSON.stringify(prevData))
            const newData = prevData.map(item => {
                if (item.id == modalData.id) {
                    return {
                        ...item,
                        id: item.id,
                        firstName: firstName,
                        lastName: lastName,
                        rackCode: rackCode,
                        rackName: rackName,
                        storeName: storeName,
                        storeLocation: storeLocation,
                        name: name,
                        userId: userId,
                        contactPerson: contactPerson,
                        contactMobileNumber: contactMobileNumber,
                        status: modalData.status
                    };
                }

                return item;

            });

            return newData;

        });

        setIsUpdated(true)

    };




    //for data table

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = () => {
        const apiUrl = getRackMaster;
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "userId": "1",
            "mobileNumber": "9704164746",
        };
        axios.get(apiUrl, { headers })
            .then((response) => {
                console.log("response", response);
                setData(response.data.response.rackMaster)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    const handlePostData = () => {
        alert("Hi");
        const apiUrl = addRackMaster;//  API endpoint
        // Set your custom headers here
        const headers = {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "userId": "1",
          "mobileNumber": "9704164746", // 
        };
    
        // Prepare the data to be sent in the POST request
        const postData = {
         
        //   "Id": "",               
        //   "mainStoreID": "2",      
        //   "name": name,       
        //   "userID": "4",         
        //   "storeLocation": storeLocation,      
        //   "contactPerson": contactPerson,     
        //   "contactMobileNumber": contactMobileNumber,   
        //   "status": status,           // Status: 0 for Inactive, 1 for Active
        //   "remarks": remarks
        "contactMobileNumber":contactMobileNumber,
        "firstName": firstName ,
        "lastName": lastName,
        "rackCode": rackCode,
        "storeLocation": storeLocation,
        "name": name,
        "contactPerson": contactPerson,
        "storeName": storeName,
        "id": '',
        "userId": userId,
        "status": status

        };
    
        console.log("request with api call", apiUrl)
    
        console.log("request with api call", postData)
    
        // Make the POST request using Axios
        axios.post(apiUrl, postData, { headers })
          .then((response) => {
            // Handle the successful response here
            console.log('submit Response:', response);
    
          })
          .catch((error) => {
            // Handle errors here
            console.error('Error:', error);
          });
      };
    


    const tableRef = useRef(null);
    useEffect(() => {
        // Check if DataTable is already initialized
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            // Destroy the existing DataTable instance
            $(tableRef.current).DataTable().destroy();
        }

        // Initialize the DataTable

        $(tableRef.current).DataTable({

            ordering: false, // Disable sorting

        });

    }, []);

    ///modal popup

    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => {
        setButtonText('Submit');
        setIsSubmitted(false);
        setIsUpdated(false)
        setErrorFirstName('');
        setShowModal(true);
        setFirstName("");
        setRackCode("");
        setRackName("");
        setStoreName("");
        setStoreLocation("");
        setUserId("");
        setName("");
        setContactMobileNumber("");
        setStatus("");
        setModalData({
            id: '',
            firstName: '',
            lastName: '',
            rackCode: '',
            rackName: '',
            storeName: '',
            storeLocation: '',
            userId: '',
            name: '',
            contactMobileNumber: '',
            status: ''
        })

    };

    const handleClose = () => {

        setShowPopup(false);

        setShowModal(false);

        setShowWarningPopup(false);

    };

    const handleCancel = () => {

        setShowModal(true);

        setShowPopup(false);

        setShowWarningPopup(false);

        setShowMsg('')

    };

    const handleCancel_new = () => {

        setShowModal(true);

        setShowPopup(true);
    };

    const closeWarning = () => {

        setShowWarningPopup(true);

    }


    const handleModalClose = () => {

        setShowModal(false);

    };

    const updatedItems = (item) => {
        setModalData(item)
        setSno(item.id)
        setFirstName(item.firstName);
        setLastName(item.lastName);
        setRackCode(item.rackCode);
        setRackCode(item.rackName);
        setStoreName(item.storeName);
        setStoreLocation(item.storeLocation);
        setUserId(item.userId);
        setName(item.name);
        setContactMobileNumber(item.contactMobileNumber);
        setStatus(item.status);
        setShowModal(true);
        setButtonText('Update');
        setIsSubmitted(false);
        setIsUpdated(false);

    }

    return (

        <body id="page-top">

            {/*  <!-- Page Wrapper --> */}

            <div id="wrapper">

                {/*  <!-- Sidebar --> */}

                <SideMenu />

                {/*  <!-- End of Sidebar --> */}

                {/*  <!-- Content Wrapper --> */}

                <div id="content-wrapper" className="d-flex flex-column">

                    {/*  <!-- Main Content --> */}

                    <div id="content">

                        {/*  <!-- Topbar --> */}

                        <Header />

                        {/* ...Content Start... */}

                        <div>

                            <div className='container-fluid'>

                                <div className='card border-0'>

                                    <div className='card-body pt-0 pl-1'>

                                        <h5 className='Roles_text'>My Masters</h5>

                                        <div className='d-flex justify-content-between'>

                                            <div><h6 className='mb-0 Sub_role_text'>Rack Master</h6></div>

                                            <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="Add Rack Master">

                                                <Button variant="primary" size="sm" onClick={handleModalOpen}>

                                                    <FontAwesomeIcon icon={faPlus} className='plus_icon mr-1' />Add Rack Master

                                                </Button>

                                            </span>

                                        </div>

                                        <div className='mcrc_hrline'></div>

                                        <div>

                                            <div className='card data_tableCard'>

                                                <div className='card-body' style={{ overflowX: 'auto' }}>

                                                    <Table className="table table-bordered Content_data_table" >

                                                        <Thead>

                                                            <Tr>
                                                                <Th className='text-center'>S.no</Th>
                                                                <Th className='text-center'>First Name</Th>
                                                                <Th className='text-center'>Last Name</Th>
                                                                <Th className='text-center'>Rack Code</Th>
                                                                <Th className='text-center'>Rack Name</Th>
                                                                <Th className='text-center'>Store Name</Th>
                                                                <Th className='text-center'>Store Location</Th>
                                                                <Th className='text-center'>User ID</Th>
                                                                <Th className='text-center'>Name</Th>
                                                                <Th className='text-center'>Contact Person</Th>
                                                                <Th className='text-center'>Contact Mobile Number</Th>
                                                                <Th className='text-center'>Status</Th>
                                                                <Th className='text-center'>Actions</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {data.map((item, index) => (
                                                                <Tr key={index}>
                                                                    <Td className='text-left'>{index + 1}</Td>
                                                                    <Td className='text-center'>{item.firstName}</Td>
                                                                    <Td className='text-center'>{item.lastName}</Td>
                                                                    <Td className='text-center'>{item.rackName}</Td>
                                                                    <Td className='text-center'>{item.rackCode}</Td>
                                                                    <Td className='text-center'>{item.storeName}</Td>
                                                                    <Td className='text-center'>{item.storeLocation}</Td>
                                                                    <Td className='text-center'>{item.userId}</Td>
                                                                    <Td className='text-center'>{item.name}</Td>
                                                                    <Td className='text-center'>{item.contactPerson}</Td>
                                                                    <Td className='text-right'>{item.contactMobileNumber
                                                                    }</Td>
                                                                    <Td className='text-center' >
                                                                        {item.status == "1" ? "Active" : "InActive"}
                                                                    </Td>
                                                                    <Td className='text-center'>
                                                                        <div
                                                                            onClick={() => updatedItems(item, index)}>
                                                                            <FontAwesomeIcon icon={faEdit} className="plus_icon mr-1" data-toggle="tooltip" data-placement="top" title="Edit Role" />
                                                                            {/* Add button content here */}
                                                                        </div>
                                                                    </Td>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>
                                                    </Table>
                                                </div>
                                                <div>

                                                    <Modal show={showModal} onHide={handleModalClose} centered backdrop="static" keyboard={false} size="lg">


                                                        <Modal.Header className="border-0 d-flex justify-content-center">

                                                            <Modal.Title> </Modal.Title>

                                                            <Button variant="link" className="close btn btn-danger btn-sm close_btn" onClick={closeWarning}>

                                                                X

                                                            </Button>

                                                        </Modal.Header>

                                                        <Modal.Body className='pl-md-5 pr-md-5 pt-0' >


                                                            <div className='d-flex justify-content-center mb-3 font-weight-600'>

                                                                <span className='modal_title'>Rack Master</span>

                                                            </div>

                                                            <div className='row'>

                                                               
                                                                <div className='col-md-6 col-12'>

                                                                    <label for="InputName" className="form-label text-sm font-weight-600"

                                                                    >Rack Code</label>


                                                                    <div className="input-group input-group w-100">


                                                                        <input type="text" className="form-control p-2" placeholder='Rack Code' value={rackCode} onChange={handleRackCodeChange} />

                                                                    </div>
                                                                    <span style={{ color: 'red' }}>{errorRackCode}</span>

                                                                </div>

                                                                <div className='col-md-6 col-12'>

                                                                    <label for="InputName" className="form-label text-sm font-weight-600"

                                                                    >Rack Name</label>


                                                                    <div className="input-group input-group w-100">


                                                                        <input type="text" className="form-control p-2" placeholder='Rack Name' value={rackName} onChange={handleRackNameChange} />

                                                                    </div>
                                                                    <span style={{ color: 'red' }}>{errorRackName}</span>

                                                                </div>
                                                                <div className='col-md-6 col-12'>

                                                                    <label for="InputName" className="form-label text-sm font-weight-600"

                                                                    >Store Name</label>


                                                                    <div className="input-group input-group w-100">


                                                                        <input type="text" className="form-control p-2" placeholder='Store Name' value={storeName} onChange={handleStoreNameChange} />

                                                                    </div>
                                                                    <span style={{ color: 'red' }}>{errorStoreName}</span>

                                                                </div>

                                                             
                                                                <div className='col-md-6 mx-auto'>
                                                                    <label for="Input" class="form-label text-sm font-weight-600">
                                                                        Status
                                                                    </label>

                                                                    <div className="input-group input-group w-100">
                                                                        <select
                                                                            className="form-select p-2"
                                                                            value={status}
                                                                            onChange={handleStatus}
                                                                        >
                                                                            <option value="" disabled selected>Select Status</option>
                                                                            <option value="1">Active</option>
                                                                            <option value="0">In Active</option>

                                                                        </select>
                                                                    </div>

                                                                    <span style={{ color: 'red' }}>{errorStatus}</span>
                                                                </div>
                                                            </div>&nbsp;

                                                            <div className='row'>

                                                                {isSubmitted ? (

                                                                    <p style={{ color: 'green' }} >{strings.recordSubmitted}.</p>

                                                                ) : null}

                                                                {isUpdated ? (

                                                                    <p style={{ color: 'green' }} >{strings.updatedSuccessfully}</p>

                                                                ) : null}

                                                            </div>

                                                            <div className="d-flex justify-content-center col-md-12">

                                                                <Button variant="primary"

                                                                    onClick={() => handleSubmit({

                                                                        // rackName, rackCode, storeName, 

                                                                        // status

                                                                    })}>

                                                                    {buttonText}

                                                                </Button>
                                                                <div style={{ width: '20px' }}></div>

                                                                <Button onClick={handleCancel_new} className="btn btn-danger">Cancel</Button>


                                                            </div>

                                                        </Modal.Body>

                                                    </Modal>

                                                </div>

                                                <div>

                                                    <Modal show={showPopup} onHide={handleModalClose} centered backdrop="static" keyboard={false} size="ml">

                                                        <Modal.Header className="border-0 d-flex justify-content-center">

                                                            <Modal.Title>  <span className='modal_title'>Rock Master</span></Modal.Title>

                                                        </Modal.Header>

                                                        <Modal.Body className='pl-md-5 pr-md-5 pt-0' >

                                                            <div className='d-flex justify-content-center mb-3 font-weight-600'>

                                                                {showMsg ? (

                                                                    <p style={{ color: 'green' }} >Sure, Do You want close window.</p>

                                                                ) : null}

                                                            </div>

                                                            <div className="d-flex justify-content-center">

                                                                <Button variant="primary" className="d-flex justify-content-center cancel_button" onClick={handleClose} >

                                                                    Yes

                                                                </Button>&nbsp;&nbsp;

                                                                <Button variant="primary" className="d-flex justify-content-center cancel_button" onClick={handleCancel} >

                                                                    No

                                                                </Button>

                                                            </div>

                                                        </Modal.Body>

                                                    </Modal>

                                                </div>

                                                <div>

                                                    <Modal show={showWarningPopup} onHide={handleModalClose} centered backdrop="static" keyboard={false} size="ml">

                                                        <Modal.Header className="border-0 d-flex justify-content-center">

                                                            <Modal.Title>  <span className='modal_title'>Rack Master</span></Modal.Title>

                                                        </Modal.Header>


                                                        <Modal.Body className='pl-md-5 pr-md-5 pt-0' >

                                                            <div className='d-flex justify-content-center mb-3 font-weight-600'>

                                                                {showMsg ? (

                                                                    <p style={{ color: 'green' }} >Sure,

                                                                        Do You want close window.</p>

                                                                ) : null}

                                                            </div>
                                                            <div className="d-flex justify-content-center">

                                                                <Button variant="primary" className="d-flex justify-content-center cancel_button" onClick={handleClose} >

                                                                    Yes

                                                                </Button>&nbsp;&nbsp;

                                                                <Button variant="primary" className="d-flex justify-content-center cancel_button" onClick={handleCancel} >

                                                                    No

                                                                </Button>

                                                            </div>

                                                        </Modal.Body>

                                                    </Modal>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>



                    </div>

                    {/* ...End of Main Content...*/}

                    {/* ...Footer...*/}

                    <Footer />

                    {/* ... End of Footer...  */}

                </div>
            </div>

            {/*  <!-- End of Page Wrapper -->


                    <!-- Scroll to Top Button--> */}

            <a className="scroll-to-top rounded" href="#page-top">

                <i className="fas fa-angle-up"></i>

            </a>

            {/*  <!-- Logout Modal--> */}

            <Logout />

        </body>

    )

}


export default RackMaster;