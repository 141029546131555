import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logout from "./Logout";
import { getAllRecievedRequests } from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import { Card } from 'react-bootstrap';


const HotWorkPermit2 = () => {


    return (
        <body id="page-top" style={{ paddingTop: "10px", margin: "10px" }} >
            <div id="wrapper">
                {/* 
                <SideMenu /> */}

                <div id="content-wrapper" className="d-flex flex-column" style={{ backgroundColor: "white" }}>

                    <div id="content">
                        {/* 
                        <Header /> */}

                        <div>
                            <div className="container-fluid">
                                {/* <div className="card border-0">
                                    <div className="card-body pt-0 pl-1"> */}

                                <div className="d-flex justify-content-between"></div>
                                {/* <div className="mcrc_hrline"></div> */}

                                <div>
                                    {/*  <div className="card data_tableCard">
                                                <div className="card-body" style={{ padding: '10px' }}> */}
                                    <form className="CorePermitM2-form">
<h2>giiii</h2>

                                        <Accordion>

                                            <Accordion.Item eventKey="0">

                                                <Accordion.Header id="ppl" className="section-1"><span class="accordion-arrow"></span>GENERAL INFORMATION</Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">EMERGENCY</label>
                                                                <div>
                                                                    <input class="form-control" />

                                                                    <input class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label for="inputPassword" class="col-9 col-form-label">AUTHORIZED PROCEDURE#/NAME:</label>
                                                                <div class="col-3">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input mt-3" type="checkbox" value="option2" />
                                                                        <label class="form-check-label mt-2" for="inlineCheckbox2">N/A</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <div>
                                                                    <input class="form-control col-12" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="Corper-subtxt">
                                                                <p className="card-subheading">PERMIT EXPERATION</p>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">Start time</label>
                                                                <div>
                                                                    <input class="form-control" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">End time   </label>
                                                                <div>
                                                                    <input class="form-control" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">PERMIT ISSUER NAME</label>
                                                                <div>
                                                                    <input class="form-control" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">PERMIT ISSUER SIGNATURE</label>
                                                                <div>
                                                                    <input class="form-control" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">PERMIT RECEIVER NAME</label>
                                                                <div>
                                                                    <input class="form-control" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">SIGNATURE</label>
                                                                <div>
                                                                    <input class="form-control" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">COMPANY</label>
                                                                <div>
                                                                    <input class="form-control" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">PHONE/RADIO CH.#</label>
                                                                <div>
                                                                    <input class="form-control" id="inputPassword" placeholder="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>


                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">

                                                <Accordion.Header id="ppl" className="mt-2"><span class="accordion-arrow"></span>HOT WORK INFORMATION</Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">HOT WORK LOCATION</label>
                                                                <div>
                                                                    <input class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">HOT WORK Task</label>
                                                                <div>
                                                                    <textarea class="form-control" rows={3}></textarea>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">EQUIPMENT</label>
                                                                <div>
                                                                    <input class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">FUEL HAZARD(S)</label>
                                                                <div>
                                                                    <div class="form-check m-2">
                                                                        <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                        <label class="form-check-label" for="option1">Combustible Liquid</label>
                                                                    </div>
                                                                    <div class="form-check m-2">
                                                                        <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                        <label class="form-check-label" for="option2">Flammable Liquid/Gas</label>
                                                                    </div>
                                                                    <div class="form-check m-2">
                                                                        <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                        <label class="form-check-label" for="option1">Combustble Dust</label>
                                                                    </div>
                                                                    <div class="form-check m-2">
                                                                        <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                        <label class="form-check-label" for="option2">Ordinary Combustibles</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="inputPassword">TYPE OF HOT WORK</label>
                                                                <div>
                                                                    <div class="form-check m-2">
                                                                        <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                        <label class="form-check-label" for="option1">Low Energy</label>
                                                                    </div>
                                                                    <div class="form-check m-2">
                                                                        <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                        <label class="form-check-label" for="option2">High Energy</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Accordion.Body>


                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2" >
                                                <Accordion.Header id="env" className="mt-2" style={{ backgroundColor: "blue" }}><span class="accordion-arrow"></span>REQUIRED PRECAUTIONS</Accordion.Header>
                                                <Accordion.Body>
                                                    <div>

                                                        <div>
                                                            <p style={{ color: "#000000", fontWeight: "600" }}>Determine Yes or NA.STOP IF"NO"</p>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "1px" }}>1.</p><p style={{ margin: "1px" }}>Fire supression systems(e.g.., fire pump,control valves,sprinkler systems,etc)are in normal operator or an approval is obtained.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>2.</p><p>Hot Work fire Extinguisher(s) are adequate and in place(N/A for low energy).</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>3.</p><p>Hot Work equipment is good working condition</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>4.</p><p>Have nearby trenches and sewers been verified to not cause a hazard to the hot work acivity?</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>5.</p><p>proper hazard mitigation in place to reduce airbome or surface dust(e.g..,clean,wetting down etc).</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>6.</p><p>Flammable and combustbles removed to 35-ft(11m)or properly shielded/protected.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>7.</p><p>Shielding/barricading in place to pevent sparks/slag from falling to lower level,exposed combustibles,or personnel below.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>8.</p><p>safegaurds in place to prevent heat(conduction) and sparks from passing through to opposite side of floor,wall ceiling,or roof(e.g..,fire watch,remove combustibles).</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>9.</p><p>Shut down or shield ventilation or conveying systems that may carry sparks.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>10.</p><p>Have additional precautions been applied for combustible building roofs(e.g.., Additional Fire Extinguisher,water buckets,etc)?</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>11.</p><p>Has vessel venting from thermal heating from the sun been considered and property mitigated(e.g.., additional air monitoring,cooling of the vessel,etc)?</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>12.</p><p>Is purging/padding required?</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="inputPassword">PURGE GAS:</label>
                                                            <div>
                                                                <input class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="inputPassword">Purge method:</label>
                                                            <div>
                                                                <input class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="inputPassword">Ventilation requirements:</label>
                                                            <div>
                                                                <input class="form-control" />
                                                            </div>
                                                        </div>





                                                    </div>


                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header id="prot" className="mt-2"><span class="accordion-arrow"></span>VERIFY:PERMIT ISSUER</Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        {/* <div>
                                                            <p style={{ color: "#000000", fontWeight: "600" }}>VERIFY:PERMIT ISSUER IF NO,STOP  </p>
                                                        </div> */}
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>1.</p><p>Workers performing hot work are trained in the safe opertion of all equipment used in the hot work task.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>2.</p><p>Hot work personnel are knowledgeable of the risks associated with fire for the building or area worked.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>3.</p><p>Fire Watch knows how to sound an alarm and call for emergency firefighting services.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>4.</p><p>Inadvertent/accidental activation has been considered for fire suppresion systems,smoke detectors,duct detectors,heat detectors,or nearby sprinkler heads.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>5.</p><p>All fuel sources (gas,flammables,dusts,fibers) have been identified and properly mitigated (e.g..,distance,clean,purge,shielded).</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>6.</p><p>Hazards of conduits,pipes,valves,and tanks reviewed(e.g..,damage to gaskets or materials with in)</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>7.</p><p>Equipment determined not intrinscially safe or explosion proof for the hazardous area location in the Equipment section of the permit.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <p style={{ margin: "2px" }}>8.</p><p>Verify that the isolation system and/or a method will result in zero(0%)LFL(lower flammable limit)and zero equivalent for dust.</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">YES</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">N/A</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header id="we" className="mt-2"><span class="accordion-arrow"></span>AIR MONITORING</Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        <div>
                                                            <p style={{ color: "#000000", fontWeight: "600" }}>Monitor for dust is performed by visual observation <b>STOP WORK ABOVE 0%LFL</b></p>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">N/A Intial/survey-Low Energy(Hazardous Area)</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">Continuous-Mandatory for High Energy(Hazardous Area)</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">N/A General Area or dust</label>
                                                            </div>
                                                        </div>
                                                        <div style={{ overflowX: "auto" }}>
                                                            <table class="table" style={{ width: "100%" }}>
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th scope="col">
                                                                            <label for="inputPassword" class="col-12 col-form-label">AIR MONITOR EQUIPMENT</label>
                                                                        </th>
                                                                        <th scope="col">
                                                                            <label for="inputPassword" class="col-12 col-form-label">BUMP TEST</label>
                                                                        </th>
                                                                        <th scope="col">
                                                                            <label for="inputPassword" class="col-12 col-form-label">LOCATION(S)</label>
                                                                        </th>
                                                                        <th scope="col">
                                                                            <label for="inputPassword" class="col-12 col-form-label">NAME</label>
                                                                        </th>
                                                                        <th scope="col">
                                                                            <label for="inputPassword" class="col-12 col-form-label">RESULT</label>
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>

                                                                        <td>
                                                                            <div class="form-group row m-1">
                                                                                <div>
                                                                                    <div class="col-6 form-check form-check-inline">
                                                                                        <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                                        <label class="form-check-label" for="inlineCheckbox2">PASS</label>
                                                                                    </div>
                                                                                    <div class="col-6 form-check form-check-inline">
                                                                                        <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                                        <label class="form-check-label" for="inlineCheckbox2">FAIL</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>

                                                                        <td>
                                                                            <div class="form-group row m-1">
                                                                                <div>
                                                                                    <div class="col-12 form-check form-check-inline">
                                                                                        <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                                        <label class="form-check-label" for="inlineCheckbox2">0% LFL</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>

                                                                        <td>
                                                                            <div class="form-group row m-1">
                                                                                <div>
                                                                                    <div class="col-6 form-check form-check-inline">
                                                                                        <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                                        <label class="form-check-label" for="inlineCheckbox2">PASS</label>
                                                                                    </div>
                                                                                    <div class="col-6 form-check form-check-inline">
                                                                                        <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                                        <label class="form-check-label" for="inlineCheckbox2">FAIL</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>

                                                                        <td>
                                                                            <div class="form-group row m-1">
                                                                                <div>
                                                                                    <div class="col-12 form-check form-check-inline">
                                                                                        <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                                        <label class="form-check-label" for="inlineCheckbox2">0% LFL</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="7">
                                                <Accordion.Header id="tr" className="mt-2"><span class="accordion-arrow"></span>FIRE WACH</Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="inputPassword">FIRE WATCH</label>
                                                            <div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">60 minutes:default fire watch intended timeframe</label>
                                                                </div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                                                    <label class="form-check-label" for="option2">30 minutes/low rusk reduced timeframe.No exposure to combustible building structure.No combustbles materials subject to smoldering or delayed igntion</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="inputPassword">SURVEY</label>
                                                            <div>
                                                                <div class="form-check m-2">
                                                                    <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                    <label class="form-check-label" for="option1">3-hour periodic/High Risk:very combustible material,crvasse,or hidden pockets,Periodic review of location after Fire watch ends for 3 hours,walking by occasionally to look for smoldering fire or smell of smoke</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{ overflowX: "auto" }}>
                                                            <table class="table" style={{ width: "100%" }}>
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th scope="col">
                                                                            <label for="inputPassword" class="col-12 col-form-label">Fire Watch Loaction</label>
                                                                        </th>
                                                                        <th scope="col" colSpan={2}>
                                                                            <label for="inputPassword" class="col-12 col-form-label">Fire watch start time</label>
                                                                        </th>
                                                                        <th scope="col" colSpan={2}>
                                                                            <label for="inputPassword" class="col-12 col-form-label">Fire watch end time </label>
                                                                        </th>
                                                                        <th scope="col">
                                                                            <label for="inputPassword" class="col-12 col-form-label">Fire watch name</label>
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>

                                                                        <td><label for="inputPassword" class="col-12 col-form-label">START TIME</label></td>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>
                                                                        <td><label for="inputPassword" class="col-12 col-form-label">END TIME</label></td>
                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>
                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>
                                                                    </tr>
                                                                    <tr>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>

                                                                        <td><label for="inputPassword" class="col-12 col-form-label">START TIME</label></td>

                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>
                                                                        <td><label for="inputPassword" class="col-12 col-form-label">END TIME</label></td>
                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>
                                                                        <td><input class="form-control col-12" id="inputPassword" placeholder="" /></td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                          
                                            <Accordion.Item eventKey="8">
                                                <Accordion.Header id="change" className="mt-2"><span class="accordion-arrow"></span>SECONDARY SIGNATURE PERMIT ISSUER</Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="inputPassword">NAME</label>
                                                            <div>
                                                                <input class="form-control" id="inputPassword" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="inputPassword">SIGNATURE</label>
                                                            <div>
                                                                <input class="form-control col-12" id="inputPassword" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="inputPassword">COMPANY</label>
                                                            <div>
                                                                <input class="form-control" id="inputPassword" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">The igntion source is identified.</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">Controls are correct (e.g cleaning, purging, distance, shielding ).</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">The fuel hazard and area reviewed.</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">The fuel source is identified.</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">Air monitoring and locations are identified.</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="form-check m-2">
                                                                <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                                                <label class="form-check-label" for="option1">The work area is prepared for hot work.</label>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                           
                                        </Accordion>
                                    </form>
                                    {/* </div>
                                            </div> */}
                                    {/* </div>
                                    </div> */}
                                </div>


                            </div>
                            {/*  Clseout end */}
                        </div>
                        {/* <Footer /> */}
                        {/* ... End of Footer...  */}
                    </div >
                </div >
                {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
                < a className="scroll-to-top rounded" href="#page-top" >
                    <i className="fas fa-angle-up"></i>
                </a >
                {/*  <!-- Logout Modal--> */}{/* <Logout /> */}
            </div >
        </body >
    );
};

export default HotWorkPermit2;
