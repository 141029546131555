import React, { useState, useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getRequestedandDeliveredCount } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import Box from "../images/box.png";
import DatePicker from "react-datepicker";

Chart.register(...registerables);

const Requested = () => {
  const [requestedCount, setRequestedCount] = useState([]);
  const [RequestDelvLabels, setRequestDelvLabels] = useState([]);
  const [selectedValue, setSelectedValue] = useState("All");
  const [hoveredPercentage, setHoveredPercentage] = useState(null);
  const [allCount, setAllCount] = useState();
  const [completedCount, setCompletedCount] = useState();
  const [inProgressCount, setInProgressCount] = useState();
  const [pendingCount, setPendingCount] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalCount, setTotalCount] = useState();
  var cumulativePercentage = "";

  const fetchData = async (type) => {
    const formattedStartDate = startDate ? formatDate(startDate) : null;
    const formattedEndDate = endDate ? formatDate(endDate) : null;
    const RequestDeliverdRequest = getRequestedandDeliveredCount;
    const postData = {
      type: startDate && endDate ? "" : selectedValue,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    try {
      const response = await axios.post(RequestDeliverdRequest, postData);
      if (type === "All") {
        setAllCount(response.data.datasets.data[0]);
        setCompletedCount(response.data.datasets.data[2]);
        setInProgressCount(response.data.datasets.data[1]);
        setPendingCount(response.data.datasets.data[3]);
      }
      setRequestedCount(response.data.datasets);
      setRequestDelvLabels(response.data.labels);
    } catch (error) {
      console.log("RequestCount", error);
    }
  };

  useEffect(() => {
    const shouldFetchData = selectedValue === "DTD" && startDate && endDate;
    if (shouldFetchData || selectedValue !== "DTD") {
      fetchData(selectedValue, startDate, endDate);
    }
  }, [startDate, endDate, selectedValue]);

  const data = {
    labels: RequestDelvLabels,
    datasets: [requestedCount],
  };
  console.log("reques", RequestDelvLabels);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "start",
        labels: {
          boxWidth: 10,
          padding: 10,
        },
      },
      title: {
        display: true,
        text: "",
        align: "start",
      },
      tooltip: {
        callbacks: {
          label: (context) => {},
        },
      },
    },
  };
  const handleSelect = (e) => {
    setSelectedValue(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <div className="card p-4 ">
        <label className="chartname">
          {" "}
          <img src={Box} style={{ width: 24 }} /> Requested & Completed
        </label>
        <div className="" id="filtersInputs">
          <select
            className="form-control w-100"
            value={selectedValue}
            onChange={handleSelect}
          >
            <option value="All">All</option>
            <option value="WTD">Current Week</option>
            <option value="MTD">Current Month</option>
            <option value="YTD">Yearly</option>
            <option value="DTD">Custom selection</option>
          </select>
        </div>
        {selectedValue === "DTD" && (
          <div className="d-flex">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="form-control  custom-datepicker"
            />
            <DatePicker
              style={{ marginLeft: "130px  " }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="form-control  custom-date"
            />
          </div>
        )}

        <ul>
          <li className="countStyle">
            Requested <span style={{ marginLeft: 11 }}>:</span> {allCount}
          </li>
          <li className="countStyle">
            {" "}
            Completed <span style={{ marginLeft: 7 }}>:</span> {completedCount}{" "}
          </li>
          <li className="countStyle">
            InProgress <span style={{ marginLeft: 10 }}>:</span>{" "}
            {inProgressCount}
          </li>
          {/* <li className="countStyle">Pending Count: {pendingCount}</li>   */}
        </ul>

        <div
          className="chart-container"
          style={{
            position: "relative",
            // width: "300px",
            height: "233px",
            marginLeft: 250,
            marginTop: "-165px",
          }}
        >
          {/* <div className="chart-container" style={{height:'170px',marginTop:'-30px', marginLeft:'auto', width: "300px",}}> */}
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </>
  );
};

export default Requested;
