
export const corePermitInitialsConst = {
  "initialsOne":"",
  "initialsTwo":"",
  "initialsThree":"",
  "dateInputOne":"",
  "dateInputTwo":"",
  "dateInputThree":"",
  "dateInputFour":"",
  "dateInputFive":"",
  "dateInputSix":"",
  "permitsOne" :"",
  "permitsTwo":""  
}


export const ElevatedWork = {
  "Location(building/area/equipment)": "",
  Company: "",
  "Elevated work scope/description": "",
  cbTitle: "See Scope on Core Permit",
  checked: false,
  description: "",
};
export const EmergencyConst = {

  "phoneNumber": "",

  "assemblyLocation": ""

}

export const HazardIdentConst = {
  "Specific fall hazards": "",
  hazard: [
    {
      cbTitle: "Wall Opening(s)",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Floor Opening(s)",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Unprotected edge",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Unstable or unusual Working surface",
      checked: false,
      description: "",
    },
    {
      cbTitle:
        "other(i.e. engaging lift drive tires while elevated of use of a forklift man-basket,non permanently installed fall anchor point)",
      checked: false,
      description: "",
    },
  ],
  "Specific falling object hazards": "",
}


export const Mitigation = {
  "Specific fall hazards": "",
  workAccess: [
    {
      cbTitle: "How personnel will access he work area",
      description: "",
    },
  ],
  Hazard: [
    // {
    //   cbTitle:
    //     "the Precations used to control or eliminate the hazards(select one or more)",
    //   description: "",
    // },
    {
      cbTitle: "Fall restraint",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Traffic:(pedestrain,vehicle,etc)",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Fall arrest",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Other",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Temporary warning line:Distance from edge",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Safety Attendant name",
      checked: false,
      description: "",
    },
    {
      cbTitle:
        "Specific fall Arrest/Restraint Equipment to be Utilized:(i.e harness,SRL,lanyard,etc)",
      checked: false,
      description: "",
    },
  ],
  anchoragePoint: [
    // {
    //   cbTitle: "Anchorage Points(if required)",
    //   description: "",
    // },
    {
      cbTitle:
        "Fall Arrest Anchorage(must be capable of supporting 5000lbs(2267kg)per employee attched or shall be designed with a safety factor of at least 2.)",
      checked: false,
      description: "",
    },
    {
      cbTitle:
        "Fall restraint Anchorage(must be capable of supporting twice the potential impact load or 3000lbs(1360.8kg),which ever is greater)",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Mobile Crane hook or load line:(req EHS&S Leader Approved)",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Other:(req.Structrual Engineer Approval)",
      checked: false,
      description: "",
    },
  ],
  fallingObjectCount: [
    {
      cbTitle: "Barricades",
      checked: false,
      description: "",
    },
    {
      cbTitle: "bags/pouches",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Toe boards",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Tool landyards",
      checked: false,
      description: "",
    },
    {
      cbTitle: "Other",
      checked: false,
      description: "",
    },
  ],
}


export const RoofAccessConstant = {
  "cbTitle": "ROOF ACCESS",
  "checked": false,
  "slopeType": {
    "question": "Slope Type of Roof (select one)",
    "list": [
      {
        "cbTitle": "Flat/Low Slope (<4 in 12) (<33% grade)",
        "checked":  ""
      },
      {
        "cbTitle": "Steep Slope (>4 in 12) (>33% grade)",
        "checked":  ""
      }
    ]
  },
  "workType": {
    "question": "Work Type (select one)",
    "list": [
      {
        "cbTitle": "Roofing",
        "checked": false
      },
      {
        "cbTitle": "All Other Work",
        "checked": false
      }
    ]
  },
  "distance": {
    "question": "Minimum Distance from the Edge (select one)",
    "list": [
      {
        "cbTitle": "<6 ft. (1.83 m)",
        "checked": false
      },
      {
        "cbTitle": "Between 6 ft. (1.83 m) & 15 ft. (4.57 m)",
        "checked": false
      },
      {
        "cbTitle": ">15 ft. (4.57 m)",
        "checked": false
      },
      {
        "cbTitle": "Hatches, Skylights, or Other Holes in the Roof Have Been Covered or Guarded (Critical Guard)",
        "checked": false
      },
      {
        "cbTitle": "Tank Roof Has Been Evaluated for the Intended Load (UT, Service Life, etc)",
        "checked": false
      },
      {
        "cbTitle": "Lighting Is Appropriate",
        "checked": false
      },
      {
        "cbTitle": "Vent, Relief, Exhaust, and Intake Hazards Are Communicated and Controlled",
        "checked": false
      },
      {
        "cbTitle": "No Inclement Weather",
        "checked": false
      },
      {
        "cbTitle": "Flammables & Controls (list)",
        "checked": false,
        "description": ""
      }
    ]
  }
}


export const FallRescueConstant = {
  "title": "People",
  "fallList": [
    {
      "cbTitle": "Contacts",
      "description": ""
    },
    {
      "cbTitle": "Rescuers",
      "description": ""
    },
    {
      "cbTitle": "Standby Personnel (if applicable)",
      "description": ""
    }
  ],
  "method": {
    "question": "Method of Contacting Rescuers",
    "list": [
      {
        "cbTitle": "Verbal/Face to Face",
        "checked": false
      },
      {
        "cbTitle": "Radio (Channel)",
        "checked": false,
        "description": ""
      },
      {
        "cbTitle": "Other",
        "checked": false,
        "description": ""
      }
    ]
  },
  "rescueEquipment": {
    "question": "Rescue Equipment",
    "list": [
      {
        "cbTitle": "Ladder",
        "checked": false
      },
      {
        "cbTitle": "Aerial Lift Device",
        "checked": false
      },
      {
        "cbTitle": "Alternative Lifting/Lowering Device",
        "checked": false
      },
      {
        "cbTitle": "Other",
        "checked": false,
        "description": ""
      }
    ],
    "list2": [
      {
        "cbTitle": "Equipment Has Been Located Prior to Work, Location",
        "description": ""
      },
      {
        "cbTitle": "Anchor Point (if applicable)",
        "description": ""
      },
      {
        "cbTitle": "Rescue Landing Area (if applicable)",
        "description": ""
      },
      {
        "cbTitle": "Rescue Obstructions/Hazards (if applicable)",
        "description": ""
      }
    ]
  }
}
export const CommentsConst = {
  title: "ADDITIONAL COMMENTS&MODIFICATIONS",
  description: "",
};

export const rWorkPermit = {
  "title": "I HAVE REVIEWED AND UNDERSTOOD THE ABOVE CRITICAL ELEVATED WORK PERMIT",
  "description": "",
  "list1": [
    {
      "cbTitle": "User Signature(s)",
      "description": ""
    },
    {
      "cbTitle": "Fall Protection Trained",
      "description": ""
    },
    {
      "cbTitle": "Date",
      "description": ""
    },
    {
      "cbTitle": "User Signature(s)",
      "description": ""
    },
    {
      "cbTitle": "Fall Protection Trained",
      "description": ""
    },
    {
      "cbTitle": "Date",
      "description": ""
    }
  ],
  "list2": [
    {
      "cbTitle": "User Signature(s)",
      "description": ""
    },
    {
      "cbTitle": "Fall Protection Trained",
      "description": ""
    },
    {
      "cbTitle": "Date",
      "description": ""
    },
    {
      "cbTitle": "User Signature(s)",
      "description": ""
    },
    {
      "cbTitle": "Fall Protection Trained",
      "description": ""
    },
    {
      "cbTitle": "Date",
      "description": ""
    }
  ],
  "list3": [
    {
      "cbTitle": "User Signature(s)",
      "description": ""
    },
    {
      "cbTitle": "Fall Protection Trained",
      "description": ""
    },
    {
      "cbTitle": "Date",
      "description": ""
    },
    {
      "cbTitle": "User Signature(s)",
      "description": ""
    },
    {
      "cbTitle": "Fall Protection Trained",
      "description": ""
    },
    {
      "cbTitle": "Date",
      "description": ""
    }
  ],
  "list4": [
    {
      "cbTitle": "User Signature(s)",
      "description": ""
    },
    {
      "cbTitle": "Fall Protection Trained",
      "description": ""
    },
    {
      "cbTitle": "Date",
      "description": ""
    },
    {
      "cbTitle": "User Signature(s)",
      "description": ""
    },
    {
      "cbTitle": "Fall Protection Trained",
      "description": ""
    },
    {
      "cbTitle": "Date",
      "description": ""
    }
  ]
}

export const SignatureConstant = {
  "dates": [
    {
      "title": "DATE(s) OF PROPOSED USAGE",
      "fromDate": "",
      "toDate": ""
    }
  ],
  "workPlanner": {
    "title": "Work Planner",
    "description": "",
    "phoneNumber": ""
  },
  "criticalElevated": {
    "title": "Critical Elevated Work Permit Approver",
    "description": "",
    "phoneNumber": ""
  },
  "structuralEngineer": {
    "title": "Structural Engineer Approval of Anchorage (if necessary)",
    "description": "",
    "phoneNumber": ""
  },
  "ehss": {
    "title": "EHS&S Leader Approval* (if necessary)",
    "description": "",
    "phoneNumber": ""
  }
}