

import React, { useState } from 'react'
import { useEffect } from 'react'

function VerifyPermitIssuer(props) {
    const handleVerify = (mkey, val, key, index) => {
        const newArr = props.data[mkey]
        newArr[index][key] = val
        if (key == 'cbYes') {
            newArr[index]['cbNA'] = 'false'
        } else {
            newArr[index]['cbYes'] = 'false'
        }
        props.setVerify(prev => ({ ...prev, [mkey]: newArr }))
    }


    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>
                    {
                      props.data.list.map((item,index)=>(
                        <div class="form-group" key={index}>
                              <p>{item.cbTitle}</p>    
                       {/* <div style={{ display: "flex", flexDirection: "row" }}>
                                <p style={{ margin: "2px" }}>1.</p><p>Workers performing hot work are trained in the safe opertion of all equipment used in the hot work task.</p>
                            </div> */}
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div class="form-check m-2">
                                    {/* <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                    <label class="form-check-label" for="option1">YES</label> */}
                                    <input class="form-check-input" type="checkbox" checked={item.cbYes == 'true'} onClick={() => {
                                        handleVerify('list', item.cbYes == 'true' ? 'false' : 'true', 'cbYes', index)
                                    }} name="answer1" id={`listReqPecActionYES${index}`} value="option1" />
                                    <label class="form-check-label" for={`listReqPecActionYES${index}`}>YES</label>
                                </div>
                                <div class="form-check m-2">
                                    {/* <input class="form-check-input" type="checkbox" name="answer2" id="option2" value="option2" />
                                    <label class="form-check-label" for="option2">N/A</label> */}
                                    <input class="form-check-input" checked={item.cbNA == 'true'} onClick={() => {
                                        handleVerify('list', item.cbNA == 'true' ? 'false' : 'true', 'cbNA', index)
                                    }} type="checkbox" name="answer2" id={`listReqPecActionNA${index}`} value="option2" />
                                    <label class="form-check-label" for={`listReqPecActionNA${index}`}>N/A</label>
                                </div>
                                {/* <label for="inputPassword" class="col-11 col-form-label lbl-bg-color" style={{ padding: "15px", margin: "5px" }}>{i.cbTitle}</label> */}
                            </div>
                        </div>
                                ))
                         }

                    </div>

                </div>
            </div>
        </div>
    );
}

export default VerifyPermitIssuer;
