
export const corePermitInitialsConst = {
    "initialsOne":"",
    "initialsTwo":"",
    "initialsThree":"",
    "dateInputOne":"",
    "dateInputTwo":"",
    "dateInputThree":"",
    "dateInputFour":"",
    "dateInputFive":"",
    "dateInputSix":"",
    "permitsOne" :"",
    "permitsTwo":""  
}

export const GeneralInfoConst = {
    "phoneNumber": "",
    "assemblyLocation": "",
    "na": false,
    "authorized": [
        {
            "cbTitle": "AUTHORIZED PROCEDURE#/NAME",
            "value": "",
        }
    ],
   
    "startTime" : "",
    "endTime" : "",
    "permitIssuerName": "",
    "permitIssuerSignature": "",
    "permitReceiverName": "",
    "signature": "",
    "company": "",
    "phone_radio_ch": "",
}
export const HotWorkInfoConst = {
    "hotWorkLocation": "",
    "hotWorkTask": "",
    "equipment": "",
    "fuelHazard": [
        {
            "cbTitle": "Combustible Liquid",
            "value": false,

        },  
        {
            "cbTitle": "Flammable Liquid/Gas",
            "value": false,

        },
        {
            "cbTitle": "Combustble Dust",
            "value": false,

        },
        {
            "cbTitle": "Ordinary Combustibles",
            "value": false,


        }
    ],
    "typeOfHotWork": [
        {
            "cbTitle": "Low Energy",
            "value": ""

        },
        {
            "cbTitle": "High Energy",
            "value": ""

        }
    ],
}
export const RequiredPreConstant = {
    "title": "REQUIRED PREACUTIONS Determine Yes or N/A.STOP if *NO*",
    "list": [
        {
            "cbTitle": "Fire supression systems(e.g.., fire pump,control valves,sprinkler systems,etc)are in normal operator or an approval is obtained.",
            "cbYes": "false",
            "cbNA": "false"
        },
        {
            "cbTitle": "Hot Work fire Extinguisher(s) are adequate and in place(N/A for low energy).",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Hot Work equipment is good working condition",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Have nearby trenches and sewers been verified to not cause a hazard to the hot work acivity?",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "proper hazard mitigation in place to reduce airbome or surface dust(e.g..,clean,wetting down etc).",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Flammable and combustbles removed to 35-ft(11m)or properly shielded/protected.",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Shielding/barricading in place to pevent sparks/slag from falling to lower level,exposed combustibles,or personnel below.",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "safegaurds in place to prevent heat(conduction) and sparks from passing through to opposite side of floor,wall ceiling,or roof(e.g..,fire watch,remove combustibles).",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Shut down or shield ventilation or conveying systems that may carry sparks.",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Have additional precautions been applied for combustible building roofs(e.g.., Additional Fire Extinguisher,water buckets,etc)?",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Has vessel venting from thermal heating from the sun been considered and property mitigated(e.g.., additional air monitoring,cooling of the vessel,etc)?",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Is purging/padding required?",
            "cbYes": "false",
            "cbNA": "false",
            "title1": "",
            "title2": "",
            "title3": ""

        }
    ]
}
export const VerifyConst = {
    "title": "PERMIT ISSUER                                       IF NO,STOP",
    "list": [
        {
            "cbTitle": "Workers performing hot work are trained in the safe opertion of all equipment used in the hot work task.",
            "cbYes": "false",
            "cbNA": "false"
        },
        {
            "cbTitle": "nel are knowledgeable of the risks associated with fire for the building or area worked.",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Fire Watch knows how to sound an alarm and call for emergency firefighting services.",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Inadvertent/accidental activation has been considered for fire suppresion systems,smoke detectors,duct detectors,heat detectors,or nearby sprinkler heads.",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "All fuel sources(gas,flammables,dusts,fibers)have been identified and properly mitigated(e.g..,distance,clean,purge,shielded).",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Hazards of conduits,pipes,valves,and tanks reviewed(e.g..,damage to gaskets or materials with in)",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Equipment determined not intrinscially safe or explosion proof for the hazardous area location in the Equipment section of the permit.",
            "cbYes": "false",
            "cbNA": "false"

        },
        {
            "cbTitle": "Verify that the isolation system and/or a method will result in zero(0%)LFL(lower flammable limit)and zero equivalent for dust.",
            "cbYes": "false",
            "cbNA": "false"

        }
    ]

}
export const AirMonitoringConst = {
    "title": "Monitor for dust is performed by visual observation",
    "list": [
        {
            "cbTitle": "Intial/survey-Low Energy(Hazardous Area)",
            "cbValue": "false"
        },
        {
            "cbTitle": "NA General Area or dust",
            "cbValue": "false"
        },
        {
            "cbTitle": "Continuous-Mandatory for High Energy(Hazardous Area)",
            "cbValue": "false"
        }
    ],
    "airMonitor": [
        {
            "airMonitorEqipment": "",
            "bumpTestPass": "false",
            "bumpTestfail": "false",
            "locations": "",
            "name": "",
            "result": "false"
        },
        {
            "airMonitorEqipment": "",
            "bumpTestPass": "false",
            "bumpTestfail": "false",
            "locations": "",
            "name": "",
            "result": "false"
        }
    ]
}
export const FireWatchConst = {
    "fireWatch": [
        {
            "cbTitle": "60 minutes:default fire watch intended timeframe",
            "cbValue": "false"
        },
        {
            "cbTitle": "30 minutes/low rusk reduced timeframe.No exposure to combustible building structure.No combustbles materials subject to smoldering or delayed igntion",
            "cbValue": "false"
        }
    ],
    "survy": [
        {
            "cbTitle": "watch ends for 3 hours,walking by occasionally to look for smoldering fire or smell of smoke",
            "cbValue": "false"
        }
    ],
    "fireWatchLoc": [
        {
            "fireWatchLocation": "",
            "startTime": "",
            "endTime": "",
            "fireWatchName": ""
        },
        {
            "fireWatchLocation": "",
            "startTime": "",
            "endTime": "",
            "fireWatchName": ""
        }
    ]
}
export const SecondaryConst = {

    "title": "SECONDARY SIGNATURE PERMIT ISSUER(High Energy Hot Work in Hazardous Area Only",
    "naValue": "false",
    "name": "",
    "signature": "",
    "company": "",
    "list": [
        {
            "cbTitle": "The igntion source is identified.",
            "cbValue": "false"
        },
        {
            "cbTitle": "Controls are correct(e.g cleaning,purging,distance,shielding).",
            "cbValue": "false"
        },
        {
            "cbTitle": "The fuel hazard and area reviewed.",
            "cbValue": "false"
        },
        {
            "cbTitle": "The fuel source is identified.",
            "cbValue": "false"
        },
        {
            "cbTitle": "Air monitoring and locations are identified.",
            "cbValue": "false"
        },
        {
            "cbTitle": "The work area is prepared for hot work.",
            "cbValue": "false"
        }
    ]
}
