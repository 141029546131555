import React from 'react'

function RMTracking() {
  return (
    <div>
      <h1>RMTracking</h1>
    </div>
  )
}

export default RMTracking
