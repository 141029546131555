import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../src/images/corteva-logo.png';
import Menu from './images/Menu.png';
import CentralLogo from './images/CentralLogo.png';
import FarmLogo from './images/FarmLogo.png';
import Tracking from './images/Tracking.png';
import Search from './images/search.png';


import researchOperation from './images/research_operation.png';
import productrequestImage from './images/product_request.png'
import issuedProducts from './images/issued-products.png';
import PendingProducts from './images/Pending_products.png';
import closedProducts from './images/closed-products.png';
import RMtracking from './images/R-M-tracking.png';
import noun from './images/noun.png';

// R-M-tracking
import user from './images/user.png';
import centralStore from './images/central-store.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SideMenu from './Pages/SideMenu';
import Header from './Pages/Header';
import Footer from './Pages/Footer';
import Logout from './Pages/Logout';
import { MOBILE_NUMBER, retrieveData, storeData } from './Utils';
import { dashboardCountSummary } from "./Utilities/URLCONSTANTS";
import { GetApiHeaders } from "./Utilities/NetworkUtilities";
import axios from 'axios';
import TurnaroundTimeChart from './components/Charts/TurnAroundChart';
import RequestDeliverdChart from './components/Charts/RequestDeliverdChart';
import CostChart from './components/Charts/CostChart';
import RequestMonthlyChart from './components/Charts/RequestMonthlyChart';


const DashboardNew=()=> {
  
    //const BASE =sessionStorage.getItem("BASE");
  // console.log("Base64::",BASE);
  const [issueStock, setissueStock] = useState();
  const [respNotification, setrespNotification] = useState();
  const [pendingRequest, setpendingRequest] = useState();
  const [acceptedRequest, setacceptedRequest] = useState();
  const [fOAcceptedRequest, setfOAcceptedRequest] = useState();
  const [fOPendingRequest, setfOPendingRequest] = useState();
  

    const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");

    const changeStyle = () => {
        if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
        }
        else {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    };
    const changeStyle1 = () => {
        if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
        }
        else {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    };
    const fetchData = async () =>{
        const notificationApi = dashboardCountSummary
        const headers = await GetApiHeaders();
        const postData ={
            "userType":"Admin",

            "mainAsset":"rmTracking,centralStore"
        }
    
        try{
            const response = await axios.post(notificationApi, postData, {headers})
            console.log("cs notification",response)
       
            setrespNotification(response.data.response.centralStore['1'].notificationSummary.stockInWard)
            setissueStock(response.data.response.centralStore['1'].notificationSummary.issuedStock)
            setpendingRequest(response.data.response.rmTracking['1'].notificationSummary.approvalPending)
            setacceptedRequest(response.data.response.rmTracking['1'].notificationSummary.totalRequestsAccepted)
      
        }catch(error){
            console.log("Notification", error)
        }
     }
     useEffect(()=>{
        fetchData()
     }, [])
    return (
        <div>
            {/* {(isUserLoggedIn != undefined && isUserLoggedIn.toString() != 'false' && isUserLoggedIn.toString() != '' &&  isUserLoggedIn!=null) && */}
                <body id="page-top">
                    {/*  <!-- Page Wrapper --> */}
                    <div id="wrapper">
                        {/*  <!-- Sidebar --> */}
                        <SideMenu />
                        {/*  <!-- End of Sidebar --> */}

                        {/*  <!-- Content Wrapper --> */}
                        <div id="content-wrapper" className="d-flex flex-column">

                            {/*  <!-- Main Content --> */}
                            <div id="content">
                                {/*  <!-- Topbar --> */}
                                <Header />
                                {/* ...Content Start... */}
                                <div className='mr-2'>
                                    <div className='container-fluid mt-2 '>
                                        <div className='card shadow border-0 research_card'>
                                            <div className='card-body'>
                                                <div className='d-lg-flex d-sm-block gap-3 flex-grow-1'>
                                                    <div className='research_text pt-2 pl-2 w-full md:w-10/12'>
                                                        <h6 className='MCRC_text '>MCRC Research Operations</h6>
                                                        <p>MCRC Research Operations, a critical element of the supply chain, is the tracking of Central
                                                            store from manufacturers to warehouses and from these facilities to a
                                                            point of sale. The goal of Central store management is to have the right
                                                            products in the right place at the right time.</p>
                                                        {/* <div className='btn btn-primary btn-md'>Take a tour
                                                            <FontAwesomeIcon icon={faArrowRight} className='arrow_icon ml-1' />
                                                        </div> */}
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        <img src={researchOperation} alt="Login" className='research_card_image m-auto pr-5' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        {/* ////charts section start */}
                                        <div className='row mt-2 mb-2'>
                                            <div className='col-md-6'>
                                            <TurnaroundTimeChart/>
                                            </div>
                                            <div className='col-md-6'> 
                                             <RequestDeliverdChart/>
                                            </div>
                                        </div>
                                        <div className='row mt-2 mb-2'>
                                            <div className='col-md-6'> 
                                             <CostChart/>
                                            </div>
                                            <div className='col-md-6'> 
                                             <RequestMonthlyChart/>
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                            </div>
                            {/*   <!-- End of Main Content -->

                               {/* ...Footer...*/}
                            <Footer />
                            {/* ... End of Footer...  */}

                        </div>

                    </div>
                    {/*  <!-- End of Page Wrapper -->

                                <!-- Scroll to Top Button--> */}
                    <a className="scroll-to-top rounded" href="#page-top">
                        <i className="fas fa-angle-up"></i>
                    </a>

                    {/*  <!-- Logout Modal--> */}
                    <Logout />

                </body>
            {/* } */}
        </div>
    )
}

export default DashboardNew;
