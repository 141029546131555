import React, { useState, useEffect, useRef } from "react";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllPermitsByCorePermitID } from "../Utilities/URLCONSTANTS";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

//Header images
import Factory from "../images/Factory-header-corepermit.png";
import CortevaImage from "../images/corteva-logo.png";
import HouseAndLighthouse from "../images/HouseAndLighthouse.png";
import Labtubeheadercorepermit from "../images/Labtubeheadercorepermit.png";
import shakinghandsHeadersCorepermit from "../images/shakinghandsHeadersCorepermit.png";
import ImageheaderCorepermit from "../images/ImageheaderCorepermit.png";
import DroneHeaderCorepermit from "../images/DroneHeaderCorepermit.png";
import Loader from "../../src/Utilities/Loader";

const HotWorkPermit2_Web = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTextHidden, setIsTextHidden] = useState(false);

  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [searchParams, setSearchParams] = useSearchParams();
  //  const IdFromURL = searchParams.get('corePermitID');
  const IdFromURLwithEDit = searchParams.get("corePermitID");
  var IdFromURL;
  var additional_permit_id;
  if (IdFromURLwithEDit == null) {
  } else {
    IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
    additional_permit_id = IdFromURLwithEDit.toString().split("/")[1];
  }

  useEffect(() => {
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0,
      additional_permit_id != undefined &&
        additional_permit_id != null &&
        additional_permit_id != ""
        ? additional_permit_id
        : 0
    );
  }, []);

  // areas
  const [generalChecked, setGeneralChecked] = useState("");
  const [hazardChecked, setHazardChecked] = useState("");

  //CORE Permit #:
  const [initialsOne, setInitialsOne] = useState("");
  const [initialsTwo, setInitialsTwo] = useState("");
  const [initialsThree, setInitialsThree] = useState("");

  const [dateInputOne, setDateInputOne] = useState("");

  const [permitsOne, setPermitsOne] = useState("");
  const [permitsTwo, setPermitsTwo] = useState("");

  //1. Emergency
  const [hotEmergency, sethotEmergency] = useState();
  const [assemblyLocation, setAssemblyLocation] = useState();
  const [hotAssembly, sethotAssembly] = useState();
  const [na, setna] = useState();
  const [hotName, sethotName] = useState();
  const [hotExpire, sethotExpire] = useState();
  const [hotStartTime, sethotStartTime] = useState();
  const [hotEndTime, sethotEndTime] = useState();
  const [hotAmPm, sethotAmPm] = useState();
  const [hotIssuerName, sethotissuerName] = useState();
  const [hotIssuerSign, sethotIssuerSign] = useState();
  const [hotReceiverName, sethotReceiverName] = useState();
  const [hotReceiverSign, sethotReceiverSign] = useState();
  const [hotCompany, sethotCompany] = useState();
  const [hotPhone, sethotPhone] = useState();

  //2. Hotwork Information
  const [hotWorkLocation, sethotWorkLocation] = useState();
  const [hotWorkTask, sethotWorkTask] = useState();
  const [equipment, setequipment] = useState();
  //FUEL HAZARD(S)
  const [fh0, setfh0] = useState();
  const [fh1, setfh1] = useState();
  const [fh2, setfh2] = useState();
  const [fh3, setfh3] = useState();
  //Type of hot Work
  const [tohw0, settohw0] = useState();
  const [tohw1, settohw1] = useState();
  //const [tohw2,settohw2]=useState();

  //Fire Watch
  const [fw60, setfw60] = useState();
  const [fw30, setfw30] = useState();
  const [fw3, setfw3] = useState();

  //3. Firewatch Location
  const [fwName0, setfwName0] = useState();
  const [fwLoc0, setfwLoc0] = useState();
  const [fwST0, setfwST0] = useState();
  const [fwET0, setfwET0] = useState();

  const [fwName1, setfwName1] = useState();
  const [fwLoc1, setfwLoc1] = useState();
  const [fwST1, setfwST1] = useState();
  const [fwET1, setfwET1] = useState();
  //Required Precautions
  const [precautionsYes0, setprecautionsYes0] = useState();
  const [precautionsNA0, setprecautionsNA0] = useState();

  const [precautionsYes1, setprecautionsYes1] = useState();
  const [precautionsNA1, setprecautionsNA1] = useState();

  const [precautionsYes2, setprecautionsYes2] = useState();
  const [precautionsNA2, setprecautionsNA2] = useState();

  const [precautionsYes3, setprecautionsYes3] = useState();
  const [precautionsNA3, setprecautionsNA3] = useState();

  const [precautionsYes4, setprecautionsYes4] = useState();
  const [precautionsNA4, setprecautionsNA4] = useState();

  const [precautionsYes5, setprecautionsYes5] = useState();
  const [precautionsNA5, setprecautionsNA5] = useState();

  const [precautionsYes6, setprecautionsYes6] = useState();
  const [precautionsNA6, setprecautionsNA6] = useState();

  const [precautionsYes7, setprecautionsYes7] = useState();
  const [precautionsNA7, setprecautionsNA7] = useState();

  const [precautionsYes8, setprecautionsYes8] = useState();
  const [precautionsNA8, setprecautionsNA8] = useState();

  const [precautionsYes9, setprecautionsYes9] = useState();
  const [precautionsNA9, setprecautionsNA9] = useState();

  const [precautionsYes10, setprecautionsYes10] = useState();
  const [precautionsNA10, setprecautionsNA10] = useState();

  const [precautionsYes11, setprecautionsYes11] = useState();
  const [precautionsNA11, setprecautionsNA11] = useState();
  const [precautionsTitle1, setprecautionsTitle1] = useState();
  const [precautionsTitle2, setprecautionsTitle2] = useState();
  const [precautionsTitle3, setprecautionsTitle3] = useState();
  //Verify
  const [verifyYes0, setverifyYes0] = useState();
  const [verifyNA0, setverifyNA0] = useState();

  const [verifyYes1, setverifyYes1] = useState();
  const [verifyNA1, setverifyNA1] = useState();

  const [verifyYes2, setverifyYes2] = useState();
  const [verifyNA2, setverifyNA2] = useState();

  const [verifyYes3, setverifyYes3] = useState();
  const [verifyNA3, setverifyNA3] = useState();

  const [verifyYes4, setverifyYes4] = useState();
  const [verifyNA4, setverifyNA4] = useState();

  const [verifyYes5, setverifyYes5] = useState();
  const [verifyNA5, setverifyNA5] = useState();

  const [verifyYes6, setverifyYes6] = useState();
  const [verifyNA6, setverifyNA6] = useState();

  const [verifyYes7, setverifyYes7] = useState();
  const [verifyNA7, setverifyNA7] = useState();

  const [verifyYes8, setverifyYes8] = useState();
  const [verifyNA8, setverifyNA8] = useState();
  //Air Monitoring
  const [amGenaral, setamGenaral] = useState();
  const [amInitial, setamInitial] = useState();
  const [amContinues, setamContinues] = useState();
  //AIR MONITOR EQUIPMENT
  const [amEqipment0, setamEqipment0] = useState();
  const [amPass0, setamPass0] = useState();
  const [amFail0, setamFail0] = useState();
  const [amLocation0, setamLocation0] = useState();
  const [amName0, setamName0] = useState();
  const [amResult0, setamResult0] = useState();
  const [amEqipment1, setamEqipment1] = useState();
  const [amPass1, setamPass1] = useState();
  const [amFail1, setamFail1] = useState();
  const [amLocation1, setamLocation1] = useState();
  const [amName1, setamName1] = useState();
  const [amResult1, setamResult1] = useState();
  // Secondary Signature
  const [secondaryNA, setsecondaryNA] = useState();
  const [secondaryName, setsecondaryName] = useState();
  const [secondarySign, setsecondarySign] = useState();
  const [secondaryCompany, setsecondaryCompany] = useState();
  const [secondaryList0, setsecondaryList0] = useState();
  const [secondaryList1, setsecondaryList1] = useState();
  const [secondaryList2, setsecondaryList2] = useState();
  const [secondaryList3, setsecondaryList3] = useState();
  const [secondaryList4, setsecondaryList4] = useState();
  const [secondaryList5, setsecondaryList5] = useState();
  const getFormData = async (id, additional_permit_id) => {
    console.log(id);
    try {
      setIsLoading(true);
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: "",
        name: "",
        PermitID: id,
      });
      setIsLoading(false);
      console.log("id:::", id);
      console.log("response data:", response.data);
      // Check if the response contains data
      if (response.data && response.data.response) {
        //const dataLast = response?.data?.response?.general_hot_Work_permit || []
        const elevated =
          response?.data?.response?.general_hot_Work_permit || [];
        // alert(JSON.stringify( elevated));
        const dataLast =
          elevated.find((item) => item.permitID === additional_permit_id)
            ?.permitObject || null;
        console.log("filteredArray checking", JSON.stringify(dataLast));

        // Set state values based on the retrieved data
        if (dataLast) {
          ///areas
          setGeneralChecked(dataLast.genaralArea);
          setHazardChecked(dataLast.hazardousArea);
          //Permit Initials
          setInitialsOne(
            dataLast?.genaralInformation.permitInitials.initialsOne
          );
          setInitialsTwo(
            dataLast?.genaralInformation.permitInitials.initialsTwo
          );
          setInitialsThree(
            dataLast?.genaralInformation.permitInitials.initialsThree
          );

          setDateInputOne(
            dataLast?.genaralInformation.permitInitials.dateInputOne
              ? moment(
                  dataLast.genaralInformation.permitInitials.dateInputOne
                ).format("DD-MMM-YYYY")
              : ""
          );

          setPermitsOne(dataLast?.genaralInformation.permitInitials.permitsOne);
          setPermitsTwo(dataLast?.genaralInformation.permitInitials.permitsTwo);

          //1. Emergency
          sethotEmergency(dataLast?.genaralInformation.phoneNumber);
          setAssemblyLocation(dataLast?.genaralInformation.assemblyLocation);
          setna(dataLast?.genaralInformation.na);

          sethotAssembly(dataLast?.genaralInformation.authorized["0"].value);
          // sethotStartTime(dataLast?.genaralInformation.permitExperation['0'].value);
          sethotStartTime(dataLast?.genaralInformation.startTime);
          //setfwST1(moment(dataLast?.genaralInformation.fireWatch.fireWatchLoc['1'].startTime).format("h:mm A"));
          sethotEndTime(dataLast?.genaralInformation.endTime);
          sethotissuerName(dataLast?.genaralInformation.permitIssuerName);
          sethotIssuerSign(dataLast?.genaralInformation.permitIssuerSignature);
          sethotReceiverName(dataLast?.genaralInformation.permitReceiverName);
          sethotReceiverSign(dataLast?.genaralInformation.signature);
          sethotCompany(dataLast?.genaralInformation.company);
          sethotPhone(dataLast?.genaralInformation.phone_radio_ch);
          //2. Hotwork Location
          sethotWorkLocation(
            dataLast?.genaralInformation.hotWorkInformation.hotWorkLocation
          );
          sethotWorkTask(
            dataLast?.genaralInformation.hotWorkInformation.hotWorkTask
          );
          setequipment(
            dataLast?.genaralInformation.hotWorkInformation.equipment
          );

          //FUEL HAZARD(S)
          if (
            dataLast?.genaralInformation.hotWorkInformation.fuelHazard["0"]
              .value == true
          ) {
            setfh0("checked");
          }

          if (
            dataLast?.genaralInformation.hotWorkInformation.fuelHazard["1"]
              .value == true
          ) {
            setfh1("checked");
          }
          if (
            dataLast?.genaralInformation.hotWorkInformation.fuelHazard["2"]
              .value == true
          ) {
            setfh2("checked");
          }
          if (
            dataLast?.genaralInformation.hotWorkInformation.fuelHazard["3"]
              .value == true
          ) {
            setfh3("checked");
          }

          //Type of hot Work
          if (
            dataLast?.genaralInformation.hotWorkInformation.typeOfHotWork["0"]
              .value == true
          ) {
            settohw0("checked");
          }
          if (
            dataLast?.genaralInformation.hotWorkInformation.typeOfHotWork["1"]
              .value == true
          ) {
            settohw1("checked");
          }

          // Required Precautions
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["0"].cbYes ==
            "true"
          ) {
            setprecautionsYes0("checked"); //alert(precautionsYes0);
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["0"].cbNA ==
            "true"
          ) {
            setprecautionsNA0("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["1"].cbYes ==
            "true"
          ) {
            setprecautionsYes1("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["1"].cbNA ==
            "true"
          ) {
            setprecautionsNA1("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["2"].cbYes ==
            "true"
          ) {
            setprecautionsYes2("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["2"].cbNA ==
            "true"
          ) {
            setprecautionsNA2("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["3"].cbYes ==
            "true"
          ) {
            setprecautionsYes3("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["3"].cbNA ==
            "true"
          ) {
            setprecautionsNA3("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["4"].cbYes ==
            "true"
          ) {
            setprecautionsYes4("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["4"].cbNA ==
            "true"
          ) {
            setprecautionsNA4("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["5"].cbYes ==
            "true"
          ) {
            setprecautionsYes5("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["5"].cbNA ==
            "true"
          ) {
            setprecautionsNA5("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["6"].cbYes ==
            "true"
          ) {
            setprecautionsYes6("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["6"].cbNA ==
            "true"
          ) {
            setprecautionsNA6("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["7"].cbYes ==
            "true"
          ) {
            setprecautionsYes7("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["7"].cbNA ==
            "true"
          ) {
            setprecautionsNA7("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["8"].cbYes ==
            "true"
          ) {
            setprecautionsYes8("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["8"].cbNA ==
            "true"
          ) {
            setprecautionsNA8("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["9"].cbYes ==
            "true"
          ) {
            setprecautionsYes9("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["9"].cbNA ==
            "true"
          ) {
            setprecautionsNA9("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["10"].cbYes ==
            "true"
          ) {
            setprecautionsYes10("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["10"].cbNA ==
            "true"
          ) {
            setprecautionsNA10("checked");
          }

          if (
            dataLast?.genaralInformation.requiredPrecautions.list["11"].cbYes ==
            "true"
          ) {
            setprecautionsYes11("checked");
          }
          if (
            dataLast?.genaralInformation.requiredPrecautions.list["11"].cbNA ==
            "true"
          ) {
            setprecautionsNA11("checked");
          }

          setprecautionsTitle1(
            dataLast?.genaralInformation.requiredPrecautions.list["11"].title1
          );
          setprecautionsTitle2(
            dataLast?.genaralInformation.requiredPrecautions.list["11"].title2
          );
          setprecautionsTitle3(
            dataLast?.genaralInformation.requiredPrecautions.list["11"].title3
          );
          //Verify

          if (dataLast?.genaralInformation.verify.list["0"].cbYes == "true") {
            setverifyYes0("checked");
            // alert(verifyYes0);
          }
          if (dataLast?.genaralInformation.verify.list["0"].cbNA == "true") {
            setverifyNA0("checked");
          }

          if (dataLast?.genaralInformation.verify.list["1"].cbYes == "true") {
            setverifyYes1("checked");
          }
          if (dataLast?.genaralInformation.verify.list["1"].cbNA == "true") {
            setverifyNA1("checked");
          }

          if (dataLast?.genaralInformation.verify.list["2"].cbYes == "true") {
            setverifyYes2("checked");
          }
          if (dataLast?.genaralInformation.verify.list["2"].cbNA == "true") {
            setverifyNA2("checked");
          }

          if (dataLast?.genaralInformation.verify.list["3"].cbYes == "true") {
            setverifyYes3("checked");
          }
          if (dataLast?.genaralInformation.verify.list["3"].cbNA == "true") {
            setverifyNA3("checked");
          }

          if (dataLast?.genaralInformation.verify.list["4"].cbYes == "true") {
            setverifyYes4("checked");
          }
          if (dataLast?.genaralInformation.verify.list["4"].cbNA == "true") {
            setverifyNA4("checked");
          }

          if (dataLast?.genaralInformation.verify.list["5"].cbYes == "true") {
            setverifyYes5("checked");
          }
          if (dataLast?.genaralInformation.verify.list["5"].cbNA == "true") {
            setverifyNA5("checked");
          }

          if (dataLast?.genaralInformation.verify.list["6"].cbYes == "true") {
            setverifyYes6("checked");
          }
          if (dataLast?.genaralInformation.verify.list["6"].cbNA == "true") {
            setverifyNA6("checked");
          }

          if (dataLast?.genaralInformation.verify.list["7"].cbYes == "true") {
            setverifyYes7("checked");
          }
          if (dataLast?.genaralInformation.verify.list["7"].cbNA == "true") {
            setverifyNA7("checked");
          }

          if (dataLast?.genaralInformation.verify.list["7"].cbYes == "true") {
            setverifyYes8("checked");
          }
          if (dataLast?.genaralInformation.verify.list["7"].cbNA == "true") {
            setverifyNA8("checked");
          }

          //Air Monitoring
          if (
            dataLast?.genaralInformation.airMonitoring.list["0"].cbValue ==
            "true"
          ) {
            setamGenaral("checked");
          }
          if (
            dataLast?.genaralInformation.airMonitoring.list["0"].cbValue ==
            "true"
          ) {
            setamInitial("checked");
          }
          if (
            dataLast?.genaralInformation.airMonitoring.list["0"].cbValue ==
            "true"
          ) {
            setamContinues("checked");
          }

          //AIR MONITOR EQUIPMENT
          setamEqipment0(
            dataLast?.genaralInformation.airMonitoring.airMonitor["0"]
              .airMonitorEqipment
          );
          //alert(dataLast?.genaralInformation.airMonitoring.airMonitor['0'].bumpTestPass);
          if (
            dataLast?.genaralInformation.airMonitoring.airMonitor["0"]
              .bumpTestPass == "true"
          ) {
            //alert('Yes In');
            setamPass0("checked");
          }
          if (
            dataLast?.genaralInformation.airMonitoring.airMonitor["0"]
              .bumpTestfail == "true"
          ) {
            setamFail0("checked");
          }
          setamLocation0(
            dataLast?.genaralInformation.airMonitoring.airMonitor["0"].locations
          );
          // alert(dataLast?.genaralInformation.airMonitoring.airMonitor['0'].name);
          setamName0(
            dataLast?.genaralInformation.airMonitoring.airMonitor["0"].name
          );

          if (
            dataLast?.genaralInformation.airMonitoring.airMonitor["0"].result ==
            "true"
          ) {
            setamResult0("checked");
          }

          setamEqipment1(
            dataLast?.genaralInformation.airMonitoring.airMonitor["1"]
              .airMonitorEqipment
          );
          if (
            dataLast?.genaralInformation.airMonitoring.airMonitor["1"]
              .bumpTestPass == "true"
          ) {
            setamPass1("checked");
          }
          if (
            dataLast?.genaralInformation.airMonitoring.airMonitor["1"]
              .bumpTestfail == "true"
          ) {
            setamFail1("checked");
          }
          //alert(dataLast?.genaralInformation.airMonitoring.airMonitor['1'].locations);
          setamLocation1(
            dataLast?.genaralInformation.airMonitoring.airMonitor["1"].locations
          );
          setamName1(
            dataLast?.genaralInformation.airMonitoring.airMonitor["1"].name
          );
          //setamName1(dataLast[0].permitObject.genaralInformation.airMonitoring.airMonitor['1'].name);

          if (
            dataLast?.genaralInformation.airMonitoring.airMonitor["1"].result ==
            "true"
          ) {
            setamResult1("checked");
          }
          //Firewatch
          if (
            dataLast?.genaralInformation.fireWatch.fireWatch["0"].cbValue ==
            "true"
          ) {
            setfw60("checked");
          }
          if (
            dataLast?.genaralInformation.fireWatch.fireWatch["1"].cbValue ==
            "true"
          ) {
            setfw30("checked");
          }
          if (
            dataLast?.genaralInformation.fireWatch.survy["0"].cbValue == "true"
          ) {
            setfw3("checked");
          }
          //3. Firewatch Location
          //alert(dataLast?.genaralInformation.fireWatch.fireWatchLoc['0'].fireWatchName);
          setfwName0(
            dataLast?.genaralInformation.fireWatch.fireWatchLoc["0"]
              .fireWatchName
          );
          setfwLoc0(
            dataLast?.genaralInformation.fireWatch.fireWatchLoc["0"]
              .fireWatchLocation
          );

          // setfwST0(
          //   moment(
          //     dataLast?.genaralInformation.fireWatch.fireWatchLoc["0"].startTime
          //   ).format("h:mm A")
          // );

          setfwST0(
            dataLast?.genaralInformation.fireWatch.fireWatchLoc["0"].startTime
              ? moment(
                  dataLast?.genaralInformation.fireWatch.fireWatchLoc["0"]
                    .startTime
                ).format("h:mm A")
              : ""
          );

          // setfwET0(
          //   moment(
          //     dataLast?.genaralInformation.fireWatch.fireWatchLoc["0"].endTime
          //   ).format("h:mm A")
          // );
          setfwET0(
            dataLast?.genaralInformation.fireWatch.fireWatchLoc["0"].endTime
              ? moment(
                  dataLast?.genaralInformation.fireWatch.fireWatchLoc["0"]
                    .endTime
                ).format("h:mm A")
              : ""
          );

          setfwName1(
            dataLast?.genaralInformation.fireWatch.fireWatchLoc["1"]
              .fireWatchName
          );
          setfwLoc1(
            dataLast?.genaralInformation.fireWatch.fireWatchLoc["1"]
              .fireWatchLocation
          );

          // setfwST1(
          //   moment(
          //     dataLast?.genaralInformation.fireWatch.fireWatchLoc["1"].startTime
          //   ).format("h:mm A")
          // );

          setfwST1(
            dataLast?.genaralInformation.fireWatch.fireWatchLoc["1"].startTime
              ? moment(
                  dataLast?.genaralInformation.fireWatch.fireWatchLoc["1"]
                    .startTime
                ).format("h:mm A")
              : ""
          );

          // setfwET1(
          //   moment(
          //     dataLast?.genaralInformation.fireWatch.fireWatchLoc["1"].endTime
          //   ).format("h:mm A")
          // );

          setfwET1(
            dataLast?.genaralInformation.fireWatch.fireWatchLoc["1"].endTime
              ? moment(
                  dataLast?.genaralInformation.fireWatch.fireWatchLoc["1"]
                    .endTime
                ).format("h:mm A")
              : ""
          );

          // Secondary Signature

          if (dataLast?.genaralInformation.secondarySign.naValue == "true") {
            setsecondaryNA("checked");
          }

          setsecondaryName(dataLast?.genaralInformation.secondarySign.name);
          setsecondarySign(
            dataLast?.genaralInformation.secondarySign.signature
          );
          setsecondaryCompany(
            dataLast?.genaralInformation.secondarySign.company
          );
          if (
            dataLast?.genaralInformation.secondarySign.list["0"].cbValue ==
            "true"
          ) {
            setsecondaryList0("checked");
          }
          if (
            dataLast?.genaralInformation.secondarySign.list["1"].cbValue ==
            "true"
          ) {
            setsecondaryList1("checked");
          }
          if (
            dataLast?.genaralInformation.secondarySign.list["2"].cbValue ==
            "true"
          ) {
            setsecondaryList2("checked");
          }
          if (
            dataLast?.genaralInformation.secondarySign.list["3"].cbValue ==
            "true"
          ) {
            setsecondaryList3("checked");
          }
          if (
            dataLast?.genaralInformation.secondarySign.list["4"].cbValue ==
            "true"
          ) {
            setsecondaryList4("checked");
          }
          if (
            dataLast?.genaralInformation.secondarySign.list["4"].cbValue ==
            "true"
          ) {
            setsecondaryList5("checked");
          }
        }
      } else {
        // Handle the case where the response does not contain the expected data
        console.log("Response data is missing or invalid.");
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      // console.error("Error fetching data:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <body id="page-top">
      <page size="A4">
        <div style={{ textAlign: "right" }}>
          <button
            onClick={handlePrint}
            className="btn btn-primary print-hidden "
          >
            Print
          </button>
        </div>
        <div id="wrapper">
          {/* 
                <SideMenu /> */}
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              {/* <Header /> */}
              <form className="CorePermit_Web-form bodys">
                <div>
                  <div className="container">
                    <div className="row">
                      <div
                        className={`d-flex justify-content-between  align-items-center  pl-0`}
                        style={{ backgroundColor: "#cccccc", color: "#000000" }}
                      >
                        <div className="d-flex flex-row">
                          <div style={{ backgroundColor: "#FFE143" }}>
                            <div
                              class="form-check form-check-inline d-flex align-items-center"
                              style={{
                                height: "0.5px",
                                backgroundColor: "#FBE517",
                                paddingRight: "11px",
                                marginLeft: "5px",
                              }}
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                checked={generalChecked}
                                value="option2"
                              />
                              <label
                                class="form-check-label"
                                style={{ fontWeight: "bold" }}
                              >
                                GENERAL AREA
                              </label>
                            </div>
                          </div>
                          <div style={{ backgroundColor: "#ED8754" }}>
                            <div
                              class="form-check form-check-inline d-flex align-items-center"
                              style={{
                                height: "0.5px",
                                backgroundColor: "#ED8754",
                                paddingRight: "11px",
                                marginLeft: "5px",
                              }}
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox2"
                                checked={hazardChecked}
                                value="option2"
                              />
                              <label
                                class="form-check-label"
                                style={{ fontWeight: "bold" }}
                              >
                                HAZARD AREA
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {" "}
                          <p style={{ margin: "5px", fontWeight: "bold" }}>
                            EHS&S Hot Work Standard
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="d-flex flex-row"
                        style={{ margin: "0px", padding: "2px", width: "100%" }}
                      >
                        <div style={{ width: "100%" }}>
                          <div
                            className="d-flex flex-row"
                            style={{
                              backgroundColor: "#E6E8E9",
                              color: "#fff",
                            }}
                          >
                            <div
                              className="d-flex flex-row"
                              style={{
                                margin: "0px",
                                padding: "0px",
                                width: "60%",
                              }}
                            >
                              <div>
                                <h3
                                  style={{
                                    fontWeight: "bolder",
                                    fontSize: "26px",
                                    color: "#000000",
                                    padding: "6px",
                                    letterSpacing: "5px",
                                  }}
                                >
                                  HOT WORK PERMIT
                                </h3>
                              </div>
                              <div style={{ marginTop: "8px" }}>
                                <i className="firepng"></i>
                              </div>
                            </div>
                            <div style={{ width: "40%" }}>
                              <div
                                className="d-flex flex-row"
                                style={{
                                  backgroundColor: "#ffffff",
                                  margin: "5px",
                                  padding: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#000",
                                    fontSize: "12px",
                                    margin: "0px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  <p>Hot Work Permit #:</p>
                                </div>
                                <div>
                                  <div className="row">
                                    <div className="mr-2">
                                      <input
                                        type="text"
                                        className="header-txtbx"
                                        value={initialsOne}
                                      />
                                      <input
                                        type="text"
                                        className="header-txtbx"
                                        value={initialsTwo}
                                      />
                                      <input
                                        type="text"
                                        className="header-txtbx"
                                        value={initialsThree}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label className="col-12 col-form-label header-lbl">
                                      Initials
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  <div className="row">
                                    <div className="d-flex flex-row">
                                      <div className="mr-2">
                                        <input
                                          type="text"
                                          className="microsite-txtbxxdate"
                                          value={dateInputOne}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label className="col-12 col-form-label header-lbl">
                                      Date
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  <div className="row">
                                    <div>
                                      <input
                                        type="text"
                                        className="header-txtbx"
                                        value={permitsOne}
                                      />
                                      <input
                                        type="text"
                                        className="header-txtbx"
                                        value={permitsTwo}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label className="col-12 col-form-label header-lbl">
                                      #permits
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`d-flex justify-content-between  align-items-center`}
                        style={{ backgroundColor: "#34B85F", color: "#fff" }}
                      >
                        <div>
                          {" "}
                          <p style={{ margin: "5px", fontWeight: "bold" }}>
                            GENERAL INFORMATION
                          </p>
                        </div>

                        <div
                          class="form-check form-check-inline d-flex align-items-center"
                          style={{ margin: "0px", height: "0.5px" }}
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox2"
                            value="option2"
                          />
                          <label class="form-check-label">
                            Check if duplicate information from core permit
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div
                        className="d-flex flex-row"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div style={{ backgroundColor: "red", width: "40%" }}>
                          <div
                            className="d-flex flex-row"
                            style={{ padding: "2px" }}
                          >
                            <div>
                              <i className="phonerining m-1 p-1"></i>
                            </div>
                            <div>
                              <label
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                  padding: "5px",
                                }}
                              >
                                Emergency
                              </label>
                            </div>
                            <div
                              className="d-flex flex-row"
                              style={{
                                margin: "0px",
                                backgroundColor: "#ffffff",
                                padding: "4px 18px",
                              }}
                            >
                              <label class="col-3" style={{ fontSize: "9px" }}>
                                Phone <br />
                                Number
                              </label>
                              <input
                                type="text"
                                class="col-3"
                                style={{
                                  border: "none",
                                  backgroundColor: "#DEE5FF",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                                value={hotEmergency}
                              />
                              <label class="col-3" style={{ fontSize: "9px" }}>
                                Assembly <br />
                                Location
                              </label>
                              <input
                                type="text"
                                class="col-3"
                                style={{
                                  border: "none",
                                  backgroundColor: "#DEE5FF",
                                  padding: "0px",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                                value={assemblyLocation}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            margin: "2px",
                            padding: "2px",
                            width: "40%",
                          }}
                        >
                          <span
                            className="header-icons"
                            style={{ border: "1px solid #F4673C" }}
                          >
                            <img
                              src={Factory}
                              alt="img"
                              className="header-img"
                            />
                          </span>
                          <span
                            className="header-icons"
                            style={{ border: "1px solid #E0A350" }}
                          >
                            <img
                              src={HouseAndLighthouse}
                              alt="img"
                              className="header-img"
                            />
                          </span>
                          <span
                            className="header-icons"
                            style={{ border: "1px solid #34B85F" }}
                          >
                            <img
                              src={Labtubeheadercorepermit}
                              alt="img"
                              className="header-img"
                            />
                          </span>
                          <span
                            className="header-icons"
                            style={{ border: "1px solid #4275BA" }}
                          >
                            <img
                              src={shakinghandsHeadersCorepermit}
                              alt="img"
                              className="header-img"
                            />
                          </span>
                          <span
                            className="header-icons"
                            style={{ border: "1px solid #FFC85B" }}
                          >
                            <img
                              src={ImageheaderCorepermit}
                              alt="img"
                              className="header-img"
                            />
                          </span>
                          <span
                            className="header-icons"
                            style={{ border: "1px solid #793637" }}
                          >
                            <img
                              src={DroneHeaderCorepermit}
                              alt="img"
                              className="header-img"
                            />
                          </span>
                          <span
                            style={{
                              display: "inline-block",
                              border: "none",
                              padding: "3px",
                              cursor: "pointer",
                              marginRight: "10px",
                              width: "35px",
                              height: "35px",
                              marginright: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <img
                              src={CortevaImage}
                              alt="img"
                              style={{ width: "120px", height: "23px" }}
                            />
                          </span>
                        </div>

                        <div
                          style={{
                            margin: "2px",
                            padding: "2px",
                            display: "none",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                display: "inline-block",
                                border: "none",
                                padding: "3px",
                                cursor: "pointer",
                                marginRight: "10px",
                                width: "35px",
                                height: "35px",
                                marginright: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <img
                                src={CortevaImage}
                                alt="img"
                                style={{ width: "170px", height: "37px" }}
                              />
                            </span>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                    {/* <div className="card border-0">
                                            <div className="card-body pt-0 pl-1"> */}
                    <div className="row">
                      <div>
                        <div
                          className="row mt-1"
                          style={{ margin: "0px -22px" }}
                        >
                          <div>
                            <div class="form-group row frm-grp-mb">
                              <div className="d-flex flex-row">
                                <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    checked={na}
                                  />
                                  <label class="form-check-label">N/A</label>
                                </div>
                                <label class="col-3 col-form-label lbl-bg-color label-height">
                                  AUTHORIZED PROCEDURE # /NAME:
                                </label>
                                <input
                                  class="col-2"
                                  type="text"
                                  value={hotAssembly}
                                />
                                <label class="col-2 col-form-label lbl-bg-color label-height">
                                  PERMIT EXPERATION
                                </label>
                                <label class="col-1 col-form-label lbl-bg-color label-height">
                                  START time
                                </label>
                                <input
                                  class="col-1"
                                  type="text"
                                  value={hotStartTime}
                                />
                                <label class="col-1 col-form-label lbl-bg-color label-height">
                                  END time
                                </label>
                                <input
                                  class="col-1"
                                  type="text"
                                  value={hotEndTime}
                                />
                                <label class="col-form-label lbl-bg-color label-height pr-2">
                                  AM/PM
                                </label>
                              </div>
                            </div>
                            <div class="form-group row frm-grp-mb">
                              <div className="col-12 d-flex flex-row">
                                <label class="col-2 col-form-label lbl-bg-color label-height">
                                  PERMIT ISSUER NAME:
                                </label>
                                <input
                                  class="col-4"
                                  type="text"
                                  value={hotIssuerName}
                                />
                                <label class="col-2 col-form-label lbl-bg-color label-height">
                                  PERMIT ISSUER SIGNATURE
                                </label>
                                <input
                                  class="col-4"
                                  type="text"
                                  value={hotIssuerSign}
                                />
                              </div>
                            </div>
                            <div class="form-group row frm-grp-mb">
                              <div className="col-12 d-flex flex-row">
                                <label class="col-3 col-form-label lbl-bg-color label-height">
                                  PERMIT RECEIVER NAME
                                </label>
                                <label class="col-3 col-form-label lbl-bg-color label-height">
                                  SIGNATURE
                                </label>
                                <label class="col-3 col-form-label lbl-bg-color label-height">
                                  COMPANY
                                </label>
                                <label class="col-3 col-form-label lbl-bg-color label-height">
                                  PHONE/RADIO CH.#
                                </label>
                              </div>
                            </div>
                            <div class="form-group row frm-grp-mb">
                              <div className="col-12 d-flex flex-row">
                                <input class="col-3" value={hotReceiverName} />
                                <input class="col-3" value={hotReceiverSign} />
                                <input class="col-3" value={hotCompany} />
                                <input class="col-3" value={hotPhone} />
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>

                        <div className="row mt-1">
                          <div
                            className="card"
                            style={{
                              border: "1px solid #34B85F",
                              borderRadius: "0px",
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontWeight: "bold",
                                backgroundColor: "#34B85F",
                                color: "white",
                                marginTop: "0px",
                              }}
                            >
                              <p style={{ margin: "5px" }}>
                                HOT WORK INFORMATION
                              </p>
                            </div>
                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              <div>
                                {/* <div className='row mt-2'>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', backgroundColor: 'black', color: 'white', marginTop: '-18px' }}>
                                                                        <p style={{ marginTop: '10px' }}>HOT WORK INFORMATION</p>
                                                                    </div>
                                                                </div> */}
                                <div className="row">
                                  <div className="col-12">
                                    <div>
                                      <div>
                                        <div>
                                          <div>
                                            <div class="form-group row frm-grp-mb">
                                              <label class="col-2 col-form-label lbl-bg-color-hwp-hwi label-height">
                                                HOT WORK LOCATION
                                              </label>
                                              <div className="col-10">
                                                <input
                                                  class="col-12"
                                                  value={hotWorkLocation}
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb">
                                              <label class="col-2 col-form-label lbl-bg-color-hwp-hwi label-height">
                                                HOT WORK Task
                                              </label>
                                              <div className="col-10">
                                                <input
                                                  class="col-12"
                                                  value={hotWorkTask}
                                                />
                                              </div>
                                            </div>
                                            {/* <div class="form-group row frm-grp-mb">
                                                                                            <div className="col-12">
                                                                                                <textarea class="col-12" rows={1}></textarea>
                                                                                            </div>
                                                                                        </div> */}
                                            <div class="form-group row frm-grp-mb">
                                              <label class="col-1 col-form-label lbl-bg-color-hwp-hwi label-height">
                                                EQUIPMENT
                                              </label>
                                              <div className="col-11">
                                                <input
                                                  class="col-12"
                                                  value={equipment}
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group row frm-grp-mb">
                                              <label class="col-2 col-form-label lbl-bg-color-hwp-hwi label-height">
                                                FUEL HAZARD(S)
                                              </label>
                                              <div className="col-10 d-flex flex-row">
                                                <div class="form-check form-check-inline d-flex align-items-center">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    checked={fh0}
                                                  />
                                                  <label class="form-check-label">
                                                    Combustible Liquid
                                                  </label>
                                                </div>
                                                <div class="form-check form-check-inline d-flex align-items-center">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    name="answer2"
                                                    id="option2"
                                                    value="option2"
                                                    checked={fh1}
                                                  />
                                                  <label class="form-check-label">
                                                    Flammable Liquid/Gas
                                                  </label>
                                                </div>
                                                <div class="form-check form-check-inline d-flex align-items-center">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    name="answer1"
                                                    id="option1"
                                                    value="option1"
                                                    checked={fh2}
                                                  />
                                                  <label class="form-check-label">
                                                    Combustble Dust
                                                  </label>
                                                </div>
                                                <div class="form-check form-check-inline d-flex align-items-center">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    name="answer2"
                                                    id="option2"
                                                    value="option2"
                                                    checked={fh3}
                                                  />
                                                  <label class="form-check-label">
                                                    Ordinary Combustibles
                                                  </label>
                                                </div>
                                                <label className="col-2 col-form-label lbl-bg-color-hwp-hwi label-height">
                                                  TYPE OF HOT WORK
                                                </label>
                                                <div class="form-check form-check-inline d-flex align-items-center">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    name="answer1"
                                                    id="option1"
                                                    value="option1"
                                                    checked={tohw0}
                                                  />
                                                  <label class="form-check-label">
                                                    Low Energy
                                                  </label>
                                                </div>
                                                <div class="form-check form-check-inline d-flex align-items-center">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    name="answer2"
                                                    id="option2"
                                                    value="option2"
                                                    checked={tohw1}
                                                  />
                                                  <label class="form-check-label">
                                                    High Energy
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div></div>
                            </div>
                          </div>
                        </div>
                        {/* sai */}
                        <div className="row mt-1">
                          <div
                            className="card"
                            style={{
                              border: "1px solid #184DA9",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="row">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontWeight: "bold",
                                  backgroundColor: "#184DA9",
                                  color: "white",
                                  marginTop: "0px",
                                }}
                              >
                                <p style={{ margin: "5px" }}>
                                  REQUIRED PRECAUTIONS
                                </p>
                                <p style={{ margin: "5px", fontSize: "10px" }}>
                                  Determine Yes or N/A. STOP if "NO"
                                </p>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              <div>
                                <div className="row">
                                  <div className="col-12">
                                    <div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              checked={precautionsYes0}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA0}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Fire supression systems(e.g.., fire
                                            pump,control valves,sprinkler
                                            systems,etc)are in normal operator
                                            or an approval is obtained.
                                          </label>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              checked={precautionsYes1}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              checked={precautionsNA1}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Hot Work Fire Extinguisher(s) are
                                            adequate and in place (N/A for low
                                            energy).
                                          </label>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              checked={precautionsYes2}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA2}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Hot work equipment is in good
                                            working condition.
                                          </label>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsYes3}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA3}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Have nearby trenches and sewers been
                                            verified to not cause a hazard to
                                            the hot work activity?
                                          </label>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsYes4}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA4}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Proper hazard mitigation in place to
                                            reduce airborne or surface dust
                                            (e.g., clean, cover, wetting down,
                                            etc).
                                          </label>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsYes5}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA5}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Flammable and combustibles removed
                                            to 35-ft (11 m) or properly
                                            shielded/protected
                                          </label>
                                        </div>
                                      </div>

                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsYes6}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA6}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Shielding/Barricading in place to
                                            prevent sparks/slag from falling to
                                            lower level, exposed combustibles,
                                            or personnel below.
                                          </label>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsYes8}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA8}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Shut down or shield ventilation or
                                            conveying systems that may carry
                                            sparks.
                                          </label>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsYes9}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA9}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Have additional precautions been
                                            applied for combustible building
                                            roofs (e.g., Additional Fire
                                            Extinguisher, water buckets, etc)?
                                          </label>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsYes10}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA10}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label className="col-11 col-form-label lbl-bg-color label-height">
                                            Has vessel venting from thermal
                                            heating from the sun been considered
                                            and properly mitigated (e.g.,
                                            additional air monitoring, cooling
                                            of the vessel, etc)?
                                          </label>
                                        </div>
                                      </div>

                                      <div class="form-group row frm-grp-mb">
                                        <div className="d-flex flex-row">
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsYes11}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              YES
                                            </label>
                                          </div>
                                          <div class="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              className="cb-size-ms"
                                              name="response"
                                              value="Water"
                                              checked={precautionsNA11}
                                            />
                                            &nbsp;
                                            <label class="form-check-label  mt-0">
                                              N/A
                                            </label>
                                            &nbsp;&nbsp;
                                          </div>
                                          <label class="col-11 col-form-label lbl-bg-color">
                                            Is purging/padding required? PURGE
                                            GAS:
                                            <input
                                              type="text"
                                              className="cb-txtbx-border-none"
                                              style={{ width: "100px" }}
                                              value={precautionsTitle1}
                                            />
                                            Purge method:
                                            <input
                                              type="text"
                                              className="cb-txtbx-border-none"
                                              style={{ width: "100px" }}
                                              value={precautionsTitle2}
                                            />
                                            Ventilation requirements:
                                            <input
                                              type="text"
                                              className="cb-txtbx-border-none"
                                              style={{ width: "100px" }}
                                              value={precautionsTitle3}
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-1">
                          <div
                            className="card"
                            style={{
                              border: "1px solid #000",
                              borderRadius: "0px",
                            }}
                          >
                            <div
                              className="row"
                              style={{ backgroundColor: "#000" }}
                            >
                              <div
                                className={`d-flex justify-content-between  align-items-center`}
                              >
                                <div>
                                  <p style={{ margin: "5px", color: "#fff" }}>
                                    VERIFY:PERMIT ISSUER
                                  </p>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      margin: "5px",
                                      color: "#fff",
                                      backgroundColor: "#E12C1A",
                                    }}
                                  >
                                    IF NO,STOP
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              <div className="row">
                                <div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes0}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA0}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label className="col-11 col-form-label lbl-bg-color label-height">
                                        Flammable and combustibles removed to
                                        35-ft (11 m) or properly
                                        shielded/protected
                                      </label>
                                    </div>
                                  </div>

                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes1}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA1}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label class="col-11 col-form-label lbl-bg-color label-height">
                                        Workers performing hot work are trained
                                        in the safe opertion of all equipment
                                        used in the hot work task.
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes2}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA2}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label class="col-11 col-form-label lbl-bg-color label-height">
                                        Hot work personnel are knowledgeable of
                                        the risks associated with fire for the
                                        building or area worked.
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes3}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA3}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label class="col-11 col-form-label lbl-bg-color label-height">
                                        Fire Watch knows how to sound an alarm
                                        and call for emergency firefighting
                                        services.
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes4}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA4}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label class="col-11 col-form-label lbl-bg-color label-height">
                                        Inadvertent/accidental activation has
                                        been considered for fire suppresion
                                        systems,smoke detectors,duct
                                        detectors,heat detectors,or nearby
                                        sprinkler heads.
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes5}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA5}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label class="col-11 col-form-label lbl-bg-color label-height">
                                        All fuel
                                        sources(gas,flammables,dusts,fibers)have
                                        been identified and properly
                                        mitigated(e.g..,distance,clean,purge,shielded).
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes6}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA6}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label class="col-11 col-form-label lbl-bg-color label-height">
                                        Hazards of conduits,pipes,valves,and
                                        tanks reviewed(e.g..,damage to gaskets
                                        or materials with in)
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes7}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA7}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label class="col-11 col-form-label lbl-bg-color label-height">
                                        Equipment determined not intrinscially
                                        safe or explosion proof for the
                                        hazardous area location in the Equipment
                                        section of the permit.
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div className="d-flex flex-row">
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyYes8}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          YES
                                        </label>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="cb-size-ms"
                                          checked={verifyNA8}
                                        />
                                        &nbsp;
                                        <label class="form-check-label  mt-0">
                                          N/A
                                        </label>
                                        &nbsp;&nbsp;
                                      </div>
                                      <label class="col-11 col-form-label lbl-bg-color label-height">
                                        Verify that the isolation system and/or
                                        a method will result in
                                        zero(0%)LFL(lower flammable limit)and
                                        zero equivalent for dust.
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div
                            className="card"
                            style={{
                              border: "1px solid #34B85F",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="row">
                              <div
                                style={{
                                  fontWeight: "bold",
                                  backgroundColor: "#34B85F",
                                  color: "white",
                                  marginTop: "0px",
                                }}
                              >
                                {/* <p style={{ margin: '10px' }}>5.CHEMICAL</p> */}
                                <div className="row">
                                  <div className="col-2 mt-1">
                                    <div class="form-group row frm-grp-mb">
                                      <p
                                        style={{
                                          fontSize: "10px",
                                          margin: "0px",
                                        }}
                                      >
                                        AIR MONITORING
                                      </p>
                                      <div class="col-12">
                                        <div class="form-check form-check-inline d-flex align-items-center ml-2">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            checked={amGenaral}
                                          />
                                          <label class="form-check-label">
                                            N/A General Area or dust
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div class="form-group row frm-grp-mb">
                                      <div class="col-12">
                                        <div class="form-check form-check-inline d-flex align-items-center">
                                          <input
                                            class="form-check-input cb-mt-5px"
                                            type="checkbox"
                                            checked={amInitial}
                                          />
                                          <label class="form-check-label">
                                            Intial/survey-Low Energy(Hazardous
                                            Area)
                                          </label>
                                        </div>
                                        <div class="form-check form-check-inline d-flex align-items-center">
                                          <input
                                            class="form-check-input cb-mt-5px"
                                            type="checkbox"
                                            checked={amContinues}
                                          />
                                          <label class="form-check-label label-height">
                                            Continuous-Mandatory for High
                                            Energy(Hazardous Area)
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div>
                                      <div class="form-group row frm-grp-mb mr-2">
                                        <div
                                          class="col-12"
                                          style={{
                                            display: "grid",
                                            alignItems: "end",
                                            direction: "rtl",
                                          }}
                                        >
                                          <label class="form-check-label">
                                            Monitor for dust is performed by
                                            visual observation
                                          </label>

                                          <label class="form-check-label">
                                            <p>STOP WORKING ABOVE 0% LFL</p>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              <div className="row">
                                <div
                                  className="col-12"
                                  style={{ margin: "0px", padding: "0px" }}
                                >
                                  <table
                                    class="table hwptbl ame"
                                    style={{ width: "100%" }}
                                  >
                                    <thead class="thead-dark">
                                      <tr>
                                        <th>
                                          <label class="col-12 col-form-label">
                                            AIR MONITOR EQUIPMENT
                                          </label>
                                        </th>
                                        <th>
                                          <label class="col-12 col-form-label">
                                            BUMP TEST
                                          </label>
                                        </th>
                                        <th>
                                          <label class="col-12 col-form-label">
                                            LOCATION(S)
                                          </label>
                                        </th>
                                        <th>
                                          <label class="col-12 col-form-label">
                                            NAME
                                          </label>
                                        </th>
                                        <th>
                                          <label class="col-12 col-form-label">
                                            RESULT
                                          </label>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input
                                            class="col-12"
                                            value={amEqipment0}
                                          />
                                        </td>

                                        <td>
                                          <div class="form-group row frm-grp-mb">
                                            <div>
                                              <div class="col-6 form-check form-check-inline">
                                                <input
                                                  class="form-check-input mt-1"
                                                  type="checkbox"
                                                  checked={amPass0}
                                                />
                                                <label class="form-check-label">
                                                  PASS
                                                </label>
                                              </div>
                                              <div class="col-6 form-check form-check-inline">
                                                <input
                                                  class="form-check-input mt-1"
                                                  type="checkbox"
                                                  checked={amFail0}
                                                />
                                                <label class="form-check-label">
                                                  FAIL
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          <input
                                            class="col-12"
                                            value={amLocation0}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            class="col-12"
                                            value={amName0}
                                          />
                                        </td>

                                        <td>
                                          <div class="form-group row frm-grp-mb">
                                            <div>
                                              <div class="col-12 form-check form-check-inline">
                                                <input
                                                  class="form-check-input mt-1"
                                                  type="checkbox"
                                                  checked={amResult0}
                                                />
                                                <label class="form-check-label">
                                                  0% LFL
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <input
                                            class="col-12"
                                            value={amEqipment1}
                                          />
                                        </td>

                                        <td>
                                          <div class="form-group row frm-grp-mb">
                                            <div>
                                              <div class="col-6 form-check form-check-inline">
                                                <input
                                                  class="form-check-input mt-1"
                                                  type="checkbox"
                                                  checked={amPass1}
                                                />
                                                <label class="form-check-label">
                                                  PASS
                                                </label>
                                              </div>
                                              <div class="col-6 form-check form-check-inline">
                                                <input
                                                  class="form-check-input mt-1"
                                                  type="checkbox"
                                                  checked={amFail1}
                                                />
                                                <label class="form-check-label">
                                                  FAIL
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          <input
                                            class="col-12"
                                            value={amLocation1}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            class="col-12"
                                            value={amName1}
                                          />
                                        </td>

                                        <td>
                                          <div class="form-group row frm-grp-mb">
                                            <div>
                                              <div class="col-12 form-check form-check-inline">
                                                <input
                                                  class="form-check-input mt-1"
                                                  type="checkbox"
                                                  checked={amResult1}
                                                />
                                                <label class="form-check-label">
                                                  0% LFL
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Euip and Chem end */}

                        <div className="row mt-1">
                          <div
                            className="card"
                            style={{
                              border: "1px solid #DF3A1C",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="row">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontWeight: "bold",
                                  backgroundColor: "#DF3A1C",
                                  color: "white",
                                  marginTop: "0px",
                                }}
                              >
                                <p style={{ margin: "5px" }}>FIRE WATCH</p>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              <div>
                                <div>
                                  <div>
                                    <div className="m-2">
                                      <div class="form-group row frm-grp-mb">
                                        <label className="col-1 col-form-label label-height">
                                          FIRE WATCH
                                        </label>
                                        <div className="col-11">
                                          <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              checked={fw60}
                                            />
                                            <label class="form-check-label">
                                              60 minutes:default fire watch
                                              intended timeframe
                                            </label>
                                          </div>
                                          <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              checked={fw30}
                                            />
                                            <label class="form-check-label">
                                              30 minutes/low rusk reduced
                                              timeframe.No exposure to
                                              combustible building structure.No
                                              combustbles materials subject to
                                              smoldering or delayed igntion
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group row frm-grp-mb">
                                        <label className="col-1 col-form-label label-height">
                                          SURVEY
                                        </label>
                                        <div className="col-11">
                                          <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              checked={fw3}
                                            />
                                            <label class="form-check-label">
                                              3-hour periodic/High Risk:very
                                              combustible material,crvasse,or
                                              hidden pockets,Periodic review of
                                              location after Fire watch ends for
                                              3 hours,walking by occasionally to
                                              look for smoldering fire or smell
                                              of smoke
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div
                                        className="col-12"
                                        style={{
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        <table
                                          class="table hwptbl"
                                          style={{ width: "100%" }}
                                        >
                                          <thead class="thead-dark">
                                            <tr>
                                              <th>
                                                <label class="col-12 col-form-label">
                                                  Fire Watch Loaction
                                                </label>
                                              </th>
                                              <th colSpan={2}>
                                                <label class="col-12 col-form-label">
                                                  Fire Watch Start Time
                                                </label>
                                              </th>
                                              <th colSpan={2}>
                                                <label class="col-12 col-form-label">
                                                  Fire Watch End Time{" "}
                                                </label>
                                              </th>
                                              <th>
                                                <label class="col-12 col-form-label">
                                                  Fire Watch Name
                                                </label>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <input
                                                  class="col-12"
                                                  value={fwLoc0}
                                                />
                                              </td>

                                              <td>
                                                <label class="col-12 col-form-label">
                                                  START TIME
                                                </label>
                                              </td>

                                              <td>
                                                <input
                                                  class="col-12"
                                                  placeholder=""
                                                  value={fwST0}
                                                />
                                              </td>
                                              <td>
                                                <label class="col-12 col-form-label">
                                                  END TIME
                                                </label>
                                              </td>
                                              <td>
                                                <input
                                                  class="col-12"
                                                  value={fwET0}
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  class="col-12"
                                                  value={fwName0}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  class="col-12"
                                                  value={fwLoc1}
                                                />
                                              </td>

                                              <td>
                                                <label class="col-12 col-form-label">
                                                  START TIME
                                                </label>
                                              </td>

                                              <td>
                                                <input
                                                  class="col-12"
                                                  placeholder=""
                                                  value={fwST1}
                                                />
                                              </td>
                                              <td>
                                                <label class="col-12 col-form-label">
                                                  END TIME
                                                </label>
                                              </td>
                                              <td>
                                                <input
                                                  class="col-12"
                                                  value={fwET1}
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  class="col-12"
                                                  value={fwName0}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div
                            className="card"
                            style={{
                              border: "1px solid #FF7E51",
                              borderRadius: "0px",
                            }}
                          >
                            <div className="row">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontWeight: "bold",
                                  backgroundColor: "#FF7E51",
                                  color: "white",
                                  margin: "0px",
                                }}
                              >
                                <div
                                  class="d-flex flex-row"
                                  style={{ height: "25.5px" }}
                                >
                                  {/* <div class="form-check form-check-inline d-flex align-items-center" style={{ margin: "0px", height: "0.5px" }}>
                                                                        <input class="form-check-input"
                                                                         type="checkbox" 
                                                                       
                                                                        checked={secondaryNA}
                                                                       />
                                                                         <label class="form-check-label" >N/A</label> 
                                                                    </div> */}
                                  <div className="mt-1 ml-1">
                                    <p style={{ fontSize: "10px" }}>
                                      SECONDARY SIGNATURE PERMIT ISSUER
                                    </p>
                                  </div>
                                  <div className="mt-2 ml-2">
                                    <p style={{ fontSize: "8px" }}>
                                      (High Energy Hot Work in Hazardous Area
                                      Only)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              <div className="row">
                                <div
                                  className="col-12"
                                  style={{ margin: "0px", padding: "0px" }}
                                >
                                  <table
                                    class="table hwptbl"
                                    style={{ width: "100%" }}
                                  >
                                    <thead class="thead-dark">
                                      <tr>
                                        <th scope="col">
                                          <label class="col-12 col-form-label">
                                            NAME
                                          </label>
                                        </th>
                                        <th scope="col">
                                          <label class="col-12 col-form-label">
                                            SIGNATURE
                                          </label>
                                        </th>
                                        <th scope="col">
                                          <label class="col-12 col-form-label">
                                            COMPANY{" "}
                                          </label>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input
                                            class="col-12"
                                            value={secondaryName}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            class="col-12"
                                            value={secondarySign}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            class="col-12"
                                            value={secondaryCompany}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row ml-1">
                                <div className="col-12 d-flex flex-row">
                                  <div class="form-group row frm-grp-mb">
                                    <div class="col-12 d-flex flex-column">
                                      <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input cb-mt-5px"
                                          type="checkbox"
                                          checked={secondaryList0}
                                        />
                                        <label class="form-check-label">
                                          The igntion source is identified.
                                        </label>
                                      </div>
                                      <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input cb-mt-5px"
                                          type="checkbox"
                                          checked={secondaryList1}
                                        />
                                        <label class="form-check-label">
                                          The fuel source is identified.
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div class="col-12 d-flex flex-column">
                                      <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input cb-mt-5px"
                                          type="checkbox"
                                          checked={secondaryList2}
                                        />
                                        <label class="form-check-label">
                                          Controls are correct(e.g
                                          cleaning,purging,distance,shielding).
                                        </label>
                                      </div>

                                      <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input cb-mt-5px"
                                          type="checkbox"
                                          checked={secondaryList3}
                                        />
                                        <label class="form-check-label">
                                          Air monitoring and locations are
                                          identified.
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row frm-grp-mb">
                                    <div class="col-12 d-flex flex-column">
                                      <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input cb-mt-5px"
                                          type="checkbox"
                                          checked={secondaryList4}
                                        />
                                        <label class="form-check-label">
                                          The fuel hazard and area reviewed.
                                        </label>
                                      </div>
                                      <div class="form-check form-check-inline form-checks d-flex align-items-center">
                                        <input
                                          class="form-check-input cb-mt-5px"
                                          type="checkbox"
                                          checked={secondaryList5}
                                        />
                                        <label class="form-check-label">
                                          The work area is prepared for hot
                                          work.
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* work envi end */}
                    </div>
                    {/*  Clseout end */}
                  </div>
                </div>

                {/* <Footer /> */}
              </form>
              {/* ... End of Footer...  */}
            </div>
          </div>
          {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
          {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
        </div>
        {isLoading && (
          <Loader
            loading={isLoading}
            message={"Fetching Data. Please Wait..!"}
            loderimage={loaderImage}
          />
        )}
      </page>
    </body>
  );
};

export default HotWorkPermit2_Web;
