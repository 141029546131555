import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import downarrowwhite from '../images/Down-arrow-white.png'
import uparrowwhite from '../images/Up-arrow-white.png';
//import TestAccordian from "../Pages/TestAccordian";

import Circle from '../images/CircleAccordianImg.png'

 const ButtonAccordion = ({ title, content, bgColor,buttonData,bordercolor }) => {

   // const [styles, setStyles] = useState(buttonData)
   const [activeIndex, setActiveIndex] = useState(null);
   const toggleItem = (index) => {
    // debugger;
     setActiveIndex(index === activeIndex ? null : index);
   };
   
    const [isOpen, setIsOpen] = useState(false);

    const bgColorT = (bgColor != '') ? bgColor : 'red';
    
   // const bgColorT = (bgColor != '') ? bgColor : 'red';

    const toggleAccordion = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    const buttonStyle = {
        display: "inline-block",
        border: "none",
        padding: "12px",
        cursor: "pointer",
        marginRight: "10px",
         border:"medium",
         backgroundrepeat: "no-repeat",
         backgroundposition: "center",
         backgroundsize: "45px",
         width: "46px",
         height: "50px",
         marginright: "10px",
      };
    // const imageStyle = {
    //     width: `${imageData.width}px`,
    //     height: `${imageData.height}px`,
    //   };
      
    return (
        <div className={`mb-4`}>
            <button
                // className={`d-flex align-items-center w-100 py-2 px-2 font-weight-bold btn btn-light text-white ${isOpen ? "custom-rounded-top" : "custom-rounded"}`} style={{backgroundColor:bordercolor}}
                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${isOpen ? "custom-rounded-top" : "custom-rounded"}`} style={{height:"55px",backgroundColor:bordercolor,border:"none"}}

                onClick={toggleAccordion}
            >
                <span></span>
                {/* <i> */}
                {/* <span className={`transform transition-transform m-2 ${isOpen ? "rotate-180" : ""}`}>
                    {isOpen ? (
                        <img src={uparrowwhite} alt="Open" className="inline-block" style={{width:"40px"}} />
                    ) : (
                        <img src={downarrowwhite} alt="Close" className="inline-block" style={{width:"40px"}} />
                    )}
                </span> */}
                <span style={{ fontSize: '16px', lineHeight: '24px',alignContent:"center"}}>
                    {title} 
                </span>
            {/* </i> */}
  {/* <i>

  {buttonData ? (
    buttonData.map((data, index) => (
        // background: data.backgroundImage
     // <span key={index} style={{display: "inline-block",border: "none",padding: "12px",cursor: "pointer",marginRight: "10px",border:"medium",backgroundrepeat: "no-repeat", backgroundposition: "center",backgroundsize: "45px !Important",width: "46px",height: "50px",marginright: "10px" }}>
        <span key={index} style={{borderRadius:"32px",display: "inline-block",border:"1px solid white",padding: "3px",cursor: "pointer",marginRight: "10px",width: "35px",height: "35px",marginright: "10px" }}>
        <img
          src={data.imageData.imageUrl}
          alt={`Image ${index + 1}`}
          style={{ width: `${data.imageData.width}px`, height: `${data.imageData.height}px` }}
        />
      </span>
    ))
  ) : (
    <span></span>
  )}
       </i>   */}


          
                
            </button>
            
            {isOpen && (
                <div className="p-4" style={{ fontSize: '16px', fontWeight: 'normal', lineHeight: '24px',border:`2px solid ${bordercolor}`,bordertop:"none",borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px" }}>
                    {content}
                </div>
            )}

{/*          
            {isOpen && (
                <div className="border-top border-gray-300 pt-4 pb-4 text-success" style={{ fontSize: '16px', fontWeight: 'normal', lineHeight: '24px' }}>
                    {content}
                </div>
            )} */}



        </div>
    );
};

export default ButtonAccordion;
