import React, { useState, useEffect, useRef } from "react";
import { getNewListWithModifyChecked } from "../../Utilities/utils";

function Equipment(props) {
    // Initialize the state for checkboxes with default values
    const [type, setType] = useState(props.data.type);
    const handleInformation = (key, val) => {
        props.setEquipmentInf((prev) => ({ ...prev, [key]: val }))
    }
    // Function to update the state when a checkbox is checked or unchecked
    const handleCheckboxChange = (index, val) => {
        const updatedArr = props.data.type.map((i, j) => {
            if (j == index) {
                return {
                    ...i, checked: val
                }
            } else {
                return i
            }
        })
        props.setEquipmentInf((prev) => ({ ...prev, type: updatedArr }))
    };

    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">


                    <div>
                        <div class="form-group">
                            <label for="inputText">Size/Capacity(Tons) <span style={{ color: "red" }}> *</span> </label>
                            <div>
                                {/* <input class="form-control" id="sc" placeholder=""
                                    value={props.data?.size_or_capacity_in_tons}
                                    onChange={(e) => handleInformation('size_or_capacity_in_tons', e.target.value)} /> */}
                                <input
                                    class="form-control"
                                    id="sc"
                                    placeholder=""
                                    value={props.data?.size_or_capacity_in_tons}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const regex = /^[A-Za-z0-9 ]*$/; // Regular expression to allow letters, numbers, and spaces

                                        if (regex.test(inputValue)) {
                                            handleInformation('size_or_capacity_in_tons', inputValue);
                                        }
                                    }}
                                />




                            </div>
                        </div>


                        <div class="form-group">
                            <label for="inputText">Make/Model</label>
                            <div>
                                <input class="form-control" id="sc" placeholder=""
                                    value={props.data?.make_or_model}
                                    onChange={(e) => handleInformation('make_or_model', e.target.value)} />
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="inputText">Serial #</label>
                            <div>
                                <input class="form-control" id="sc" placeholder=""
                                    value={props.data?.searal}
                                    onChange={(e) => handleInformation('searal', e.target.value)} />
                            </div>
                        </div>

                     
                        <label  class="col-12 col-form-label"><b>Type</b></label>
                        {props.data.type.map((item, index) => (
                            <div className="form-group row" key={index}>
                                <div className="col-12">

                                    <div className="form-check form-check-inline">

                                        <input
                                            className="form-check-input mt-1"
                                            type="checkbox"
                                            id={`inlineCheckbox${index}`}
                                            cbTitle={item.cbTitle}
                                            checked={item.checked}
                                            onChange={() => {
                                                handleCheckboxChange(index, !item.checked);
                                            }}
                                        />
                                        <label className="form-check-label mt-0" htmlFor={`inlineCheckbox${index}`}>
                                            {item.cbTitle}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div class="form-group">
                            <label for="inputText">Scope of work</label>
                            <div>
                                <input class="form-control" id="sc" placeholder=""
                                    value={props.data?.scopeOfWork}
                                    onChange={(e) => handleInformation('scopeOfWork', e.target.value)} />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    )

};
export default Equipment;


