import React, { useState, useEffect, useRef } from "react";
import "datatables.net";
import { Link, useSearchParams, useNavigate, json } from "react-router-dom";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
//import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getCorePermitFormByNumber,
  saveCorePermitForm,
  ApproveRejectPermit,
  transferCorePermit,
  getAllPermitsByCorePermitID,
} from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import {
  corePermitInitialsConst,
  coreChemicalConstant,
  coreEmergencyConst,
  coreEnergyConstant,
  environMentConstants,
  CloseOutConstants,
  changeConstants,
  responsibilitites,
  corePermitReceiverConstants,
  trainingConstants,
  coreEquipmentConstant,
  corePeopleConstant,
  coreProtectionConstant,
  coreWorkEnvConstant,
} from "../Utilities/corePermit.constant";
import CorePermitM2People from "../components/corePermitM2/CorePermitM2People";
import CorePermitEmergency from "../components/corePermitM2/corePermitEmergency";
import CorePermitM2Energy from "../components/corePermitM2/CorePermitM2Energy";
import CorePermitM2Equipment from "../components/corePermitM2/CorePermitM2Equipment";
import CorePermitM2Chemical from "../components/corePermitM2/CorePermitM2Chemical";
import CorePermitM2Protection from "../components/corePermitM2/CorePermitM2Protection";
import CorePermitM2Work from "../components/corePermitM2/CorePermitM2Work";
import CorePermitEnvironment from "../components/corePermitM2/CorePermitEnvironment";
import CorePermitCloseOut from "../components/corePermitM2/corePermitCloseOut";
import CorePermitChange from "../components/corePermitM2/corePermitChange";
import CorePermitResponsibilities from "../components/corePermitM2/corePermitResponsibilities";
import CorePermitReceiver from "../components/corePermitM2/corePermitReceiver";
import CorePermitTraining from "../components/corePermitM2/corePermitTraining";
import CorePermitInputFields from "../components/corePermitM2/corePermitInputFields";
import arrowIcon from "../images/Left-arrow.png";
import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  CorePermitM2_PERMITID,
  MOBILE_NUMBER,
  USER_PROFILE,
  retrieveData,
  ADDITIONAL_PERMITID,
  PERMIT_NAME,
  checkForEmptyValues,
} from "../Utils";
// import { USER_PROFILE, retrieveData } from "../Utils";
import moment from "moment";
import Accordion from "../Accordation/Accordian";

//import FireImg from '../images/FireAccordionImg.png'
//import Thunder from '../images/Thunder.png'
import Circle from "../images/CircleAccordianImg.png";

//people-images
import teachpeople from "../images/Teach-People-CorePermit.png";
import stepbysteppeople from "../images/Step-by-step-People-Corepermit.png";
import stalline from "../images/Stalline-People-Corepermit.png";
//environment-images
import envi1 from "../images/Window-man-enivron-corepermit.png";
import suit from "../images/suit-man-envi-corepermit.png";
import globe from "../images/Hand-glob-envi.png";
//Energy -images
import FireImg from "../images/FireAccordionImg.png";
import Thunder from "../images/Thunder.png";
import water from "../images/waterdrop-energy-corepermit.png";
//Equipment-Images
import Car from "../images/Car-euip-corepermit.png";
import cropmachine from "../images/cropmachine-euip-corepermit.png";
import van from "../images/Van-equip-corepermit.png";
//Chemical -Images
import spraytracktor from "../images/spray-tractor-chem-corepermit.png";
import buerate from "../images/Buret-chem-corepermit.png";
import tine from "../images/tin-chem-corepermit.png";
//Change-images
import couple from "../images/couple-change-corepermit.png";
import cloudthunder from "../images/cloud-thunder-change-corepermit.png";
import chart from "../images/chartlist-change-corepermit.png";
import sun from "../images/sun-change-corepermit.png";
import wave from "../images/Wave-change-corepermit.png";

//Approve
import Approved from "../images/Approved.png";
import Reject from "../images/Reject.png";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
//import Loader_MS from "../Utilities/Loader_MS";
import Loader_Bulk from "../Utilities/Loader_Bulk";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronCircleRight } from "@fortawesome/free-regular-svg-icons";

const CorePermitM2Acceptor = (props) => {
  const peopleImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: teachpeople,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: stepbysteppeople,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: stalline,
        width: 20,
        height: 20,
      },
    },
  ];
  const enviImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: envi1,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: suit,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: globe,
        width: 20,
        height: 20,
      },
    },
  ];
  const energyImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: FireImg,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: Thunder,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: water,
        width: 20,
        height: 20,
      },
    },
  ];
  const equipImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: Car,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: van,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: cropmachine,
        width: 20,
        height: 20,
      },
    },
  ];
  const chemiImg = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: spraytracktor,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: buerate,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: tine,
        width: 20,
        height: 20,
      },
    },
  ];
  const changeimages = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: couple,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: chart,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: cloudthunder,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: sun,
        width: 20,
        height: 20,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: wave,
        width: 20,
        height: 20,
      },
    },
  ];
  const buttonData = [
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: FireImg,
        width: 30,
        height: 25,
      },
    },
    {
      backgroundImage: `url(${Circle})`,
      imageData: {
        imageUrl: Thunder,
        width: 30,
        height: 25,
      },
    },
  ];
  const [formData, setFormData] = useState({
    name: "",
    sign: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [emergency, setEmergency] = useState(coreEmergencyConst);
  const [people, setPeople] = useState(corePeopleConstant);
  const [energy, setEnergy] = useState(coreEnergyConstant);
  const [equipment, setEquipment] = useState(coreEquipmentConstant);
  const [chemical, setChemical] = useState(coreChemicalConstant);
  const [protection, setProtection] = useState(coreProtectionConstant);
  const [workEnvironment, setWorkEnvironment] = useState(coreWorkEnvConstant);
  const [environmentData, setEnvironMentData] = useState(environMentConstants);
  const [closeOutData, setCloseOutData] = useState(CloseOutConstants);
  const [changeData, setChangeData] = useState(changeConstants);
  const [respdata, setRespData] = useState(responsibilitites);
  const [recData, setRecData] = useState(corePermitReceiverConstants);
  const [trainData, setTrainData] = useState(trainingConstants);
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [serverRecordId, setServerRecordId] = useState(null);
  //Save
  const [showModal, setShowModal] = useState(false);
  //Transfer
  const [showTransfer, setShowTransfer] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  //Reject
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEdit, setIsEdit] = useState(false);
  const [corePermitID, setcorePermitID] = useState("");
  const [mb, setMB] = useState("");
  const [permitStatus, setPermitStatus] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [permitObject, setPermitObject] = useState(null);
  const IdFromURL = searchParams.get("corePermitID");
  const PermitNamefromURL = searchParams.get("permitName");
  const PermitUserIdfromURL = searchParams.get("acceptorUserId");
  const AditionpermitIDFromURL = searchParams.get("additionalPermitID");

  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);
    //const IdFromURLwithEDit = searchParams.get('corePermitId');
    const IdFromURL = searchParams.get("corePermitID");
    var isEdit = "true";
    setIsEdit(isEdit);
    // alert(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0
    );
    return () => {};
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);
    var filteredArray = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }
  useEffect(() => {}, [isExpanded]);

  const getFormData_backup = async (id) => {
    // const dataFromStore = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
    const dataFromStore = await retrieveData(USER_PROFILE);
    const response = await axios.post(
      getCorePermitFormByNumber(dataFromStore, {})
    );
    console.log("id:::", id);
    console.log("responseFFFF", JSON.stringify(response.data.response));
    var dataLast = await filterArray(response.data.response, "id", id);
    console.log("filteredArray checking", JSON.stringify(dataLast));
    console.log("dataLast", dataLast);
    console.log(response.serverRecordId);
    setPermitInitials(
      dataLast.permitInitials ? dataLast?.permitInitials : permitInitials
    );
    setPeople(dataLast?.people ? dataLast?.people : people);
    setEnergy(dataLast.energy ? dataLast.energy : energy);
    setEquipment(dataLast.equipment ? dataLast.equipment : equipment);
    setChemical(dataLast.chemical ? dataLast.chemical : chemical);
    setProtection(dataLast.protection ? dataLast.protection : protection);
    setWorkEnvironment(
      dataLast.workEnvironment ? dataLast.workEnvironment : workEnvironment
    );
    setEnvironMentData(
      dataLast.environment ? dataLast.environment : environmentData
    );
    setCloseOutData(dataLast.closeout ? dataLast.closeout : closeOutData);
    setChangeData(dataLast.change ? dataLast.change : changeData);
    setRespData(
      dataLast.responsibilities ? dataLast.responsibilities : responsibilitites
    );
    setRecData(
      dataLast.corePermitReceiver ? dataLast.corePermitReceiver : recData
    );
    setTrainData(dataLast.training ? dataLast.training : trainData);
    setServerRecordId(dataLast.serverRecordId);
  };

  const getFormData = async (permitID) => {
    const dataFromStore = await retrieveData(USER_PROFILE);
    const headers = await GetApiHeaders();
    const dataToSend = {
      mobileNumber: headers.mobileNumber == null ? "" : headers.mobileNumber,
      name: "",
      PermitID: IdFromURL,
    };
    setIsLoading(true);
    //   const response = await axios.post(getCorePermitFormByNumber(dataFromStore.mobileNumber, {}))
    const response = await axios.post(getAllPermitsByCorePermitID, dataToSend, {
      headers,
    });
    // console.log("responseFFFF", JSON.stringify(response.data.response))
    console.log("id:::", dataFromStore);
    if (response.data.statusCode == 200) {
      if (
        response.data.response != undefined &&
        response.data.response != null
      ) {
        // alert("response")
        console.log(
          "response123:::",
          JSON.stringify(response.data.response.core_permit)
        );
        if (
          response.data.response.core_permit != null &&
          response.data.response.core_permit.length > 0
        ) {
          setIsExpanded(
            response.data.response.core_permit.find(
              (item) => item.permitID === IdFromURL
            )?.permitStatus == "Approve"
              ? true
              : false
          );
          setPermitStatus(
            response.data.response.core_permit.find(
              (item) => item.permitID === IdFromURL
            )?.permitStatus
          );
        }
        var data = await filterArray(
          response.data.response.core_permit,
          "permitID",
          IdFromURL
        ); //113
        console.log("data:::", JSON.stringify(data));
        var dataLast =
          data.permitObject != undefined && data.permitObject != null
            ? data.permitObject
            : undefined;
        if (dataLast != undefined) {
          console.log("dataLast:::::::::::::::::::", JSON.stringify(dataLast));
          setPermitObject(dataLast);
          console.log(response.serverRecordId);
          setPermitInitials(
            dataLast.permitInitials ? dataLast?.permitInitials : permitInitials
          );
          setEmergency(dataLast.emergency ? dataLast.emergency : emergency);
          setPeople(dataLast?.people ? dataLast?.people : people);
          setEnergy(dataLast.energy ? dataLast.energy : energy);
          setEquipment(dataLast.equipment ? dataLast.equipment : equipment);
          setChemical(dataLast.chemical ? dataLast.chemical : chemical);
          setProtection(dataLast.protection ? dataLast.protection : protection);
          setWorkEnvironment(
            dataLast.workEnvironment
              ? dataLast.workEnvironment
              : workEnvironment
          );
          setEnvironMentData(
            dataLast.environmentData
              ? dataLast.environmentData
              : environmentData
          );
          setCloseOutData(
            dataLast.closeOutData ? dataLast.closeOutData : closeOutData
          );
          setChangeData(dataLast.changeData ? dataLast.changeData : changeData);
          setRespData(
            dataLast.respdata ? dataLast.respdata : responsibilitites
          );
          setRecData(
            dataLast.recData ? dataLast.recData : corePermitReceiverConstants
          );
          setTrainData(dataLast.trainData ? dataLast.trainData : trainData);
          setServerRecordId(dataLast.serverRecordId);
          setTimeout(() => {
            console.log("emergency :: ", JSON.stringify(emergency));
          }, 5000);

          console.log("people :: ", JSON.stringify(people));
          console.log("energy : ", JSON.stringify(energy));
          console.log("equipment : ", JSON.stringify(equipment));
          console.log("chemical : ", JSON.stringify(chemical));
        }
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTransferTo = (e) => {
    e.preventDefault();
    setShowTransfer(true);
    setName("");
    setMobileNumber("");
    console.log("Transfer button clicked");
  };
  const handleTransferToOk = async (e) => {
    console.log(e, "hanhandleTransferToOkdleOk");
    const TransferData = {
      transferFromMobileNumber: "9491919103",
      transferFromName: "Doondi",
      corePermitID: "4",
      transferToMobileNumber: "9966611875",
      transferToName: "Hari",
    };
    const response = await axios.post(transferCorePermit, TransferData);
    console.log("responce", response);
    if (response.data.statusCode !== "200") {
      setApiStatus(response.data.status);
      console.log("API throwing error");
    }
    setShowTransfer(false);
    console.log(status);
  };

  const handleConfirmationOk = () => {
    setShowReject(false);
    setShowConfirmationPopup(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleAprrovedOk = async (e) => {
    e.preventDefault();
    setStatus("");
    setMessage("");
    setIsLoading(false);
    setShowApproved(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  // const handleApproved = async (e) => {
  async function handleApproved(e) {
    const changeDataList = changeData.changeList.filter((item) => item.checked);
    const changeDataListChecked = changeDataList.length >= 1 ? "true" : "";

    const closeOutDataList = closeOutData.closeOutList.filter(
      (item) => item.checked
    );
    const closeOutDataChecked = closeOutDataList.length >= 1 ? "true" : "";

    const valuesToCheck = [
      {
        permitInitials: {
          dateInputOne: permitInitials.dateInputOne,
        },

        changeData: {
          changeList: changeDataListChecked,
        },
        closeOutData: {
          closeOutList: closeOutDataChecked,
        },
      },
    ];
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValues(item);

      switch (returnKey) {
        case "permitInitials":
          alert("Please Select Date");
          return;
        case "changeConstants":
          alert("Fill The Change Data");
          break;
        case "CloseOutConstants":
          alert("Please Fill The CloseOut Data");
          break;
      }
    }
    // alert(JSON.stringify(e))
    if (e == "Submitted") {
      const userProfileData = {
        primaryMobileNumber: permitObject.primaryMobileNumber,
        primaryRequesterName: permitObject.primaryRequesterName,
        secondaryRequesterName: permitObject.secondaryRequesterName,
        secondaryMobileNumber: permitObject.secondaryMobileNumber,
        corePermits: permitObject.corePermits,
        corePermitDate: moment(new Date()).format("YYYY-MM-DD"),
        corePermitInitials: permitObject.corePermitInitials,
        serverRecordId: IdFromURL ? IdFromURL : 0,
      };
      const finalJson = {
        permitInitials,
        emergency,
        people,
        energy,
        equipment,
        chemical,
        protection,
        workEnvironment,
        environmentData,
        closeOutData,
        changeData,
        respdata,
        recData,
        trainData,
        ...userProfileData,
      };
      // console.log(finalJson)
      setIsLoading(true);
      const response = await axios.post(saveCorePermitForm, finalJson);
      console.log("response", response);
      if (response.data.statusCode == "200") {
        console.log(e, "handleOk");
        const ApproveData = {
          acceptorUserId: PermitUserIdfromURL,
          permitName: PermitNamefromURL,
          corePermitID: IdFromURL,
          additionalPermitID:
            AditionpermitIDFromURL == "" ? "0" : AditionpermitIDFromURL,
          status: "Approve",
          deviceType: "iOS, Android",
          remarks: "",
        };
        try {
          setIsLoading(true);
          const response = await axios.post(ApproveRejectPermit, ApproveData);
          console.log("responce", response);

          if (response.data.statusCode !== "200") {
            setTimeout(() => {
              setIsLoading(false);
              setApiStatus(response.data.status);
              //setApiStatus(response.data.message);
              console.log("API throwing error");
            }, 1000);
          } else {
            setTimeout(() => {
              setIsLoading(false);
              setIsExpanded(true);
              setShowApproved(true);
            }, 1000);
          }
        } catch (err) {
          setIsLoading(false);
          console.log(err);
        }
        console.log(status);
      }
      setIsLoading(false);
    } else if (e == "Approve") {
      handleSubmit();
    }
  }

  const handleReject = (e) => {
    e.preventDefault();
    setRemarks("");
    setShowReject(true);
  };

  const handleNewModalOk = () => {
    setShowNewModal(false);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("GoBack");
    }
  };

  const handleRejectOk = async () => {
    console.log("handleRejectOk");

    const RejectData = {
      acceptorUserId: PermitUserIdfromURL,
      permitName: PermitNamefromURL,
      corePermitID: IdFromURL,
      additionalPermitID: AditionpermitIDFromURL || "0",
      status: "Reject",
      deviceType: "iOS, Android",
      remarks: remarks,
    };

    try {
      setIsLoading(true);

      const response = await axios.post(ApproveRejectPermit, RejectData);
      console.log("response", response);

      if (response.data.statusCode !== "200") {
        // Handle error scenario
        setShowConfirmationPopup(true);
        setApiStatus(response.data.message);
        console.log("API throwing error");
      } else {
        // Handle success scenario
        setShowConfirmationPopup(true); // Show success message
        setApiStatus("Rejection successful!"); // Set success message
      }

      setIsLoading(false);
      setShowReject(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleBackButtonClick = () => {
    // Handle navigation logic here
    navigate(-1);
  };

  const handleSubmit = async () => {
    const userProfileData = {
      primaryMobileNumber: permitObject.primaryMobileNumber,
      primaryRequesterName: permitObject.primaryRequesterName,
      secondaryRequesterName: permitObject.secondaryRequesterName,
      secondaryMobileNumber: permitObject.secondaryMobileNumber,
      corePermits: permitObject.corePermits,
      corePermitDate: moment(new Date()).format("YYYY-MM-DD"),
      corePermitInitials: permitObject.corePermitInitials,
      serverRecordId: IdFromURL ? IdFromURL : 0,
    };
    const finalJson = {
      permitInitials,
      emergency,
      people,
      energy,
      equipment,
      chemical,
      protection,
      workEnvironment,
      environmentData,
      closeOutData,
      changeData,
      respdata,
      recData,
      trainData,
      ...userProfileData,
    };
    setIsLoading(true);
    const response = await axios.post(saveCorePermitForm, finalJson);
    console.log("response", response);
    if (response.data.statusCode == "200") {
      // setApiStatus(response.data.status);
      setApiStatus(response.data.message);
      //console.log("API throwing error");
    }
    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => {
        setShowModal(true);
      }, 500);
    }, 2000);

    // setFinalSubmit(true)
  };

  return (
    <body id="page-top" style={{ paddingTop: "10px", margin: "10px" }}>
      {finalSubmit ? (
        <>
          <div id="wrapper">
            <div
              className="row"
              style={{ backgroundColor: "blue", padding: "7px" }}
            >
              <div className="col-md-3 col-12">
                <img
                  src={arrowIcon}
                  onClick={handleBackButtonClick}
                  alt="Back"
                  style={{
                    width: 40,
                    height: 30,
                    padding: 5,
                    verticalAlign: "middle",
                  }}
                />
                <span
                  style={{
                    fontSize: 20,
                    color: "white",
                    marginLeft: 10,
                    marginTop: 12,
                  }}
                >
                  Core Permit
                </span>
              </div>
            </div>
            {/* 
                <SideMenu /> */}
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <form className="CorePermit_Web-form">
                  <div>
                    <div>
                      <div className="card border-0">
                        <div className="card-body">
                          <div className="m-4">
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  color: "green",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "13px 0px 14px 10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Core Permit Submitted Successfully
                                </p>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontWeight: "bold",
                                  backgroundColor: "#DC275E",
                                  color: "white",
                                  margin: "10px 0px 10px 0px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Link
                                  to="/AdditionalPermitOptions"
                                  aria-expanded="true"
                                >
                                  <button
                                    className="btn mt-2"
                                    style={{
                                      margin: "0px 0px 6px 40px",
                                      color: "white",
                                    }}
                                  >
                                    Add Additional Permits
                                  </button>
                                </Link>
                              </div>
                            </div>

                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  fontWeight: "bold",
                                  backgroundColor: "#DC275E",
                                  color: "white",
                                  margin: "10px 0px 10px 0px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Link to="/CorePermitM3" aria-expanded="true">
                                  <button
                                    className="btn mt-2"
                                    style={{
                                      margin: "0px 0px 6px 40px",
                                      color: "white",
                                    }}
                                  >
                                    Go To Core Permit Status
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* rns */}
                      </div>
                    </div>
                  </div>
                </form>
                {/* ... End of Footer...  */}
              </div>
            </div>
            {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up"></i>
            </a>
            {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
          </div>
        </>
      ) : (
        <div id="wrapper">
          {/* 
                <SideMenu /> */}

          <div
            id="content-wrapper"
            className="d-flex flex-column"
            style={{ backgroundColor: "white" }}
          >
            <div id="content">
              {/* 
                        <Header /> */}

              <div>
                <div className="container-fluid">
                  <div className="d-flex justify-content-between"></div>
                  <div>
                    <form className="CorePermitM2-form">
                      <CorePermitInputFields
                        data={permitInitials}
                        editable={true}
                        setPermitInitials={setPermitInitials}
                        onDataChange={(data) => {
                          setPermitInitials(data);
                        }}
                      />

                      <Accordion
                        title="EMERGENCY"
                        bgColor="#DF3A1C"
                        content={
                          <CorePermitEmergency
                            data={emergency}
                            setEmergency={setEmergency}
                            onDataChange={(data) => {
                              setEmergency(data);
                            }}
                          />
                        }
                        buttonData={peopleImg}
                        bordercolor="#DF3A1C"
                      />
                      <Accordion
                        title="PEOPLE"
                        bgColor="#404040"
                        content={
                          <CorePermitM2People
                            data={people}
                            setPeople={setPeople}
                            onDataChange={(data) => {
                              setPeople(data);
                            }}
                          />
                        }
                        buttonData={peopleImg}
                        bordercolor="#404040"
                      />
                      <Accordion
                        title="ENVIRONMENT"
                        bgColor="#2CC743"
                        content={
                          <CorePermitEnvironment
                            environMentData={environmentData}
                            setEnvironMentData={setEnvironMentData}
                            onDataChange={(data) => setEnvironMentData(data)}
                          />
                        }
                        buttonData={enviImg}
                        bordercolor="#2CC743"
                      />
                      <Accordion
                        title="ENERGY"
                        bgColor="#3F57B1"
                        content={
                          <CorePermitM2Energy
                            data={energy}
                            setEnergy={setEnergy}
                            onDataChange={(val) => {
                              setEnergy(val);
                            }}
                          />
                        }
                        buttonData={energyImg}
                        bordercolor="#3F57B1"
                      />
                      <Accordion
                        title="EQUIPMENT"
                        bgColor="#F17A5A"
                        content={
                          <CorePermitM2Equipment
                            data={equipment}
                            setEnergy={setEquipment}
                            onDataChange={(val) => {
                              setEquipment(val);
                            }}
                          />
                        }
                        buttonData={equipImg}
                        bordercolor="#F17A5A"
                      />
                      <Accordion
                        title="CHEMICAL"
                        bgColor="#C1403B"
                        content={
                          <CorePermitM2Chemical
                            data={chemical}
                            setChemical={setChemical}
                            onDataChange={(val) => {
                              setChemical(val);
                            }}
                          />
                        }
                        buttonData={chemiImg}
                        bordercolor="#C1403B"
                      />
                      <Accordion
                        title="PROTECTION"
                        bgColor="#4E74C2"
                        content={
                          <CorePermitM2Protection
                            data={protection}
                            setProtection={setProtection}
                            onDataChange={(val) => {
                              setProtection(val);
                            }}
                          />
                        }
                        bordercolor="#4E74C2"
                      />
                      <Accordion
                        title="WORK ENVIRONMENT"
                        bgColor="#636260"
                        content={
                          <CorePermitM2Work
                            setWorkEnvironment={setWorkEnvironment}
                            data={workEnvironment}
                            onDataChange={(val) => {
                              setWorkEnvironment(val);
                            }}
                          />
                        }
                        bordercolor="#636260"
                      />
                      <Accordion
                        title="TRAINING"
                        bgColor="#282928"
                        content={
                          <CorePermitTraining
                            trainData={trainData}
                            setTrainData={setTrainData}
                          />
                        }
                        bordercolor="#282928"
                      />
                      <Accordion
                        title="CORE PERMIT RECEIVER"
                        bgColor="#484848"
                        content={
                          <CorePermitReceiver
                            recData={recData}
                            setRecData={setRecData}
                          />
                        }
                        bordercolor="#484848"
                      />
                      <Accordion
                        title="RESPONSIBILITIES"
                        bgColor="#6D6D6D"
                        content={
                          <CorePermitResponsibilities
                            respData={respdata}
                            setRespData={setRespData}
                          />
                        }
                        bordercolor="#6D6D6D"
                      />
                      <Accordion
                        isExpand={isExpanded}
                        title="CHANGE"
                        bgColor="#DF3A1C"
                        content={
                          <CorePermitChange
                            changeData={changeData}
                            setChangeData={setChangeData}
                          />
                        }
                        buttonData={changeimages}
                        bordercolor="#DF3A1C"
                      />
                      <Accordion
                        isExpand={isExpanded}
                        title="CLOSEOUT"
                        bgColor="#262721"
                        content={
                          <CorePermitCloseOut
                            closeOutData={closeOutData}
                            setCloseOutData={setCloseOutData}
                          />
                        }
                        bordercolor="#262721"
                      />

                      <div>
                        {/* onClick={handleApproved} */}
                        {permitStatus !== "" && (
                          <button
                            type="button"
                            onClick={() => {
                              handleApproved(permitStatus);
                            }}
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: "#007AFF",
                              color: "white",
                              border: "none",
                              marginTop: "10px",
                            }}
                          >
                            {permitStatus == "Submitted" ? "Approve" : "Update"}
                          </button>
                        )}
                      </div>
                      {permitStatus == "Submitted" && (
                        <button
                          type="button"
                          onClick={handleReject}
                          className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                          style={{
                            height: "50px",
                            backgroundColor: "#FC2727",
                            color: "white",
                            border: "none",
                            marginTop: "10px",
                          }}
                        >
                          Reject
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={handleTransferTo}
                        className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white 
                                             ${"custom-rounded"}`}
                        style={{
                          height: "50px",
                          backgroundColor: "#FFA200",
                          color: "white",
                          border: "none",
                          marginTop: "10px",
                          visibility: "hidden",
                        }}
                      >
                        Transfer
                      </button>

                      <div>
                        <Modal
                          show={showTransfer}
                          onHide={() => setShowTransfer(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Body>
                            <p
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <h3> Transfer to </h3>
                            </p>
                            <div className="form-group">
                              <label htmlFor="name">Name:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="mobileNumber">
                                Mobile Number:
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="mobileNumber"
                                value={mobileNumber}
                                onChange={(e) => {
                                  setMobileNumber(e.target.value);
                                }}
                              />
                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ border: "0" }}>
                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="primary btn-lg w-100"
                                onClick={handleTransferToOk}
                              >
                                Ok
                              </Button>
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          show={showApproved}
                          onHide={() => setShowApproved(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "15vh",
                                    }}
                                  >
                                    <img
                                      src={Approved}
                                      alt="save"
                                      className="popupicons"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>Core Permit approved successfully</h5>
                                  <h5>{apiStatus}</h5>
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                    style={{
                                      height: "43px",
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={handleAprrovedOk}
                                  >
                                    Ok
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </Modal.Body>
                          {/* <Modal.Body>

                                                        <div>
                                                            <span style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '13vh' }}>

                                                                <img src={Approved} alt="save" style={{ width: "50px", height: "50px" }} />
                                                            </span>
                                                        </div>

                                                        <div className="form-group">
                                                            <p style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3vh' }}>

                                                                <h5>Approved</h5>
                                                            </p>
                                                        </div>
                                                    </Modal.Body> */}
                          {/* <Modal.Footer style={{ border: '0' }}>
                                                        <div className="d-flex" style={{ width: '100%', justifyContent: 'center' }} >

                                                            <Button
                                                                variant="primary"
                                                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                                                style={{ height: "43px", backgroundColor: '#007AFF', color: 'white', border: "none" }}
                                                                onClick={handleAprrovedOk}
                                                            >
                                                                Ok
                                                            </Button>
                                                        </div>
                                                        <span>{apiStatus}</span>
                                                    </Modal.Footer > */}
                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showReject}
                          onHide={() => setShowReject(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "15vh",
                                    }}
                                  >
                                    <img
                                      src={Reject}
                                      alt="save"
                                      className="popupicons"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>Are you sure want to Reject?</h5>
                                </p>
                              </div>
                              <div>
                                <div className="form-group">
                                  <label htmlFor="name">
                                    <h5>Reason:</h5>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Reason for Rejection"
                                    value={remarks}
                                    onChange={(e) => {
                                      setRemarks(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "8vh",
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                    style={{
                                      height: "43px",
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={handleRejectOk}
                                    disabled={remarks.trim() === ""}
                                  >
                                    Ok
                                  </Button>
                                </p>
                              </div>
                              {/* New Modal triggered by the "Ok" button in the rejection modal */}
                              <Modal
                                show={showNewModal}
                                onHide={() => setShowNewModal(false)}
                                size="sm"
                                id="newModal"
                                className="ppopup"
                              >
                                <Modal.Body>
                                  <div className="row">
                                    <div>
                                      <span
                                        style={{
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "15vh",
                                        }}
                                      >
                                        <img
                                          src={Approved}
                                          alt="save"
                                          className="popupicons"
                                        />
                                      </span>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "3vh",
                                          color: "green",
                                        }}
                                      >
                                        <h5>
                                          CorePermit Rejected Successfully
                                        </h5>
                                      </p>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "3vh",
                                        }}
                                      >
                                        <h5>{apiStatus}</h5>
                                      </p>
                                    </div>
                                  </div>

                                  <Button
                                    variant="primary"
                                    onClick={handleNewModalOk}
                                  >
                                    Ok
                                  </Button>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </Modal.Body>
                          {/* <Modal.Footer style={{ border: '0' }}>

                                                        <div className="d-flex" style={{ width: '100%', justifyContent: 'center' }}>
                                                            <Button variant="primary btn-lg w-100"
                                                                onClick={handleRejectOk}>
                                                                Ok
                                                            </Button>
                                                        </div>
                                                    </Modal.Footer> */}
                        </Modal>

                        {/* Confirmation Popup */}
                        <Modal
                          show={showConfirmationPopup}
                          onHide={() => setShowConfirmationPopup(false)}
                          size="sm"
                          id="nsmodel"
                          className="ppopup"
                        >
                          <Modal.Body>
                            <div className="row">
                              <div>
                                <span
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "13vh",
                                  }}
                                >
                                  <img
                                    src={Reject}
                                    alt="save"
                                    className="popupicons"
                                  />
                                </span>
                              </div>
                            </div>
                            {/* <div>
                                                            <p style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3vh' }}>
                                                                <h5>{apiStatus}</h5>
                                                            </p>
                                                        </div> */}
                            <div className="form-group">
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "3vh",
                                  color: "green",
                                }}
                              >
                                <h5 style={{ textAlign: "center" }}>
                                  CorePermit Rejected Successfully
                                </h5>
                              </p>
                            </div>
                            <Modal.Footer style={{ border: "0" }}>
                              <div
                                className="d-flex"
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary"
                                  className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                  style={{
                                    height: "43px",
                                    backgroundColor: "#007AFF",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={handleConfirmationOk}
                                >
                                  Ok
                                </Button>
                              </div>
                            </Modal.Footer>
                          </Modal.Body>
                        </Modal>
                      </div>

                      <div>
                        <Modal
                          show={showModal}
                          onHide={() => setShowModal(false)}
                          centered={true}
                          size="sm"
                          id="nsmodel"
                        >
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "15vh",
                                    }}
                                  >
                                    <img
                                      src={Approved}
                                      alt="save"
                                      className="popupicons"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "3vh",
                                    }}
                                  >
                                    {/* <h5>Core Permit Accepter</h5> */}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "3vh",
                                  }}
                                >
                                  <h5>{apiStatus}</h5>
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                    style={{
                                      backgroundColor: "#007AFF",
                                      color: "white",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      if (window.ReactNativeWebView) {
                                        window.ReactNativeWebView.postMessage(
                                          "GoBack"
                                        );
                                      }
                                      setShowModal(false);
                                    }}
                                  >
                                    Ok
                                  </Button>
                                </p>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </form>
                    {/* </div>
                                            </div> */}
                    {/* </div>
                                    </div> */}
                  </div>
                </div>
                {/*  Clseout end */}
              </div>
              {/* <Footer /> */}
              {/* ... End of Footer...  */}
            </div>
          </div>
          {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
          {/*  <!-- Logout Modal--> */}
          {/* <Logout /> */}
        </div>
      )}
      {/* {isLoading && <Loader_MS loading={isLoading} message={"Please Wait..!"} />} */}
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default CorePermitM2Acceptor;
