
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import SideMenu from '../Pages/SideMenu';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Logout from '../Pages/Logout';
import { strings } from '../Utilities/strings'
import Loader from '../Utilities/Loader';
import * as XLSX from 'xlsx'; 
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../Utilities/utils';
import { FarmOperation_getMasters, rmGetmasters, reportForRMTracking } from '../Utilities/URLCONSTANTS';
import { GetApiHeaders } from '../Utilities/NetworkUtilities';
import { filterObjects } from '../Utils';
import Loader_Bulk from '../Utilities/Loader_Bulk';



//ProductMaster

const DailyWorkAllocationReport = ({ }) => {

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [loaderImage, setLoaderImage] = useState(require('../images/rmloader.gif'));
    const [submitResponse, setsubmitResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showResponsePopup, setShowResponsePopup] = useState(false);
    const [id, setId] = useState('');
    const [selectedLocation, setSelectedLocation] = useState({ code: "", name: "" });
    const [locationDropDownData, setlocationDropDownData] = useState([]);
    const [typeOfOperation, setTypeOfOperation] = useState([])
    const [operations, setOpeartions] = useState('');

   
    const [ActivityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTermOption, setSearchTermOption] = useState('');
    

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [todayDate, settodayDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const [getData, setGetData] = useState([])
    const [generalWork, setGeneralWork] = useState()
    const [errgeneralWork, setErrGeneralWork] = useState('')
    const [generalList, setGeneralList] = useState([])
    const [errLocation, setErrLocation] = useState("");
    const [errSubLocation, setErrSubLocation] = useState("");
    const [subLocation, setSubLocation] = useState("");
    const [locationData, setlocationData] = useState("");
    const [sublocationData, setsublocationData] = useState("");
    const [locatinBasedsubLocation, setlocatinBasedsubLocation] = useState([]);
    const [equipment, setEquipment] = useState("");
    const [popupText, setPopupText] = useState('Do you want to close Spray Service');
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [data, setData] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState('');
    const [showWarningPopup, setShowWarningPopup] = useState(false);

    const [filteredData, setFilteredData] = useState([]); // For filtered results
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default: 10 items per page
    const [searchTerm, setSearchTerm] = useState('');
    const [totalItems, setTotalItems] = useState();
    const [productCategoryOptions, setProductCategoryOptions] = useState([]);
    const [productNameOptionsOriginal, setProductNameOptionsOriginal] = useState([]);
    const [storeLocationOptions, setStoreLocationOptions] = useState([]);
    const [productCategory, setProductCategory] = useState("");
    const [productCategoryID, setProductCategoryID] = useState("");
    const [productNameOptions, setProductNameOptions] = useState([]);
    const [errorProductCategory, setErrorProductCategory] = useState("");
    const [productName, setProductName] = useState("");
    const [productNameID, setProductNameID] = useState("");
    const [permitType, setpermitType] = useState('');
    const [wmy, setwmy] = useState('');
    const [location, setLocation] = useState('');
    const [locationID, setLocationID] = useState('');
    const [WeekSelected, setWeekSelected] = useState('no');
    const [monthSelected, setmonthSelected] = useState('no');
    const [yearSelected, setYearSelected] = useState('no');
    const [allSelected, setAllSelected] = useState('yes');
    const [getEquipments, setGetEquipments] = useState([]);
    const [getEquipmentsArray, setGetEquipmentsArray] = useState([]);
    const [subLocationName, setSubLocationName] = useState()
    const [equipmentName, setEquipmentName] = useState()
    const [errEquipment, setErrEquipment] = useState("");
    const [thCount, setThCount] = useState(0);
    const [locationName, setLocationName] = useState()
    




    const handleFilter = (searchTerm) => {
        setSearchTerm(searchTerm);
        if (data != null && data != undefined) {
            const filteredResults = data.filter((item) =>
                (item.date && item.date.includes(searchTerm)) ||
                (item.location && item.location.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.workLogSubLocation && item.workLogSubLocation.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.generalList && item.generalList.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.corePermitRequired && item.corePermitRequired.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.equipment && item.equipment.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.assignedBy && item.assignedBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.assignedTo && item.assignedTo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.workAssignedDate && item.workAssignedDate.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.equipmentCondition && item.equipmentCondition.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.equipmentConditionReason && item.equipmentConditionReason.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.workCompletedDate && item.workCompletedDate.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredData(filteredResults);
            handlePageClick(1)
        }
    };

    useEffect(() => {
        if (data != null && data != undefined) {
            var dtToday = new Date();
            var month = dtToday.getMonth() + 1;
            var day = dtToday.getDate();
            var year = dtToday.getFullYear();
            if (month < 10)
                month = '0' + month.toString();
            if (day < 10)
                day = '0' + day.toString();
            var maxDate = year + '-' + month + '-' + day;
            setFilteredData(data);
            setTotalItems(data.length);

        }
    }, [data]);

    const tableId = 'Content_data_table'; // Replace 'yourTableId' with the actual id of your table
    useEffect(() => {
        const table = document.getElementById(tableId);
        if (table) {
            const theadRow = table.querySelector('thead tr');
            const thCount = theadRow ? theadRow.querySelectorAll('th').length : 0;
            setThCount(thCount);
        } else {
            console.warn(`Table with id '${tableId}' not found.`);
        }
    }, [tableId]); // Run this effect only once when the component mounts

    const handlePrint = () => {
        const printWindow = window.open();
        const printContent = document.querySelector('.print-table');
        // Check if the printContent is available
        if (printContent) {
            printWindow.document.write('<html><head>' +
                '<meta charset="utf-8" />' +
                '<link rel="icon" href="/favicon.ico" />' +
                '<meta name="viewport" content="width=device-width, initial-scale=1" />' +
                '<meta name="theme-color" content="#000000" />' +
                '<meta name="description" content="Web site created using create-react-app" />' +
                '<title>MCRC</title>' +
                '<link href="css/sb-admin-2.min.css" rel="stylesheet">' +
                '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css">' +
                '<script defer src="/static/js/bundle.js"></script>' +
                '<style>' +
                '@media print {' +
                '  table {' +
                '    border-collapse: collapse;' +
                '    width: 100%;' +
                '    margin-bottom: 10px;' +
                '  }' +
                '  th, td {' +
                '    border: 1px solid #ddd;' +
                '    text-align: left;' +
                '  }' +
                '}' +
                '</style>' +
                '</head><body>');

            printWindow.document.write(printContent.innerHTML);
            printWindow.document.write('</body></html>');

            // Delay the print function to ensure styles are applied
            printWindow.setTimeout(() => {
                printWindow.print();
                printWindow.close();
            });
        } else {
            // Handle the case where printContent is not found  
            alert("print is not available");
        }
    }

    // const commaSeparatedString = subLocation.join(',');
    let commaSeparatedString;
    if (Array.isArray(subLocation)) {
        commaSeparatedString = subLocation.join(',');
    } else if (typeof subLocation === 'string') {
        // If subLocation is a string, split it by comma to form an array
        commaSeparatedString = subLocation;
    } else {
        // Handle other cases or assign a default value
        commaSeparatedString = ''; // or any default value you want
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1); // Reset to the first page when changing items per page.
    };

    // const totalItems = data.length;

    const pageNumbers = [];
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1); i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
            setCurrentPage(pageNumber);
          }
    };

    const exportToExcel = () => {
        const data = [
            ['S.no',
                'Date',
                'Location',
                'Sub Location',
                'General List',
                'Core Permit Required',
                'Eqipment',
                'Assign BY', 
                'Assign To',
                'Work Assigned Date',
                'Equipment Condition',
                'Equipment condition Reason',
                'Work completed date'
            ], // Custom headers
            // ...currentItems.map((item) => [
            ...filteredData.map((item, index) => [
                index + 1,
                item.date,
                item.location,
                item.subLocation,
                item.generalList,
                item.corePermitRequired,
                item.equipment,
                item.assignedBy,
                item.assignedTo,
                item.workAssignedDate,
                item.equipmentCondition,
                item.equipmentConditionReason,
                item.workCompletedDate
            ]),
        ];
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        // Save the file
        XLSX.writeFile(wb, ' Daily work Allocation Report.xlsx');
    };

    const handleGeneralList = (e) => {
        setGeneralWork(e.target.value)
        if (e.target.value) {
            setErrGeneralWork('')
        }
    }
    const handleLocation = async (e) => { 
        setLocation(e.target.value);
        const subLocationsArray = [];
        const selectedLocation =
          e.target.options[e.target.selectedIndex].getAttribute("data-location");
        setLocationName(selectedLocation);
        if (e.target.value) {
          sublocationData.find((item) => {
            if (item.locationId === parseInt(e.target.value)) {
              subLocationsArray.push(item);
            }
          });
          setFilteredLocations(subLocationsArray);
          setErrLocation("");
          console.log("subLocationsArray" + JSON.stringify(filteredLocations));
        } else {
          setErrLocation("Select Location");
          setFilteredLocations([]);
        }
    }; 

    useEffect(() => { 
        const filtered = locatinBasedsubLocation.filter(item =>
            item.name.toLowerCase().includes(searchTermOption.toLowerCase())
        );
        setFilteredLocations(filtered);
    }, [searchTermOption, locatinBasedsubLocation]);

    const handleSubLocation = (e) => { 
        const codeIs = e.target.getAttribute('data-equipmentID'); // Accessing data from checkbox
        const subLocationName = e.target.options[e.target.selectedIndex].getAttribute('data-sublocation');
        setSubLocationName(subLocationName);
    
        setSubLocation(e.target.value);
    
        // Logic for handling equipment based on sub-location selection
        // const EquipmentArray = [];
        // if (e.target.value) {
        //     getEquipments.find((item) => {
        //         if (item.subLocationId === parseInt(e.target.value)) {
        //             EquipmentArray.push(item);
        //         }
        //     });
        //     setGetEquipmentsArray(EquipmentArray);
        //     setEquipment(''); // Clear the selected equipment
        // } else {
        //     setGetEquipmentsArray([]);
        //     setEquipment(''); // Clear the selected equipment
        // }
    }; 
    
    const handleSelectAll = () => { 
        if (subLocation.length === filteredLocations.length) {
            setSubLocation([]);
        } else {
            const allLocations = filteredLocations.map(item => item.name);
            setSubLocation(allLocations);
        }
    };

    const handleEquipment = (e) => {
        const selectedEquipment = e.target.value;
        setEquipment(selectedEquipment);
        const selectedEquipmentName = e.target.options[e.target.selectedIndex].getAttribute('data-equipmentName')
        setEquipmentName(selectedEquipmentName)
        if (e.target.value) {
            setErrEquipment("");
        }
    };
    const handleTypeOfOeration = (e) => {
        const selectTractorType = e.target.value
        setOpeartions(selectTractorType)
    }

    const handleWMYChange = (e) => {
        $('.date_show_hide').hide();
        let chk_val = (e.target.value);
        setwmy(chk_val);
        if (chk_val === 'M' || chk_val === 'Y' || chk_val === 'W' || chk_val === 'All') { 
            setGeneralWork('')
            setLocation('')
            setSubLocation([]) 
            setStartDate(new Date());
            setEndDate(new Date());
         }
        if (chk_val == 'W') {
            setWeekSelected('yes');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('no');
            setwmy('W')
        }
        else if (chk_val == 'M') {
            setWeekSelected('no');
            setmonthSelected('yes');
            setYearSelected('no');
            setAllSelected('no');
            setwmy('M')
        }
        else if (chk_val == 'Y') {
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('yes');
            setAllSelected('no');
            setwmy('Y')
        }
        else if (chk_val == 'All') { 
            setWeekSelected('no');
            setmonthSelected('no');
            setYearSelected('no');
            setAllSelected('yes');
            $('.date_show_hide').show();
            setwmy("");
        }
    } 

    function extractTimeFromDate(dateTimeString) {
        const dateObject = new Date(dateTimeString);
        const timeOnly = dateObject.toLocaleTimeString('en-US', { hour12: true });
        return timeOnly;
    }



    const tableRef = useRef(null);
    useEffect(() => {
        // Check if DataTable is already initialized
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
            // Destroy the existing DataTable instance
            $(tableRef.current).DataTable().destroy();
        }

        // Initialize the DataTable
        $(tableRef.current).DataTable({
            ordering: false, // Disable sorting
        });
        // fetchData();
        // fetchDataReports();
        getMasterData()
    }, []);
    const getMasterData = async () => {
        const getData = rmGetmasters
        const headers = await GetApiHeaders();
        try {
            const responseGetData = await axios.get(getData, { headers }) 
            setGetData(responseGetData.data.response)
            setGetEquipmentsArray(responseGetData.data.response.workLogEquipment) 
            setlocationData(responseGetData.data.response.workLogLocation);
            setsublocationData(responseGetData.data.response.workLogSubLocation);
            setGetEquipments(responseGetData.data.response.workLogEquipment);

        } catch (err) {
            console.log("getError", err)
        }
    }

console.log(getData,"getData");
    const handleProductCategory = async (event) => {
        const selectedCategory = event.target.value;
        const selectedCategoryId = event.target.options[event.target.selectedIndex].getAttribute('code');
        console.log("Category", selectedCategory + "--" + selectedCategoryId)
        setProductCategory(selectedCategory);
        setProductCategoryID(selectedCategoryId);
        var subLocationList = await filterObjects(sublocationData, "productCategoryId", selectedCategoryId) // kiran
        if (subLocationList.length > 0) {
            setlocatinBasedsubLocation(subLocationList)
        } 
        if (selectedCategory) {
            setErrorProductCategory('');
        }
    }

    const fetchTypeOfOperationDropdownData = async () => {
        const apiUrl = FarmOperation_getMasters;
        const headers = await GetApiHeaders();
        axios.get(apiUrl, { headers })
            .then((response) => {
                console.log("responseLocation", response);
                setTypeOfOperation(response.data.response.subSiteMaster)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }

    ////Data table api IntigrationN  
    const fetchData = async () => { 
        const apiUrl = reportForRMTracking;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);
        setIsLoading(true);
        axios.get(apiUrl, { headers }).then((response) => { 
            setIsLoading(false);
            setData(response.data.response.rmHistory);
            const respData = response.data.response.rmHistory;
            const activityType = [...new Set(respData.map(item => item.activityType))];
            setActivityData(activityType);
            setLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }); 
    };

    // useEffect(() => {
    //     fetchData();
    // }, []);


    const fetchDataReports = async () => {

        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        const reqObj = {
            "WMY": wmy,
            "startDate": "",
            "endDate": "",
            "permitType": "",
            "location": "",
            "subLocation": "",
            "typeOfWork": "",
            "reportType": "dailyWork",
            "generalList": "",
            "equipment": ""
        }
        try {
            const response = await axios.post(fetchApi, reqObj, { headers })
            //console.log("update Activity", response.data.response.requests)
            setData(response.data.response.dailyWork)
        } catch (err) {
            console.log("err123", err)
        }
    }

    const handleSubmit = async (event) => { 
        if (!generalWork) {
            setErrGeneralWork("Please Select General Work")
        }
        if (!location) {
            setErrLocation('Please Select Location')
        }
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;

        if (startDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            formattedEndDate = endDate.toISOString().split('T')[0];
        }

        event.preventDefault();
        const reqObj = {
            "WMY": wmy,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "location": locationName,
            "subLocation": subLocationName,
            "typeOfWork": "",
            "reportType": "dailyWork",
            "generalList": generalWork,
            "equipment": equipmentName
        }

        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        console.log("Dynamic Headers::", headers);
        // try {
        //     if (location !=='' && generalWork !=='') {
        //         setIsLoading(true);
        // axios.post(fetchApi, reqObj, { headers }).then((response) => {
        //     if (response.data.response == "undefined" || response.data.response == "") {
        //         setData([]);
        //     }
        //     else
        //         setData(response.data.response.dailyWork); 
        //     setIsLoading(false);
        // })
        //     }
            
        // } 
        try {
            if (location !=='' && generalWork !=='') {
            setIsLoading(true);
            const res = await axios.post(fetchApi , reqObj,{headers} )
        const worklog = res?.data?.response?.dailyWork;
        
        if (worklog === undefined || worklog === '') {
            setData([])
        }else{
            setData(worklog)
        }
        setIsLoading(false);
        }
            
        } catch (error) {
           console.log(error); 
           setIsLoading(false);
        }
        
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setpermitType(value);

    }

    const Sendmail = async (event) => {
        // debugger;
        let formattedStartDate = startDate;
        let formattedEndDate = endDate;

        if (startDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
        }
        if (endDate) {
            formattedEndDate = endDate.toISOString().split('T')[0];
        }

        event.preventDefault();
        const reqObj = {
            "WMY": wmy,
            "startDate": formattedStartDate,
            "endDate": formattedEndDate,
            "permitType": "",
            "location": location,
            "subLocation": subLocation,
            "typeOfWork": "",
            "reportType": "dailyWork",
            "generalList": generalWork,
            "equipment": equipmentName
        }
        const fetchApi = reportForRMTracking;
        const headers = await GetApiHeaders();
        setIsLoading(true);
        axios.post(fetchApi, reqObj, { headers }).then((response) => {
            setIsLoading(false);
            if (response.data.response == "undefined" || response.data.response == "") {
                setData([]);
            }
            else {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: 'Your work has been saved.',
                    confirmButtonColor: '#007aff'
                });
            }

        })
    }

    function resetData() {
        setwmy("w");

        setlocatinBasedsubLocation([]);
        setGeneralWork("");
        setLocation("");
        setEquipment("");
        setStartDate(new Date());
        setEndDate(new Date());
        settodayDate(new Date()); 
        // fetchData();
        setTimeout(()=>{
            setErrGeneralWork('')
            setErrLocation('')
        },100)
        window.location.reload()
    }


    return (
        <body id="page-top">
            {/*  <!-- Page Wrapper --> */}
            <div id="wrapper">
                {/*  <!-- Sidebar --> */}
                <SideMenu />
                {/*  <!-- End of Sidebar --> */}

                {/*  <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/*  <!-- Main Content --> */}
                    <div id="content">
                        {/*  <!-- Topbar --> */}
                        <Header />
                        {/* ...Content Start... */}
                        <div>
                            <div className='container-fluid' style={{ backgroundColor: "#F7F8FA" }}>
                                <div className='card border-0' style={{ backgroundColor: "transparent" }}>
                                    <div className='card-body pt-4 pl-1'>
                                        <h5 className='Roles_text'>Daily Work Allocation Report</h5>
                                        <div className='mt-4'>
                                            <div className='d-flex gap-1 status_card'>
                                                <div className='card flex-grow-1' style={{
                                                    border: '1px solid #0000001A',
                                                    boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                                }}>
                                                    <div className='card-body' style={{ padding: "20px 20px" }}>

                                                        <form onSubmit={handleSubmit}>
                                                            <div className='row'>
                                                                <div className='col-md-3 col-12'>
                                                                    <label for="InputName" className="form-label font-weight-bold mt-1"
                                                                    >Select Date Duration      </label>

                                                                    <div className="btn-group" id='filter-btn' role="group" aria-label="Basic radio toggle button group" onChange={handleWMYChange}>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={WeekSelected === 'yes'} value="W" />
                                                                        <label className="btn btn-outline-primary" for="btnradio1">Week</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked={monthSelected === 'yes'} value="M" />
                                                                        <label className="btn btn-outline-primary" for="btnradio2">Month</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={yearSelected === 'yes'} value="Y" />
                                                                        <label className="btn btn-outline-primary" for="btnradio3">Year</label>
                                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autocomplete="off" checked={allSelected === 'yes'} value="All" />
                                                                        <label className="btn btn-outline-primary" for="btnradio4">All</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3 col-12'   >
                                                                    <label for="InputName" className="form-label font-weight-bold mt-1"
                                                                    >General List  </label>

                                                                    <select id="dropdown"
                                                                        className="form-control p-2" value={generalWork} onChange={handleGeneralList} >
                                                                        <option value="" >Select General List</option>
                                                                        {
                                                                            getData.generalWorksList != undefined && getData.generalWorksList.map((items, index) => {
                                                                                return (
                                                                                    <option key={items.code} value={items.name}>{items.name}</option>
                                                                                )
                                                                            })

                                                                        }

                                                                    </select>
                                                                    <span style={{color:'red'}}>{errgeneralWork}</span>
                                                                </div>
                                                                <div className='col-md-3 col-12' 
                                                                >
                                                                    <label for="InputName" className="form-label font-weight-bold mt-1"
                                                                    >Location  </label>

                                                                    <select className="form-select p-2" value={location} onChange={handleLocation}>
                                                                        <option value="" >Select Location</option>
                                                                        {
                                                                            getData.workLogLocation != undefined && getData.workLogLocation.map((items, itemsIndex) => {
                                                                                return (
                                                                                    <option key={items.code} data-location={items.name} value={items.code}>{items.name}</option>
                                                                                )
                                                                            })

                                                                        }

                                                                    </select>
                                                                    <span style={{color:'red'}}>{errLocation}</span>
                                                                </div>
 
                                                                <div className='col-md-3 col-12' style={{  position: 'relative' }}>
                                                                <label className="form-label font-weight-bold mt-1"
                                                                    >Sub Location  </label>
                                                                    <select className="form-select p-2 "onChange={handleSubLocation}> 
                                                                           <option>Select sub Location</option>  
                                                                            {filteredLocations.map((items, itemsIndex) => (
                                                                                 <option key={items.code}value={items.code} data-sublocation={items.name}>{items.name}</option>
                                                                            ))} 
                                                                    </select>
                                                                </div>

                                                                <div className='col-md-3 col-12'
                                                                    style={{ marginLeft: "25%", marginBottom: "40px" }}
                                                                >
                                                                    <label className="form-label font-weight-bold mt-1"
                                                                    >Equipment  </label>

                                                                    <select className="form-select p-2" value={equipment}
                                                                        onChange={handleEquipment} >
                                                                        <option value="">Select Equipment</option>
                                                                        {getEquipmentsArray.map(
                                                                            (items, itemsIndex) => {
                                                                                return (
                                                                                    <option
                                                                                        key={items.code}
                                                                                        value={items.code}
                                                                                        data-equipmentName={items.name}
                                                                                    >
                                                                                        {items.name}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <div className='col-sm-3 col-12 date_show_hide'  >
                                                                    <div class="mb-3">
                                                                        <label for="startdate"
                                                                            className="form-label font-weight-bold mt-1"

                                                                        >From Date</label>
                                                                        <DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select Start Date" selected={startDate}
                                                                            onChange={(date) => { setStartDate(date) }}
                                                                            maxDate={endDate} dateFormat="dd-MMM-yyyy"
                                                                            id="activitystartdate" name="activitystartdate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-3 col-12 date_show_hide'
                                                                    style={{   marginBottom: "40px" }}>
                                                                    <div class="mb-3">
                                                                        <label
                                                                            className="form-label font-weight-bold mt-1"
                                                                            for="enddate">To Date</label>
                                                                        <span><DatePicker showIcon className='form-control w-100'
                                                                            placeholderText="Select End Date" selected={endDate}
                                                                            onChange={(date) => setEndDate(date)} dateFormat="dd-MMM-yyyy"
                                                                            minDate={startDate} maxDate={todayDate} id="activityenddate"
                                                                            name="activityenddate"
                                                                            customInput={<CustomDatePickerInput />}
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='mt-2 d-flex w-full justify-content-center gap-2'>
                                                                <button class="btn btn-primary mr-0"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    type='submit'>Apply</button>

                                                                <button class="btn btn-danger"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={resetData}>Clear</button>

                                                                <button

                                                                    className="btn btn-secondary mr-0 buttons-excel buttons-html5"
                                                                    style={{
                                                                        paddingLeft: "3rem",
                                                                        paddingRight: "3rem",
                                                                    }}
                                                                    onClick={Sendmail}>Mail</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                       
                                        <div className='card flex-grow-1 mt-2' style={{
                                            border: '1px solid #0000001A',
                                            boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)"
                                        }}>
                                            <div className='card-body' style={{ padding: "10px 10px" }}>

                                                <div className="d-flex justify-content-between align-items-center"
                                                    style={{ paddingLeft: 25, paddingBottom: 20 }}>
                                                    <div className="d-flex align-items-center">
                                                        <label className="mr-2">Show {' '}</label> &nbsp;&nbsp;
                                                        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>
                                                            {/* Add more options as needed */}
                                                        </select>&nbsp;&nbsp;
                                                        <label>{' '} entries</label>
                                                    </div>

                                                    <div className="d-flex align-items-center" style={{ marginRight: '2%' }}>
                                                    <div style={{ position: "relative" }}>
                                                            <input
                                                                type="text"
                                                                placeholder="🔍 Type to Search..."
                                                                value={searchTerm}
                                                                onChange={(e) => handleFilter(e.target.value)}
                                                                style={{
                                                                height: "36px",
                                                                width: "160px",
                                                                paddingRight: "30px",
                                                                }}
                                                                className="mr-2"
                                                            />
                                                            {searchTerm && (
                                                                <button
                                                                className="btn btn-link"
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "50%",
                                                                    right: "10px",
                                                                    transform: "translateY(-50%)",
                                                                    zIndex: 1,
                                                                }}
                                                                onClick={() => handleFilter("")}
                                                                >
                                                                <i className="fas fa-times"></i>
                                                                </button>
                                                            )}
                                                            </div>  &nbsp;
                                                        <div className="my-2 d-flex w-full justify-content-end gap-2">
                                                            <button className="btn btn-secondary buttons-excel buttons-html5"
                                                                onClick={exportToExcel}>
                                                                Download Excel
                                                            </button>
                                                            <button
                                                                className="btn btn-primary"
                                                                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                                                                onClick={handlePrint}
                                                            >
                                                                Print
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="print-table"
                                                    style={{ overflowX: 'auto' }}>
                                                    <Table className="table table-bordered Content_data_table table-striped" id="Content_data_table"  >

                                                        <thead>
                                                            <tr>
                                                                <th className='text-center'>S.No</th>
                                                                <th className='text-center'>Date</th>
                                                                <th className='text-center'>Location</th>
                                                                <th className='text-center'>Sub Location</th>
                                                                <th className='text-center'>General List</th>
                                                                <th className='text-center'>Core Permit Required </th>
                                                                <th className='text-center'>Equipment</th>
                                                                <th className='text-center'>Assign BY </th>
                                                                <th className='text-center'>Assign To  </th>
                                                                <th className='text-center'>Work Assigned Date</th>
                                                                <th className='text-center'>Equipment Condition </th>
                                                                <th className='text-center'>Equipment condition Reason</th>
                                                                <th className='text-center'>Work completed date </th>

                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {currentItems && currentItems.length > 0 ? (
                                                                currentItems.map((item, index) => (
                                                                    <React.Fragment >
                                                                        <tr>
                                                                            <td className='text-center'  >  {(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                            <td className='text-left'>{item.date}</td>
                                                                            <td className='text-left'>{item.location}</td>
                                                                            <td className='text-left'>{item.subLocation}</td>
                                                                            <td className='text-left'>{item.generalList}</td>
                                                                            <td className='text-left'>{item.corePermitRequired}</td>
                                                                            <td className='text-left'>{item.equipment}</td>
                                                                            <td className='text-left'>{item.assignedBy}</td>
                                                                            <td className='text-right'>{item.assignedTo}</td>
                                                                            <td className='text-left'>{item.workAssignedDate}</td>
                                                                            <td className='text-left'>{item.equipmentCondition}</td>
                                                                            <td className='text-left'>{item.equipmentConditionReason}</td>
                                                                            <td className='text-right'>{item.workCompletedDate}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={thCount} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            )}
                                                        </tbody>   
                                                    </Table>
                                                </div>

                                                <div className='py-3'>
                                                    <div className='card data_tableCard'>
                                                        <div className='card-body'>

                                                            {/* Pagination */}

                                                            <div className="d-flex justify-content-between mb-10" style={{ borderRadius: 5, }}>
                                                                <div>
                                                                Showing {Math.min(((currentPage - 1) * itemsPerPage) + 1, totalItems)} 
                                                                &nbsp;to {Math.min((currentPage - 1) * itemsPerPage + currentItems.length, totalItems)}&nbsp;
                                                                of {totalItems} entries
                                                                </div>
                                                                <div>
                                                                    <div className="div-button text-center" >
                                                                        <button
                                                                            className='borderleft-radius-5'
                                                                            // className='ul-pagination'
                                                                            onClick={() => handlePageClick(currentPage - 1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            Previous
                                                                        </button>


                                                                        <button className='ul-pagination page-item'
                                                                            style={{
                                                                                backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                                                                color: (currentPage == 1) ? 'white' : 'black'
                                                                            }}
                                                                            key={1}
                                                                            onClick={() => handlePageClick(1)}
                                                                            disabled={currentPage === 1}
                                                                        >
                                                                            {1}

                                                                        </button>
                                                                        {pageNumbers.map((number) => (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == number) ? 'white' : 'black'
                                                                                }}
                                                                                key={number}
                                                                                onClick={() => handlePageClick(number)}
                                                                                disabled={currentPage === number}
                                                                            >
                                                                                {number}
                                                                            </button>
                                                                        ))}
                                                                        {((Math.ceil(totalItems / itemsPerPage)) > 1 && currentPage !== (Math.ceil(totalItems / itemsPerPage) - 1) && currentPage !== (Math.ceil(totalItems / itemsPerPage))) && (
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: 'white',
                                                                                    color: 'black'
                                                                                }}
                                                                            >
                                                                                ...
                                                                            </button>
                                                                        )}
                                                                        {(Math.ceil(totalItems / itemsPerPage)) > 1 &&
                                                                            <button className='ul-pagination page-item'
                                                                                style={{
                                                                                    backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                                                                    color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                                                                }}
                                                                                key={(Math.ceil(totalItems / itemsPerPage))}
                                                                                onClick={() => handlePageClick((Math.ceil(totalItems / itemsPerPage)))}
                                                                                disabled={currentPage === (Math.ceil(totalItems / itemsPerPage))}
                                                                            >
                                                                                {(Math.ceil(totalItems / itemsPerPage))}

                                                                            </button>
                                                                        }
                                                                        <button className='borderright-radius-5'
                                                                            onClick={() => handlePageClick(currentPage + 1)}
                                                                            disabled={indexOfLastItem >= totalItems}
                                                                        >
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                            </div>
                        </div>
                        {isLoading && <Loader_Bulk loading={isLoading} message={"Fetching Data. Please Wait..!"} loderimage={loaderImage} />}
                    </div>
                    {/* ...End of Main Content...*/}
                    {/* ...Footer...*/}
                    <Footer />
                    {/* ... End of Footer...  */}
                </div>
            </div>
            {/*  <!-- End of Page Wrapper -->
        <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
            {/*  <!-- Logout Modal--> */}
            <Logout />
            {/* {!isUserLoggedIn && <Login />} */}
        </body>
    )
}

export default DailyWorkAllocationReport;
