import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logout from "./Logout";
import { getAllRecievedRequests } from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import "../RandD.css";
import { Link, useNavigate } from "react-router-dom";
//import arrowIcon from "../images/arrowIcon.png";
import arrowIcon from "../images/Left-arrow.png";
import { COREPERMIT_DATE, COREPERMIT_MOBILE_NUMBER, COREPERMIT_VENDOR_NAME, CorePermitM2_PERMITID, FIRST_Time } from "../Utils";


const AdditionalPermitOptions = () => {

  const navigate = useNavigate();
  const [isPermitEnabled, setIsExcavationPermitEnabled] = useState(false);
  const [isGotoPermitEnabled, setisGotoPermitEnabled] = useState(false);
  const CPM2_PermitID = sessionStorage.getItem(CorePermitM2_PERMITID);
  var isVendoretime = sessionStorage.getItem(FIRST_Time);
  const COREPERMIT_MOBILE_NUMBERs = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);

  useEffect(() => {
    console.log("CPM2_PermitID", CPM2_PermitID);
    if (COREPERMIT_MOBILE_NUMBERs == "COREPERMIT_MOBILE_NUMBER" 
    || COREPERMIT_MOBILE_NUMBERs == null ||COREPERMIT_MOBILE_NUMBERs == undefined ||COREPERMIT_MOBILE_NUMBERs.toString() == "null") {   
      window.location.href = "/CorePermitOne";
    }
    if (CPM2_PermitID == "CorePermitM2_PERMITID" 
    || CPM2_PermitID == null ||CPM2_PermitID == undefined ||CPM2_PermitID.toString() == "null") {     
    }
    else{
      setIsExcavationPermitEnabled(true);
      setisGotoPermitEnabled(true);
    }
  }, [isPermitEnabled])
  // Handle the click event when the link is clicked
  const handleExcavationPermitLinkClick = () => {
    if (isPermitEnabled) {
      window.location.href = "/ExcavationPermit?permitstatus=Submit";
    }
  };
  // Handle the click event when the link is clicked
  const handleECorePermitM2LinkClick = () => {
    window.location.href = "/CorePermitM2";
  };
  // Handle the click event when the link is clicked
  const handleCraneOperationPermitLinkClick = () => {
    if (isPermitEnabled) {
      // window.location.href = "/CraneOperationPermit";
      window.location.href = "/CranePermitMain?permitstatus=Submit";
    }
  };
  // Handle the click event when the link is clicked
  const handleCriticalElevatedLinkClick = () => {
    if (isPermitEnabled) {
      window.location.href = "/CriticalElevated?permitstatus=Submit";
    }
  };

  // Handle the click event when the link is clicked
  const handleHotWorkPermitLinkClick = () => {
    if (isPermitEnabled) {
      window.location.href = "/HotWorkPermit2Main?permitstatus=Submit";
    }
  };

  // Handle the click event when the link is clicked
  const handleCorePermitM3LinkClick = () => {
    if (isVendoretime.toString() == 'false') {
      window.location.href = "/CorePermitM3";
    }
    if (isGotoPermitEnabled.toString() == 'true') {
      window.location.href = "/CorePermitM3";
    }
  };


  const handleBackButtonClick = () => {

    // Handle navigation logic here
      // sessionStorage.setItem(CorePermitM2_PERMITID , null);
      // sessionStorage.setItem(FIRST_Time , null);
      // sessionStorage.setItem(COREPERMIT_MOBILE_NUMBER, null)
      // sessionStorage.setItem(COREPERMIT_VENDOR_NAME, null)
      // sessionStorage.setItem(COREPERMIT_DATE, null)     
      // alert(isVendoretime + CPM2_PermitID)
      navigate(-1);

  };
  
  useEffect(() => {
    console.log("isVendoretime::", isVendoretime);
    if (isVendoretime.toString() == 'false') {
      setisGotoPermitEnabled(true);
    }
  }, [isGotoPermitEnabled])

useEffect (() => {
  // alert(isPermitEnabled)
})


  return (
    <body id="page-top">
      <div id="wrapper">
        {/* 
                <SideMenu /> */}

        <div id="content-wrapper" className="d-flex flex-column">

          <div id="content">
            {/* 
                        <Header /> */}
            <div className="row" style={{ backgroundColor: 'blue', padding: "7px" }}>
              <div className="col-md-3 col-12"  >
                {/* <span className="arrow" style={{ width: 50, height: 25, padding: 10,color:"white"}}>{'<-'}</span>    */}
                <img src={arrowIcon} onClick={handleBackButtonClick} alt="Back" style={{ width: 40, height: 30, padding: 5, verticalAlign: 'middle' }} />
                <span style={{ fontSize: 20, color: 'white', marginLeft: 10, marginTop: 12 }}>Work Permits</span>
                {/* <h1 style={{ fontSize: 20, color: 'white'}}>Core Permit</h1> */}
              </div>
            </div>

            <form className="CorePermit_Web-form">
              <div>
                {/* <div className="container-fluid"> */}
                <div>
                  <div className="card border-0">
                    <div className="card-body">
                      <div className="m-5">
                        <div className='row mt-2'>
                          {/* <Link to="/CorePermitM2" 
                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`} 
                                                    style={{ height: "50px", backgroundColor: '#2373BE', color: 'white', border: "none" }}> */}
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: '#2373BE',
                              color: 'white',
                              cursor: 'pointer',
                              border: "none"
                            }}
                            onClick={handleECorePermitM2LinkClick}
                          >
                            Core Permit</Link>
                        </div>
                        <div className='row mt-2' disable>
                          {/* <Link to="/CraneOperationPermit" className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`} style={{ height: "50px", backgroundColor: '#F58355', color: 'white', border: "none" }}>*/}
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isPermitEnabled ? '#F58355' : '#CCCCCC',
                              color: isPermitEnabled ? 'white' : 'gray',
                              cursor: isPermitEnabled ? 'pointer' : 'not-allowed', // Change cursor when enabled/disabled
                              border: "none"
                            }}
                            onClick={handleCraneOperationPermitLinkClick} // Use the custom handler for click event
                            disabled={!isPermitEnabled}  >
                            Crane Opeation Permit</Link>
                        </div>
                        <div className='row mt-2'>
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isPermitEnabled ? '#262828' : '#CCCCCC',
                              color: isPermitEnabled ? 'white' : 'gray',
                              cursor: isPermitEnabled ? 'pointer' : 'not-allowed', // Change cursor when enabled/disabled
                              border: "none"
                            }}
                            onClick={handleExcavationPermitLinkClick} // Use the custom handler for click event
                            disabled={!isPermitEnabled}  >
                            Excavation Permit</Link>
                        </div>
                        <div className='row mt-2'>
                          {/* <Link  to="/CriticalElevated"
                                                     className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`} 
                                                     style={{ height: "50px", backgroundColor: '#566060', color: 'white', border: "none" }}> */}
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isPermitEnabled ? '#566060' : '#CCCCCC',
                              color: isPermitEnabled ? 'white' : 'gray',
                              cursor: isPermitEnabled ? 'pointer' : 'not-allowed', // Change cursor when enabled/disabled
                              border: "none"
                            }}
                            onClick={handleCriticalElevatedLinkClick} // Use the custom handler for click event
                            disabled={!isPermitEnabled}  >
                            Critical Elevated Work</Link>
                        </div>
                        <div className='row mt-2'>
                          {/* <Link to="/HotWorkPermit" className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`} style={{ height: "50px", backgroundColor: '#34B85F', color: 'white', border: "none" }}> */}
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isPermitEnabled ? '#34B85F' : '#CCCCCC',
                              color: isPermitEnabled ? 'white' : 'gray',
                              cursor: isPermitEnabled ? 'pointer' : 'not-allowed', // Change cursor when enabled/disabled
                              border: "none"
                            }}
                            onClick={handleHotWorkPermitLinkClick} // Use the custom handler for click event
                            disabled={!isPermitEnabled}  >
                            Hot Work Permit</Link>
                        </div>
                        <div className='row mt-2'>
                          {/* <Link to="/CorePermitM3" className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`} style={{ height: "50px", backgroundColor: '#DC275E', color: 'white', border: "none" }}> */}
                          <Link
                            className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                            style={{
                              height: "50px",
                              backgroundColor: isGotoPermitEnabled ? '#DC275E' : '#CCCCCC',
                              color: isGotoPermitEnabled ? 'white' : 'gray',
                              cursor: isGotoPermitEnabled ? 'pointer' : 'not-allowed', // Change cursor when enabled/disabled
                              border: "none"
                            }}
                            disabled={!isGotoPermitEnabled}
                            onClick={handleCorePermitM3LinkClick} // Use the custom handler for click event
                          >
                            Go To Permit Status</Link>
                          {/* <Link  to="/CorePermitM3" 
                                                     className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`} 
                                                     style={{ height: "50px",
                                                     backgroundColor:'#DC275E' , 
                                                     color:'gray' ,
                                                     cursor: 'pointer' , // Change cursor when enabled/disabled
                                                     border: "none" }} // Use the custom handler for click event
                                                     > 
                                                        Go To Permit Status</Link> */}
                        </div>

                      </div>
                    </div>
                    {/* rns */}

                  </div>
                </div>
              </div>

              <Footer />
            </form>
            {/* ... End of Footer...  */}
          </div>
        </div>
        {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        {/*  <!-- Logout Modal--> */} {/* <Logout /> */}
      </div >
    </body >
  );
};

export default AdditionalPermitOptions;
