import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "../Pages/SideMenu";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";
import Logout from "../Pages/Logout";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllRecievedReturns } from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import Loader from "../Utilities/Loader";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import { notifications } from "../Utilities/URLCONSTANTS";
import moment from "moment";
import { USER_PROFILE } from "../Utils";

const Notification = (category, index) => {
  const [isLoading, setIsLoading] = useState(false);
  const [getLogin, setGetLogin] = useState([]);
  const [data, setData] = useState([]);
  const [dataRMTracking, setDataRMTracking] = useState([]);
  const [dataFarmOperations, setDataFarmOperations] = useState([]);
  const tableRef = useRef(null);

  let itemClicked = sessionStorage.getItem("itemClicked");
  console.log(itemClicked);

  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
      paging: true,
      lengthChange: false,
    });
    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetLogin(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
    fetchData();
    fetchDataRM();
    fetchDataFarmOperation();
  }, []);

  const fetchData = async () => {
    const notificationApi = notifications;
    const headers = await GetApiHeaders();
    const postData = {
      userType: getLogin.userType,
      mainAssetType: "CS",
      mainAsset: "centralStore",
    };
    try {
      setIsLoading(true);
      const response = await axios.post(notificationApi, postData, { headers });
      console.log("notification", response);
      setData(response.data.response.notifications);
      setIsLoading(false);
    } catch (error) {
      console.log("Notification", error);
    }
  };
  const fetchDataRM = async () => {
    const notificationApi = notifications;
    const headers = await GetApiHeaders();
    const postData = {
      userType: getLogin.userType,
      mainAssetType: "RM",
      mainAsset: "r&mTracking",
    };
    try {
      setIsLoading(true);
      const response = await axios.post(notificationApi, postData, { headers });
      console.log("notificationRM", response);
      setDataRMTracking(response.data.response.notifications);
      setIsLoading(false);
    } catch (error) {
      console.log("Notification", error);
    }
  };
  const fetchDataFarmOperation = async () => {
    const notificationApi = notifications;
    const headers = await GetApiHeaders();
    const postData = {
      userType: getLogin.userType,
      mainAssetType: "FO",
      mainAsset: "farmOperations",
    };
    try {
      setIsLoading(true);
      const response = await axios.post(notificationApi, postData, { headers });
      console.log("notificationFo", response);
      setDataFarmOperations(response.data.response.notifications);
      setIsLoading(false);
    } catch (error) {
      console.log("Notification", error);
    }
  };
  return (
    <body id="page-top">
      <div id="wrapper">
        <SideMenu />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <h5 className="Roles_text ">Notifications</h5>
                    <div className="d-flex justify-content-between"></div>
                    <div className="mcrc_hrline"></div>
                    <div>
                      <div className="card data_tableCard">
                        <div className="card-body">
                          <Table className=" Content_data_table">
                            <Thead>
                              <Tr>
                                <Th className="pl-1">S.No</Th>
                                <Th className="text-center p-2">
                                  Notifications
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {itemClicked === "rmTracking"
                                ? dataRMTracking.map((item, index) => {
                                    return (
                                      <Tr key={index}>
                                        <Td className="pl-1">{index + 1}</Td>
                                        <Td>
                                          <Accordion
                                            defaultActiveKey="0"
                                            className="acc mt-3 mr-1"
                                          >
                                            <Accordion.Item eventKey="1">
                                              <Accordion.Header>
                                                <div
                                                  key={index}
                                                  style={{
                                                    fontSize: 14,
                                                    width: "100%",
                                                  }}
                                                  className="ProductName d-flex"
                                                >
                                                  <span
                                                    className=" "
                                                    style={{ width: "20%" }}
                                                  >
                                                    <b className="">
                                                      Request ID &nbsp;:
                                                    </b>
                                                    <span>
                                                      {" "}
                                                      &nbsp;{
                                                        item.requestId
                                                      }{" "}
                                                    </span>
                                                  </span>
                                                  <span
                                                    className=" "
                                                    style={{ width: "75%" }}
                                                  >
                                                    <b className="">
                                                      {" "}
                                                      Title&nbsp;:{" "}
                                                    </b>
                                                    <span>
                                                      {" "}
                                                      &nbsp;{item.title}{" "}
                                                    </span>
                                                  </span>
                                                  <span
                                                    className="  "
                                                    style={{ width: "20%" }}
                                                  >
                                                    {" "}
                                                    <b> Time &nbsp;:</b>
                                                    <span>
                                                      &nbsp;&nbsp; {item.time}
                                                    </span>{" "}
                                                    &nbsp; &nbsp;
                                                  </span>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <>
                                                  <div className="mt-3">
                                                    <span></span>
                                                    <>
                                                      <div
                                                        style={{
                                                          fontSize: 14,
                                                          width: "100%",
                                                        }}
                                                        className="ProductNameForRandM d-flex ml-3"
                                                      >
                                                        <span
                                                          className=""
                                                          style={{
                                                            width: "41.5%",
                                                          }}
                                                        >
                                                          <b className="">
                                                            {" "}
                                                            Description &nbsp;
                                                            &nbsp; &nbsp;&nbsp;
                                                            :
                                                          </b>{" "}
                                                          <span>
                                                            &nbsp;
                                                            {item.description}
                                                          </span>
                                                        </span>
                                                        {/* <span className=""style={{  width:"37.5%" }}>
                                                                      <b className="quantty">
                                                                        Product
                                                                        Quantity &nbsp;:
                                                                      </b>{" "}
                                                                      <span>
                                                                      &nbsp;dfgds
                                                                      </span> 
                                                                    </span>
                                                                    <span className=""style={{  width:"15.5%" }}>
                                                                      <b className="">
                                                                        Issued Quantity
                                                                        &nbsp;:
                                                                      </b>{" "}
                                                                      <span>
                                                                      &nbsp;dgasffa
                                                                      </span>
                                                                    </span> */}
                                                      </div>
                                                    </>
                                                  </div>
                                                </>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>
                                        </Td>
                                      </Tr>
                                    );
                                  })
                                : ""}
                              {itemClicked === "centralStore"
                                ? data.map((item, index) => {
                                    return (
                                      <Tr key={index}>
                                        <Td className="pl-1">{index + 1}</Td>
                                        <Td>
                                          <Accordion
                                            defaultActiveKey="0"
                                            className="acc mt-3 mr-1"
                                          >
                                            <Accordion.Item eventKey="1">
                                              <Accordion.Header>
                                                <div
                                                  key={index}
                                                  style={{
                                                    fontSize: 14,
                                                    width: "100%",
                                                  }}
                                                  className="ProductName d-flex"
                                                >
                                                  <span
                                                    className=" "
                                                    style={{ width: "20%" }}
                                                  >
                                                    <b className="">
                                                      Request ID &nbsp;:
                                                    </b>
                                                    <span>
                                                      {" "}
                                                      &nbsp;{
                                                        item.requestId
                                                      }{" "}
                                                    </span>
                                                  </span>
                                                  <span
                                                    className=" "
                                                    style={{ width: "60%" }}
                                                  >
                                                    <b className="">
                                                      {" "}
                                                      Title&nbsp;:{" "}
                                                    </b>
                                                    <span>
                                                      {" "}
                                                      &nbsp;{item.title}{" "}
                                                    </span>
                                                  </span>
                                                  <span
                                                    className="  "
                                                    style={{ width: "20%" }}
                                                  >
                                                    {" "}
                                                    <b> Time &nbsp;:</b>
                                                    <span>
                                                      &nbsp;&nbsp; {item.time}
                                                    </span>{" "}
                                                    &nbsp; &nbsp;
                                                  </span>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <>
                                                  <div className="mt-3">
                                                    <span></span>
                                                    <>
                                                      <div
                                                        style={{
                                                          fontSize: 14,
                                                          width: "100%",
                                                        }}
                                                        className="ProductNameForRandM d-flex ml-3"
                                                      >
                                                        <span
                                                          className=""
                                                          style={{
                                                            width: "41.5%",
                                                          }}
                                                        >
                                                          <b className="">
                                                            {" "}
                                                            Description &nbsp;
                                                            &nbsp; &nbsp;&nbsp;
                                                            :
                                                          </b>{" "}
                                                          <span>
                                                            &nbsp;
                                                            {item.description}
                                                          </span>
                                                        </span>
                                                        {/* <span className=""style={{  width:"37.5%" }}>
                                                                      <b className="quantty">
                                                                        Product
                                                                        Quantity &nbsp;:
                                                                      </b>{" "}
                                                                      <span>
                                                                      &nbsp;dfgds
                                                                      </span> 
                                                                    </span>
                                                                    <span className=""style={{  width:"15.5%" }}>
                                                                      <b className="">
                                                                        Issued Quantity
                                                                        &nbsp;:
                                                                      </b>{" "}
                                                                      <span>
                                                                      &nbsp;dgasffa
                                                                      </span>
                                                                    </span> */}
                                                      </div>
                                                    </>
                                                  </div>
                                                </>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>
                                        </Td>
                                      </Tr>
                                    );
                                  })
                                : ""}
                              {itemClicked === "farmOperations"
                                ? dataFarmOperations.map((item, index) => {
                                    return (
                                      <Tr key={index}>
                                        <Td className="pl-1">{index + 1}</Td>
                                        <Td>
                                          <Accordion
                                            defaultActiveKey="0"
                                            className="acc mt-3 mr-1"
                                          >
                                            <Accordion.Item eventKey="1">
                                              <Accordion.Header>
                                                <div
                                                  key={index}
                                                  style={{
                                                    fontSize: 14,
                                                    width: "100%",
                                                  }}
                                                  className="ProductName d-flex"
                                                >
                                                  <span
                                                    className=" "
                                                    style={{ width: "20%" }}
                                                  >
                                                    <b className="">
                                                      Request ID &nbsp;:
                                                    </b>
                                                    <span>
                                                      {" "}
                                                      &nbsp;{
                                                        item.requestId
                                                      }{" "}
                                                    </span>
                                                  </span>
                                                  <span
                                                    className=" "
                                                    style={{ width: "60%" }}
                                                  >
                                                    <b className="">
                                                      {" "}
                                                      Title&nbsp;:{" "}
                                                    </b>
                                                    <span>
                                                      {" "}
                                                      &nbsp;{item.title}{" "}
                                                    </span>
                                                  </span>
                                                  <span
                                                    className="  "
                                                    style={{ width: "20%" }}
                                                  >
                                                    {" "}
                                                    <b> Time &nbsp;:</b>
                                                    <span>
                                                      &nbsp;&nbsp; {item.time}
                                                    </span>{" "}
                                                    &nbsp; &nbsp;
                                                  </span>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <>
                                                  <div className="mt-3">
                                                    <span></span>
                                                    <>
                                                      <div
                                                        style={{
                                                          fontSize: 14,
                                                          width: "100%",
                                                        }}
                                                        className="ProductNameForRandM d-flex ml-3"
                                                      >
                                                        <span
                                                          className=""
                                                          style={{
                                                            width: "41.5%",
                                                          }}
                                                        >
                                                          <b className="">
                                                            {" "}
                                                            Description &nbsp;
                                                            &nbsp; &nbsp;&nbsp;
                                                            :
                                                          </b>{" "}
                                                          <span>
                                                            &nbsp;
                                                            {item.description}
                                                          </span>
                                                        </span>
                                                        {/* <span className=""style={{  width:"37.5%" }}>
                                                                      <b className="quantty">
                                                                        Product
                                                                        Quantity &nbsp;:
                                                                      </b>{" "}
                                                                      <span>
                                                                      &nbsp;dfgds
                                                                      </span> 
                                                                    </span>
                                                                    <span className=""style={{  width:"15.5%" }}>
                                                                      <b className="">
                                                                        Issued Quantity
                                                                        &nbsp;:
                                                                      </b>{" "}
                                                                      <span>
                                                                      &nbsp;dgasffa
                                                                      </span>
                                                                    </span> */}
                                                      </div>
                                                    </>
                                                  </div>
                                                </>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>
                                        </Td>
                                      </Tr>
                                    );
                                  })
                                : ""}
                            </Tbody>
                            {isLoading && (
                              <Loader
                                loading={isLoading}
                                message={"Fetching Data. Please Wait..!"}
                              />
                            )}
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      <Logout />
    </body>
  );
};

export default Notification;
