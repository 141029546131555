import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import mcrc_loader from "../images/mcrc_loader.gif";
import SideMenu from "../Pages/SideMenu";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import Logout from "../Pages/Logout";
import "../App.css";

export default class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: this.props.loading == undefined ? false : this.props.loading,
      message:
        this.props.message == undefined ? "Loading..." : this.props.message,
      loderimage:
        this.props.loderimage == undefined
          ? mcrc_loader
          : this.props.loderimage,
    };
  }
  componentWillReceiveProps(props) {
    if (props.message != this.state.message) {
      this.setState({
        message: props.message,
      });
    }
  }
  // {mcrc_loader}
  render() {
    return (
      <div show={this.state.loading} className="loader-container">
        <img
          src={this.state.loderimage}
          alt="mcrc_loader"
          width="150px"
          height="150px"
          style={{
            position: "relative",
            top: "50%",
            left: "50%",
            background: "transparent",
            transform: "translate(-50%, -50%)",
          }}
        />{" "}
        <br />
        <label
          style={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0)",
          }}
        >
          {this.state.message}
        </label>
      </div>
    );
  }
}
