import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
//import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logout from "./Logout";
import {
  saveAdditionalPermit,
  getAllPermitsByCorePermitID,
  ApproveRejectPermit,
} from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
// import CorePermitInputFields from "../components/criticalElevatedWork/corePermitInputFields";
import {
  corePermitInitialsConst,
  ElevatedWork,
  EmergencyConst,
  HazardIdentConst,
  Mitigation,
  RoofAccessConstant,
  FallRescueConstant,
  CommentsConst,
  rWorkPermit,
  SignatureConstant,
} from "../Utilities/criticalElevated-JSON";
import {
  USER_PROFILE,
  retrieveData,
  COREPERMIT_MOBILE_NUMBER,
  checkForEmptyValue,
} from "../Utils";
// import moment from "moment";
import Emergency from "../components/criticalElevatedWork/Emergency";
import ScopeOfElevatedWork from "../components/criticalElevatedWork/ScopeOfElevatedWork";
import FallRescue from "../components/criticalElevatedWork/FallRescue";
import CoreRoofAccess from "../components/criticalElevatedWork/RoofAccess";
import HazardIdentific from "../components/criticalElevatedWork/HazardIdentification";
import HazardMitigation from "../components/criticalElevatedWork/HazardMitigation";
import ReviedWork from "../components/criticalElevatedWork/ReviedWork";
import Signatures from "../components/criticalElevatedWork/Signatures";
import AdditionalComments from "../components/criticalElevatedWork/AdditionalComments";
import { useNavigate, useSearchParams } from "react-router-dom";
import arrowIcon from "../images/Left-arrow.png";
import Accordion from "../Accordation/Accordian";
//loader img
import Loader_Bulk from "../Utilities/Loader_Bulk";

//Approve
import Approved from "../images/Approved.png";

const CriticalElevated = (props) => {
  const [permitInitials, setPermitInitials] = useState(corePermitInitialsConst);
  const [emergency, setEmergency] = useState(EmergencyConst);
  const [scopeOfElevatedWork, setScopeOfElevatedWork] = useState(ElevatedWork);
  const [hazardIdentification, setHazardIdentification] =
    useState(HazardIdentConst);
  const [hazardMitigation, setHazardMitigation] = useState(Mitigation);
  const [roofAccess, setRoofAccess] = useState(RoofAccessConstant);
  const [fallRescue, setFallRescue] = useState(FallRescueConstant);
  const [additionalComments, setAdditionalComments] = useState(CommentsConst);
  const [terms, setTerms] = useState(rWorkPermit);
  const [signature, setSignature] = useState(SignatureConstant);
  const [serverRecordId, setServerRecordId] = useState(null);
  const [finalSubmit, setFinalSubmit] = useState(false);
  //Approve
  const [showApproved, setShowApproved] = useState(false);
  const [status, setStatus] = useState("");
  const [apiStatus, setApiStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  //loader
  const [isLoading, setIsLoading] = useState(false);
  const [loaderImage, setLoaderImage] = useState(
    require("../images/rmloader.gif")
  );

  //Reject
  const [showReject, setShowReject] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const addtionalpermitstatus = searchParams.get("permitstatus");
  const navigate = useNavigate();
  // api
  const handleBackButtonClick = () => {
    // Handle navigation logic here
    navigate(-1);
  };

  //get

  useEffect(() => {
    getFormData();
    return () => {};
  }, []);

  const resetForm = () => {
    setPermitInitials(corePermitInitialsConst);
    setEmergency(EmergencyConst);
    setScopeOfElevatedWork(ElevatedWork);
    setHazardIdentification(HazardIdentConst);
    setHazardMitigation(Mitigation);
    setRoofAccess(RoofAccessConstant);
    setFallRescue(FallRescueConstant);
    setAdditionalComments(CommentsConst);
    setTerms(rWorkPermit);
    setSignature(SignatureConstant);
    setFinalSubmit(false);
  };

  useEffect(() => {
    const search = props.location; // returns the URL query String
    const params = new URLSearchParams(search);
    const IdFromURLwithEDit = searchParams.get("corePermitId");

    var IdFromURL;
    var isEdit = "true";
    var additional_permit_id;
    if (IdFromURLwithEDit == null) {
    } else {
      IdFromURL = IdFromURLwithEDit.toString().split("/")[0];
      isEdit = IdFromURLwithEDit.toString().split("/")[1];
      additional_permit_id = IdFromURLwithEDit.toString().split("/")[2];
    }

    // alert("IdFromURL"+IdFromURL);
    setIsEdit(isEdit);
    // alert(isEdit);
    getFormData(
      IdFromURL != undefined && IdFromURL != null && IdFromURL != ""
        ? IdFromURL
        : 0,
      additional_permit_id != undefined &&
        additional_permit_id != null &&
        additional_permit_id != ""
        ? additional_permit_id
        : 0
    );
    return () => {};
  }, [isEdit]);
  async function filterArray(array, keyName, searchText) {
    console.log("responseFFFF", keyName + "---" + searchText);

    var filteredArray = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].permitID.toString() === searchText.toString()) {
        filteredArray.push(array[i]);
        break;
      }
    }
    console.log("Myfilter array::", filteredArray);
    return filteredArray[0];
  }

  const getFormData = async (id, additional_permit_id) => {
    console.log(id);
    try {
      // First, retrieve data from sessionStorage if needed
      const dataFromStore = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
      // Make an API request using Axios to get permits by core permit ID
      const vendormobileNumber = sessionStorage.getItem(
        "COREPERMIT_MOBILE_NUMBER"
      );
      const vendorname = sessionStorage.getItem("COREPERMIT_VENDOR_NAME");
      const PermitID = sessionStorage.getItem("CorePermitM2_PERMITID");
      const response = await axios.post(getAllPermitsByCorePermitID, {
        mobileNumber: vendormobileNumber,
        name: vendorname,
        PermitID: addtionalpermitstatus == "Submit" ? PermitID : id, //PermitID
      });
      console.log("id:::", dataFromStore);
      console.log("response data:", response.data);
      if (addtionalpermitstatus != "Submit") {
        // Check if the response contains data
        if (response.data && response.data.response) {
          const elevated =
            response?.data?.response?.critical_elevated_permit || [];
          // const dataLast = elevated[elevated.length - 1]?.permitObject || null
          const dataLast =
            elevated.find((item) => item.permitID === additional_permit_id)
              ?.permitObject || null;
          console.log("filteredArray checking", JSON.stringify(dataLast));

          // Set state values based on the retrieved data
          if (dataLast) {
            setPermitInitials(
              dataLast?.permitInitials
                ? dataLast?.permitInitials
                : permitInitials
            );
            setEmergency(dataLast?.emergency ? dataLast?.emergency : emergency);
            setScopeOfElevatedWork(
              dataLast?.scopeOfElevatedWork
                ? dataLast?.scopeOfElevatedWork
                : scopeOfElevatedWork
            );
            setHazardIdentification(
              dataLast?.hazardIdentification
                ? dataLast?.hazardIdentification
                : hazardIdentification
            );
            setHazardMitigation(
              dataLast?.hazardMitigation
                ? dataLast?.hazardMitigation
                : hazardMitigation
            );
            setRoofAccess(
              dataLast?.roofAccess ? dataLast?.roofAccess : roofAccess
            );
            setFallRescue(
              dataLast?.fallRescue ? dataLast?.fallRescue : fallRescue
            );
            setAdditionalComments(
              dataLast?.additionalComments
                ? dataLast?.additionalComments
                : additionalComments
            );
            setTerms(dataLast?.terms ? dataLast?.terms : terms);
            setSignature(dataLast?.signature ? dataLast?.signature : signature);

            //setServerRecordId(elevated[elevated.length - 1]?.permitID || "0");
            setServerRecordId(
              elevated.find((item) => item.permitID === additional_permit_id)
                ?.permitID || "0"
            );
          }
        } else {
          // Handle the case where the response does not contain the expected data
          console.log("Response data is missing or invalid.");
        }
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      console.error("Error fetching data:", error);
    }
  };

  const handleApproved = async (e) => {
    e.preventDefault();
    setStatus("");
    setShowApproved(true);
  };
  const handleAprrovedOk = async (e) => {
    console.log(e, "handleOk");
    const ApproveData = {
      permitName: "criticalElevatedPermit",
      corePermitID: "5",
      additionalPermitID: "11",
      status: "Approved",
      //"remarks": "remarks"
    };
    const response = await axios.post(ApproveRejectPermit, ApproveData);
    console.log("responce", response);
    if (response.data.statusCode !== "200") {
      setApiStatus(response.data.status);
      console.log("API throwing error");
    }
    setShowApproved(false);
    console.log(status);
  };

  //Approve and Rejectd Intigration

  const handleReject = (e) => {
    e.preventDefault();
    setRemarks("");
    setShowReject(true);
  };
  const handleRejectOk = async (e) => {
    console.log(e, "handleRejectOk");

    const RejectData = {
      permitName: "cranePermit",
      corePermitID: "5",
      additionalPermitID: "7",
      status: "Rejected",
      remarks: remarks,
    };
    const response = await axios.post(ApproveRejectPermit, RejectData);
    console.log("responce", response);
    if (response.data.statusCode !== "200") {
      setApiStatus(response.data.status);
      //console.log("API throwing error");
    }
    setShowReject(false);
    console.log(status);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const hazardList = hazardIdentification.hazard.filter(
      (item) => item.checked
    );
    const hazardChecking = hazardList.length >= 1 ? "true" : "";

    const workAccessList = hazardMitigation.workAccess.filter(
      (item) => item.description
    );
    const workAccessChecking = workAccessList.length >= 1 ? "true" : "";

    const slopeList = roofAccess.slopeType.list.filter((item) => item.checked);
    const slopeListchecked = slopeList.length >= 1 ? "true" : "";

    const fallListcheck = fallRescue.fallList.filter(
      (item) => item.description
    ); //  .fallList.filter(item => item.description);
    const fallListchecked = fallListcheck.length >= 1 ? "true" : "";

    const termsList = terms.list1.filter((item) => item.description);
    const termsListchecked = termsList.length >= 1 ? "true" : "";

    const datesList = signature.dates[0].fromDate;
    const datesList1 = signature.dates[0].toDate;
    const datesListchecked =
      datesList != null &&
      datesList != "" &&
      datesList1 != null &&
      datesList1 != ""
        ? "true"
        : "";

    const valuesToCheck = [
      {
        // "permitInitials": {
        //     "initialsOne": permitInitials.initialsOne,
        //     "dateInputOne": permitInitials.dateInputOne,
        //     "dateInputThree": permitInitials.dateInputThree,
        //     "dateInputThree": permitInitials.dateInputFive,
        //     "permitsOne": permitInitials.permitsOne
        // },

        emergency: {
          phoneNumber: emergency.phoneNumber,
        },
        scopeOfElevatedWork: {
          Company: scopeOfElevatedWork.Company,
        },
        hazardIdentification: {
          hazard: hazardChecking,
        },
        //
        hazardMitigation: {
          workAccess: workAccessChecking,
        },
        roofAccess: {
          slopeType: slopeListchecked,
        },
        //
        fallRescue: {
          fallList: fallListchecked,
        },
        additionalComments: {
          description: additionalComments.description,
        },
        //
        terms: {
          list1: termsListchecked,
        },
        signature: {
          dates: datesListchecked,
        },
      },
    ];

    let allCasesValid = true;
    for (const item of valuesToCheck) {
      const returnKey = checkForEmptyValue(item);
      // alert("returnKey   " + returnKey)
      switch (returnKey) {
        case "permitInitials":
          // Handle case 1
          alert("Please Select Date");
          allCasesValid = false;
          break;

        case "emergency":
          // Handle case 1
          alert("Fill the Emergency Data");
          allCasesValid = false;
          break;

        case "scopeOfElevatedWork":
          // Handle case 2
          alert("Please Fill The ScopeOfElevatedWork Data");
          allCasesValid = false;
          break;

        case "hazardIdentification":
          // Handle case 2
          alert("Please Fill The HazardIdentification Data");
          allCasesValid = false;
          break;

        case "hazardMitigation":
          // Handle case 2
          alert("Please Fill The hazardMitigation Data");
          allCasesValid = false;
          break;

        case "roofAccess":
          // Handle case 2
          alert("Please Fill The roofAccess Data");
          allCasesValid = false;
          break;
        case "fallRescue":
          // Handle case 2
          alert("Please Fill The fallRescue Data");
          allCasesValid = false;
          break;
        case "additionalComments":
          // Handle case 2
          alert("Please Fill The additionalComments Data");
          allCasesValid = false;
          break;
        case "terms":
          // Handle case 2
          alert("Please Fill The terms Data");
          allCasesValid = false;
          break;
        case "signature":
          // Handle case 2
          alert("Please Fill The signature Data");
          allCasesValid = false;
          break;
      }
    }
    if (allCasesValid) {
      var vendorname = sessionStorage.getItem("COREPERMIT_VENDOR_NAME");
      var vendormobileNumber = sessionStorage.getItem(
        "COREPERMIT_MOBILE_NUMBER"
      );
      var corePermitID = sessionStorage.getItem("CorePermitM2_PERMITID");
      const dataFromStore = await retrieveData(USER_PROFILE);
      const userProfileData = {
        id: serverRecordId ? serverRecordId : "0",
        mobileNumber: vendormobileNumber,
        name: vendorname,
        corePermitID: corePermitID, //"12345",
        additionalPermitName: "critical_elevated_permit",
      };

      const finalJson = {
        request: {
          permitInitials,
          emergency,
          scopeOfElevatedWork,
          hazardIdentification,
          hazardMitigation,
          roofAccess,
          fallRescue,
          additionalComments,
          terms,
          signature,
        },
        ...userProfileData,
      };
      try {
        setIsLoading(true);
        console.log(finalJson, "finalJson***");
        const response = await axios.post(saveAdditionalPermit, finalJson);
        console.log(response.status, "sdmnhba");
        console.log("response", response);
        if (response.status == 200 && response) {
          setShowModal(true);
          setIsLoading(false);
          resetForm(); // Reset form fields
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <body id="page-top">
      {/* {finalSubmit ? <>
                <h4>Form Submitted Successful!!!</h4>
            </> :  */}
      {finalSubmit ? (
        <>
          <h3>Form Submitted Successfully!!!</h3>
        </>
      ) : (
        <>
          <div id="wrapper">
            {/* 
                <SideMenu /> */}

            <div
              id="content-wrapper"
              className="d-flex flex-column"
              style={{ backgroundColor: "white" }}
            >
              <div id="content">
                {/* 
                        <Header /> */}
                <div
                  className="row"
                  style={{ backgroundColor: "blue", padding: "7px" }}
                >
                  <div className="col-md-3 col-12">
                    {/* <span className="arrow" style={{ width: 50, height: 25, padding: 10,color:"white"}}>{'<-'}</span>    */}
                    <img
                      src={arrowIcon}
                      onClick={handleBackButtonClick}
                      alt="Back"
                      style={{
                        width: 40,
                        height: 30,
                        padding: 5,
                        verticalAlign: "middle",
                      }}
                    />
                    <span
                      style={{
                        fontSize: 20,
                        color: "white",
                        marginLeft: 10,
                        marginTop: 12,
                      }}
                    >
                      Critical Evaluated Permit
                    </span>
                    {/* <h1 style={{ fontSize: 20, color: 'white'}}>Core Permit</h1> */}
                  </div>
                </div>
                <div>
                  <div className="container-fluid">
                    <div className="d-flex justify-content-between"></div>
                    <div>
                      <form className="CriticalElevated-form">
                        {/* <CorePermitInputFields
                          data={permitInitials}
                          setPermitInitials={setPermitInitials}
                          editable={false}
                          onDataChange={(data) => {
                            setPermitInitials(data);
                          }}
                        /> */}
                        <Accordion
                          title="EMERGENCY"
                          bgColor="#DF3A1C"
                          content={
                            <Emergency
                              data={emergency}
                              setEmergency={setEmergency}
                              onDataChange={(data) => {
                                setEmergency(data);
                              }}
                            />
                          }
                          bordercolor="#DF3A1C"
                        />
                        <Accordion
                          title="SCOPE OF ELEVATED WORK"
                          bgColor="#262828"
                          content={
                            <ScopeOfElevatedWork
                              data={scopeOfElevatedWork}
                              setScopeOfElevatedWork={setScopeOfElevatedWork}
                              onDataChange={(data) => {
                                setScopeOfElevatedWork(data);
                              }}
                            />
                          }
                          bordercolor="#262828"
                        />
                        <Accordion
                          title="HAZARD IDENTIFICATION"
                          bgColor="#566060"
                          content={
                            <HazardIdentific
                              data={hazardIdentification}
                              setHazardIdentification={setHazardIdentification}
                              onDataChange={(data) =>
                                setHazardIdentification(data)
                              }
                            />
                          }
                          bordercolor="#566060"
                        />
                        <Accordion
                          title="HAZARD MITIGATION"
                          bgColor="#DF3A1C"
                          content={
                            <HazardMitigation
                              data={hazardMitigation}
                              setHazardMitigation={setHazardMitigation}
                              onDataChange={(data) =>
                                setHazardIdentification(data)
                              }
                            />
                          }
                          bordercolor="#DF3A1C"
                        />
                        <Accordion
                          title="ROOF ACCESS"
                          bgColor="#FF7E51"
                          content={
                            <CoreRoofAccess
                              data={roofAccess}
                              setRoofAccess={setRoofAccess}
                              onDataChange={(data) => setRoofAccess(data)}
                            />
                          }
                          bordercolor="#FF7E51"
                        />
                        <Accordion
                          title="FALL RESCUE"
                          bgColor="#34B85F"
                          content={
                            <FallRescue
                              data={fallRescue}
                              setFallRescue={setFallRescue}
                              onDataChange={(val) => {
                                setFallRescue(val);
                              }}
                            />
                          }
                          bordercolor="#34B85F"
                        />
                        <Accordion
                          title="ADDITIONAL COMMENTS & MODIFICATIONS"
                          bgColor="#566060"
                          content={
                            <AdditionalComments
                              data={additionalComments}
                              setAdditionalComments={setAdditionalComments}
                              onDataChange={(data) =>
                                setAdditionalComments(data)
                              }
                            />
                          }
                          bordercolor="#566060"
                        />
                        {/* <Accordion
                                                title="I HAVE REVIEWED AND UNDERSTOOD THE ABOVE CRITICAL ELEVATED WORK PERMIT"
                                                bgColor="#A1A2A5"
                                                content={<ReviedWork data={terms} setTerms={setTerms} onDataChange={(data) => setTerms(data)} />}
                                                bordercolor="#A1A2A5"
                                            /> */}
                        {/* <Accordion
                                                title="I HAVE REVIEWED AND UNDERSTOOD..."
                                                bgColor="#A1A2A5"
                                                content={<ReviedWork data={terms} setTerms={setTerms} onDataChange={(data) => setTerms(data)} />}
                                                bordercolor="#A1A2A5"
                                            /> */}

                        <Accordion
                          title={
                            <span>
                              I HAVE REVIEWED AND UNDERSTOOD...
                              <span className="required">
                                {" "}
                                *
                                <span
                                  className="text-white"
                                  style={{ fontSize: "12px" }}
                                >
                                  {" "}
                                  (Fill Required Fields)
                                </span>
                              </span>
                            </span>
                          }
                          bgColor="#A1A2A5"
                          content={
                            <ReviedWork
                              data={terms}
                              setTerms={setTerms}
                              onDataChange={(data) => {
                                setTerms(data);
                              }}
                            />
                          }
                          bordercolor="#A1A2A5"
                        />

                        <Accordion
                          title="SIGNATURES"
                          bgColor="#2C2E35"
                          content={
                            <Signatures
                              data={signature}
                              setSignature={setSignature}
                              onDataChange={(data) => setSignature(data)}
                            />
                          }
                          bordercolor="#2C2E35"
                        />
                        {/* <Accordion> */}
                        {/* <Accordion.Item eventKey="0">
                                                    <Accordion.Header id="sew" className="section-1"><span class="accordion-arrow"></span>SCOPE OF ELEVATED WORK</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ScopeOfElevatedWork data={scopeOfElevatedWork} setScopeOfElevatedWork={setScopeOfElevatedWork} onDataChange={(data) => { setScopeOfElevatedWork(data) }} />
                                                    </Accordion.Body>
                                                </Accordion.Item> */}

                        {/* <Accordion.Item eventKey="1" >
                                                    <Accordion.Header id="hi" className="mt-2"><span class="accordion-arrow"></span>HAZARD IDENTIFICATION</Accordion.Header>
                                                    <Accordion.Body>
                                                        <HazardIdentific data={hazardIdentification}
                                                            setHazardIdentification={setHazardIdentification}
                                                            onDataChange={(data) => setHazardIdentification(data)} />
                                                    </Accordion.Body>
                                                </Accordion.Item> */}

                        {/* <Accordion.Item eventKey="2">
                                                    <Accordion.Header id="hm" className="mt-2"><span class="accordion-arrow"></span>HAZARD MITIGATION</Accordion.Header>
                                                    <Accordion.Body>
                                                        <HazardMitigation data={hazardMitigation} setHazardMitigation={setHazardMitigation} onDataChange={(data) => setHazardIdentification(data)} />
                                                    </Accordion.Body>
                                                </Accordion.Item> */}

                        {/* <Accordion.Item eventKey="3">
                                                    <Accordion.Header id="ra" className="mt-2"><span class="accordion-arrow"></span>ROOF ACCESS</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CoreRoofAccess data={roofAccess} setRoofAccess={setRoofAccess} onDataChange={(data) => setRoofAccess(data)} />
                                                    </Accordion.Body>
                                                </Accordion.Item> */}

                        {/* 
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header id="fr" className="mt-2"><span class="accordion-arrow"></span>FALL RESCUE</Accordion.Header>
                                                    <Accordion.Body>
                                                        <FallRescue data={fallRescue} setFallRescue={setFallRescue} onDataChange={(val) => {setFallRescue(val)}} />
                                                    </Accordion.Body>
                                                </Accordion.Item> */}

                        {/* <Accordion.Item eventKey="5">
                                                    <Accordion.Header id="acm" className="mt-2"><span class="accordion-arrow"></span>I HAVE REVIEWED AND UNDERSTOOD THE ABOVE CRITICAL ELEvATED WORK PERMIT</Accordion.Header>
                                                    <Accordion.Body>
                                                        <AdditionalComments data={additionalComments} setAdditionalComments={setAdditionalComments} onDataChange={(data) => setAdditionalComments(data)}/>
                                                    </Accordion.Body>
                                                </Accordion.Item> */}

                        {/* <Accordion.Item eventKey="6">
                                                    <Accordion.Header id="cewp" className="mt-2"><span class="accordion-arrow"></span>I HAVE REVIEWED AND UNDERSTOOD THE ABOVE CRITICAL ELEvATED WORK PERMIT</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ReviedWork data={terms} setTerms={setTerms} onDataChange={(data) => setTerms(data)}  />
                                                    </Accordion.Body>
                                                </Accordion.Item> */}

                        {/* <Accordion.Item eventKey="7">
                                                    <Accordion.Header id="sig" className="mt-2"><span class="accordion-arrow"></span>SIGNATURES</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Signatures data={signature} setSignature={setSignature} onDataChange={(data) => setSignature(data)}/>
                                                    </Accordion.Body>
                                                </Accordion.Item> */}
                        {/* </Accordion> */}
                        <div>
                          {isEdit.toString() == "true" && (
                            <button
                              type="button"
                              className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                              style={{
                                height: "50px",
                                backgroundColor: "#007AFF",
                                color: "white",
                                border: "none",
                              }}
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          )}
                          <Modal
                            show={showModal}
                            onHide={() => setShowModal(false)}
                            size="sm"
                            id="nsmodel"
                            className="ppopup"
                          >
                            <Modal.Body>
                              <div>
                                <div className="row">
                                  <div>
                                    <span
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "15vh",
                                      }}
                                    >
                                      <img
                                        src={Approved}
                                        alt="save"
                                        className="popupicons"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "3vh",
                                    }}
                                  >
                                    <h5>Critical Elevated sent for Approval</h5>
                                  </p>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      textAlign: "center",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      margin: "0px",
                                    }}
                                  >
                                    <Button
                                      variant="primary"
                                      className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                                      style={{
                                        backgroundColor: "#007AFF",
                                        color: "white",
                                        border: "none",
                                      }}
                                      onClick={() => {
                                        handleBackButtonClick();
                                        setShowModal(false);
                                      }}
                                    >
                                      Ok
                                    </Button>
                                  </p>
                                </div>
                              </div>
                            </Modal.Body>
                            {/* <Modal.Body>
                                                        <div>
                                                            <div className="row">
                                                                <div>
                                                                    <span style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh' }}>

                                                                        <img src={Approved} alt="save" className="popupicons"  />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '3vh' }}>
                                                                    <h5>Critical Elevated sent for Approval</h5>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '8vh' }}>
                                                                    <Button variant="primary" className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`} style={{ height: "43px", backgroundColor: '#007AFF', color: 'white', border: "none" }} onClick={() => { handleBackButtonClick(); setShowModal(false); }}>
                                                                        Ok
                                                                    </Button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Modal.Body> */}
                            {/* <Modal.Footer style={{ border: '0' }}>
                                                        <div className="d-flex justify-content-center">
                                                            <Button variant="primary" className="mx-auto" onClick={() => { handleBackButtonClick(); setShowModal(false); }}>
                                                                Ok
                                                            </Button>
                                                        </div>
                                                    </Modal.Footer> */}
                          </Modal>
                          {/* <button type="button" onClick={handleApproved}
                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`} style={{ height: "50px", backgroundColor: '#007AFF', color: 'white', border: "none", marginTop: "10px" }}
                                                >Approve</button>
                                                <button type="button" onClick={handleReject} className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`} style={{ height: "50px", backgroundColor: '#FC2727', color: 'white', border: "none", marginTop: "10px" }}>Reject</button> */}
                        </div>
                        <div>
                          <Modal
                            show={showApproved}
                            onHide={() => setShowApproved(false)}
                            size="sm"
                            centered
                          >
                            <Modal.Body>
                              <p
                                style={{
                                  textAlign: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <h3>Permit Status</h3>
                              </p>
                              <div className="form-group">
                                <label htmlFor="name" class="centered-text">
                                  <h6>Permit Approved Successfully</h6>
                                </label>
                                {/* <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name"
                                                                placeholder="Type here"
                                                                value={status}
                                                                onChange={(e) => {
                                                                    setStatus(e.target.value);
                                                                }}
                                                            /> */}
                              </div>
                            </Modal.Body>
                            <Modal.Footer style={{ border: "0" }}>
                              <div
                                className="d-flex"
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary btn-lg w-100"
                                  onClick={handleAprrovedOk}
                                >
                                  Ok
                                </Button>
                              </div>
                              <span>{apiStatus}</span>
                            </Modal.Footer>
                          </Modal>
                        </div>

                        <div>
                          <Modal
                            show={showReject}
                            onHide={() => setShowReject(false)}
                            size="sm"
                            centered
                          >
                            <Modal.Body>
                              <p
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "15vh",
                                }}
                              >
                                <h3> Are you sure want to Reject?</h3>
                              </p>
                              <div className="form-group">
                                <label htmlFor="name">Reason:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  placeholder="Reason for Rejection"
                                  value={remarks}
                                  onChange={(e) => {
                                    setRemarks(e.target.value);
                                  }}
                                />
                              </div>
                            </Modal.Body>
                            <Modal.Footer style={{ border: "0" }}>
                              <div
                                className="d-flex"
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="primary btn-lg w-100"
                                  onClick={handleRejectOk}
                                >
                                  Ok
                                </Button>
                              </div>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </form>
                      {/* </div>
                                            </div> */}
                      {/* </div>
                                    </div> */}
                    </div>
                  </div>
                  {/*  Clseout end */}
                </div>
                {/* <Footer /> */}
                {/* ... End of Footer...  */}
              </div>
            </div>
            {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
            <a className="scroll-to-top rounded" href="#page-top">
              <i className="fas fa-angle-up"></i>
            </a>
            {/*  <!-- Logout Modal--> */}
            {/* <Logout /> */}
          </div>
        </>
      )}
      {isLoading && (
        <Loader_Bulk
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
          loderimage={loaderImage}
        />
      )}
    </body>
  );
};

export default CriticalElevated;
