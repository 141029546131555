import React, { useState } from 'react';
function AdditionalComments(props) {
  // const [inputValue, setInputValue] = useState('');
  const handleInputChange = (e) => {
    props.onDataChange({...props.data, description:e.target.value})
  };
  return (
    <div>
      <div className="form-group" style={{padding:"0px",margin:"-19px"}} >
        <div ><span className='required' style={{float:"right"}}>*</span>
          <textarea 
            className="form-control"
            type="text"
            id="inputPassword"
            placeholder="Additional Comments"
            value={props.data.description || ''} 
            onChange={handleInputChange} 
          />
        </div>
      </div>
    </div>
  );
}
export default AdditionalComments;
