export const corePermitInitialsConst = {
  "initialsOne":"",
  "initialsTwo":"",
  "initialsThree":"",
  "dateInputOne":"",
  "dateInputTwo":"",
  "dateInputThree":"",
  "dateInputFour":"",
  "dateInputFive":"",
  "dateInputSix":"",
  "permitsOne" :"",
  "permitsTwo":""  
}

export const EmergencyConst ={
  "phoneNumber": "",
  "assemblyLocation": ""
}
export const PermitIssuerConst ={
  
  "permitissuer": "",
  "date":""
}
export const PermitReceiverConst ={
  "permitreceiver": "",
  "date": ""
}

export const BasicDetailsConstant = {
  "company": "",
  "issueDate": "",
  "locationForCraneWork": "",
  "expirationDate": "",
  "craneOperatorDate": ""
}

export const EqipmentInformationConst = {
  "size_or_capacity_in_tons": "",
  "make_or_model": "",
  "searal": "",
  "type": [
    {
      "cbTitle": "Boom",
      "checked": false,
      "id": "1",
    },
    {
      "cbTitle": "Hydraulic",
      "checked": false,
      "id": "2"
    },
    {
      "cbTitle": "All Terrain",
      "checked": false,
      "id": "3",

    },
    {
      "cbTitle": "Truck",
      "checked": false,
      "id": "4",

    },
    {
      "cbTitle": "Lattice",
      "checked": false,
      "id": "5",

    },
    {
      "cbTitle": "Rough Terrain",
      "checked": false,
      "id": "6",

    },
    {
      "cbTitle": "Crawler",
      "checked": false,
      "id": "7",

    }
  ],
  "scopeOfWork": "",
}


export const ImportantConst = {
  "question": "Review this checklist with the crew completing crane operations. All answers must be answered Yes or N/A. If no, then take proper corrective measures.",
  "capacity" : "",
  "importantList": [
    {
      "cbTitle": "Have proper crane escort precautions/safeguards been communicated?",
      "checked": false,

    },
    {
      "cbTitle": "Has a pre-lift plan for the job been completed and communicated to all personnel involved in the lift?",
      "checked": false,
    },
    {
      "cbTitle": "Does the crane on-site match what is in the plan? (Size/Capacity/Type)",
      "checked": false,
    },
    {
      "cbTitle": "Has a rigging plan been completed and communicated? (Equipment, configuration, working load limits)",
      "checked": false,

    },
    {
      "cbTitle": "Have ground conditions been verified and communicated? (Underground drawings, utilities, tanks, etc.)",
      "checked": false,

    },
    {
      "cbTitle": "Have locations of overhead electric power lines been communicated? (Within 20 ft)",
      "checked": false,

    },
    {
      "cbTitle": "Have weather conditions been evaluated? (High wind, lightning, ice, etc.)",
      "checked": false,

    },
    {
      "cbTitle": "Has proximity to existing equipment/adjacent hazards been communicated?",
      "checked": false,

    },
    {
      "cbTitle": "Have barricades been effectively used to protect people from all hazards? (Swing radius of counterweights, outriggers, lifting zone)",
      "checked": false,

    },
    {
      "cbTitle": "Have all work and lift areas been evacuated? (This includes occupied buildings, structures, or buildings designed to be occupied.)",
      "checked": false,

    },
    {
      "cbTitle": "Have proper inspections been documented? Crane (Daily)",
      "checked": false,
    },
    {
      "cbTitle": "Have proper inspections been documented? Crane (Annually)",
      "checked": false,

    },
    {
      "cbTitle": "Have proper inspections been documented? Rigging Equipment (Before each use)",
      "checked": false,

    },
    {
      "cbTitle": "Has load capacity been verified for crane configuration?",
      "checked": false,

    },
   
  ],
  
  "cerifications": {
    "question": "Are certificates,qualifications and training in place prior to work beginning?(All Individuals)",
    "cerificationsList": [
      {
        "cbTitle": "National Commision certified Crane Opeation(NCCCO)or national equivalent",
        "checked": false,

      },
      {
        "cbTitle": "Certified Signal Person",
        "checked": false,

      },
      {
        "cbTitle": "Qualified Rigger(S)",
        "checked": false,

      },
      {
        "cbTitle": "Lead Rigger Idenitified",
        "checked": false,

      },
      {
        "cbTitle": "Will a Critical Lift take place? If yes, a critical lift meeting is required.",
        "checked": false,

      },

    ],
  }
}

export const NoteConstant = {
  "question": "If a lift meets any of the following criteria,then it shall be deemed a critical lift",
  "noteList": [
    {
      "cbTitle": "Lift that exceeds 75% of the rated capacity of the crane.",
      "checked": false,
      "id": "1",
    },
    {
      "cbTitle": "Lift that exceeds 50% of rated capacity over power lines,process lines,or equipment.",
      "checked": false,
      "id": "1",
    },
    {
      "cbTitle": "Lift that involves the use of crane siuspended work platform/man-basket(requires an additional permit).",
      "checked": false,
      "id": "1",
    },
    {
      "cbTitle": "Lift that requires the use of more than one boom or another mobile lifting device.",
      "checked": false,
      "id": "1",
    },
    {
      "cbTitle": "Working with any part of a crane or load closer than 20 feet(6.1 meters)to an overhead electric line",
      "checked": false,
      "id": "1",
    },
    {
      "cbTitle": "Lift over an occupied building,strcutures,or building designed to be occupied.",
      "checked": false,
      "id": "1",
    },
    {
      "cbTitle": "Crane travelling with load that poses a hazard or exceeds 50% of the cranes's load chart under lifting condition.",
      "checked": false,
      "id": "1",
    },
    {
      "cbTitle": "Designated Site Crane Focal Point determines it to be a Critical Lift.",
      "checked": false,
      "id": "1",
    },
  ],


  // "permit": {
  //   "permitissuer": "",
  //   "permitissuerdate": "",
  //   "permitireceiver": "",
  //   "permitireceiverdate": "",
  // },
}

export const LiftConditionsConst = {

  "title1": "For all cranes:(us the diagram to the above to fill in the information below)",
  "r": "",
  "h": "",
  "l": "",
  "w": "",

  "Lifting in Quadrants": "",
  "title2": "Outrigger mat sizes(front and back)",
  "m_front_length": "",
  "m_front_width": "",
  "m_front_height": "",
  "m_back_length": "",
  "m_back_width": "",
  "m_back_height": "",


  "question3": "Mat sizes are approved by an engineer?",
  "question3_ans": "",
  "question4": "For Crawler cranes",
  "question5": "Maximum pressure applied by the tracks",
  "question5_ans": "",
  "question6": "If track pressure is gretaer than allowable surface bearing pressure,how will surface be stabilized?",
  "question6_ans": "",


    "one" : "" ,
    "two" : "" ,
    "three" : "",
    "four" : "",


}
