


import React, { useState } from 'react'
import { useEffect } from 'react'

function AirMonitoring(props) {
    const handleGeneralInfo = (val, key, subKey, type, index = null) => {
        if (type == 1) {
            props.setAirMonitoring(prev => ({ ...prev, [key]: val }))
        } else if (type == 2) {
            const newArr = props.data[key]
            newArr[index][subKey] = val
            props.setAirMonitoring(prev => ({ ...prev, [key]: newArr }))
        }
    }


    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>

                        <div>
                            <p style={{ color: "#000000", fontWeight: "600" }}>Monitor for dust is performed by visual observation <b>STOP WORK ABOVE 0%LFL</b> <span className='required'> * </span>  </p>
                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                                {/* <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                <label class="form-check-label" for="option1">N/A Intial/survey-Low Energy(Hazardous Area)</label> */}

                                <input class="form-check-input" type="checkbox" checked={props.data.list[0].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[0].cbValue == 'true' ? 'false' : 'true', 'list', 'cbValue', 2, 0,)
                                }} name="answer2" id={`airMonitors1p1`} value="option2" />
                                <label class="form-check-label" for={`airMonitors1p1`}>N/A Intial/survey-Low Energy(Hazardous Area)</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                                {/* <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                <label class="form-check-label" for="option1">Continuous-Mandatory for High Energy(Hazardous Area)</label> */}
                                <input class="form-check-input" type="checkbox" checked={props.data.list[2].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[2].cbValue == 'true' ? 'false' : 'true', 'list', 'cbValue', 2, 2)
                                }} name="answer2" id={`airMonitorsp2`} value="option2" />
                                <label class="form-check-label" for={`airMonitorsp2`}>Continuous-Mandatory for High Energy(Hazardous Area)</label>
                            </div>

                        </div>
                        <div class="form-group">
                            <div class="form-check m-2">
                                {/* <input class="form-check-input" type="checkbox" name="answer1" id="option1" value="option1" />
                                <label class="form-check-label" for="option1">N/A General Area or dust</label> */}
                                <input class="form-check-input " type="checkbox" checked={props.data.list[1].cbValue == 'true'} onClick={() => {
                                    handleGeneralInfo(props.data.list[1].cbValue == 'true' ? 'false' : 'true', 'list', 'cbValue', 2, 1)
                                }} name="answer2" id={`airMonitorrss1}`} />
                                <label class="form-check-label" for={`airMonitorrss1}`}>N/A General Area or dust</label>

                            </div>
                        </div>
                        <div style={{ overflowX: "auto" }}>
                            <table class="table hwptbl ar" style={{ width: "100%" }}>
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">
                                            <label class="col-12 col-form-label">AIR MONITOR EQUIPMENT</label>
                                        </th>
                                        <th scope="col">
                                            <label class="col-12 col-form-label">BUMP TEST</label>
                                        </th>
                                        <th scope="col">
                                            <label class="col-12 col-form-label">LOCATION(S)</label>
                                        </th>
                                        <th scope="col">
                                            <label class="col-12 col-form-label">NAME</label>
                                        </th>
                                        <th scope="col">
                                            <label class="col-12 col-form-label">RESULT</label>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.data.airMonitor.map((i, index) => (
                                            <tr>
                                                <td><input class="form-control col-12" id="inputPassword" placeholder="" value={i.airMonitorEqipment} onChange={(e) => {
                                                    handleGeneralInfo(e.target.value, 'airMonitor', 'airMonitorEqipment', 2, index)
                                                }} /></td>

                                                <td>
                                                    <div class="form-group row m-1">
                                                        <div>
                                                            {/* <div class="col-6 form-check form-check-inline">
                                                                <input class="form-check-input mt-1" type="checkbox" checked={i.bumpTestPass == 'true'} onClick={() => {
                                                                    handleGeneralInfo(i.bumpTestPass == 'true' ? 'false' : 'true', 'airMonitor', 'bumpTestPass', 2, index)
                                                                }} id={`bumppass${index}`} value="option1" />
                                                                <label class="form-check-label" for={`bumppass${index}`}>PASS</label>

                                                            </div> */}
                                                            <div class="col-6 form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input mt-1"
                                                                    type="checkbox"
                                                                    checked={i.bumpTestPass == 'true'}
                                                                    onClick={() => {
                                                                        if (i.bumpTestfail == 'false') { // Check if Fail is not checked
                                                                            handleGeneralInfo(i.bumpTestPass == 'true' ? 'false' : 'true', 'airMonitor', 'bumpTestPass', 2, index);
                                                                            // const handleGeneralInfo = (val, key, subKey, type, index = null)
                                                                        }
                                                                        // Disable the Fail checkbox when Pass is checked
                                                                        if (i.bumpTestPass == 'false') {
                                                                            handleGeneralInfo('false', 'airMonitor', 'bumpTestfail', 2, index);
                                                                        }
                                                                    }}
                                                                    id={`bumppass${index}`}
                                                                    value="option"
                                                                />
                                                                <label class="form-check-label" for={`bumppass${index}`}>PASS</label>
                                                            </div>

                                                            {/* <div class="col-6 form-check form-check-inline">
                                                                <input class="form-check-input mt-1" type="checkbox" checked={i.bumpTestfail == 'true'} onClick={() => {
                                                                    handleGeneralInfo(i.bumpTestfail == 'true' ? 'false' : 'true', 'airMonitor', 'bumpTestfail', 2, index,)
                                                                }} id={`bumpfail${index}`} value="option2" />
                                                                <label class="form-check-label" for={`bumpfail${index}`}>FAIL</label>
                                                            </div> */}
                                                            <div class="col-6 form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input mt-1"
                                                                    type="checkbox"
                                                                    checked={i.bumpTestfail == 'true'}
                                                                    onClick={() => {
                                                                        // Toggle the value of 'bumpTestfail'
                                                                        handleGeneralInfo(i.bumpTestfail == 'true' ? 'false' : 'true', 'airMonitor', 'bumpTestfail', 2, index);

                                                                        // Disable 'bumpTestPass' when 'bumpTestfail' is checked
                                                                        if (i.bumpTestfail == 'true') {
                                                                            handleGeneralInfo('false', 'airMonitor', 'bumpTestPass', 2, index);
                                                                        }
                                                                    }}
                                                                    id={`bumpfail${index}`}
                                                                    value="option2"
                                                                />
                                                                <label class="form-check-label" for={`bumpfail${index}`}>FAIL</label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </td>


                                                <td><input class="form-control col-12" id="inputPassword" placeholder="" value={i.locations} onChange={(e) => {
                                                    handleGeneralInfo(e.target.value, 'airMonitor', 'locations', 2, index)
                                                }} /></td>

                                                <td>
                                                    {/* <input class="form-control col-12" id="inputPassword" placeholder="" value={i.name} onChange={(e) => {
                                                    handleGeneralInfo(e.target.value, 'airMonitor', 'name', 2, index)
                                                }} /> */}
                                                    <input
                                                        class="form-control col-12"
                                                        id="inputPassword"
                                                        placeholder=""
                                                        value={i.name}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const alphabetsOnly = inputValue.replace(/[^a-zA-Z .]/g, ''); // Replace non-alphabet characters with an empty string
                                                            handleGeneralInfo(alphabetsOnly, 'airMonitor', 'name', 2, index);
                                                        }}
                                                    />


                                                </td>

                                                <td>
                                                    <div class="form-group row m-1">
                                                        <div>
                                                            <div class="col-12 form-check form-check-inline">

                                                                <input class="form-check-input mt-1" type="checkbox" id={`oFailBump${index}`} checked={i.result == 'true'} onClick={() => {
                                                                    handleGeneralInfo(i.result == 'true' ? 'false' : 'true', 'airMonitor', 'result', 2, index)
                                                                }} value="option2" />
                                                                <label class="form-check-label" for={`oFailBump${index}`}>0% LFL</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                        ))
                                    }


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AirMonitoring;
