import React, { useState } from "react";
import { getNewListWithModifyChecked } from '../../Utilities/utils';



const CorePermitResponsibilities = ({ respData, setRespData,approvalStatus }) => {
  const handleRespData = (key, val) => {
    setRespData((prev) => ({ ...prev, [key]: val }));
  };



  return (
    <div>

      <div className="Corper-subtxt wenv-sub-bg-color" style={{ marginTop: "-20px" }}>
        <p className="card-subheading wenv-sub-bg-color">
          Responsibilities communicated to permit receiver:<span style={{ color: "red" }}> *</span>
        </p>
      </div>

      {respData.responsibilitiesList.map((data, index) =>

      // alert("JSON STRINGY"+data[0].inputAvaiable) 


      (
        <div class="form-group row mt-2">
          <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
            <div class="form-check form-check-inline d-flex align-content-center">
              {data.cbTitle != '' && (

                <input
                  class="form-check-input  mt-0"
                  disabled={approvalStatus}
                  type="checkbox"               
                  checked={data.checked}
                  onChange={() =>
                    setRespData({
                      ...respData,
                      environmentList: getNewListWithModifyChecked(index, respData.responsibilitiesList),
                    })
                  }
                />
              )}
              <label class="form-check-label  mt-0" >
                {data.cbTitle}{" "}
              </label>
            </div>



          </div>

        </div>

      ))}


      <div className="Corper-subtxt wenv-bg-color">
        <p className="card-subheading wenv-bg-color">
          Permit receiver:
        </p>
      </div>
      <div class="form-group">
        <label>Initials</label>
        <div>
          {/* <input
            class="form-control col-12"
            id="input"
            placeholder=""
            value={respData.initials}
            onChange={(e) => handleRespData('initials', e.target.value)}
          /> */}
          <input
            class="form-control mb-1"
            id="inputPassword"
            type='text'
            readOnly={approvalStatus}
            placeholder=""
            value={respData?.initials || ''}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Use a regular expression to match only alphabets (A-Z and a-z)
              const alphabetsOnly = inputValue.replace(/[^a-zA-Z .]/g, '');
              handleRespData('initials', alphabetsOnly);
            }}
          />
        </div>

      </div>
      <div class="form-group">
        <label >Phone#</label>
        <div>
        
          <input
            class="form-control"
            id="ph"
            readOnly={approvalStatus}
            type="text"
            maxLength={10}
            value={respData.phone}
            onChange={(e) => {
              const inputText = e.target.value;
              const sanitizedInput = inputText.replace(/[^0-9]/g, ''); // Remove non-numeric characters
              const validPhoneNumber = /^[^6789]/.test(sanitizedInput) ? '' : sanitizedInput; // Check if it starts with 0, 6, 7, 8, or 9

              handleRespData('phone', validPhoneNumber);
            }}
          />

        </div>
      </div>

    </div>
  );
};

export default CorePermitResponsibilities;
