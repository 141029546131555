import {useState} from "react";
import { useNavigate ,} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery'; 

import Form from 'react-bootstrap/Form';

const Logout = () => {
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleLogout = () => {
   // $("#logoutModal").modal('close');
  // window.$('#addSupModal').modal('hide')
    localStorage.removeItem('authToken');
    sessionStorage.clear();
    window.location.href='/';
   // nav("/");
  };

  return (
    <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
          </div>
          <div className="modal-body">Are You sure you want to LogOut ?</div>
          <div className="modal-footer">
            <button className="btn btn-primary" type="button" data-dismiss="modal">Cancel</button>
            <button className="btn btn-danger cursor-pointer" onClick={handleLogout}>Logout</button>
          </div>
        </div>
      </div>
    </div>
  //  
  );
};

export default Logout;
