import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import SideMenu from "../../Pages/SideMenu";
import Footer from "../../Pages/Footer";
import Header from "../../Pages/Header";
import Logout from "../../Pages/Logout";
import { strings } from "../../Utilities/strings";
// import 'datatables.net';
// import 'datatables.net-dt/css/jquery.dataTables.css';
// for Sidebar And Navbar
import Search from "../../images/search.png";
//for fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faEye,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
//for data table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { GetApiHeaders } from "../../Utilities/NetworkUtilities";
//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import {
  openStock,
  getMasters,
  openStockDetails,
} from "../../Utilities/URLCONSTANTS";
// import {Oval as Loader} from 'react-loader-spinner';
import Loader from "../../Utilities/Loader";
import ViewIcon from "../../images/viewIcon.png";
import moment from "moment";
import * as XLSX from "xlsx";

const OpenStock = () => {
  const [loaderImage, setLoaderImage] = useState(
    require("../../images/cs_loader.gif")
  );
  const [submitResponse, setsubmitResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [productCategoryID, setProductCategoryID] = useState();
  const [productCategory, setProductCategory] = useState();
  const [productNameID, setProductNameID] = useState();
  const [productName, setProductName] = useState();
  const [getData, setGetData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [getMasterLocation, setGetMasterLocation] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState();

  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }
    $(tableRef.current).DataTable({
      ordering: false,
    });
    fetchData();
    getMasterData();
  }, []);
  const handleModalClose = () => {
    setShowPopup(false);
    setShowModal(false);
    window.location.reload();
  };

  const handleModalOpen = (item, index) => {
    setShowModal(true);
    setSelectedItem(item);
    setSelectedItemIndex(index);
  };
  const closeWarning = () => {
    setShowWarningPopup(true);
  };
  const handleClose = () => {
    setShowPopup(false);
    setShowModal(false);
    setShowWarningPopup(false);
  };
  const filteredData = data.filter((item) => {
    return (
      selectedLocation === "" || item.productStoreLocation === selectedLocation
    );
  });
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to the first page when changing items per page.
  };

  const pageNumbers = [];
  //
  for (
    let i = Math.max(2, currentPage - 1);
    i <= Math.min(Math.ceil(totalItems / itemsPerPage) - 1, currentPage + 1);
    i++
  ) {
    // for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const fetchData = async () => {
    const GetApi = openStock;
    const headers = await GetApiHeaders();
    try {
      setIsLoading(true);
      const response = await axios.get(GetApi, { headers });
      console.log("openStock", response);
      setData(response.data.response.openStock);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleData = async () =>{
  //   const DataApi = openStockDetails;
  //   const sendData = {
  //     productCategoryID: "productCategoryID",
  //     productCategory: "productCategory",
  //     productNameID: "productNameID",
  //     productName: "productName",
  //   }
  //   const headers = {
  //     " Accept": "application/json",
  //     "Content-Type": "application/json",
  //     userId: 1,
  //     mobileNumber: 9704164746,
  //     "deviceType": "Web"
  //   }
  //  try{
  //   const responseData = await axios.post(DataApi,{headers},sendData)
  //   console.log("dataResponse", responseData.data);
  //  }catch(error){
  //   console.log(error)
  //  }
  // }
  const handleData = async (item) => {
    const DataApi = openStockDetails;
    const headers = await GetApiHeaders();
    const sendData = {
      productCategoryID: item.productCategoryID,
      productCategory: item.productCategory,
      productNameID: item.productNameID,
      productName: item.productName,
    };
    console.log(item);
    try {
      const responseData = await axios.post(DataApi, sendData, { headers });

      console.log("dataResponse", responseData.data);

      setGetData(responseData.data.response.openStock);
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterData = async () => {
    const getData = getMasters;
    const headers = await GetApiHeaders();
    // const headers ={
    //   "Accept": "application/json",
    //   "Content-Type": "application/json",
    //   // "deviceType": "Web",
    //   userId: 1,
    //   mobileNumber: 9704164746
    // }
    try {
      const responseGetData = await axios.post(getData, null, { headers });
      console.log("getmaster Data,", responseGetData);
      setGetMasterLocation(responseGetData.data.response.stockInwardLocations);
    } catch (err) {
      console.log("getError", err);
    }
  };

  const exportToExcel = () => {
    const data = [
      [
        "S.no",
        "Product Name",
        "Product Location",

        "Availability",
        "Issued",
        "Balance Stock",
        "Manufacture Date",
        "Expiry Date",
      ], // Custom headers
      // ...currentItems.map((item) => [
      ...filteredData.map((item, index) => [
        index + 1,
        item.productName,
        item.productStoreLocation,
        item.inWardStock,
        item.issuedStock,
        item.balanceStock,
        item.productInDate,
        item.productExpiryDate,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Save the file
    XLSX.writeFile(wb, "openSotck.xlsx");
  };

  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}
        <SideMenu />
        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <Header />
            {/* ...Content Start... */}
            <div>
              <div className="container-fluid">
                <h5 className="Roles_text">Central Store</h5>

                <div>
                  <h5 className="mb-2 Sub_role_text"> Open Stock</h5>
                </div>
                <div className="card border-0">
                  <div className="card-body pt-0 pl-1">
                    <div className="d-flex justify-content-between"></div>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ paddingBottom: 20 }}
                    >
                      <div className="d-flex align-items-center">
                        <label className="mr-2">Show </label> &nbsp;&nbsp;
                        <select
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                        &nbsp;&nbsp;
                        <label> Entries</label>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* Search Input */}
                        <select
                          className="selector border border-info mt-2"
                          id="dropdown"
                          type="dropdown"
                          onChange={(e) => setSelectedLocation(e.target.value)}
                          value={selectedLocation}
                        >
                          <option>select Location</option>
                          {getMasterLocation != undefined &&
                            getMasterLocation.map((items, itemsIndex) => {
                              return (
                                <option key={items.code} value={items.name}>
                                  {items.name}
                                </option>
                              );
                            })}
                        </select>
                        &nbsp;
                        <div className="my-2 d-flex w-full justify-content-end gap-2">
                          <button
                            className="btn btn-secondary buttons-excel buttons-html5"
                            onClick={exportToExcel}
                          >
                            Download Excel
                          </button>
                          
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="card data_tableCard mr-2 ml-1"
                        style={{ width: "100%", overflowX: "auto" }}
                      >
                        <div className="card-body ">
                          <Table className="table table-bordered table-bg Content_data_table table-striped">
                            {/* <ref={tableRef}/> */}
                            <Thead>
                              <Tr>
                                <Th className="text-center">S.No</Th>
                                <Th className="text-center">Item Name</Th>

                                <Th className="text-center">Location</Th>
                                <Th className="text-center">Availabality</Th>
                                <Th className="text-center">Issued </Th>
                                <Th className="text-center">Balance</Th>
                                <Th className="text-center">MFG Date</Th>
                                <Th className="text-center">Expiry Date</Th>
                                <Th className="text-center">Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {currentItems.length === 0 ? (
                                <Tr>
                                  <Td colSpan={9} className="text-center">
                                   <span style={{padding:195}}>Data is not available</span> 
                                  </Td>
                                </Tr>
                              ) : (
                                currentItems.map((item, index) => {
                                  // var newDateFormat = moment( item.productManufactureDate  ).format("DD-MMM-YYYY ");
                                  var newDateFormat = moment(item.productManufactureDate); 
                                  if (newDateFormat.isValid()) {
                                    newDateFormat = newDateFormat.format("DD-MMM-YYYY");
                                  } else {
                                    newDateFormat = "";
                                  }

                                  var ExpiryDAte = moment( item.productExpiryDate);
                                  if (ExpiryDAte.isValid()) {
                                    ExpiryDAte = ExpiryDAte.format("DD-MMM-YYYY ")
                                  }else{
                                    ExpiryDAte = "";
                                  }
                                  return (
                                    <Tr key={index}>
                                      <Td className="text-left">{(currentPage - 1) * itemsPerPage + index + 1}</Td>
                                      <Td className="text-left">
                                        {item.productName}
                                      </Td>

                                      <Td className="text-left">
                                        {item.productStoreLocation}
                                      </Td>
                                      <Td className="text-left">
                                        {item.inWardStock}
                                      </Td>
                                      <Td className="text-left">
                                        {item.issuedStock}
                                      </Td>
                                      <Td className="text-left">
                                        {item.balanceStock}
                                      </Td>

                                      <Td className="text-center">
                                        {newDateFormat}{" "}
                                      </Td>
                                      <Td className="text-center">
                                        {ExpiryDAte}{" "}
                                      </Td>

                                      <Td
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div onClick={() => handleData(item)}>
                                          <img
                                            src={ViewIcon}
                                            onClick={handleModalOpen}
                                            alt="viewIcon"
                                            style={{
                                              height: 10,
                                              cursor: "pointer",
                                              color: "#007AFF",
                                            }}
                                          />
                                        </div>
                                      </Td>
                                    </Tr>
                                  );
                                })
                              )}
                            </Tbody>
                          </Table>
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ borderRadius: 5, padding: 11 }}
                        >
                          <div>
                          Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, totalItems)}&nbsp; of {totalItems} entries
                          </div>
                          <div>
                            <div className="div-button text-center">
                              <button
                                className={`borderleft-radius-5 ${
                                  currentPage === 1 ? "active" : ""
                                }`}
                                //  className='borderleft-radius-5'
                                // className='ul-pagination'
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Prev
                              </button>
                              <button
                                //  className='ul-pagination page-item'
                                //   style={{
                                //     backgroundColor: (currentPage == 1) ? '#0056b3' : 'white',
                                //     color: (currentPage == 1) ? 'white' : 'black'
                                //   }}
                                className={`ul-pagination page-item ${
                                  currentPage === 1 ? "active" : ""
                                }`}
                                key={1}
                                onClick={() => handlePageClick(1)}
                                disabled={currentPage === 1}
                              >
                                {1}
                              </button>
                              {pageNumbers.map((number) => (
                                <button
                                  className={`ul-pagination page-item ${
                                    currentPage === number ? "active" : ""
                                  }`}
                                  //className='ul-pagination page-item'
                                  //   style={{
                                  //     backgroundColor: (currentPage == number) ? '#0056b3' : 'white',
                                  //     color: (currentPage == number) ? 'white' : 'black'
                                  //   }}
                                  key={number}
                                  onClick={() => handlePageClick(number)}
                                  disabled={currentPage === number}
                                >
                                  {number}
                                </button>
                              ))}
                              {Math.ceil(totalItems / itemsPerPage) > 1 &&
                                currentPage !==
                                  Math.ceil(totalItems / itemsPerPage) - 1 &&
                                currentPage !==
                                  Math.ceil(totalItems / itemsPerPage) && (
                                  <button
                                    className="ul-pagination page-item"
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                  >
                                    ...
                                  </button>
                                )}
                              {Math.ceil(totalItems / itemsPerPage) > 1 && (
                                <button
                                  className={`ul-pagination page-item ${
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                      ? "active"
                                      : ""
                                  }`}
                                  // className='ul-pagination page-item'
                                  // style={{
                                  //   backgroundColor: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? '#0056b3' : 'white',
                                  //   color: (currentPage == (Math.ceil(totalItems / itemsPerPage))) ? 'white' : 'black'
                                  // }}
                                  key={Math.ceil(totalItems / itemsPerPage)}
                                  onClick={() =>
                                    handlePageClick(
                                      Math.ceil(totalItems / itemsPerPage)
                                    )
                                  }
                                  disabled={
                                    currentPage ===
                                    Math.ceil(totalItems / itemsPerPage)
                                  }
                                >
                                  {Math.ceil(totalItems / itemsPerPage)}
                                </button>
                              )}
                              <button
                                className={`borderright-radius-5 ${
                                  currentPage ===
                                  Math.ceil(totalItems / itemsPerPage)
                                    ? "active"
                                    : ""
                                }`}
                                //className='borderright-radius-5'
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={indexOfLastItem >= totalItems}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Modal
                            show={showModal}
                            onHide={handleModalClose}
                            centered
                            backdrop="static"
                            keyboard={false}
                            size="xl"
                            style={{ marginLeft: -20 }}
                          >
                            <Modal.Header className="border-0 d-flex justify-content-center">
                              <Modal.Title> Open Stock </Modal.Title>
                              <Button
                                variant="link"
                                className="close btn btn-danger close_btn"
                                onClick={handleClose}
                              >
                                X
                              </Button>
                            </Modal.Header>

                            <Modal.Body
                              className="pl-md-5 pr-md-5 pt-0"
                              style={{ overflowX: "auto", width: "100%" }}
                            >
                              <Table className="table table-bordered table-bg Content_data_table table-striped ">
                                <Thead>
                                  <Tr>
                                    <Th className="">S.No</Th>
                                    <Th
                                      className="item-name-cell"
                                      style={{
                                        whiteSpace: "normal",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      Item Name
                                    </Th>
                                    <Th className="">Batch No</Th>
                                    <Th className="">Invoice No</Th>
                                    <Th className="">Stock Inward</Th>
                                    <Th className="">Balance</Th>
                                    <Th className="">Issued</Th>

                                    <Th className="">MFG Date</Th>
                                    <Th className="">Expiry Date</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {getData.length === 0 ? ( // Check if no data is found
                                    <Tr   >
                                      <Td colSpan={13} className="dataNotAvailable text-center"  >
                                        Data is not available
                                      </Td>
                                    </Tr>
                                  ) : (
                                    getData.map((item, getIndex) => {
                                      var newDateFormat = moment(
                                        item.productManufactureDate
                                      ).format("DD-MMM-YYYY ");
                                      var ExpiryDAte = moment(
                                        item.productExpiryDate
                                      ).format("DD-MMM-YYYY ");

                                      return (
                                        <Tr key={getIndex}>
                                          <Td>{getIndex + 1}</Td>
                                          <Td className="item-name-cell d-flex">
                                            {item.productName}
                                          </Td>
                                          <Td>{item.batchNumber}</Td>
                                          <Td>{item.productInvoiceNumber}</Td>
                                          <Td>{item.inWardStock}</Td>
                                          <Td className="text-center">-</Td>
                                          <Td className="text-center">-</Td>

                                          <Td>{newDateFormat}</Td>
                                          <Td>{ExpiryDAte}</Td>
                                        </Tr>
                                      );
                                    })
                                  )}
                                </Tbody>
                              </Table>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isLoading && (
                    <Loader
                      loading={isLoading}
                      message={"Fetching Data. Please Wait..!"}
                      loderimage={loaderImage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ...End of Main Content...*/}

          {/* ...Footer...*/}
          <Footer />
          {/* ... End of Footer...  */}
        </div>
      </div>
      {/*  <!-- End of Page Wrapper -->

                    <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      {/*  <!-- Logout Modal--> */}
      <Logout />
    </body>
  );
};

export default OpenStock;
