import React, { useEffect, useState } from "react";
import { Chart, registerables } from 'chart.js';
import { Bar } from "react-chartjs-2";
import { getTurnAroundTime } from "../Utilities/URLCONSTANTS";
import axios from "axios";
import TurnOverTime from '../images/turnoverTime.png'
import Box from '../images/box.png'
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
 
Chart.register(...registerables);
const TurnAroundTime = () => {

    const [turnAroundData , setTurnAroundData]= useState([]);
    const [durationLabels , setDurationLabels]= useState([]);

    const fetchData = async () =>{
    const trunAroundRequest = getTurnAroundTime; 
    try{ 
    const response = await axios.get(trunAroundRequest)   
    
    setTurnAroundData(response.data.response.datasets); 
    setDurationLabels(response.data.response.durationDays); 
    }catch(error){
    console.log("trunAroundRequest", error)
    }
    }

    useEffect(()=>{
    fetchData()
    }, [])

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              boxWidth: 10, // Set box width for each legend item
              padding: 10, // Set padding between legend items
            }, 
          },
          title: {
            display: true,
            text: '',
            align: "start"
          },
        },
      };
      
      const labels = durationLabels; 
      const data = {
        labels,
         datasets: turnAroundData,
      };   

  return (
    <div className="card p-4 "> 
      <label className="chartname"><img src={TurnOverTime} style={{width:20}}/> TAT(Turnaround time)</label>
      <div className="chart-container"style={{height:'230px',marginTop:'-40px'}}>
       <Bar options={options} data={data} />
    </div>
    </div>
  );
};

export default TurnAroundTime;
