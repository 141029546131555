import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
// for Sidebar And Navbar
import Search from "../images/search.png";
import Notification from "../images/notification.png";
import Switch from "../images/Switch.png";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { notifications, changePassword } from "../Utilities/URLCONSTANTS";
//for modal
import "bootstrap/dist/css/bootstrap.min.css";
import { USER_PROFILE, MOBILE_NUMBER, retrieveData } from "../Utils";
import { useNavigate } from "react-router-dom";
import { GetApiHeaders } from "../Utilities/NetworkUtilities";
import { Button, Modal } from "react-bootstrap";
import { Base64 } from "js-base64";
import ChangePassword from "../images/ChangePassword.png";
import LogoutIcon from "../images/logOutIcon.png";
import ViewIcon from "../images/loginViewIcon.png";
import CloseEyeIcon from "../images/loginCloseIcon.png";

var isUserLoggedIn;

function Header() {
  const [firstName, setFirstName] = useState();
  const [lastName, setlastName] = useState();
  const [roleTypeName, setRoleTypeName] = useState();
  const [error, setError] = useState("");
  const [data, setData] = useState();
  const [notificationCount, setNotificationCount] = useState("");
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [getLogin, setGetLogin] = useState([]);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [submitResponse, setsubmitResponse] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [isChangeButtonEnabled, setIsChangeButtonEnabled] = useState(false);
  isUserLoggedIn = sessionStorage.getItem("MOBILE_NUMBER");

  // 🆗 Ship it
  useEffect(() => {
    navigateToLogin();
    (async () => {
      const users = await retrieveData("USER_PROFILE");
      setFirstName(users.firstName);
      setlastName(users.lastName);
      setRoleTypeName(users.roleTypeName);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  const navigateToLogin = () => {
    if (
      isUserLoggedIn == undefined ||
      isUserLoggedIn.toString() == "false" ||
      isUserLoggedIn.toString() == "" ||
      isUserLoggedIn == null
    ) {
      window.location.href = "/";
    }
  };
  // for sidebar And Navbar
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const navigate = useNavigate();

  //for data table
  const tableRef = useRef(null);
  useEffect(() => {
    // Check if DataTable is already initialized
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // Destroy the existing DataTable instance
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize the DataTable
    $(tableRef.current).DataTable({
      ordering: false, // Disable sorting
    });
    fetchData();
  }, []);

  ///modal popup
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleNotification = () => {
    navigate("/Notification");
  };
  const handleSwitch = () => {
    navigate("/popup");
  };
  const fetchData = async () => {
    const notificationApi = notifications;
    const headers = await GetApiHeaders();
    const postData = {
      userType: "admin",
      mainAsset: "centralStore",
    };

    try {
      const response = await axios.post(notificationApi, postData, { headers });
      console.log("notification", response);
      const count = response.data.response.notifications.length;
      setNotificationCount(count);

      // setData(response.data.response.notifications)
    } catch (error) {
      console.log("Notification", error);
    }
  };
  const handleresponse = () => {
    setShowResponsePopup(false);
    setShowModal(false);
    window.location.reload();
  };
  const handleCancel = () => {
    setShowModal(false);
    setShowPasswordPopup(false);
    setShowResetPopup(false);
  };
  useEffect(() => {
    const userProfileJSON = sessionStorage.getItem(USER_PROFILE);
    setGetLogin(JSON.parse(userProfileJSON));
    console.log("User Profile:", JSON.parse(userProfileJSON));
  }, []);
  const handleModalForYes = () => {
    setShowPasswordPopup(false);
    setShowResetPopup(true);
  };
  const handleChangePassword = async () => {
    setShowPasswordPopup(true);
  };
  const handleOldPassword = (e) => {
    setOldPassword(e.target.value);
    if (e.target.value) {
      setError("");
    }
  };
  const handleCancelNew = () => {
    setShowResetPopup(false);
    window.location.reload();
  };

  const handleNewPassword = (e) => {
    const newPasswordValue = e.target.value;
    const isLengthValid = newPasswordValue.length >= 8; // Minimum length of 8 characters
    const hasUpperCase = /[A-Z]/.test(newPasswordValue); // At least one uppercase letter
    const hasLowerCase = /[a-z]/.test(newPasswordValue); // At least one lowercase letter
    const hasNumber = /\d/.test(newPasswordValue); // At least one digit
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPasswordValue); // At least one special character

    // Check all validation rules 
    if (
      !isLengthValid ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumber ||
      !hasSpecialChar
    ) {
      // If the password does not meet the criteria, set an error message
      setErrorMessage(
        "Please enter Strong password, password must contain @, alphanumerical values"
      );
    } else {
      // If the password is valid, update the state and clear the error message
      setNewPassword(newPasswordValue);
      setErrorMessage("");
    }
    if (newPasswordValue) {
      setPasswordError("");
    }
  };
  const handleResetPassword = async () => {
    if (!oldPassword) {
      setError("Enter Old Password");
    }
    if (!newPassword) {
      setPasswordError("Enter New Password");
    }
    const apiChangePassword = changePassword;
    const headers = await GetApiHeaders();
    const passData = {
      Id: getLogin.userId,
      oldPassword: Base64.encode(oldPassword),
      newPassword: Base64.encode(newPassword),
    };
    try {
      if (oldPassword !== "" && newPassword !== "") {
        const respons = await axios.post(apiChangePassword, passData, {
          headers,
        });
        console.log("password Checking", respons);
        if (respons.data.statusCode === "200") {
          setsubmitResponse(respons.data.message);
          setShowModal(false);
          setShowResetPopup(false);
          setShowPasswordPopup(false);
          setShowResponsePopup(true);
          setIsSubmitted(true);
        } else {
          setShowResponsePopup(false);
          setIsSubmitted(false);
          setErrorMessage(respons.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setIsSubmitted(false);
    }
  };
  const handleConfirmPassword = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);

    if (password !== newPassword) {
      setErrorMessage("New password, confirm password should be same");
    } else {
      setErrorMessage("");
    }
    // if (password) {
    //   setErrorMessage('')
    // }
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordOld = () => {
    setShowPasswordOld(!showPasswordOld);
  };
  useEffect(() => {
    const doPasswordsMatch = newPassword === confirmPassword;
    setIsChangeButtonEnabled(confirmPassword !== "" && doPasswordsMatch);
    setConfirmPasswordError(doPasswordsMatch ? "" : " ");
  }, [newPassword, confirmPassword]);

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar static-top Navbar_shadow">
        {/*  <!-- Sidebar Toggle (Topbar) --> */}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={changeStyle1}
        >
          <i className="fa fa-bars"></i>
        </button>

        <form
          className="d-none d-sm-inline-block 
          form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
        >
          {/* <div className="input-group ml-5 input-group w-100">
        <span className="input-group-text mcrc-input-group-text"
          id="inputGroup-sizing-sm">
          <img src={Search} alt='search' className='search_image'></img>
        </span>
        <input type="text" className="form-control p-2 search_input"
          aria-label="Sizing example input"
          aria-describedby="inputGroup-sizing-sm"
          placeholder='Type to search...' />
      </div> */}
        </form>
        {/*  <!-- Topbar Navbar --> */}
        <ul className="navbar-nav ml-auto">
          {/*  <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
          {/* notification */}
          <li className="nav-item dropdown no-arrow d-sm-none">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-search fa-fw"></i>
            </a>

            <div
              className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          {/* <!-- Nav Item - User Information --> */}

          <li className="nav-item no-arrow">
            <div
              className="nav-link align-items-center"
              id="userDropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {/* <img
              src={Switch}
              alt="Switch"
              className="Notification_image mr-4"
              onClick={handleSwitch}
            /> */}
              <button
                className="btn-sm mr-4"
                style={{
                  maxWidth: 75,
                  border: "none",
                  backgroundColor: "#6f42c1",
                  color: "white",
                  fontWeight: 500,
                  maxHeight: 27,
                }}
                onClick={handleSwitch}
              >
                Switch
              </button>

              <img
                src={Notification}
                alt="Notification"
                className="Notification_image "
                onClick={handleNotification}
              />
              <span
                className="badge badge-danger badge-pill"
                style={{ color: "red", width: "10px", height: "10px" }}
              >
                {/* {notificationCount} */}.
              </span>
            </div>
          </li>
          <li className="nav-item dropdown no-arrow">
            <div
              className="nav-link dropdown-toggle pr-4 align-items-center"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              
            >
              <img
                className="img-profile rounded-circle mr-3"
                src="img/undraw_profile.svg"
              />
              <div className="d-flex flex-column">
                {/* <span className='name'>Niharika</span> */}
                <strong className="userN mr-2 text-black md user_role">
                  {firstName} {lastName}
                </strong>
              </div>
            </div>
            <div className="d-flex flex-column">
              {/* <span className='name'>Niharika</span> */}
              <div className=" AdminName mr-2 text-gray-600 small user_role">
                {roleTypeName}
              </div>
            </div>
            {/*  <!-- Dropdown - User Information --> */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"  style={{minWidth:'200px'}}
            >
              {/* <div className="dropdown-item" href="#">
            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
            Settings
          </div>
          <div className="dropdown-item" href="#">
            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
            Activity Log
          </div> */}
              {/* <div className="dropdown-divider"></div> */}
              <div>
                <button
                  style={{ border: "none", background: "none" }}
                  onClick={() => handleChangePassword()}
                >
                  <img
                    className="text-gray-400"
                    src={ChangePassword}
                    style={{ marginBottom: 5, width: 12 }}
                  />{" "}
                  <span style={{ marginLeft: 5 }}>Change Password</span>
                </button>
              </div>
              <div
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <img
                  className="text-gray-400"
                  src={LogoutIcon}
                  style={{ marginBottom: 5, width: 12, marginLeft: 5 }}
                />{" "}
                <span style={{ marginLeft: 5 }}>Logout</span>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <div>
        <Modal
          show={showPasswordPopup}
          centered
          onHide={() => setShowPasswordPopup(false)}
        >
          <Modal.Header className="border-0 d-flex justify-content-center">
            <Modal.Title>
              <span className="modal_title">Change Password</span>
            </Modal.Title>
            <Button
              variant="link"
              className="close btn btn-danger close_btn"
              onClick={handleCancel}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="border-0 d-flex justify-content-center mb-3 font-weight-600">
              <p>Are You Sure, do you want to Change the password?</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center">
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                className="d-flex justify-content-center cancel_button"
                onClick={handleModalForYes}
              >
                Yes
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="danger"
                className="d-flex justify-content-center cancel_button"
                onClick={() => setShowPasswordPopup(false)}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showResetPopup}
          centered
          onHide={() => setShowResetPopup(false)}
          size="md"
        >
          <Modal.Header className="border-0 d-flex justify-content-center">
            <Modal.Title>
              <span className="modal_title">Change Password</span>
            </Modal.Title>
            <Button
              variant="link"
              className="close btn btn-danger close_btn"
              onClick={handleCancelNew}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
            <div className="col-md-12 col-12 mt-2">
              <label
                htmlFor="InputType"
                className="custom-input label-style form-label font-weight-600 mb-0"
              >
                Old Password
              </label>
              <div className="form-group mt-2">
                <input
                  type={showPasswordOld ? "text" : "password"}
                  className="form-control"
                  id=" "
                  placeholder="Password"
                  value={oldPassword}
                  onChange={handleOldPassword}
                />
                <span>
                  {showPasswordOld ? (
                    <img
                      src={ViewIcon}
                      onClick={handleTogglePasswordOld}
                      alt="closeEyeIcon"
                      className="passIcon "
                      style={{ width: 27, height: 13 }}
                    />
                  ) : (
                    <img
                      src={CloseEyeIcon}
                      onClick={handleTogglePasswordOld}
                      alt="viewIcon"
                      className="passIcon"
                      style={{ width: 27, height: 14 }}
                    />
                  )}
                </span>
              </div>
              
              {error && <div style={{ color: "red" }}>{error}</div>}
            </div>

            <div className="col-md-12 col-12 mt-2">
              <label
                htmlFor="InputType"
                className="custom-input label-style form-label font-weight-600 mb-0"
              >
                New Password
              </label>
              <div className="form-group mt-2">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id=" "
                  placeholder="Password"
                  value={newPassword}
                  onChange={handleNewPassword}
                />
                <span>
                  {showPasswordOld ? (
                    <img
                      src={ViewIcon}
                      onClick={handleTogglePassword}
                      alt="closeEyeIcon"
                      className="passIcon "
                      style={{ width: 27, height: 13 }}
                    />
                  ) : (
                    <img
                      src={CloseEyeIcon}
                      onClick={handleTogglePassword}
                      alt="viewIcon"
                      className="passIcon"
                      style={{ width: 27, height: 14 }}
                    />
                  )}
                </span>
              </div>
             
              {passwordError && (
                <div style={{ color: "red" }}>{passwordError}</div>
              )}
            </div>

            <div className="col-md-12 col-12 mt-2">
              <label
                htmlFor="InputType"
                className="custom-input label-style form-label font-weight-600 mb-0"
              >
                Confirm Password
              </label>
              <div className="input-group input-group w-100 mt-2">
                <input
                  type="text"
                  className="form-control p-2"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                />
              </div>
              {confirmPasswordError && (
                <div style={{ color: "red" }}>{confirmPasswordError}</div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center">
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                className="d-flex justify-content-center cancel_button"
                onClick={() => handleResetPassword()}
                disabled={!isChangeButtonEnabled}
              >
                Change
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="danger"
                className="d-flex justify-content-center cancel_button"
                onClick={handleCancelNew}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showResponsePopup}
          centered
          backdrop="static"
          keyboard={false}
          size="ml"
        >
          <Modal.Header className="border-0 d-flex justify-content-center">
            <Modal.Title>
              {" "}
              <span className="modal_title">Change Password</span>
            </Modal.Title>
            {/* <Button
              variant="link"
              className="close btn btn-danger close_btn"
              onClick={handleCancel}
            >
              X
            </Button> */}
          </Modal.Header>
          <Modal.Body className="pl-md-5 pr-md-5 pt-0">
            <div className="d-flex justify-content-center mb-3 font-weight-600">
              {showMsg ? (
                <p style={{ color: "green" }}>{submitResponse}</p>
              ) : null}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                className="d-flex justify-content-center cancel_button"
                onClick={handleresponse}
              >
                Okay
              </Button>
              &nbsp;&nbsp;
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Header;
