import React, { useState, useEffect, useRef, useContext } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logout from "./Logout";
import {
  getAllPermitsByCorePermitID,
  getAllRecievedRequests,
  getCorePermits,
} from "../Utilities/URLCONSTANTS";
import { Button, Modal } from "react-bootstrap";
import "../RandD.css";
import {
  COREPERMIT_DATE,
  COREPERMIT_MOBILE_NUMBER,
  COREPERMIT_VENDOR_NAME,
  CorePermitM2_PERMITID,
} from "../Utils";
import MyContext from "./MyContext";
import CorePermitM2 from "./CorePermitM2";
import ButtonAccordion from "../Accordation/ButtonAccordion";
import { Link, useNavigate } from "react-router-dom";
//import arrowIcon from "../images/arrowIcon.png";
import arrowIcon from "../images/Left-arrow.png";
import Loader from "../Utilities/Loader";
import { TransferCorePermit } from "../Utilities/URLCONSTANTS";

//Approve
import Transfer from "../images/Transfer.png";

//Approve
import Approved from "../images/Approved.png";
import Loader_MS from "../Utilities/Loader_MS";

const CorePermitM3 = () => {
  const [TransferToName, setName] = useState("");
  const [TransferToMobileNumber, setMobileNumber] = useState("");
  const [submitResponse, setsubmitResponse] = useState([]);
  const [showResponsePopup, setShowResponsePopup] = useState(false);
  const [showMsg, setShowMsg] = useState("false");
  const [totalCorePermitMenu, setTotalCorePermitMenu] = useState();
  const [corePermitMenu, setCorePermitMenu] = useState();
  const [cranePermitMenu, setCranePermitMenu] = useState();
  const [excavationPermitMenu, setExcavationPermitMenu] = useState();
  const [criticalElevatedPermitMenu, setCriticalElevatedPermitMenu] =
    useState();
  const [generalHotWorkPermitMenu, setGeneralHotWorkPermitMenu] = useState();
  const [aprove, setAprove] = useState();

  const handleMobileNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    // Check if the input starts with 6, 7, 8, or 9 and has a maximum length of 10 digits
    if (/^[6-9]\d{0,9}$|^$/.test(inputNumber)) {
      setMobileNumber(inputNumber);
    }
    console.log(inputNumber);
  };

  const handleNameBeChange = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ""); // Remove non-alphabetic characters
    setName(alphaValue);
    console.log(alphaValue);
  };

  const navigate = useNavigate();
  var name = sessionStorage.getItem(COREPERMIT_VENDOR_NAME);
  var mobileNumber = sessionStorage.getItem(COREPERMIT_MOBILE_NUMBER);
  var date = sessionStorage.getItem(COREPERMIT_DATE);
  const [isEdit, setIsEdit] = useState(false);
  const [iscoreVE, setIscoreVE] = useState();
  console.log(
    "value1" + name + ":::value2::" + mobileNumber + "::value3::" + date
  );

  const [data, setData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [permitId, setPermitId] = useState(-1);
  const handleAddNewPermit = () => {
    console.log("CorePermitM2 :::", window.location.href);
    alert("CorePermitM2 :::");
    window.location.href = "/CorePermitM2";
  };

  const handleEyeIcon = async (permitID) => {
    const dataToSend = {
      mobileNumber: mobileNumber, //"9010893574",
      name: name, //"kalyani",
      PermitID: permitID.toString(), //  "93"
    };
    var URL = getAllPermitsByCorePermitID;
    try {
      setIsLoading(true);
      var response = await axios.post(URL, dataToSend);
      setIsLoading(false);
      console.log("response:::", response);
      if (response.data.statusCode == 200) {
        if (
          response.data.response != undefined &&
          response.data.response != null
        ) {
          setTotalCorePermitMenu(response.data.response);
          console.log("setCorePermitMenu:::", totalCorePermitMenu);
          setCorePermitMenu(response.data.response.core_permit);
          console.log(
            "CorepermitMenu12345::::::::::",
            response.data.response.core_permit
          );
          setCranePermitMenu(response.data.response.crane_permit);
          setCriticalElevatedPermitMenu(
            response.data.response.critical_elevated_permit
          );
          setExcavationPermitMenu(response.data.response.excavation_permit);
          setGeneralHotWorkPermitMenu(
            response.data.response.general_hot_Work_permit
          );
        }
      }
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };
  const fetchData = async () => {
    const dataToSend = {
      name: name,
      mobileNumber: mobileNumber,
      date: date,
    };
    var URL = getCorePermits;
    setIsLoading(true);
    var response = await axios.post(URL, dataToSend);
    if (response.data.statusCode == 200) {
      if (
        response.data.response.corePermits != undefined &&
        response.data.response.corePermits.length > 0
      ) {
        setData(response.data.response.corePermits);
      }
    }
    setIsLoading(false);
    setTimeout(() => {
      console.log("data::", data);
    }, 2000);
  };
  useEffect(() => {}, [iscoreVE]);
  useEffect(() => {
    if (data != undefined && data.response != undefined) {
      console.log("data1::", data.response.corePermits);
    }
  }, [data]);
  useEffect(() => {
    console.log("CorepermitMenu", corePermitMenu);
  }, [corePermitMenu]);

  useEffect(() => {
    // write logic
    // console.log("permitId::",permitId);
  }, [isEdit]);
  useEffect(() => {
    fetchData();
  }, []);

  const handleBackButtonClick = () => {
    // Handle navigation logic here
    navigate(-1);
  };

  const handleCraneoperation = (value) => {
    var ex_true = value.toString().split("/")[0];
    var ex_permitId = value.toString().split("/")[1];
    console.log("handleCraneoperation");
    if (ex_true == "true") {
      window.location =
        "/CranePermitMain?corePermitId=" +
        permitId +
        "/" +
        "true" +
        "/" +
        ex_permitId; //"false";
    } else {
      window.location =
        "/CranePermitMain?corePermitId=" +
        permitId +
        "/" +
        "false" +
        "/" +
        ex_permitId;
    }
    // window.location = "/CranePermitMain";
    window.close();
  };
  const handleCore = (value) => {
    console.log("handleCore");
    var ex_true = value.toString().split("/")[0];
    var ex_permitStatus = value.toString().split("/")[1];

    if (ex_true == "true") {
      window.location =
        "/CorePermitM2?corePermitId=" +
        permitId +
        "/" +
        "true" +
        "/" +
        ex_permitStatus; //"false";
    } else {
      window.location =
        "/CorePermitM2?corePermitId=" +
        permitId +
        "/" +
        "false" +
        "/" +
        ex_permitStatus;
    }
    //to="/CorePermitM2"

    window.close();
  };
  const handleExcavation = (value) => {
    console.log("handleExcavation::" + value);
    var ex_true = value.toString().split("/")[0];
    var ex_permitId = value.toString().split("/")[1];
    console.log(ex_true + " : " + ex_permitId);
    //to="/ExcavationPermit"
    if (ex_true == "true") {
      window.location =
        "/ExcavationPermit?corePermitId=" +
        permitId +
        "/" +
        "true" +
        "/" +
        ex_permitId; //"false";
    } else {
      //alert(ex_permitId)
      window.location =
        "/ExcavationPermit?corePermitId=" +
        permitId +
        "/" +
        "false" +
        "/" +
        ex_permitId;
    }
    window.close();
  };
  const handleCriticalElevated = (value) => {
    var ex_true = value.toString().split("/")[0];
    var ex_permitId = value.toString().split("/")[1];
    console.log("handleCriticalElevated");
    //to="/CriticalElevated"
    if (ex_true === "true") {
      window.location =
        "/CriticalElevated?corePermitId=" +
        permitId +
        "/" +
        "true" +
        "/" +
        ex_permitId; //"false";
    } else {
      window.location =
        "/CriticalElevated?corePermitId=" +
        permitId +
        "/" +
        "false" +
        "/" +
        ex_permitId;
    }
    window.close();
  };
  const handleHotWorkPermit = (value) => {
    var ex_true = value.toString().split("/")[0];
    var ex_permitId = value.toString().split("/")[1];
    console.log("handleHotWorkPermit");
    //to="/HotWorkPermit"
    //  window.location = "/HotWorkPermit2Main";
    if (ex_true === "true") {
      window.location =
        "/HotWorkPermit2Main?corePermitId=" +
        permitId +
        "/" +
        "true" +
        "/" +
        ex_permitId; //"false";
    } else {
      window.location =
        "/HotWorkPermit2Main?corePermitId=" +
        permitId +
        "/" +
        "false" +
        "/" +
        ex_permitId;
    }
    window.close();
  };
  const handleAddNewCore = () => {
    console.log("handleAddNewCore");
    //to="/HotWorkPermit"
    window.location = "/CorePermitM2";
    // window.close();
  };
  const handleAdditionalPermit = () => {
    console.log("handleAdditionalPermit");
    //to="/HotWorkPermit"
    window.location = "/AdditionalPermitOptions?corePermitId=" + permitId;
    sessionStorage.setItem(CorePermitM2_PERMITID, permitId);
    window.close();
  };

  /******************************************************** */
  const handleSubmit = async () => {
    if (TransferToName !== "" && TransferToMobileNumber !== "") {
      const dataToSend = {
        transferFromMobileNumber: mobileNumber,
        transferFromName: name,
        corePermitID: permitId.toString(),
        transferToMobileNumber: TransferToMobileNumber,
        transferToName: TransferToName,
      };
      var URL = TransferCorePermit;
      try {
        setIsLoading(true);

        var response = await axios.post(URL, dataToSend);
        setIsLoading(false);
        console.log("response:::", response);

        if (response.data.statusCode == 200) {
          if (
            response.data.message != undefined &&
            response.data.message != null
          ) {
            setsubmitResponse(response.data.message);
            setShowResponsePopup(true);
            console.log("submitResponse:::", submitResponse);

            // Clear input fields after successful submission
            setName(""); // Assuming you have a state variable for TransferToName
            setMobileNumber(""); // Assuming you have a state variable for TransferToMobileNumber

            sessionStorage.setItem(CorePermitM2_PERMITID, null);
          }
        }
      } catch (error) {
        console.error("Axios Error:", error);
      }
    }
  };
  const handleresponse = () => {
    setShowResponsePopup(false);
    window.location.reload();
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Get day with leading zero
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      date
    ); // Get abbreviated month
    const year = date.getFullYear(); // Get full year
    return `${day}-${month}-${year}`;
  }

  /******************************************************** */
  return (
    <body id="page-top">
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <div
              className="row"
              style={{ backgroundColor: "blue", padding: "7px" }}
            >
              <div className="col-md-3 col-12">
                {/* <span className="arrow" style={{ width: 50, height: 25, padding: 10,color:"white"}}>{'<-'}</span>    */}
                <img
                  src={arrowIcon}
                  onClick={handleBackButtonClick}
                  alt="Back"
                  style={{
                    width: 40,
                    height: 30,
                    padding: 5,
                    verticalAlign: "middle",
                  }}
                />
                <span
                  style={{
                    fontSize: 20,
                    color: "white",
                    marginLeft: 10,
                    marginTop: 12,
                  }}
                >
                  CORE PERMIT Status
                </span>
                {/* <h1 style={{ fontSize: 20, color: 'white'}}>Core Permit</h1> */}
              </div>
            </div>
            {/* <form className="CorePermit_Web-form"> */}
            <div>
              <div className="container-fluid">
                <div className="card border-0">
                  <div className="card-body">
                    <div>
                      <div
                        className="card"
                        style={{
                          padding: "10px",
                          margin: "5px",
                          top: "10px",
                          border: "1px solid #DC275E",
                        }}
                      >
                        <div className="card-body">
                          <div>
                            <div className="row mt-2">
                              <div
                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white`}
                                style={{
                                  height: "50px",
                                  backgroundColor: "#DC275E",
                                  color: "white",
                                  border: "none",
                                  marginTop: "-18px",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                }}
                              >
                                <p>CORE PERMIT</p>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-12">
                                  <div>
                                    <div className="m-1">
                                      <table class="table nstable">
                                        {data != undefined &&
                                          data.length > 0 &&
                                          data.map((item, index) => (
                                            <tr>
                                              <td>
                                                <label
                                                  for="inputPassword"
                                                  className="col-form-label"
                                                >
                                                  {index + 1}.
                                                </label>
                                              </td>
                                              <td>
                                                <label
                                                  for="inputPassword"
                                                  className="col-form-label"
                                                >
                                                  {item.status}
                                                </label>
                                              </td>{" "}
                                              <td>
                                                <div className="centered-date">
                                                  <label
                                                    htmlFor="inputPassword"
                                                    className="col-form-label pt-0"
                                                  >
                                                    {formatDate(item.date)}
                                                  </label>
                                                </div>
                                              </td>
                                              <td>
                                                <button
                                                  type="button"
                                                  class="btn"
                                                  data-toggle="modal"
                                                  data-target="#exampleModal"
                                                  onClick={() => {
                                                    handleEyeIcon(
                                                      item.permitID
                                                    );
                                                    setPermitId(item.permitID);
                                                    setAprove(item.status);
                                                  }}
                                                >
                                                  <i className="eye-blue"></i>
                                                </button>
                                              </td>
                                            </tr>
                                          ))}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div>
                                    <div
                                      class="modal fade"
                                      id="exampleModal"
                                      tabindex="-1"
                                      role="dialog"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                          <div
                                            class="modal-header"
                                            style={{ borderBottom: "none" }}
                                          >
                                            <button
                                              type="button"
                                              className="close btn btn-danger close_btn btn-close-rd"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">
                                                &times;
                                              </span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <div></div>
                                            <div
                                              className="card"
                                              style={{
                                                padding: "10px",
                                                margin: "5px",
                                                top: "10px",
                                                border: "none",
                                              }}
                                            >
                                              <div
                                                className="card-body"
                                                style={{ border: "none" }}
                                              >
                                                <div>
                                                  <div className="row mt-2">
                                                    <ButtonAccordion
                                                      title="View"
                                                      bgColor="#2CC743"
                                                      content={
                                                        <div>
                                                          {corePermitMenu !==
                                                            null &&
                                                            corePermitMenu !==
                                                              undefined &&
                                                            corePermitMenu.length >
                                                              0 &&
                                                            corePermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() => {
                                                                      handleCore(
                                                                        "false" +
                                                                          "/" +
                                                                          ""
                                                                      );
                                                                      setIscoreVE(
                                                                        false
                                                                      );
                                                                    }}
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#2373BE",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Core Permit
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}

                                                          {cranePermitMenu !==
                                                            null &&
                                                            cranePermitMenu !==
                                                              undefined &&
                                                            cranePermitMenu.length >
                                                              0 &&
                                                            cranePermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() =>
                                                                      handleCraneoperation(
                                                                        "false" +
                                                                          "/" +
                                                                          item.permitID
                                                                      )
                                                                    }
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#F58355",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Crane
                                                                    Opeation
                                                                    Permit
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}
                                                          {excavationPermitMenu !==
                                                            null &&
                                                            excavationPermitMenu !==
                                                              undefined &&
                                                            excavationPermitMenu.length >
                                                              0 &&
                                                            excavationPermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() => {
                                                                      handleExcavation(
                                                                        "false" +
                                                                          "/" +
                                                                          item.permitID
                                                                      );
                                                                    }}
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#262828",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Excavation
                                                                    Permit
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}

                                                          {criticalElevatedPermitMenu !==
                                                            null &&
                                                            criticalElevatedPermitMenu !==
                                                              undefined &&
                                                            criticalElevatedPermitMenu.length >
                                                              0 &&
                                                            criticalElevatedPermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() => {
                                                                      handleCriticalElevated(
                                                                        "false" +
                                                                          "/" +
                                                                          item.permitID
                                                                      );
                                                                    }}
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#566060",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Critical
                                                                    Elevated
                                                                    Work
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}
                                                          {generalHotWorkPermitMenu !==
                                                            null &&
                                                            generalHotWorkPermitMenu !==
                                                              undefined &&
                                                            generalHotWorkPermitMenu.length >
                                                              0 &&
                                                            generalHotWorkPermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() =>
                                                                      handleHotWorkPermit(
                                                                        "false" +
                                                                          "/" +
                                                                          item.permitID
                                                                      )
                                                                    }
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#34B85F",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Hot Work
                                                                    Permit
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}
                                                        </div>
                                                      }
                                                      bordercolor="#2CC743"
                                                    />

                                                    <ButtonAccordion
                                                      title="Edit"
                                                      bgColor="#007AFF"
                                                      content={
                                                        <div>
                                                          {corePermitMenu !==
                                                            null &&
                                                            corePermitMenu !==
                                                              undefined &&
                                                            corePermitMenu.length >
                                                              0 &&
                                                            corePermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() => {
                                                                      handleCore(
                                                                        "true" +
                                                                          "/" +
                                                                          item.permitStatus
                                                                      );
                                                                      setIscoreVE(
                                                                        true
                                                                      );
                                                                    }}
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#2373BE",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Core Permit
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}
                                                          {cranePermitMenu !==
                                                            null &&
                                                            cranePermitMenu !==
                                                              undefined &&
                                                            cranePermitMenu.length >
                                                              0 &&
                                                            cranePermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() =>
                                                                      handleCraneoperation(
                                                                        "true" +
                                                                          "/" +
                                                                          item.permitID
                                                                      )
                                                                    }
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#F58355",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Crane
                                                                    Opeation
                                                                    Permit
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}
                                                          {excavationPermitMenu !==
                                                            null &&
                                                            excavationPermitMenu !==
                                                              undefined &&
                                                            excavationPermitMenu.length >
                                                              0 &&
                                                            excavationPermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() => {
                                                                      handleExcavation(
                                                                        "true" +
                                                                          "/" +
                                                                          item.permitID
                                                                      );
                                                                    }}
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#262828",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Excavation
                                                                    Permit
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}
                                                          {criticalElevatedPermitMenu !==
                                                            null &&
                                                            criticalElevatedPermitMenu !==
                                                              undefined &&
                                                            criticalElevatedPermitMenu.length >
                                                              0 &&
                                                            criticalElevatedPermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() => {
                                                                      handleCriticalElevated(
                                                                        "true" +
                                                                          "/" +
                                                                          item.permitID
                                                                      );
                                                                    }}
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#566060",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Critical
                                                                    Elevated
                                                                    Work
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}
                                                          {generalHotWorkPermitMenu !==
                                                            null &&
                                                            generalHotWorkPermitMenu !==
                                                              undefined &&
                                                            generalHotWorkPermitMenu.length >
                                                              0 &&
                                                            generalHotWorkPermitMenu.map(
                                                              (item, index) => (
                                                                <div className="row mt-2">
                                                                  <Link
                                                                    onClick={() =>
                                                                      handleHotWorkPermit(
                                                                        "true" +
                                                                          "/" +
                                                                          item.permitID
                                                                      )
                                                                    }
                                                                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                                    style={{
                                                                      height:
                                                                        "50px",
                                                                      backgroundColor:
                                                                        "#34B85F",
                                                                      color:
                                                                        "white",
                                                                      border:
                                                                        "none",
                                                                    }}
                                                                  >
                                                                    Hot Work
                                                                    Permit
                                                                  </Link>
                                                                </div>
                                                              )
                                                            )}
                                                          <div className="row mt-2">
                                                            <Link
                                                              onClick={
                                                                handleAdditionalPermit
                                                              }
                                                              className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                                              style={{
                                                                height: "50px",
                                                                backgroundColor:
                                                                  "#FF0000",
                                                                color: "white",
                                                                border: "none",
                                                              }}
                                                            >
                                                              Go To Additional
                                                              Permit
                                                            </Link>
                                                          </div>
                                                        </div>
                                                      }
                                                      bordercolor="#007AFF"
                                                    />
                                                    {/* }*/}
                                                    <ButtonAccordion
                                                      title="Transfer Permit"
                                                      bgColor="#FFA200"
                                                      content={
                                                        <div>
                                                          <div>
                                                            <div className="form-group">
                                                              <label for="inputPassword">
                                                                Name
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                >
                                                                  *
                                                                </span>
                                                              </label>
                                                              <div>
                                                                <input
                                                                  className="form-control"
                                                                  id="inputPassword"
                                                                  placeholder="Enter Name"
                                                                  value={
                                                                    TransferToName
                                                                  }
                                                                  onChange={
                                                                    handleNameBeChange
                                                                  }
                                                                />
                                                              </div>
                                                              <label for="inputPassword">
                                                                Mobile Number
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                >
                                                                  *
                                                                </span>{" "}
                                                              </label>
                                                              <div>
                                                                <input
                                                                  className="form-control"
                                                                  id="inputPassword"
                                                                  placeholder="Enter Mobile Number"
                                                                  value={
                                                                    TransferToMobileNumber
                                                                  }
                                                                  onChange={
                                                                    handleMobileNumberChange
                                                                  }
                                                                />
                                                              </div>

                                                              <div className="mt-1">
                                                                <button
                                                                  type="submit"
                                                                  class="btn btn-primary col-12"
                                                                  onClick={
                                                                    handleSubmit
                                                                  }
                                                                >
                                                                  Transfer
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      }
                                                      bordercolor="#FFA200"
                                                    />
                                                  </div>

                                                  <div></div>
                                                </div>
                                                <div></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="card"
                        style={{
                          padding: "10px",
                          margin: "5px",
                          top: "10px",
                          border: "none",
                        }}
                      >
                        <div className="card-body" style={{ border: "none" }}>
                          <div>
                            <div className="row mt-2">
                              <Link
                                onClick={handleAddNewCore}
                                className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white  ${"custom-rounded"}`}
                                style={{
                                  height: "50px",
                                  backgroundColor: "#DC275E",
                                  color: "white",
                                  border: "none",
                                }}
                              >
                                Add New Permit
                              </Link>
                            </div>
                            <div></div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
      <div>
        <Modal
          show={showResponsePopup}
          centered
          backdrop="static"
          keyboard={false}
          size="sm"
          id="nsmodel"
          className="ppopup"
        >
          <Modal.Body>
            <div>
              <div className="row">
                <div>
                  <span
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "10vh",
                    }}
                  >
                    <img src={Approved} alt="save" className="popupicons" />
                  </span>
                </div>
              </div>
              <div>
                <p
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "3vh",
                  }}
                >
                  <h5>Permit Transferred Successfully</h5>
                </p>
              </div>
              <div>
                <p
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0px",
                  }}
                >
                  <Button
                    variant="primary"
                    className={`d-flex justify-content-center align-items-center w-100 btn btn-light text-white mb-2  ${"custom-rounded"}`}
                    style={{
                      backgroundColor: "#007AFF",
                      color: "white",
                      border: "none",
                    }}
                    onClick={handleresponse}
                  >
                    Ok
                  </Button>
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {isLoading && (
        <Loader_MS
          loading={isLoading}
          message={"Fetching Data. Please Wait..!"}
        />
      )}
    </body>
  );
};

export default CorePermitM3;
