import React, { useState } from 'react'
import { useEffect } from 'react'
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from '../../Utilities/utils'

const RenderCheBoxYN = (props) => {

    const handleDataByJson = (type, key, val, opType) => {
        let dataOb = props.data
        dataOb[key] = val
        if (opType == 2) {
            if (key == 'cbYes') {
                if (val == 'true')
                    dataOb['cbNo'] = 'false'
            } else {
                if (val == 'true')
                    dataOb['cbYes'] = 'false'
            }
        } else if (opType == 3) {
            if (key == 'cbYes') {
                if (val == 'true') {
                    dataOb['cbNo'] = ''
                    dataOb['cbNA'] = ''
                }
            } else if (key == 'cbNo') {
                if (val == 'true') {
                    dataOb['cbYes'] = ''
                    dataOb['cbNA'] = ''
                }
            } else {
                if (val == 'true') {
                    dataOb['cbYes'] = ''
                    dataOb['cbNo'] = ''
                }
            }
        }
        props.onDataChange(dataOb)
    }

    return (

        <div className="form-group row">
            <label className="col-12 col-form-label">{props.label}</label>
            <div className="col-12" style={{ display: "flex", flexDirection: "row" }}>
                <div className="form-check form-check-inline d-flex align-items-center">
                    <input className="form-check-input  mt-0" checked={props.data.cbYes == 'true'} onClick={(e) => {
                        handleDataByJson('', 'cbYes', props.data.cbYes == 'true' ? '' : 'true', 3)
                    }} type="checkbox" id={`myuniqueID${props.label}`} value="option1" />
                    <label className="form-check-label  mt-0" for={`myuniqueID${props.label}`}>YES</label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center">
                    <input className="form-check-input mt-0" checked={props.data.cbNo == 'true'} onClick={(e) => {
                        handleDataByJson('', 'cbNo', props.data.cbNo == 'true' ? '' : 'true', 3)
                    }} type="checkbox" id={`myuniqueIDNO${props.label}`} value="option2" />
                    <label className="form-check-label mt-0" for={`myuniqueIDNO${props.label}`}>NO</label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center">
                    <input className="form-check-input mt-0" checked={props.data.cbNA == 'true'} onClick={(e) => {
                        handleDataByJson('', 'cbNA', props.data.cbNA == 'true' ? '' : 'true', 3)
                    }} type="checkbox" id={`myuniqueIDNA${props.label}`} value="option2" />
                    <label className="form-check-label mt-0" for={`myuniqueIDNA${props.label}`}>N/A</label>
                </div>
            </div>
        </div>
    )
}


function PartTHREE(props) {
    const currentDate = new Date().toISOString().split('T')[0];
    const handleScopeElevated = (key, val) => {
        props.setScopeOfElevatedWork((prev) => ({ ...prev, [key]: val }))
    }

    const handleRenderCheck = (key, val) => {
        props.setPartThree(prev => ({ ...prev, [key]: val }))
    }

    const handleFirstQuestion = (key, val) => {
        const keys = Object.keys(props.data.soilClasification)
        const soilQ = props.data.soilClasification
        keys.forEach(i => {
            soilQ[i] = i == key ? val : ''
            //soilQ[i] = i == key ? val : 'false' 
        })
        props.setPartThree(prev => ({ ...prev, soilClasification: soilQ }))
    }



    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">

                    <div>

                        <div className="row" style={{ margin: "-31px -30px 3px -30px", backgroundColor: "#C2C9C8", color: "white" }}>
                            <div>
                                <p>Excavation Competent Person Completes this section</p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-12 col-form-label">Soll Classification*(check one): </label>
                            <div className="col-12">
                                <div className="form-check form-check-inline col-12 d-flex align-items-center">
                                    <input className="form-check-input  mt-0" checked={props.data.soilClasification.StableRock == 'true'} onClick={() => {
                                        handleFirstQuestion('StableRock', props.data.soilClasification.StableRock == 'true' ? 'false' : 'true')
                                    }} type="checkbox" id="soilCheck1" value="option1" />
                                    <label className="form-check-label  mt-0" for="soilCheck1">Stable rock</label>
                                </div>
                                <div className="form-check form-check-inline col-12 d-flex align-items-center">
                                    <input className="form-check-input mt-0" checked={props.data.soilClasification.typeA == 'true'} type="checkbox" onClick={() => {
                                        handleFirstQuestion('typeA', props.data.soilClasification.typeA == 'true' ? 'false' : 'true')
                                    }} id="soilCheck2" value="option2" />
                                    <label className="form-check-label mt-0" for="soilCheck2">Type A</label>
                                </div>
                                <div className="form-check form-check-inline col-12 d-flex align-items-center">
                                    <input className="form-check-input mt-0" checked={props.data.soilClasification.typeB == 'true'} type="checkbox" onClick={() => {
                                        handleFirstQuestion('typeB', props.data.soilClasification.typeB == 'true' ? 'false' : 'true')
                                    }} id="soilCheck3" value="option2" />
                                    <label className="form-check-label mt-0" for="soilCheck3">Type B</label>
                                </div>
                                <div className="form-check form-check-inline col-12 d-flex align-items-center">
                                    <input className="form-check-input mt-0" checked={props.data.soilClasification.typeC == 'true'} type="checkbox" onClick={() => {
                                        handleFirstQuestion('typeC', props.data.soilClasification.typeC == 'true' ? 'false' : 'true')
                                    }} id="soilCheck4" value="option2" />
                                    <label className="form-check-label mt-0" for="soilCheck4">Type C</label>
                                </div>

                            </div>
                        </div>


                        {/* <RenderCheBoxYN label={props.data.isTheescavation.cbTitle} data={props.data.isTheescavation} onDataChange={(dataM) => {
                            console.log(dataM, 'dataM')
                            handleRenderCheck('isTheescavation', dataM)
                        }} ></RenderCheBoxYN>  */}
                        <RenderCheBoxYN
                            label={(
                                <span>
                                    {props.data.isTheescavation.cbTitle}
                                    <span className='required'> *</span>
                                </span>
                            )}
                            data={props.data.isTheescavation}
                            onDataChange={(dataM) => {
                                console.log(dataM, 'dataM');
                                handleRenderCheck('isTheescavation', dataM);
                            }}
                        ></RenderCheBoxYN>



                        <RenderCheBoxYN label={props.data.haveTheOwners.cbTitle}
                            data={props.data.haveTheOwners} onDataChange={(dataM) => {
                                handleRenderCheck('haveTheOwners', dataM)
                            }} ></RenderCheBoxYN>


                        <RenderCheBoxYN label={props.data.hasDetection.cbTitle} data={props.data.hasDetection} onDataChange={(dataM) => {
                            handleRenderCheck('hasDetection', dataM)
                        }} ></RenderCheBoxYN>



                        <RenderCheBoxYN label={props.data.hasACheck.cbTitle} data={props.data.hasACheck} onDataChange={(dataM) => {
                            handleRenderCheck('hasACheck', dataM)
                        }} ></RenderCheBoxYN>


                        <RenderCheBoxYN label={props.data.adeqacy.cbTitle} data={props.data.adeqacy} onDataChange={(dataM) => {
                            handleRenderCheck('adeqacy', dataM)
                        }} ></RenderCheBoxYN>


                        <RenderCheBoxYN label={props.data.checkBeen.cbTitle} data={props.data.checkBeen} onDataChange={(dataM) => {
                            handleRenderCheck('checkBeen', dataM)
                        }} ></RenderCheBoxYN>

                        <div className="form-group">
                            <label >If other obstructions have been found,list them.</label>
                            <div>
                                <input value={props.data.obstructions.description} onChange={(e) => {
                                    props.setPartThree(prev => ({ ...prev, obstructions: { ...prev.obstructions, 'description': e.target.value } }))
                                }} className="form-control" id="inputPassword" placeholder="" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label >Allowable steps:</label>
                            <div>
                                <input value={props.data.allowable.description} onChange={(e) => {
                                    props.setPartThree(prev => ({ ...prev, allowable: { ...prev.allowable, 'description': e.target.value } }))
                                }} className="form-control" id="inputPassword" placeholder="" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label >Describe in detail the risk control measure for the excavation.</label>
                            <div>
                                <input value={props.data.describe.description} onChange={(e) => {
                                    props.setPartThree(prev => ({ ...prev, describe: { ...prev.describe, 'description': e.target.value } }))
                                }} className="form-control" id="inputPassword" placeholder="" />
                            </div>
                        </div>
                        <div className="Corper-subtxt" style={{ color: "white", backgroundColor: "#C2C9C8" }}>

                            <p className="card-subheading" style={{ color: "white", backgroundColor: "#C2C9C8" }}>Size of Excavation:</p>
                        </div>
                        <div style={{ backgroundColor: "#D4D4D4", marginRight: "-20px", marginLeft: "-20px", color: "#000" }}>
                            <div className="col-12">
                                <div className="form-group" style={{ margin: "7px", padding: "10px" }}>
                                    <label>Depth</label>
                                    <div>
                                        <input className="form-control col-12" id="es" placeholder="" value={props.data?.depthval || ""} onChange={(e) => handleRenderCheck('depthval', e.target.value)} />
                                    </div>
                                    <label>Width</label>
                                    <div>
                                        <input className="form-control col-12" id="es" placeholder="" value={props.data?.widthval || ""} onChange={(e) => handleRenderCheck('widthval', e.target.value)} />
                                    </div>
                                    <label>Height</label>
                                    <div>
                                        <input className="form-control col-12" id="es" placeholder="" value={props.data?.heightval || ""} onChange={(e) => handleRenderCheck('heightval', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ overflowX: "auto", display: "none" }}>
                            <table class="table partthree" style={{ width: "100%" }}>

                                <tbody className='thead-dark'>
                                    <tr>
                                        <th scope="row" style={{ backgroundColor: "#C2C9C8" }}>Depth</th>
                                        <td>
                                            {/* <input type="text" class="form-control col-12" id="inputPassword"
                                         placeholder="" style={{ width: "50px" }} /> */}
                                            {/* <input value={props.data.sizeofexcavation.depthval} onChange={(e) => {
                                                props.setPartThree(prev => ({ ...prev, sizeofexcavation: { ...prev.sizeofexcavation, 'depthval': e.target.value } }))
                                            }} className="form-control" id="inputPassword" placeholder="" style={{ width: "50px" }} /> */}
                                            <input
                                                className="form-control col-12"
                                                id="es"
                                                placeholder=""
                                                style={{ width: "50px" }}
                                                value={props.data?.depthval || ""}
                                                onChange={(e) => handleRenderCheck('depthval', e.target.value)}
                                            />
                                        </td>
                                        <th>Width</th>
                                        <td>
                                            {/* <input type="text" class="form-control col-12" id="inputPassword" placeholder=""
                                             style={{ width: "50px" }} /> */}
                                            <input
                                                className="form-control col-12"
                                                id="es"
                                                placeholder=""
                                                style={{ width: "50px" }}
                                                value={props.data?.widthval || ""}
                                                onChange={(e) => handleRenderCheck('widthval', e.target.value)}
                                            />
                                        </td>
                                        <th>Height</th>
                                        <td>
                                            {/* <input type="text" class="form-control col-12" id="inputPassword"
                                       placeholder="" style={{ width: "50px" }} /> */}

                                            <input className="form-control col-12" id="es" placeholder="" style={{ width: "50px" }} value={props.data?.heightval || ""} onChange={(e) => handleRenderCheck('heightval', e.target.value)} />

                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {
                            props.data.list.map((dataItem, index) => (
                                <RenderCheBoxYN label={dataItem.cbTitle}
                                    data={dataItem}
                                    onDataChange={(dataM) => {
                                        const newList = props.data.list.map((i, j) => {
                                            if (index == j) {
                                                return dataM
                                            } else {
                                                return i
                                            }
                                        })
                                        handleRenderCheck('list', newList)
                                    }} ></RenderCheBoxYN>
                            ))
                        }


                        <div className="form-group row">
                            <label className="col-12 col-form-label">If the depth of the excavation is 5feet(1.5 meters)or more,check the applicabl OSHA appendix below:   </label>
                            <div className="col-12">

                                {
                                    props.data.depthOfExcavation.list.map((indItemSpe, index) => (
                                        <>
                                            {
                                                indItemSpe.cbTitle != '' && <div className="form-check form-check-inline col-12 d-flex align-items-center">
                                                    <input className="form-check-input mt-0" checked={indItemSpe.cbChecked == 'true'} onClick={() => {
                                                        // handlePartTwoJson('indicate', 'checked', !indItemSpe.checked, index)
                                                        const roofObj = { ...props.data }
                                                        const newObj = roofObj['depthOfExcavation']
                                                        const newList = newObj['list'].map((i, j) => {
                                                            if (j == index) {
                                                                return { ...i, 'cbChecked': indItemSpe.cbChecked == 'true' ? 'false' : 'true' }
                                                            } else {
                                                                return i
                                                            }
                                                        });
                                                        newObj['list'] = newList

                                                        props.setPartThree(prev => ({ ...prev, 'depthOfExcavation': newObj }))
                                                    }} type="checkbox" id={`indItemSpe@${index}`} value="option1" />
                                                    <label className="form-check-label  mt-0" for={`indItemSpe@${index}`} >{indItemSpe.cbTitle}</label>
                                                </div>
                                            }
                                        </>
                                    ))
                                }

                            </div>
                        </div>
                        <div style={{ fontWeight: "600" }}>
                            <p>NOTE:Protective Systems for excavations deeper than 20ft(6meters)deep must be designedby a registered professional engineer.</p>
                        </div>
                        <div className="form-group">
                            <label>The excavation requires no protective systems for the following reasons(list):</label>
                            <div>
                                {/* <input className="form-control" id="inputPassword" placeholder="" /> */}
                                <input
                                    className="form-control"
                                    id="es"
                                    placeholder=""
                                    value={props.data?.escavationval || ""}
                                    onChange={(e) => handleRenderCheck('escavationval', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Competent person(please print your name):</label>

                            <div>
                                <input
                                    className="form-control"
                                    id="cv"
                                    type="text"
                                    placeholder=""
                                    value={props.data?.inputValue || ""}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const alphabetOnly = inputValue.replace(/[^a-zA-Z .]/g, ''); // Remove non-alphabet characters
                                        handleRenderCheck('inputValue', alphabetOnly);
                                    }}
                                />
                            </div>

                        </div>
                        <div className="form-group">
                            <label >Date</label>
                            <div>
                                
                                <DatePicker
                                    className="form-control"
                                    id="date"
                                    customInput={<CustomDatePickerInput />}
                                    placeholderText="Select a date"
                                    dateFormat="dd-MMM-yyyy"
                                    selected={props.data?.date ? new Date(props.data.date) : null}
                                    minDate={new Date(currentDate)}
                                    onChange={(date) => {
                                        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);                                      
                                        const formattedDate = adjustedDate.toISOString().split('T')[0];           
                                        handleRenderCheck('date', formattedDate)
                                    }}
                                />
                                
                               

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default PartTHREE;