import React, { useState, useEffect, useRef } from "react";
function RequiredPrecautions(props) {
    const handleRequiredPrecautions = (mkey, val, key, index) => {
        const newArr = props.data.list;
        newArr[index][key] = val
        if (mkey == 'cbYes' && val == 'true') {
            newArr[index]['cbNA'] = ''
            newArr[index]['cbYes'] = 'true'
        } 
        if (mkey == 'cbNA' && val == 'true') {
            newArr[index]['cbNA'] = 'true'
            newArr[index]['cbYes'] = ''
        } 
        // else {
        //     newArr[index]['cbYes'] = 'false'
        // }
        props.setRequiredPrecautions(prev => ({ ...prev, [index]: newArr }))
    }
    console.log(  "listedItem",props.data.list)
    return (
        <div className="m-2">
            <div className="row">
                <div className="col-md-6 col-12">
                    <div>

                        <div>

                            <p style={{ color: "#000000", fontWeight: "600" }}>Determine Yes or NA.STOP IF"NO" <span  className='required'> * </span></p>
                        </div>
                        <div>
                            {
                                props.data.list.map((item, index) => (
                                
                                    <div class="form-group" key={index}>
                                        <p>{item.cbTitle}</p>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div class="form-check m-2">
                                                <input class="form-check-input" checked={item.cbYes == 'true'} onClick={() => {
                                                    handleRequiredPrecautions('cbYes', item.cbYes == 'true' ? 'false' : 'true', 'cbYes', index)
                                                   
                                                }} type="checkbox" name="answer1" id={`listReqPecActionYES_${index}`} value="option1" />
                                                <label class="form-check-label" for={`listReqPecActionYES_${index}`} >YES</label>
                                                {/* <label class="form-check-label" for={`importListIndex${index}`}>YES</label> */}
                                            </div>

                                            <div class="form-check m-2">
                                                <input checked={item.cbNA == 'true'} onClick={() => {
                                                    // handleRequiredPrecautions(index, item.cbNA == 'true' ? 'false' : 'true', 'list', 'cbNA')
                                                    handleRequiredPrecautions('cbNA', item.cbNA == 'true' ? 'false' : 'true', 'cbNA', index)
                                                }} class="form-check-input" type="checkbox" name="answer2" id={`listReqPecActionNA_${index}`} value="option2" />

                                                <label class="form-check-label" for={`listReqPecActionNA_${index}`}>N/A</label>

                                            </div>
                                            </div>
                                            <div>
                                            <div style={{ marginTop: "10px" }}>
                                                <label  class="col-12 col-form-label">
                                                    {/* {item.cbTitle} */}
                                                    {(item.title1 !== undefined && item.title2 !== undefined && item.title3 !== undefined) &&
                                                        <>
                                                            <div>
                                                                <div>
                                                                    PURGE GAS:
                                                                    <input className="form-control" type="text" value={item.title1} onChange={(e) => {
                                                                        handleRequiredPrecautions('list', e.target.value, 'title1', index)
                                                                    }}/>
                                                                </div>

                                                                <div >
                                                                    Purge method:
                                                                    <input className="form-control" type="text" value={item.title2} onChange={(e) => {
                                                                        handleRequiredPrecautions('list', e.target.value, 'title2', index)
                                                                    }} />
                                                                </div>

                                                                <div>
                                                                    Ventilation requirements:
                                                                    <input className="form-control" type="text" value={item.title3} onChange={(e) => {
                                                                        handleRequiredPrecautions('list', e.target.value, 'title3', index)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                       


                    </div>
                </div>
            </div>
        </div>
    );
}

export default RequiredPrecautions;
