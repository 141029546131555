import { MOBILE_NUMBER,USER_PROFILE } from "../Utils";

export async function GetApiHeaders(){
    var mobileNumber,userId= "";
    var details = sessionStorage.getItem(USER_PROFILE);

    if (details != "" && details!=undefined && details !=null){

        var userDetails = JSON.parse(details);
        mobileNumber=userDetails.mobileNumber;
        userId=userDetails.userId;
        console.log("GetApiHeaders",mobileNumber);
    }

    var headers = {

        Accept: 'application/json',

        'Content-Type': 'application/json',

        "userId" : userId.toString(),

        'mobileNumber' : mobileNumber,
        
    };

    console.log(headers)

    return headers;

}