import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAssembleLocation } from "../../Utilities/URLCONSTANTS";
import Select from "react-select";

function CorePermitEmergency(props) {
  const [assemblyLocations, setAssemblyLocations] = useState([]);

  const handleGeneralInfo = (selectedOption) => {
    // alert("selectedOption:" + selectedOption.name);
    if (selectedOption) {
      const obj = {
        assemblyLocation: selectedOption.name,
        phoneNumber: selectedOption.mobileNumber,
      };
      props.setEmergency(obj);
    }
  };

  const getLocations = async () => {
    const apiUrl = getAssembleLocation;
    try {
      const response = await axios.get(apiUrl);
      console.log("responseLocation", response);
      setAssemblyLocations(response.data.response.assembleLocations);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getLocations();
    console.log(props.data, "Emergency");
  }, []);

  return (
    <div className="m-2">
      <div className="row">
        <div>
          <div className="form-group">
            <label htmlFor="inputAssemblyLocation">
              Assembly Location <span style={{ color: "red" }}> *</span>
            </label>
            <br />
            <Select
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              // isClearable={true}
              menuPlacement="auto"
              options={assemblyLocations}
              value={assemblyLocations.find(
                (option) => option.name === props.data.assemblyLocation
              )}
              onChange={handleGeneralInfo}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.code}
              placeholder="Select Assembly Location & Search..."
            />
          </div>

          <div className="form-group">
            <label htmlFor="inputPhoneNumber">
              Phone Number<span style={{ color: "red" }}> *</span>
            </label>
            <div>
              <input
                className="form-control"
                id="ph"
                type="text"
                maxLength={12}
                readOnly={props.approvalStatus}
                value={props.data.phoneNumber}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorePermitEmergency;
