import React, { useEffect, useState } from 'react';
import { coreProtectionConstant } from '../../Utilities/corePermit.constant';
function CorePermitM2Protection(props) {
    const [protection, setProtection] = useState(props.data)

    useEffect(() => {
        setProtection(props.data)
    }, [])

    useEffect(() => {
        props.onDataChange(protection)
    }, [protection])

    const handleCheckBox = (id, list) => {

        if (list == 'headFace') {
            const updatedHeadFaceList = protection.headFaceList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setProtection((prev) => ({ ...prev, headFaceList: updatedHeadFaceList }))
        } else if (list == 'handsList') {
            const updatedHandsList = protection.handsList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setProtection((prev) => ({ ...prev, handsList: updatedHandsList }))
        } else if (list == 'armsBodyList') {
            const updatedArmsBodyList = protection.armsBodyList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setProtection((prev) => ({ ...prev, armsBodyList: updatedArmsBodyList }))
        } else if (list == 'respiratoryList') {
            const updatedRespiratoryList = protection.respiratoryList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setProtection((prev) => ({ ...prev, respiratoryList: updatedRespiratoryList }))
        } else if (list == 'feetLegsList') {
            const updatedFeetLegList = protection.feetLegsList.map(opItem => ({ ...opItem, checked: opItem.id === id ? !opItem.checked : opItem.checked }))
            setProtection((prev) => ({ ...prev, feetLegsList: updatedFeetLegList }))
        }
    }

    const handleInput = (id, list, value) => {
        console.log('updeted', value)
        if (list == 'headFace') {
            const updatedHeadFaceList = protection.headFaceList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setProtection((prev) => ({ ...prev, headFaceList: updatedHeadFaceList }))
        } else if (list == 'handsList') {
            const updatedHandsList = protection.handsList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setProtection((prev) => ({ ...prev, handsList: updatedHandsList }))
        } else if (list == 'armsBodyList') {
            const updatedArmsBodyList = protection.armsBodyList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setProtection((prev) => ({ ...prev, armsBodyList: updatedArmsBodyList }))
        } else if (list == 'respiratoryList') {
            const updatedRespiratoryList = protection.respiratoryList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setProtection((prev) => ({ ...prev, respiratoryList: updatedRespiratoryList }))
        } else if (list == 'feetLegsList') {
            const updatedFeetLegList = protection.feetLegsList.map(opItem => {
                if (id == opItem.id) {
                    return { ...opItem, 'inputValue': value }
                } else {
                    return opItem
                }
            })
            setProtection((prev) => ({ ...prev, feetLegsList: updatedFeetLegList }))
        }
    }


    const handleComments = (protection, key, value) => {
        // alert("aaa");
        // debugger;
        var comments = protection.comments;
        comments[key] = value;
        var temppeopleData = comments;
        setProtection((prev) => ({ ...prev, comments }))
        // alert(protection.Comments.aa);
    }
    // const handleComments = (key, val) => {
    //     props.setProtection((prev) => ({ ...prev, [key]: val }))
    // }

  
    return (
        <div>
            <div className="row">
                <div className="col-12 col-lg-2">
                    <div>
                        <div className="Corper-subtxt protection-sub-bg-color">
                            <p className="card-subheading protection-sub-bg-color">Head/Face<span style={{ color: "blue" }}>*</span></p>
                        </div>
                        {
                            protection.headFaceList.map(headItem => (
                                <>

                                    {(headItem.cbTitle && headItem.cbTitle !== "") &&
                                        <div class="form-group row mt-2">
                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                <div class="col-12 form-check form-check-inline">
                                                    <input class="form-check-input  mt-1"
                                                            disabled={props.approvalStatus}
                                                        checked={headItem.checked === true}
                                                        onChange={() => { handleCheckBox(headItem.id, 'headFace') }} type="checkbox" id={`headFaceList${headItem.id}`} value="option1" />
                                                    <label class="form-check-label  mt-0" for={`headFaceList${headItem.id}`}>{headItem.cbTitle}</label>


                                                </div>
                                            </div>
                                        </div>}
                                    {
                                        headItem && headItem.inputAvaiable && <div class="form-group row mt-0">
                                            <div>
                                                <input type='text'  readOnly={props.approvalStatus} value={headItem.inputValue} onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(headItem.id, 'headFace', e.target.value)
                                                }}
                                                    class="form-control col-12" id="inputPassword"
                                                    placeholder="" />
                                            </div>
                                           
                                        </div>}

                                </>
                            ))
                        }
                      


                    </div>
                </div>

                <div className="col-12 col-lg-2">
                    <div>
                        <div className="Corper-subtxt protection-sub-bg-color">
                            <p className="card-subheading protection-sub-bg-color">Hands</p>
                        </div>

                       
  {
                            protection.handsList.map(handItem => (
                                <>

                                    {(handItem.cbTitle && handItem.cbTitle !== "") &&
                                        <div class="form-group row mt-2">
                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                <div class="col-12 form-check form-check-inline">
                                                    <input class="form-check-input  mt-1"
                                                            disabled={props.approvalStatus}
                                                        checked={handItem.checked === true}
                                                        onChange={() => { handleCheckBox(handItem.id, 'handsList') }} type="checkbox" id={`headFaceList${handItem.id}`} value="option1" />
                                                    <label class="form-check-label  mt-0" for={`headFaceList${handItem.id}`}>{handItem.cbTitle}</label>


                                                </div>
                                            </div>
                                        </div>}
                                    {
                                        handItem && handItem.inputAvaiable && <div class="form-group row mt-0">
                                            <div>
                                                <input type='text' value={handItem.inputValue} 
                                                        readOnly={props.approvalStatus} onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(handItem.id, 'handsList', e.target.value)
                                                }}
                                                    class="form-control col-12" id="inputPassword"
                                                    placeholder="" />
                                            </div>
                                          
                                        </div>}

                                </>
                            ))
                        }



                    </div>
                </div>
                <div className="col-12 col-lg-2">
                    <div>
                        <div className="Corper-subtxt protection-sub-bg-color">
                            <p className="card-subheading protection-sub-bg-color">Arms/Body</p>
                        </div>


             
  {
                            protection.armsBodyList.map(armsItem => (
                                <>

                                    {(armsItem.cbTitle && armsItem.cbTitle !== "") &&
                                        <div class="form-group row mt-2">
                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                <div class="col-12 form-check form-check-inline">
                                                    <input class="form-check-input  mt-1"
                                                            disabled={props.approvalStatus}
                                                        checked={armsItem.checked === true}
                                                        onChange={() => { handleCheckBox(armsItem.id, 'armsBodyList') }} type="checkbox" id={`headFaceList${armsItem.id}`} value="option1" />
                                                    <label class="form-check-label  mt-0" for={`headFaceList${armsItem.id}`}>{armsItem.cbTitle}</label>


                                                </div>
                                            </div>
                                        </div>}
                                    {
                                        armsItem && armsItem.inputAvaiable && <div class="form-group row mt-0">
                                            <div>
                                                <input type='text' value={armsItem.inputValue}
                                                        readOnly={props.approvalStatus}  onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(armsItem.id, 'armsBodyList', e.target.value)
                                                }}
                                                    class="form-control col-12" id="inputPassword"
                                                    placeholder="" />
                                            </div>
                                          
                                        </div>}

                                </>
                            ))
                        }



                    </div>
                </div>
                <div className="col-12 col-lg-2">
                    <div>
                        <div className="Corper-subtxt protection-sub-bg-color">
                            <p className="card-subheading protection-sub-bg-color">Respiratory </p>
                        </div>               

{
                            protection.respiratoryList.map(resItem => (
                                <>

                                    {(resItem.cbTitle && resItem.cbTitle !== "") &&
                                        <div class="form-group row mt-2">
                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                <div class="col-12 form-check form-check-inline">
                                                    <input class="form-check-input  mt-1"
                                                            disabled={props.approvalStatus}
                                                        checked={resItem.checked === true}
                                                        onChange={() => { handleCheckBox(resItem.id, 'respiratoryList') }} type="checkbox" id={`headFaceList${resItem.id}`} value="option1" />
                                                    <label class="form-check-label  mt-0" for={`headFaceList${resItem.id}`}>{resItem.cbTitle}</label>


                                                </div>
                                            </div>
                                        </div>}
                                    {
                                        resItem && resItem.inputAvaiable && <div class="form-group row mt-0">
                                            <div>
                                                <input type='text' value={resItem.inputValue} 
                                                        readOnly={props.approvalStatus} onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(resItem.id, 'respiratoryList', e.target.value)
                                                }}
                                                    class="form-control col-12" id="inputPassword"
                                                    placeholder="" />
                                            </div>
                                          
                                        </div>}

                                </>
                            ))
                        }
                    </div>
                </div>
                <div className="col-12 col-lg-2">
                    <div>
                        <div className="Corper-subtxt protection-sub-bg-color">
                            <p className="card-subheading protection-sub-bg-color">Feet/Legs</p>
                        </div>



                          {
                            protection.feetLegsList.map(feetItem => (
                                <>

                                    {(feetItem.cbTitle && feetItem.cbTitle !== "") &&
                                        <div class="form-group row mt-2">
                                            <div class="col-12" style={{ display: "flex", flexDirection: "row" }}>
                                                <div class="col-12 form-check form-check-inline">
                                                    <input class="form-check-input  mt-1"
                                                            disabled={props.approvalStatus}
                                                        checked={feetItem.checked === true}
                                                        onChange={() => { handleCheckBox(feetItem.id, 'feetLegsList') }} type="checkbox" id={`headFaceList${feetItem.id}`} value="option1" />
                                                    <label class="form-check-label  mt-0" for={`headFaceList${feetItem.id}`}>{feetItem.cbTitle}</label>


                                                </div>
                                            </div>
                                        </div>}
                                    {
                                        feetItem && feetItem.inputAvaiable && <div class="form-group row mt-0">
                                            <div>
                                                <input type='text' value={feetItem.inputValue} 
                                                        readOnly={props.approvalStatus} onChange={(e) => {
                                                    console.log(e, e.target.value)
                                                    handleInput(feetItem.id, 'feetLegsList', e.target.value)
                                                }}
                                                    class="form-control col-12" id="inputPassword"
                                                    placeholder="" />
                                            </div>
                                          
                                        </div>}

                                </>
                            ))
                        }
                    </div>
                </div>

            </div>
            <div class="form-group">
                {/* <label for="inputPassword" style={{ backgroundColor: "lightblue" }}
                ><h6>Comments: <span>Reason for upgrading or downgrading PPE</span></h6></label> */}
                <div className="Corper-subtxt protection-sub-bg-color">
                    <p className="card-subheading protection-sub-bg-color"><b>Comments:</b>Reason for upgrading or downgrading PPE</p>
                </div>
                <div>
                     
                        
                         <input class="form-control" 
                                 readOnly={props.approvalStatus} id="inputPassword" type='text' placeholder=""
                        value={protection.comments.commentValue || ''}
                        onChange={(e) => 
                        handleComments(protection, 'commentValue', e.target.value)} /> 
                </div>
            </div>


            
          


        </div>

    )
}

export default CorePermitM2Protection